import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { first } from "rxjs/operators";
import { IErrorResponse } from "@model/errorResponse";
import { ISuccessResponse } from "@model/successResponse";
import { IForgotPassword } from "@model/userRegistrationLogin";
import { BaseComponent } from "@components/base.component";
import { AutoUnsubscribe } from "@decorator/autounsubscribe";
import { RegistrationService } from "@services/registration.service";

@Component({
  selector: "app-otp-verification",
  templateUrl: "./otp-verification.component.html",
  styleUrls: ["./otp-verification.component.scss"],
})
@AutoUnsubscribe()
export class OtpVerificationComponent extends BaseComponent implements OnInit {
  loading = false;
  isServiceRequester: boolean;
  submitted = false;
  requesterTypeURL: string;
  token = "";
  email = "";
  public model = {
    field1: "",
    field2: "",
    field3: "",
    field4: "",
  };
  isAllFieldSubmitted = false;
  sendMail = false;
  timer = 60;
  userRole = this.getRoutesRole();

  constructor(
    private registrationService: RegistrationService,
    private route: ActivatedRoute
  ) {
    super();
    this.isServiceRequester = this.isServiceRequesterRoute;
    this.requesterTypeURL = this.requesterType();
    this.userRole = this.getRoutesRole();
    this.email = this.route.snapshot.queryParams["email"]
      ? decodeURIComponent(this.route.snapshot.queryParams["email"])
      : "";
    this.token = this.route.snapshot.queryParams["token"]
      ? decodeURIComponent(this.route.snapshot.queryParams["token"])
      : "";
    this.intervalFunction();
  }

  /**
   * Intial Function
   */
  ngOnInit(): void {
    if (!this.email) {
      this.router.navigate([this.userRole + "/forget-password"]);
    }
    this.isServiceRequester = this.isServiceRequesterRoute;
  }

  /**
   * OTP Verification
   */
  public onOtpVerification(form: NgForm) {
    this.submitted = true;

    if (
      this.model.field1 &&
      this.model.field2 &&
      this.model.field3 &&
      this.model.field4
    ) {
      this.isAllFieldSubmitted = true;
    }
    // stop here if form is invalid
    if (form.invalid) {
      return;
    }
    this.spinner.showLoader();
    const otpData = {
      token: this.token,
      code: Object.values(this.model).join(""),
    };

    this.registrationService
      .otpVerify(otpData)
      .pipe(first())
      .subscribe(
        (data: ISuccessResponse) => {
          this.spinner.hideLoader();
          if (data.status_code === this.statusCode.OK) {
            this.toastr.success(data.message);
            const encodedToken = encodeURIComponent(this.token);
            this.router.navigateByUrl(
              this.userRole + `/reset-password?token=${encodedToken}`
            );
          }
        },
        (error: IErrorResponse) => {
          this.spinner.hideLoader();
          this.errorHandler(this.toastr, this.translateService, error);
        }
      );
  }

  /**
   * Interval Function
   */
  public intervalFunction() {
    this.timer = 60;
    const intervalTimer = setInterval(() => {
      this.timer--;
      if (this.timer === 0) {
        clearInterval(intervalTimer);
        this.sendMail = true;
      }
    }, 1000);
  }

  /**
   * Send Mail Again
   */
  public onSendEmail() {
    if (!this.sendMail) {
      return; // Do nothing if the link/button is disabled
    }

    this.spinner.showLoader();
    this.registrationService
      .forgetPassword({ email: this.email })
      .pipe(first())
      .subscribe(
        (data: IForgotPassword) => {
          this.spinner.hideLoader();
          if (data.status_code === this.statusCode.OK) {
            this.toastr.success(data.message);
            this.token = data.data.token;
            this.sendMail = false; // Disable the link/button when sending email
            this.intervalFunction(); // Start the timer
          }
        },
        (error: IErrorResponse) => {
          this.spinner.hideLoader();
          this.errorHandler(this.toastr, this.translateService, error);
        }
      );
  }

  /**
   * Focus on next field
   */
  moveToNextInput(nextField: string) {
    return document?.getElementById(nextField)?.focus();
  }
}
