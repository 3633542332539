import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { NgxPermissionsService } from "ngx-permissions";
import { Observable } from "rxjs";
import { CONFIG } from "../config/app-config";
import { RoutesRole } from "../config/app-constants";
import { userPermissionsGet } from "../utils/enum-const";
import { EncrDecrService } from "../_services/encr-decr.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private permissionsService: NgxPermissionsService,
    private EncrDecr: EncrDecrService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const subAdminPermission = JSON.parse(
      localStorage.getItem("sub_admin_permissions") as string
    );
    const routePermission = next.data["permission"] || null;
    const currentUserProvider = this.getUserFromLocalStorage(
      "currentUserProvider"
    );
    const currentUserRequester = this.getUserFromLocalStorage(
      "currentUserRequester"
    );
    const currentAdmin = this.getUserFromLocalStorage("currentAdmin");
    const currentSubAdmin = this.getUserFromLocalStorage("currentSubAdmin");
    const url = state.url;
    // Permission handling
    this.handleSubAdminPermissions(currentSubAdmin, subAdminPermission, url);
    this.handleAdminPermissions(currentAdmin, url);
    this.handleUserPermissions(currentUserRequester, currentUserProvider, url);

    const isAdminRoute = url.startsWith(RoutesRole.ADMIN_ROUTE);
    switch (true) {
      case currentSubAdmin &&
        routePermission !== null &&
        isAdminRoute &&
        !subAdminPermission?.includes(routePermission):
        this.router.navigate(["admin/dashboard"]);
        return false;

      case !currentUserRequester &&
        url.startsWith(RoutesRole.SERVICE_REQUESTER):
        this.router.navigate(["requester/login"]);
        return false;

      case !currentUserProvider && url.startsWith(RoutesRole.SERVICE_PROVIDER):
        this.router.navigate(["provider/login"]);
        return false;

      case !currentAdmin && !currentSubAdmin && isAdminRoute:
        this.router.navigate(["admin/login"]);
        return false;

      case this.hasPermission(
        url,
        RoutesRole.SERVICE_REQUESTER,
        currentUserRequester
      ):
      case this.hasPermission(
        url,
        RoutesRole.SERVICE_PROVIDER,
        currentUserProvider
      ):
      case isAdminRoute &&
        (this.hasPermission(url, RoutesRole.ADMIN_ROUTE, currentAdmin) ||
          this.hasPermission(url, RoutesRole.ADMIN_ROUTE, currentSubAdmin)):
        return true;

      default:
        this.router.navigate(["/403"]);
        return false;
    }
  }

  private getUserFromLocalStorage(key: string): string {
    return this.EncrDecr.get(
      CONFIG.EncrDecrKey as string,
      localStorage.getItem(key)
    );
  }

  private handleSubAdminPermissions(
    currentSubAdmin: string,
    subAdminPermission: string[],
    url: string
  ): void {
    if (
      currentSubAdmin &&
      url.startsWith(RoutesRole.ADMIN_ROUTE) &&
      subAdminPermission?.length > 0
    ) {
      this.permissionsService.loadPermissions(subAdminPermission);
    }
  }

  private handleAdminPermissions(currentAdmin: string, url: string): void {
    if (currentAdmin && url.startsWith(RoutesRole.ADMIN_ROUTE)) {
      this.permissionsService.loadPermissions([userPermissionsGet.SUPER_ADMIN]);
    }
  }

  private handleUserPermissions(
    currentUserRequester: string,
    currentUserProvider: string,
    url: string
  ): void {
    if (
      (currentUserRequester || currentUserProvider) &&
      (url.startsWith(RoutesRole.SERVICE_REQUESTER) ||
        url.startsWith(RoutesRole.SERVICE_PROVIDER))
    ) {
      this.permissionsService.loadPermissions(["USER"]);
    }
  }

  private hasPermission(
    url: string,
    role: string,
    currentUser: string
  ): boolean {
    return url.startsWith(role) && !!currentUser;
  }

  /*
   * Authentication check for child routes, In case we want to protect only child route.
   */
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(route, state);
  }
}
