import { Component, EventEmitter, Input, Output } from "@angular/core";
import { qtyValidation } from "@utils/enum-const";
import { BaseComponent } from "@components/base.component";

@Component({
  selector: "app-truck-assigment-popup",
  templateUrl: "./truck-assignment.component.html",
  styleUrls: ["./truck-assignment.component.scss"],
})
export class TruckAssignmentPopupComponent extends BaseComponent {
  @Input() truck_quantity!: number;
  @Input() vehicle_count!: number;

  @Output() getVehicleCount = new EventEmitter();
  @Output() acceptOrder = new EventEmitter();
  @Output() close = new EventEmitter();
  @Output() detailsForm = new EventEmitter();

  submitted = false;
  isInvalidQuantity = false;
  isIncrementDisable = false;

  /**
   * Change Vehicle Count
   */
  changeVehicleCount(opration: string) {
    if (opration === this.oprations.ADD) {
      if (
        this.truck_quantity > 0 &&
        this.vehicle_count === this.truck_quantity
      ) {
        this.isIncrementDisable = true;
      } else {
        this.isIncrementDisable = false;
        this.vehicle_count = this.vehicle_count + 1;
        this.isInvalidQuantity = this.vehicle_count > qtyValidation.MAX_TRUCK;
      }
    }
    if (opration === this.oprations.MINUS && Number(this.vehicle_count) > 1) {
      this.vehicle_count = this.vehicle_count - 1;
    }
  }

  /**
   * Close Popup
   */
  closePopup() {
    this.close.emit();
  }

  /**
   * Get Vehicle Count
   */
  getVehicleCountData() {
    this.submitted = true;
    if (this.vehicle_count > 0) {
      this.getVehicleCount.emit(this.vehicle_count);
    }
  }

  /**
   * Accept Order Vehicles
   */
  acceptOrderVehicles() {
    this.acceptOrder.emit();
  }
}
