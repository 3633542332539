<div class="left-section">
  <picture>
    <source
      [srcset]="requesterTypeURL + siteImages.SIDE_IMAGE_WEBP"
      type="image/webp"
    />
    <source
      [srcset]="requesterTypeURL + siteImages.SIDE_IMAGE_JPG"
      type="image/jpeg"
    />
    <img
      [src]="requesterTypeURL + siteImages.SIDE_IMAGE_JPG"
      alt="Side Banner"
      title="Side Banner"
      width="941px"
      height="970px"
    />
  </picture>
</div>
