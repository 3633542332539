<app-language-selection *ngIf="!this.isAdminRoute"> </app-language-selection>
<div class="form-data">
  <app-left-register-panel> </app-left-register-panel>
  <div class="right-section">
    <div class="logo">
      <app-right-register-panel> </app-right-register-panel>
    </div>
    <div class="form-area">
      <h1>{{ "FORGET_PASSWORD" | translate }}</h1>
      <p class="subtitle">{{ "ENTER_EMAIL_FOR_SEND_PASS" | translate }}</p>
      <div class="forgot-password-form">
        <form
          (ngSubmit)="onForgetPasswordClick(forgetPasswordForm)"
          novalidate
          #forgetPasswordForm="ngForm"
          InvalidFormScrollNGFormDirective
        >
          <div
            class="form-group"
            [ngClass]="model.email ? 'floatingLabel' : ''"
          >
            <input
              type="email"
              [(ngModel)]="model.email"
              name="email_id"
              [dir]="isEnglish ? 'ltr' : 'rtl'"
              class="form-control"
              required
              id="email_id"
              #email_id="ngModel"
              maxlength="50"
              [pattern]="CommonRegx.emailRegx"
              placeholder=" "
            />
            <div
              class="form-control-feedback text-danger"
              *ngIf="email_id.errors && submitted"
            >
              <span *ngIf="email_id.errors.required">
                {{ "PLEASE_ENTER_EMAIL" | translate }}
              </span>
              <span *ngIf="email_id.errors.pattern">
                {{ "ENTER_VALID_EMAIL" | translate }}
              </span>
              <span *ngIf="email_id.errors.maxlength">
                {{ "ENTER_VALID_EMAIL_LENGTH" | translate }}
              </span>
            </div>
            <label class="floating-label" for="email_id">{{
              "EMAIL_ID" | translate
            }}</label>
          </div>
          <div class="row">
            <div class="d-col space-between">
              <a
                [routerLink]="[userRole + '/login']"
                class="btn bordered-btn btn-small"
                >{{ "BACK" | translate }}</a
              >
              <button type="submit" class="btn btn-secondary btn-small">
                {{ "NEXT" | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<app-spinner></app-spinner>
