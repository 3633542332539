import { Injectable } from "@angular/core";
import CryptoJS from "crypto-js";

@Injectable({
  providedIn: "root",
})
export class EncrDecrService {
  // The set method is use for encrypt the value.
  set(keys: string, value: string | object) {
    if (value) {
      const key = CryptoJS.enc.Utf8.parse(keys);
      const iv = CryptoJS.enc.Utf8.parse(keys);
      const encrypted = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(JSON.stringify(value)),
        key,
        {
          keySize: 128 / 8,
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      );

      return encrypted.toString();
    } else {
      return "";
    }
  }

  // The get method is use for decrypt the value.
  get(keys: string, value: string | null) {
    if (value) {
      const key = CryptoJS.enc.Utf8.parse(keys);
      const iv = CryptoJS.enc.Utf8.parse(keys);
      const decrypted = CryptoJS.AES.decrypt(value, key, {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });

      return decrypted.toString(CryptoJS.enc.Utf8);
    } else {
      return "";
    }
  }
}
