<a
  (click)="openModal(statusChangeTemplate)"
  title="{{ 'CHANGE_STATUS' | translate }}"
>
  <i
    [ngClass]="{
      'icon-toggle-on': currentStatus === actionStatusValues.ACTIVE,
      'icon-toggle-off': currentStatus === actionStatusValues.IN_ACTIVE
    }"
    aria-hidden="true"
  ></i>
</a>

<ng-template #statusChangeTemplate>
  <app-confirmation-popup-modal
    [popupMessage]="confirmStatusMessage"
    (submit)="handleDelete()"
    (close)="decline()"
  >
  </app-confirmation-popup-modal>
</ng-template>
