import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { CONFIG } from "@config/app-config";
import { ISuccessResponse } from "@model/successResponse";
import {
  ICreateAccountPayload,
  IForgotPassword,
  IRegisterAccountPayload,
  IRegisterUploadDocumentModel,
  IRequesterAccountCreationResponse,
  IServiceProviderRegistrationResponse,
  IUpdateProviderPayload,
} from "@model/userRegistrationLogin";
import { IPasswordData, IUploadImage } from "@model/common";
import { IUpdateReqAccountDetails } from "@model/serviceRequester";

@Injectable({
  providedIn: "root",
})
export class RegistrationService {
  constructor(private http: HttpClient) {}

  /**
   * Create provider accounts step 1
   */
  createProviderAccountDetails(providerUserData: ICreateAccountPayload) {
    return this.http
      .post<IServiceProviderRegistrationResponse>(
        CONFIG.providerRegistrationURL,
        providerUserData
      )
      .pipe(
        map((response: IServiceProviderRegistrationResponse) => {
          return response;
        })
      );
  }

  /**
   * Create requester accounts step 1
   */
  createRequesterAccountDetails(requesterUserData: IRegisterAccountPayload) {
    return this.http
      .post<IRequesterAccountCreationResponse>(
        CONFIG.requesterRegistrationURL,
        requesterUserData
      )
      .pipe(
        map((response: IRequesterAccountCreationResponse) => {
          return response;
        })
      );
  }

  /**
   * Update provider accounts step 1
   */
  updateProviderAccountDetails(
    providerUserData: IUpdateProviderPayload,
    userId: number | string
  ) {
    return this.http
      .post<IServiceProviderRegistrationResponse>(
        CONFIG.updateProviderAccountData + userId,
        providerUserData
      )
      .pipe(
        map((response: IServiceProviderRegistrationResponse) => {
          return response;
        })
      );
  }

  /**
   * Update provider accounts step 1
   */
  updateRequesterAccountDetails(
    requesterUserData: IUpdateReqAccountDetails,
    userId: string
  ) {
    return this.http
      .post<IRequesterAccountCreationResponse>(
        CONFIG.updateRequesterAccountData + userId,
        requesterUserData
      )
      .pipe(
        map((response: IRequesterAccountCreationResponse) => {
          return response;
        })
      );
  }

  /**
   * Update provider accounts step 2
   */
  uploadProviderDocuments(
    providerDocumentData: IRegisterUploadDocumentModel,
    userId: string
  ) {
    return this.http
      .post<IServiceProviderRegistrationResponse>(
        CONFIG.updateProviderDocuments + userId,
        providerDocumentData
      )
      .pipe(
        map((response: IServiceProviderRegistrationResponse) => {
          return response;
        })
      );
  }

  /**
   * Update requester accounts step 2
   */
  uploadRequesterDocuments(
    requesterDocumentData: IUploadImage,
    userId: string
  ) {
    return this.http
      .post<IRequesterAccountCreationResponse>(
        CONFIG.updateRequsterDocuments + userId,
        requesterDocumentData
      )
      .pipe(
        map((response: IRequesterAccountCreationResponse) => {
          return response;
        })
      );
  }

  /**
   * Set requester accounts step 4
   */
  createSetPassword(passwordData: IPasswordData, userId: string | undefined) {
    return this.http
      .post<ISuccessResponse>(CONFIG.updateUserPassword + userId, passwordData)
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }

  /**
   * Handle forgot password
   */
  forgetPassword(userEmailData: { email: string }) {
    return this.http
      .post<IForgotPassword>(CONFIG.forgotPassword, userEmailData)
      .pipe(
        map((response: IForgotPassword) => {
          return response;
        })
      );
  }

  /**
   * OTP verify
   */
  otpVerify(userOtpData: { token: string; code: string }) {
    return this.http.post<ISuccessResponse>(CONFIG.otpVerify, userOtpData).pipe(
      map((response: ISuccessResponse) => {
        return response;
      })
    );
  }

  /**
   * Check password regex
   */
  checkPassword(pass: string) {
    if (pass !== null) {
      return pass
        .replace(/\*/g, "[*]")
        .replace(/\^/g, "[^]")
        .replace(/\$/g, "[$]");
    } else {
      return "";
    }
  }

  /**
   * Handle reset password
   */
  resetPassword(userData: {
    password: string;
    confirm_password: string;
    token: string;
  }) {
    return this.http
      .post<ISuccessResponse>(CONFIG.resetPassword, userData)
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }
}
