<div class="body-part" *ngIf="!this.isSelectServiceProvider">
  <div class="top-area">
    <div class="top-left">
      <h1 *ngIf="!this.isAdminRoute">
        {{ "ORDER_DETAILS" | translate }} - #{{ this.orderDetails?.order_id }}
      </h1>
      <h1 *ngIf="this.isAdminRoute">
        {{ "VIEW_ORDER_DETAILS" | translate }} - #{{
          this.orderDetails?.order_id
        }}
      </h1>
      <span class="breadcrum">
        <a [routerLink]="[this.getRoutesRole() + '/dashboard']">
          {{ "HOME" | translate }}
        </a>
        <a
          [routerLink]="[this.getRoutesRole() + '/offers-orders']"
          *ngIf="this.router.url.includes('offers-orders')"
        >
          {{ "OFFER_ORDERS" | translate }}
        </a>
        <a
          [routerLink]="[this.getRoutesRole() + '/direct-orders']"
          *ngIf="this.router.url.includes(this.routerUrl.DIRECT_ORDER)"
        >
          {{ "DIRECT_ORDERS" | translate }}
        </a>
        <a
          [routerLink]="[this.getRoutesRole() + '/contracts']"
          *ngIf="
            this.router.url.includes(this.routerUrl.CONTRACTS) &&
            !this.router.url.includes(this.routerUrl.MY_CONTRACTS)
          "
        >
          {{ "CONTRACT" | translate }}
        </a>
        <a
          [routerLink]="[this.getRoutesRole() + '/contracts/order-history']"
          *ngIf="
            this.router.url.includes(this.routerUrl.CONTRACTS) &&
            !this.router.url.includes(this.routerUrl.MY_CONTRACTS) &&
            this.router.url.includes(this.routerUrl.ORDER_HISTORY)
          "
        >
          {{ "ORDER_HISTORY" | translate }}
        </a>
        <a
          [routerLink]="[this.getRoutesRole() + '/order-history']"
          *ngIf="
            this.router.url.includes(this.routerUrl.ORDER_HISTORY) &&
            !this.router.url.includes(this.routerUrl.CONTRACTS)
          "
        >
          {{ "ORDER_HISTORY" | translate }}
        </a>
        <a
          [routerLink]="[this.getRoutesRole() + '/my-contracts']"
          *ngIf="this.router.url.includes(this.routerUrl.MY_CONTRACTS)"
        >
          {{ "CONTRACTS" | translate }}
        </a>
        <a
          [routerLink]="[this.getRoutesRole() + '/special-orders']"
          *ngIf="this.router.url.includes(this.routerUrl.SPECIAL_ORDER)"
        >
          {{ "SPECIAL_ORDERS" | translate }}
        </a>
        <a
          [routerLink]="[
            this.getRoutesRole() + '/my-contracts/contract-orders-list/all'
          ]"
          *ngIf="this.router.url.includes(this.routerUrl.MY_CONTRACTS)"
        >
          {{ "CONTRACT_ORDERS" | translate }}
        </a>
        <span class="breadcrum-text" *ngIf="!this.isAdminRoute">{{
          "ORDERS_DETAILS" | translate
        }}</span>
        <span class="breadcrum-text" *ngIf="this.isAdminRoute">{{
          "VIEW_ORDER_DETAILS" | translate
        }}</span>
      </span>
    </div>
    <div
      class="breadcrum-btn"
      *ngIf="!this.isAdminRoute && !this.isCancelOrderButtonHide"
    >
      <a (click)="openModal(cancelOrderPopup)" class="btn cancel-btn-medium">{{
        "CANCEL_ORDER" | translate
      }}</a>
    </div>
  </div>
  <div class="content-area">
    <div
      class="offer"
      *ngIf="this.orderTypeRoute === this.orderType.offer_order"
    >
      <div class="offer-price">
        <h2>SAR {{ orderDetails?.offer?.price }} / Each</h2>
        <div class="location">
          <div class="from">
            <span class="point">
              <span class="fill"></span>
            </span>
            <span class="text">
              {{ orderDetails?.city_from_details?.name }}
            </span>
            <span class="text">{{ "SAUDI_ARABIA" | translate }}</span>
          </div>
          <div class="to">
            <span class="point">
              <span class="fill"></span>
            </span>
            <span class="text">
              {{ orderDetails?.city_to_details?.name }}
            </span>
            <span class="text">{{ "SAUDI_ARABIA" | translate }}</span>
          </div>
        </div>
      </div>
      <div class="offer-detail">
        <h3>{{ orderDetails?.offer?.title }}</h3>
        <p>
          {{ orderDetails?.offer?.description }}
        </p>
        <div class="offer-row">
          <div class="row">
            <div class="d-col d-col-3">
              <div class="offer-id d-flex align-center">
                <span class="title">{{ "OFFER_ID" | translate }}:</span>
                <span class="subtitle">{{
                  orderDetails?.offer?.offer_uid
                }}</span>
              </div>
            </div>
            <div class="d-col d-col-3">
              <div class="trucks d-flex align-center">
                <i class="icon-truck"></i>
                <span
                  >{{ orderDetails?.truck?.name }} x
                  <strong
                    >{{ orderDetails?.offer?.max_shipment_number }}
                    {{ "TRUCKS_NAME" | translate }}
                  </strong></span
                >
              </div>
            </div>
            <div class="d-col d-col-3">
              <div class="date-validity">
                <span class="title">Valid Till:</span>
                <span class="date">{{
                  this.convertUTCtoLOCAL(orderDetails?.offer?.end_date)
                    | customDate: dateFormatListing
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-admin-order-info
      *ngIf="this.isAdminRoute && this.orderDetails"
      [(orderDetails)]="this.orderDetails"
      (detailsForm)="this.initForm()"
      (changeStatus)="changeOrderStatus($event)"
      (close)="decline()"
    >
    </app-admin-order-info>
    <app-provider-order-info
      *ngIf="this.isServiceProviderRoute && this.orderDetails"
      [(orderDetails)]="this.orderDetails"
      (handleTruckQuantity)="this.openProviderTruckModal(truckAssignment)"
      (changeStatus)="changeOrderStatus($event)"
      ([tripDetails])="(this.tripDetails)"
      (editTripDetails)="openViewTripsModal(editTripDetailsPopup)"
      (detailsForm)="this.initForm()"
      (close)="decline()"
      (approveContractOrder)="this.approveContractOrder()"
      [(showTruckQuantity)]="this.showTruckQuantity"
    >
    </app-provider-order-info>

    <app-requester-order-info
      *ngIf="this.isServiceRequesterRoute && this.orderDetails"
      [(orderDetails)]="this.orderDetails"
      (selectProvider)="this.selectProvider()"
      (handleTruckQuantity)="this.openProviderTruckModal(truckAssignment)"
      (detailsForm)="this.initForm()"
      (close)="decline()"
      (viewTripDetails)="openViewTripsModal(tripDetailsPopup)"
      (changeStatus)="changeOrderStatus($event)"
    >
    </app-requester-order-info>
    <div class="shipment-info">
      <h3>{{ "SHIPMENT_INFO" | translate }}</h3>
      <div class="shipment-info-wrap">
        <div class="provider-list-area">
          <div class="border-box">
            <div class="truck-detail-area">
              <div class="payment-status" *ngIf="this.isServiceRequesterRoute">
                <div class="col-2 bb-none">
                  <span class="title">
                    {{ "PAYMENT_STATUS" | translate }}
                  </span>
                  <span
                    class="detail"
                    *ngIf="this.orderDetails?.paid === paymentStatus.PENDING"
                  >
                    {{ "PENDING" | translate }}
                  </span>
                  <span
                    class="detail"
                    *ngIf="this.orderDetails?.paid === paymentStatus.PAID"
                  >
                    {{ "PAID" | translate }}
                  </span>
                </div>
                <div
                  class="col-2 bb-none"
                  *ngIf="
                    this.orderDetails?.status ===
                    this.orderStatus.SR_INVOICE_SENT
                  "
                >
                  <a
                    (click)="this.paymentShipment()"
                    class="btn btn-medium btn-secondary"
                  >
                    {{ "PAY_FOR_SHIPMENT" | translate }}
                  </a>
                </div>
              </div>
              <div class="shipment-box">
                <div class="col-2" *ngIf="this.isAdminRoute">
                  <span class="title">
                    {{ "SR" | translate }}
                  </span>
                  <span
                    class="detail"
                    [title]="
                      this.orderDetails?.requester?.service_requester
                        ?.company_name
                    "
                  >
                    {{
                      this.orderDetails?.requester?.service_requester
                        ?.company_name
                    }}
                  </span>
                </div>
                <div class="col-2" *ngIf="this.isAdminRoute">
                  <span class="title">
                    {{ "SP" | translate }}
                  </span>
                  <span
                    class="detail"
                    [title]="this.orderDetails?.provider?.company_name"
                  >
                    {{ this.orderDetails?.provider?.company_name }}
                  </span>
                </div>
                <div class="col-2">
                  <span class="title">
                    {{ "LOADING_DATE" | translate }}
                  </span>
                  <span
                    class="detail"
                    [ngClass]="!isAdminRoute ? 'shipment-date' : ''"
                  >
                    {{
                      this.orderDetails?.shipment_date
                        | customDate: dateFormatListing
                    }}
                  </span>
                </div>
                <div class="col-2">
                  <span class="title">
                    {{ "OFF_LOADING_DATE" | translate }}
                  </span>
                  <span
                    class="detail"
                    [ngClass]="!isAdminRoute ? 'shipment-date' : ''"
                  >
                    {{
                      this.orderDetails?.offloading_date
                        ? (this.orderDetails?.offloading_date
                          | customDate: dateFormatListing)
                        : "-"
                    }}
                  </span>
                </div>
                <div class="col-2">
                  <span class="title">
                    {{ "SHIPMENT_TYPE" | translate }}
                  </span>
                  <span class="detail">
                    {{
                      this.orderDetails?.shipment_type.name
                        ? this.orderDetails?.shipment_type.name
                        : "-"
                    }}
                  </span>
                </div>
                <div class="col-2">
                  <span class="title">
                    {{ "ACCESSORIES" | translate }}
                  </span>
                  <span class="detail">
                    <ng-container
                      *ngIf="
                        orderDetails?.accessories &&
                        orderDetails?.accessories.length > 1
                      "
                    >
                      <ng-container
                        *ngFor="
                          let i = index;
                          let accessories;
                          of: orderDetails?.accessories
                        "
                      >
                        {{ accessories?.name }}
                        <span *ngIf="orderDetails?.accessories.length - 1 !== i"
                          >,</span
                        >
                      </ng-container>
                    </ng-container>
                    <ng-container
                      *ngIf="
                        orderDetails?.accessories &&
                        orderDetails?.accessories.length === 1
                      "
                    >
                      <ng-container
                        *ngFor="let accessories of orderDetails?.accessories"
                      >
                        {{ accessories.name }}
                      </ng-container>
                    </ng-container>
                    <ng-container
                      *ngIf="orderDetails?.accessories.length === 0"
                    >
                      -
                    </ng-container>
                  </span>
                </div>
                <div class="col-2">
                  <span class="title">
                    {{ "TRUCK" | translate }}
                  </span>
                  <span class="detail">
                    {{
                      this.orderDetails?.truck.name
                        ? this.orderDetails?.truck.name
                        : "-"
                    }}
                  </span>
                </div>
                <div class="col-2">
                  <span class="title">
                    {{ "NO_TRUCK" | translate }}
                  </span>
                  <span class="detail">
                    {{
                      this.orderDetails?.providers &&
                      this.orderDetails?.shahen_order === 1
                        ? this.showTruckQuantity
                        : this?.orderDetails?.quantity
                        ? this?.orderDetails?.quantity
                        : "-"
                    }}
                  </span>
                </div>
                <div class="col-2">
                  <span class="title">
                    {{ "COMMODITIES" | translate }}
                  </span>
                  <span class="detail">
                    {{
                      this.orderDetails?.goods_type?.name
                        ? this.orderDetails?.goods_type?.name
                        : "-"
                    }}
                  </span>
                </div>
                <div class="col-2">
                  <span class="title">
                    {{ "DANGEROUS_CODE" | translate }}
                  </span>
                  <span class="detail">
                    {{
                      orderDetails?.dangerous_code
                        ? orderDetails?.dangerous_code
                        : "-"
                    }}
                  </span>
                </div>
                <div class="col-2 w-full">
                  <span class="title">
                    {{ "WEIGHT" | translate }}
                  </span>
                  <span class="detail">
                    {{
                      this.orderDetails?.weight +
                        " " +
                        this.measurementUnits.TON
                    }}
                  </span>
                </div>
              </div>
              <div
                class="sr-sp-payment-description"
                *ngIf="!this.isAdminRoute"
                title="{{ orderDetails?.description }}"
              >
                <span class="title">
                  {{ "DESCRIPTION" | translate }}
                </span>
                <span
                  class="detail multiline"
                  title="{{ orderDetails?.description }}"
                  [innerHTML]="orderDetails?.description | newlinesToBr"
                ></span>
              </div>
            </div>
          </div>
        </div>
        <div class="load-summary">
          <div class="border-box">
            <div *ngIf="this.markers.length > 0" class="detail-marker-map">
              <app-multiple-markers-map
                [googleMapId]="'locationMarkerDetailsMap'"
                [(latlong)]="this.markers"
                [(isHideSearch)]="isHideSearch"
              >
              </app-multiple-markers-map>
            </div>
            <div class="summary-top-area">
              <div class="location">
                <ng-container
                  *ngFor="
                    let location of this.orderDetails?.order_locations;
                    let locationNumber = index
                  "
                >
                  <div class="from">
                    <div class="address-container">
                      <span class="icon-area">
                        <span class="point"><span class="fill"></span></span>
                      </span>
                      <span class="title">
                        {{
                          (locationNumber === 0
                            ? "LOADING_LOCATION"
                            : locationNumber ===
                                this.orderDetails?.order_locations?.length -
                                  1 &&
                              this.orderDetails?.order_locations?.length === 1
                            ? "OFF_LOADING_LOCATION"
                            : "INTERMEDIATE_LOCATION"
                          ) | translate
                        }}
                        <a
                          role="button"
                          class="copy-location"
                          title="copy"
                          (click)="
                            copyLocation(
                              getFullLocationText(locationNumber) +
                                ': ' +
                                location?.loading_location?.name +
                                '\n' +
                                location?.loading_detail_addr
                            )
                          "
                        >
                          <i class="icon-copy"></i>
                        </a>
                      </span>
                      <a
                        role="button"
                        class="open-location"
                        (click)="
                          openGoogleMaps(
                            location?.loading_lat,
                            location?.loading_lng,
                            location?.loading_detail_addr
                          )
                        "
                      >
                        <span
                          class="location-address"
                          *ngIf="location?.loading_location?.name"
                        >
                          {{ location?.loading_location?.name }}
                        </span>
                        <span
                          class="location-address"
                          *ngIf="location?.loading_detail_addr"
                        >
                          {{ location?.loading_detail_addr }}
                        </span>
                      </a>
                    </div>
                  </div>
                  <ng-container
                    *ngIf="
                      locationNumber ===
                      this.orderDetails?.order_locations?.length - 1
                    "
                  >
                    <div class="to">
                      <div class="address-container">
                        <span class="icon-area">
                          <span class="point"><span class="fill"></span></span>
                        </span>
                        <span class="title">
                          {{ "OFF_LOADING_LOCATION" | translate }}
                          <a
                            role="button"
                            title="copy"
                            class="copy-location"
                            (click)="
                              copyLocation(
                                this.locationTypes.OFFLOADING_LOCATION +
                                  ': ' +
                                  '\n' +
                                  location?.offloading_detail_addr
                              )
                            "
                          >
                            <i class="icon-copy"></i>
                          </a>
                        </span>
                        <a
                          role="button"
                          class="open-location"
                          (click)="
                            openGoogleMaps(
                              location?.offloading_lat,
                              location?.offloading_lng,
                              location?.offloading_detail_addr
                            )
                          "
                        >
                          <span
                            class="location-address"
                            *ngIf="location?.offloading_location?.name"
                          >
                            {{ location?.offloading_location?.name }}
                          </span>
                          <span
                            class="location-address"
                            *ngIf="location?.offloading_detail_addr"
                          >
                            {{ location?.offloading_detail_addr }}
                          </span>
                        </a>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
          <div
            class="order-description"
            *ngIf="this.isAdminRoute && !isChangeDescription"
          >
            <span class="title">
              {{ "DESCRIPTION" | translate }}
              <a
                title="{{ 'EDIT' | translate }}"
                class="icon-edit-box"
                (click)="handleChangeDescriptionView()"
                ><i class="icon-edit edit-size"></i>
              </a>
            </span>
            <span
              class="detail multiline"
              title="{{ orderDetails?.description }}"
              [innerHTML]="orderDetails?.description | newlinesToBr"
            ></span>
          </div>
          <div *ngIf="isChangeDescription" class="d-col change-description">
            <div
              [ngClass]="descriptionModel.description ? 'floatingLabel' : ''"
              class="form-group"
            >
              <textarea
                appSpaceRemove
                type="text"
                name="description"
                [dir]="isEnglish ? 'ltr' : 'rtl'"
                rows="3"
                cols="3"
                [(ngModel)]="descriptionModel.description"
                placeholder=" "
                class="form-control"
                #description="ngModel"
                maxlength="300"
                id="description"
              >
              </textarea>
              <label class="floating-label">{{
                "DESCRIPTION" | translate
              }}</label>
            </div>
            <div class="d-flex btn-wrapper flex-col">
              <a
                (click)="handleChangeDescriptionView()"
                class="btn bordered-btn btn-small"
                >{{ "CANCEL" | translate }}</a
              >
              <button
                type="button"
                class="btn btn-secondary btn-small"
                (click)="handleChangeDescription()"
              >
                {{ "SUBMIT" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="this.isAdminRoute">
      <ng-container *ngFor="let tripData of tripDetails">
        <div class="trip-info" id="{{ tripData.id }}">
          <div class="trip-left-box">
            <ng-container
              *ngxPermissionsOnly="[
                'SUPER_ADMIN',
                'cancel_trip_delete_direct_order'
              ]"
            >
              <a
                *ngIf="
                  tripData?.is_canceled === tripCancelStatus.zero &&
                  this.orderDetails?.status !== this.orderStatus.CANCELLED &&
                  this.orderTypeRoute !== this.orderType.offer_order
                "
                (click)="
                  openModal(cancelTripPopup, 'cancelTripPopup', tripData?.id)
                "
                class="close__icon"
              >
                <span class="close">
                  <i class="icon-close"></i>
                </span>
              </a>
            </ng-container>
            <div class="trip-id">
              <span class="title"> {{ "TRIP_ID" | translate }}: </span>
              <span class="trip-data"> #{{ tripData.trip_id }} </span>
            </div>
            <div class="btn-wrapper">
              <button
                (click)="
                  tripData?.is_bayan_sync !== tripSyncFlag.zero
                    ? downloadBayanWaybillFile(
                        tripData?.id,
                        orderFileType.BAYAN
                      )
                    : ''
                "
                [ngClass]="
                  tripData?.is_bayan_sync === tripSyncFlag.zero
                    ? 'btn btn-small btn-download disabled-button'
                    : 'btn btn-small btn-secondary btn-download'
                "
                [disabled]="tripData?.is_bayan_sync === tripSyncFlag.zero"
              >
                <i class="icon-download"></i>{{ "BAYAN" | translate }}
              </button>
              <button
                class="btn btn-small btn-secondary btn-download"
                (click)="
                  downloadBayanWaybillFile(tripData?.id, orderFileType.WAYBILL)
                "
              >
                <i class="icon-Waybill"></i>{{ "WAY_BILL" | translate }}
              </button>
              <button
                *ngIf="this.isButtonVisible(tripData)"
                class="btn btn-small"
                [ngClass]="{
                  'bayan-close': buttonLabel(tripData) === 'BAYAN_CLOSE',
                  'bordered-btn': buttonLabel(tripData) === 'BAYAN_SYNC',
                  'btn-medium-reject': buttonLabel(tripData) === 'BAYAN_CANCEL'
                }"
                (click)="manageBayanAction(tripData)"
              >
                {{ buttonLabel(tripData) | translate }}
              </button>
            </div>
            <ng-container
              *ngIf="tripData?.is_canceled === tripCancelStatus.zero"
            >
              <div
                class="truck-price"
                *ngxPermissionsOnly="[
                  'SUPER_ADMIN',
                  'USER',
                  'price_show_direct_order'
                ]"
              >
                <span class="title">
                  {{ "TRUCK_PRICE" | translate }}
                </span>
                <span class="subtitle">
                  {{
                    this.orderDetails?.price + " " + this.measurementUnits.SAR
                  }}
                </span>
              </div>
            </ng-container>
          </div>
          <div class="trip-detail">
            <div class="trip-data-top">
              <div
                class="col-6"
                *ngIf="
                  !(
                    tripData?.is_canceled == 1 && tripData?.is_bayan_cancel == 1
                  ) &&
                  !(tripData?.is_bayan_sync == 1 && tripData?.closed == 1) &&
                  buttonLabel(tripData) === 'BAYAN_SYNC'
                "
              >
                <div class="trip-data-msg">
                  <span class="sync-msg">
                    {{ "SYNC_BUTTON_MESSAGE" | translate }}
                  </span>
                </div>
              </div>
              <div class="col-3">
                <span class="title">{{ "DRIVER_NAME" | translate }}</span>
                <span class="desc">{{ tripData.driver?.name }}</span>
              </div>
              <div class="col-3">
                <span class="title">{{ "NATIONAL_ID" | translate }}</span>
                <span class="desc">{{ tripData.driver?.national_id }}</span>
              </div>
              <div class="col-3 br-none">
                <span class="title">{{ "MOBILE_NO" | translate }}</span>
                <span class="desc m-number">{{
                  tripData.driver?.absher_mobile
                    ? tripData.driver?.absher_mobile
                    : tripData.driver?.mobile
                }}</span>
              </div>
              <div class="col-3" *ngIf="tripData?.extra_driver">
                <span class="title">{{ "EXTRA_DRIVER_NAME" | translate }}</span>
                <span class="desc">{{ tripData.extra_driver?.name }}</span>
              </div>
              <div class="col-3" *ngIf="tripData?.extra_driver">
                <span class="title">{{ "NATIONAL_ID" | translate }}</span>
                <span class="desc">{{
                  tripData.extra_driver?.national_id
                }}</span>
              </div>
              <div class="col-3 br-none" *ngIf="tripData?.extra_driver">
                <span class="title">{{ "MOBILE_NO" | translate }}</span>
                <span class="desc m-number">{{
                  tripData.extra_driver?.absher_mobile
                    ? tripData.extra_driver?.absher_mobile
                    : tripData.extra_driver?.mobile
                    ? tripData.extra_driver?.mobile
                    : "-"
                }}</span>
              </div>
              <div class="col-3">
                <span class="title">{{
                  "TRUCK_PLATE_DETAILS_ORDER" | translate
                }}</span>
                <span class="desc">
                  {{ this.tripData?.vehicle?.full_plate_number }}
                </span>
              </div>
              <div class="col-3">
                <span class="title">{{ "TRUCK_TYPE" | translate }}</span>
                <span class="desc">{{
                  tripData?.vehicle?.truck?.name
                    ? tripData?.vehicle?.truck?.name
                    : "-"
                }}</span>
              </div>
              <div class="col-3 br-none">
                <span class="title">{{ "WEIGHT" | translate }}</span>
                <span class="desc">{{
                  this.orderDetails?.weight + " " + this.measurementUnits.TON
                }}</span>
              </div>
            </div>
            <div class="trip-data-bottom">
              <div
                *ngIf="tripData?.is_canceled !== isCanceled"
                class="trip-left"
              >
                <div class="shipment-in-progress">
                  <i class="icon-mark"></i>
                  <span>{{ this.orderTripStatusList[0]?.label }}</span>
                </div>
                <div class="shipment-on-way">
                  <ng-container>
                    <label
                      for="{{ tripData?.id + 'shipment-on-way' }}"
                      [title]="
                        !isSuperAdmin && !hasChangeStatusPermission
                          ? ('NO_PERMISSION_DASHBOARD' | translate)
                          : ''
                      "
                    >
                      <input
                        [dir]="isEnglish ? 'ltr' : 'rtl'"
                        id="{{ tripData?.id + 'shipment-on-way' }}"
                        type="checkbox"
                        class="inActiveTrip"
                        name="{{ tripData?.id + 'shipment-on-way' }}"
                        [checked]="
                          tripData.next_status ===
                            this.orderTripStatusList[2]?.key ||
                          tripData.next_status === ''
                        "
                        (change)="
                          this.orderDetails?.status !==
                            this.orderStatus.CANCELLED &&
                          (isSuperAdmin || hasChangeStatusPermission)
                            ? changeTripStatus(
                                tripData?.id,
                                tripData.next_status ===
                                  this.orderTripStatusList[1]?.key
                                  ? this.orderTripStatusList[1]?.key
                                  : this.orderTripStatusList[0]?.key
                              )
                            : ''
                        "
                        [disabled]="
                          this.orderDetails?.status ===
                            this.orderStatus.CANCELLED ||
                          (!isSuperAdmin && !hasChangeStatusPermission)
                        "
                      />
                      <div class="toggle">
                        <i class="icon-check"></i>
                        <div class="bullet"></div>
                      </div>
                    </label>
                    <span>{{ this.orderTripStatusList[1]?.label }} </span>
                  </ng-container>
                </div>
                <div class="delivered">
                  <ng-container>
                    <label
                      for="{{ tripData?.id + 'delivered' }}"
                      [title]="
                        !isSuperAdmin && !hasChangeStatusPermission
                          ? ('NO_PERMISSION_DASHBOARD' | translate)
                          : ''
                      "
                    >
                      <input
                        [dir]="isEnglish ? 'ltr' : 'rtl'"
                        type="checkbox"
                        name="{{ tripData?.id + 'delivered' }}"
                        id="{{ tripData?.id + 'delivered' }}"
                        class="inActiveTrip"
                        [checked]="tripData.next_status === ''"
                        (change)="
                          this.orderDetails?.status !==
                            this.orderStatus.CANCELLED &&
                          (isSuperAdmin || hasChangeStatusPermission)
                            ? changeTripStatus(
                                tripData?.id,
                                tripData.next_status !== ''
                                  ? this.orderTripStatusList[2]?.key
                                  : this.orderTripStatusList[1]?.key
                              )
                            : ''
                        "
                        [disabled]="
                          this.orderDetails?.status ===
                            this.orderStatus.CANCELLED ||
                          (!isSuperAdmin && !hasChangeStatusPermission)
                        "
                      />
                      <div class="toggle">
                        <i class="icon-check"></i>
                        <div class="bullet"></div>
                      </div>
                    </label>
                    <span>{{ this.orderTripStatusList[2]?.label }}</span>
                  </ng-container>
                </div>
              </div>
              <div
                class="trip-left flex-col"
                *ngIf="tripData?.is_canceled === isCanceled"
              >
                <div class="canceled">
                  <span class="cancelled-title"
                    >{{ "CANCELLATION_REASON" | translate }}:
                  </span>
                  <span>{{ tripData?.canceled_reason }}</span>
                </div>
                <div class="canceled" *ngIf="tripData?.canceled_by">
                  <span class="cancelled-title"
                    >{{ "TRIP_CANCEL_BY" | translate }}:
                  </span>
                  <span
                    >{{ tripData?.canceled_by?.name }}({{
                      getCancelTripAuthorDetails(tripData)
                    }})&nbsp;{{ "AT" | translate }}&nbsp;{{
                      tripData?.cancelled_at | customDate: momentDateTimeFormat
                    }}</span
                  >
                </div>
              </div>
              <div
                class="trip-right"
                *ngxPermissionsOnly="['SUPER_ADMIN', 'USER', 'show_chat']"
              >
                <a
                  class="btn btn-medium btn-secondary"
                  (click)="
                    redirectToChat(
                      userType.SERVICE_PROVIDER,
                      tripData?.user_id,
                      tripData?.driver?.name,
                      tripData?.driver?.absher_mobile,
                      tripData?.trip_id,
                      tripData?.vehicle?.full_plate_number,
                      tripData?.vehicle?.truck?.name
                    )
                  "
                  >{{ "CHAT_SP" | translate }}</a
                >
                <a
                  class="btn btn-medium btn-secondary"
                  (click)="
                    redirectToChat(
                      userType.SERVICE_REQUESTER,
                      orderDetails?.requester_id,
                      tripData?.driver?.name,
                      tripData?.driver?.mobile,
                      tripData?.trip_id,
                      tripData?.vehicle?.full_plate_number,
                      tripData?.vehicle?.truck?.name
                    )
                  "
                  >{{ "CHAT_SR" | translate }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container
        *ngxPermissionsOnly="[
          'SUPER_ADMIN',
          'USER',
          'cancel_order_delete_direct_order'
        ]"
      >
        <div
          class="cancel-btn"
          *ngIf="
            this.isAdminRoute &&
            this.orderDetails?.status !== this.orderStatus.CANCELLED
          "
        >
          <a
            (click)="openModal(cancelOrderPopup)"
            class="btn bordered-btn btn-medium cancel"
          >
            {{ "CANCEL_ORDER" | translate }}
          </a>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
<app-container *ngIf="this.isSelectServiceProvider">
  <div class="provider-select-content-area">
    <app-select-provider
      [(payloadData)]="getProviderPayload"
      (getProviderData)="getProviderData($event)"
      id="selectProviderDer"
      (clickBackButton)="this.backToAdd()"
      (noProviderFound)="noProviderFound()"
    >
    </app-select-provider>
    <div class="load-summary">
      <h3>{{ "LOADS_SUMMARY" | translate }}</h3>
      <div class="summary-area">
        <div class="summary-top-area">
          <div class="location">
            <div class="from">
              <span class="icon-area">
                <span class="point"><span class="fill"></span></span>
              </span>
              <span class="title">
                {{ "LOADING_LOCATION" | translate }}
              </span>
              {{ this.orderDetails.detail_address_from }}
            </div>
            <div class="to">
              <span class="icon-area">
                <span class="point"><span class="fill"></span></span>
              </span>
              <span class="title">
                {{ "OFF_LOADING_LOCATION" | translate }}
              </span>
              {{ this.orderDetails.detail_address_to }}
            </div>
          </div>
        </div>
        <div class="summary-bottom-area">
          <div class="truck-detail-area">
            <div class="col">
              <span class="title">
                {{ "TRUCK_TYPE" | translate }}
              </span>
              <span class="detail">
                {{ this.orderDetails.truck?.name }}
              </span>
            </div>
            <div class="col">
              <span class="title">
                {{ "TOTAL_TRUCKS" | translate }}
              </span>
              <span class="detail">
                {{ this.orderDetails.quantity }}
              </span>
            </div>
            <div class="col">
              <span class="title">
                {{ "COMMODITIES" | translate }}
              </span>
              <span class="detail">
                {{ this.orderDetails?.goods_type?.name }}
              </span>
            </div>
            <div class="col">
              <span class="title">
                {{ "SHIPMENT_WEIGHT" | translate }}
              </span>
              <span class="detail">
                {{ this.orderDetails?.weight }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-container>
<ng-template #tripDetailsPopup>
  <app-trip-details-popup
    [(orderTripDetails)]="this.tripDetails"
    [(orderData)]="this.orderDetails"
    [(tripStatus)]="this.orderTripStatusList"
    (close)="decline()"
  >
  </app-trip-details-popup>
</ng-template>
<ng-template #truckAssignment>
  <app-truck-assigment-popup
    [(truck_quantity)]="truck_quantity"
    [(vehicle_count)]="vehicle_count"
    (getVehicleCount)="getTruckAssignment($event)"
    (acceptOrder)="acceptOrder()"
    (close)="decline()"
    (detailsForm)="this.initForm()"
  >
  </app-truck-assigment-popup>
</ng-template>
<ng-template #editTripDetailsPopup>
  <app-edit-trip-details-popup
    [(orderTripDetails)]="this.tripDetails"
    [(orderData)]="this.orderDetails"
    [(tripStatus)]="this.orderTripStatusList"
    [(orderTripStatusList)]="this.orderTripStatusList"
    (close)="decline()"
    (editTripDateTime)="this.initForm()"
    (changeTripStatusData)="
      this.changeTripStatus($event.tripId, $event.tripStatus)
    "
    (handleTripStatusChange)="
      this.handleTripStatusChange($event.tripId, $event.tripStatus)
    "
    (detailsForm)="this.initForm()"
    (manageBayanAction)="this.manageBayanAction($event.tripData)"
  >
  </app-edit-trip-details-popup>
</ng-template>
<ng-template #cancelOrderPopup>
  <app-cancel-order-popup
    (orderCancel)="cancelOrder($event)"
    (close)="decline()"
  >
  </app-cancel-order-popup>
</ng-template>
<ng-template #cancelTripPopup>
  <app-cancel-order-popup
    (orderCancel)="cancelOrder($event)"
    (close)="decline()"
    [isTripCancel]="isTripCancel"
  >
  </app-cancel-order-popup>
</ng-template>
<app-spinner></app-spinner>
