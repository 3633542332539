import { Component, Input, TemplateRef } from "@angular/core";
import { first } from "rxjs/operators";
import { IErrorResponse } from "@model/errorResponse";
import { ISuccessResponse } from "@model/successResponse";
import { ChangeStatusValue } from "@utils/enum-const";
import { BaseComponent } from "@components/base.component";
import { AutoUnsubscribe } from "@decorator/autounsubscribe";
import { RequesterProviderService } from "@services/requester-provider.service";

@Component({
  selector: "app-change-status",
  templateUrl: "./change-status.component.html",
  styleUrls: ["./change-status.component.scss"],
})
@AutoUnsubscribe()
export class ChangeStatusComponent extends BaseComponent {
  @Input() currentStatus!: string;
  @Input() dataId!: number;
  @Input() rerenderFunction!: (
    goFirstPage: boolean,
    isDelete?: boolean
  ) => void;
  @Input() fetchlistFunction!: () => void;

  private changedStatus!: string;
  requesterStatusEnum = ChangeStatusValue;
  constructor(private requesterService: RequesterProviderService) {
    super();
  }

  /**
   * Handle change requester status
   */
  public changeRequesterStatus() {
    this.spinner.showLoader();
    this.changedStatus =
      this.currentStatus === this.requesterStatus.activated
        ? this.requesterStatus.inactive
        : this.requesterStatus.activated;
    this.requesterService
      .changeStatusRequester(this.changedStatus, this.dataId)
      .pipe(first())
      .subscribe(
        (data: ISuccessResponse) => {
          this.spinner.hideLoader();
          this.decline();
          if (data.status_code === this.statusCode.OK) {
            this.toastr.success(data.message);
            this.rerenderFunction(false);
          }
        },
        (error: IErrorResponse) => {
          this.errorHandler(this.toastr, this.translateService, error, () => {
            this.spinner.hideLoader();
            this.decline();
          });
        }
      );
  }

  /**
   * Handle open modal
   */
  openModal(template: TemplateRef<string>) {
    this.modalRef = this.modalService.show(template, { class: "modal-md" });
  }

  /**
   * Handle decline popup
   */
  decline(): void {
    this.modalRef.hide();
  }
}
