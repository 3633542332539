import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { ImageCropperModule } from "ngx-image-cropper";
import { SharedModule } from "@module/shared.module";
import { OrderHistoryComponent } from "@views/order-history/order-history.component";
import { OrderHistoryRoutingModule } from "@views/order-history/order-history-routing.module";
import { SpinnerModule } from "@containers/spinner";

@NgModule({
  declarations: [OrderHistoryComponent],
  imports: [
    CommonModule,
    OrderHistoryRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    NgbModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    SpinnerModule,
    BsDatepickerModule.forRoot(),
    ImageCropperModule,
    SharedModule,
    NgSelectModule,
  ],
})
export class OrderHistoryModule {}
