import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { languageTypes } from "@utils/enum-const";
import { BaseComponent } from "@components/base.component";
import { LanguageService } from "@services/language.service";

@Component({
  selector: "app-language-selection",
  templateUrl: "./language-selection.component.html",
  styleUrls: ["./language-selection.component.scss"],
})
export class LanguageSelectionComponent extends BaseComponent
  implements OnInit {
  @Input() isProfile = false;

  constructor(
    private languageSwitcher: LanguageService,
    private translate: TranslateService
  ) {
    super();
  }
  isEnglish = false;

  /**
   * Init function load when this module call
   */
  ngOnInit(): void {
    const userKey = this.getUser() + "_lan";
    const currentLanguage = this.languageSwitcher.getUserDefaultLanguage(
      userKey
    );
    this.isEnglish = currentLanguage === languageTypes.DEFAULT_ADMIN;
  }

  /**
   * Handle switch language
   */
  public switchLanguage() {
    const userKey = this.getUser() + "_lan";
    const currentLanguage = this.languageSwitcher.getUserDefaultLanguage(
      userKey
    );

    const switchedLanguage =
      currentLanguage === languageTypes.DEFAULT_ADMIN
        ? languageTypes.DEFAULT
        : languageTypes.DEFAULT_ADMIN;

    this.languageSwitcher.setUserTypeLanguage(userKey, switchedLanguage);
    this.translate.use(switchedLanguage);

    const updatedLanguage = this.languageSwitcher.getUserDefaultLanguage(
      userKey
    );
    this.isEnglish = updatedLanguage === languageTypes.DEFAULT_ADMIN;
    window.location.reload();
  }
}
