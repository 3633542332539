import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { first } from "rxjs/operators";
import { BaseComponent } from "@components/base.component";
import { ActivatedRoute } from "@angular/router";
import { RegistrationService } from "@services/registration.service";
import { IErrorResponse } from "@model/errorResponse";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent extends BaseComponent implements OnInit {
  loading = false;
  isServiceRequester: boolean;
  submitted = false;
  requesterTypeURL: string;
  showPassword = false;
  showConfirmPassword = false;
  conf_pass_match = "";
  public model = {
    password: "",
    confirm_password: "",
    token: "",
  };
  userRole = this.getRoutesRole();

  constructor(
    private registrationService: RegistrationService,
    private route: ActivatedRoute
  ) {
    super();
    this.isServiceRequester = this.isServiceRequesterRoute;
    this.requesterTypeURL = this.requesterType();
    this.model.token = this.route.snapshot.queryParams["token"]
      ? decodeURIComponent(this.route.snapshot.queryParams["token"])
      : "";
    this.userRole = this.getRoutesRole();
  }

  ngOnInit(): void {
    if (!this.model.token) {
      this.router.navigate([this.userRole + "/forget-password"]);
    }
  }

  /*
   * Handle reset password
   */
  public onResetPassword(form: NgForm) {
    this.submitted = true;
    // stop here if form is invalid
    if (form.invalid) {
      return;
    }

    const resetData = {
      password: this.model.password,
      confirm_password: this.model.confirm_password,
      token: this.model.token,
    };

    this.spinner.showLoader();
    this.registrationService
      .resetPassword(resetData)
      .pipe(first())
      .subscribe(
        (data) => {
          this.spinner.hideLoader();
          if (data.status_code === this.statusCode.OK) {
            this.toastr.success(data.message);
            this.router.navigate([this.getRoutesRole() + "/login"]);
          }
        },
        (error: IErrorResponse) => {
          this.spinner.hideLoader();
          this.errorHandler(this.toastr, this.translateService, error);
        }
      );
  }

  /*
   * Handle check password
   */
  checkPass() {
    this.conf_pass_match = this.registrationService.checkPassword(
      this.model.password
    );
  }

  /*
   * Handle password visiblity
   */
  togglePasswordVisibility(field: string) {
    if (field === "password") {
      this.showPassword = !this.showPassword;
    } else if (field === "confirm_password") {
      this.showConfirmPassword = !this.showConfirmPassword;
    }
  }
}
