import { Component, EventEmitter, Output, TemplateRef } from "@angular/core";
import { BaseComponent } from "@components/base.component";
import { CommonApiService } from "@services/common-api.service";

@Component({
  selector: "app-logout-section",
  templateUrl: "./logout.component.html",
  styleUrls: ["./logout.component.scss"],
})
export class LogoutComponent extends BaseComponent {
  @Output() confirmLogout: EventEmitter<string> = new EventEmitter<string>();
  constructor(protected commonService: CommonApiService) {
    super();
  }

  /**
   * Handle open modal
   */
  openModal(template: TemplateRef<string>) {
    this.modalRef = this.modalService.show(template, { class: "modal-md" });
  }

  /**
   * Handle close modal
   */
  decline(): void {
    this.modalRef.hide();
  }

  /**
   * Handle logout
   */
  async handleLogout() {
    this.decline();
    let getDeviceToken = "";
    this.commonService.getBrowserLoginToken().subscribe(
      (deviceToken) => {
        getDeviceToken = deviceToken as string;
        return deviceToken;
      },
      () => {
        return "";
      }
    );
    this.spinner.showLoader();
    await this.delay(1000);
    this.confirmLogout.emit(getDeviceToken);
    this.spinner.hideLoader();
  }

  /**
   * Handle check delays
   */
  private delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
