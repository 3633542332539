import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CONFIG } from "@config/app-config";
import { ILocationData } from "@model/mapDetails";
import { CommonService } from "@services/common.service";
import { UserRole } from "@utils/enum-const";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class MapService {
  userType = "";
  constructor(
    private http: HttpClient,
    private ManageCommonService: CommonService
  ) {
    this.userType = this.ManageCommonService.getLoginUserType();
  }

  /**
   * check bounds to avoid grey space
   */
  checkBounds(mapData: google.maps.Map | undefined) {
    if (!mapData || typeof mapData.getBounds() === "undefined") return;

    const bounds = mapData.getBounds();
    const latNorth = bounds?.getNorthEast().lat();
    const latSouth = bounds?.getSouthWest().lat();
    let newLat: number | undefined;

    if ((latNorth as number) < 85 && (latSouth as number) > -85) {
      /* in both side */ return;
    } else if ((latNorth as number) > 85 && (latSouth as number) < -85) {
      /* out both side */ return;
    } else {
      if ((latNorth as number) > 85) {
        newLat = mapData.getCenter().lat() - ((latNorth as number) - 85);
      }
      /* too north, centering */
      if ((latSouth as number) < -85) {
        newLat = mapData.getCenter().lat() - ((latSouth as number) + 85);
      }
      /* too south, centering */
    }
    if (newLat !== undefined) {
      const newCenter = new google.maps.LatLng(
        newLat,
        mapData.getCenter().lng()
      );
      mapData.setCenter(newCenter);
    }
  }

  /**
   * Get formatted address details based on map data
   */
  getFormattedAddress(mapData: ILocationData) {
    const getDetailesURL =
      this.userType === UserRole.SERVICE_PROVIDER ||
      this.userType === UserRole.SUPER_ADMIN_USER
        ? CONFIG.getProviderDetailedAddress
        : CONFIG.getRequesterDetailedAddress;
    return this.http.post(getDetailesURL, mapData).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
}
