import { Component } from "@angular/core";
@Component({
  selector: "app-spinner",
  template: `<ngx-spinner
    [type]="config.type"
    [color]="config.pbColor"
    [size]="config.size"
    class="spinner"
  ></ngx-spinner>`,
})
export class SpinnerComponent {
  config = {
    type: "ball-spin-fade",
    pbColor: "#2da5de",
    size: "default",
  };
}
