import { ICommodityMessage } from "@model/dashboard";
import { LogLevel, OrderStatus } from "@utils/enum-const";

export const persistPageWiseFilter = true;

export const CONFIGCONSTANTS = {
  siteName: "Shahen",
  dateFormat: "MM/dd/yyyy hh:mm:ss",
  dateShortFormat: "MM/dd/yyyy",
  momentDateTime24Format: "MM/DD/YYYY hh:mm:ss",
  momentDateTime12Format: "MM/DD/YYYY h:mm A",
  momentTime24Format: "hh:mm:ss",
  momentTime12Format: "hh:mm A",
  momentChatTimeFormat: "h:mmA",
  momentDateFormat: "MM/DD/YYYY",
  momentDateMonthYearFormat: "DD/MM/YYYY",
  mediumDate: "MMM d, y",
  dateFormatListing: "D MMM YYYY",
  languageOption: true,
  ApiRequestFormat: "YYYY-MM-DD",
  Api24RequestFormat: "YYYY-MM-DD HH:mm:ss",
  momentDateMonthFormat: "DD / MM / YYYY",
  momentNoDashDateFormat: "DD MMM YYYY",
  momentDateTimeFormat: "DD MMM YYYY h:mm A",
  momentyearmonthdateFormat: "YYYY/MM/DD",
  moemnethourminwithdateFormat: "h:mm A , DD MMM YYYY",
  moment24DateTimeFormat: "DD-MM-YYYY HH:mm",
  moment24TimeOnlyFormat: "HH:mm",
  moment24DateOnlyFormat: "DD-MM-YYYY",
  momentDateTimeSecondFormat: "DD MMM YYYY h:mm:ss A",
  dateRangeConfig: {
    dateInputFormat: "DD/MM/YYYY",
    containerClass: "theme-blue",
    rangeSeparator: " To ",
    showWeekNumbers: false,
  },
  datatableConfig: {
    reorderable: false,
    scrollbarH: true,
    serverSorting: false,
    clientSorting: false,
    serverPaging: true,
    clientPaging: false,
    piningRight: false,
    headerHeight: 50,
    footerHeight: 50,
    rowHeight: "auto",
    dtMessages: {
      emptyMessage: "NO_DATA",
      totalMessage: "TOTAL_RECORDS",
    },
    page: {
      size: 10,
      pageNumber: 0,
      totalReords: 0,
    },
    limitList: [10, 25, 50, 100],
  },
  dataTableIndexColumnWidth: "100",

  imageUpload: {
    ALLOW_FILE_TYPE: ["image/jpeg", "image/jpg", "image/png"],
    MAX_FILE_SIZE: 6,
    MESSAGE: "PLEASE_UPLOAD_VALID_IMAGE_FILE",
    SIZE_MESSAGE: "PLEASE_UPLOAD_IMAGE_MAXSIZE",
    FILE: "jpeg, jpg, png",
    ACCEPT: ".jpg, .jpeg, .png",
  },
  documentUpload: {
    ALLOW_FILE_TYPE: [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "document/pdf",
      "application/pdf",
    ],
    MAX_FILE_SIZE: 6,
    MESSAGE: "PLEASE_UPLOAD_VALID_DOCUMENT_FILE",
    SIZE_MESSAGE: "PLEASE_UPLOAD_DOCUMENT_MAXSIZE",
    FILE: "jpeg, jpg, png, pdf",
    ACCEPT: ".jpg, .jpeg, .png, .pdf",
  },
  ExcelFileUpload: {
    ALLOW_FILE_TYPE: [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ],
    MAX_FILE_SIZE: 10, // Maximum file size in megabytes
    MESSAGE: "PLEASE_UPLOAD_VALID_IMPORT_FILE",
    SIZE_MESSAGE: "PLEASE_UPLOAD_FILE_MAXSIZE",
    FILE: "xlsx, xls", // List of allowed file extensions
  },
  logLevel: LogLevel.All,
};

export enum ChatType {
  ONE_TO_ONE = "one-to-one",
  GROUP = "group",
}
export enum ModuleType {
  FULL_SCREEN = "full-screen",
  POPUP = "popup",
}

export enum UserRole {
  SERVICE_REQUESTER = "service_requester",
  SERVICE_PROVIDER = "service_provider",
  SUPER_ADMIN_USER = "super_admin",
  ADMIN_USER = "admin",
}

export enum RoutesRole {
  SERVICE_REQUESTER = "/requester",
  SERVICE_PROVIDER = "/provider",
  ADMIN_ROUTE = "/admin",
}

export enum RoleBaseImageURL {
  SERVICE_PROVIDER = "assets/images/service_provider",
  SERVICE_REQUESTER = "assets/images/service_requester",
  ADMIN = "assets/images/admin",
}

export const RoleBasedLanguage = [
  { locale: "en", name: "English", text_direction: "LTR", icon: "icon-en" },
  { locale: "ar", name: "Arabic", text_direction: "RTL", icon: "icon-ar" },
];

export enum UserRequesterType {
  INDIVIDUAL = "individual",
  COMPANY = "company",
}

export const VALIDATION_ERRORS = "Validation Errors";

export enum RegistrationTabs {
  ACCOUNT_DETAIL = "account_detail",
  ADDRESS_DETAIL = "address_detail",
  UPLOAD_DOCUMENT = "upload_document",
  CREATE_PASSWORD = "create_password",
}

export enum RememberFields {
  REM_REQ_DATA = "remRequesterData",
  REM_PRO_DATA = "remProviderData",
  REM_ADMIN_DATA = "remAdminData",
}

export const isAlreadyRegisterMessage = "User registation is not completed";

export const activeStatusList = [
  { id: "1", status: "Active" },
  { id: "0", status: "Inactive" },
];

export const driverSearchFieldsList = [
  { name: "Service Provider", field: "provider" },
  { name: "National ID", field: "national_id" },
  { name: "Mobile Number", field: "mobile" },
  { name: "Absher Mobile Number", field: "absher_mobile" },
];

export const vehicleSearchFieldsList = [
  { name: "Truck Type", field: "truck_type" },
  { name: "Plate Type", field: "plate_type" },
  { name: "Truck Number", field: "truck_number" },
];

export const requesterSearchFieldList = [
  { name: "Authorized Person", value: "authorization_person" },
  { name: "Email", value: "email" },
  { name: "Mobile Number", value: "mobile" },
];

export const providerSearchFieldList = [...requesterSearchFieldList];

export const providerRequesterStatusList = [
  { name: "Active", status: "activated" },
  { name: "Inactive", status: "inactive" },
  { name: "Under Approval", status: "pending" },
];
export const profitType = [
  { name: "Percentage", value: "percentage" },
  { name: "Fixed", value: "fixed" },
];

export const exportOptions = [
  { value: "excel", name: "EXCEL" },
  { value: "pdf", name: "PDF" },

  // Add more export options as needed
];
export enum ExportDataType {
  PDF = "pdf",
  EXCEL = "excel",
}
export const requesterTypeList = [
  { id: "0", status: "individual", name: "Individual" },
  { id: "1", status: "company", name: "Company" },
];

export const directOrdersStatusList = [
  { name: "New", value: "" },
  { name: "Waiting for Approval", value: "" },
  { name: "Invoice Sent", value: "" },
  { name: "Shipment in  Progress", value: "" },
  { name: "Shipment on the Way", value: "" },
  { name: "Delieverd", value: "" },
  { name: "Invoice Paid", value: "" },
];
export const directOrderSearchFieldList = [
  { name: "Service Requester", value: "requester_id" },
  { name: "Service Provider", value: "provider_id" },
  { name: "From(Location)", value: "from_location" },
  { name: "To(Location)", value: "to_location" },
  { name: "Truck Type", value: "truck_type" },
  { name: "Commodity", value: "goods_type" },
  { name: "Weight(Tons)", value: "weight" },
];

export const offerOrderSearchFieldList = [
  { name: "Service Requester", value: "requester_id" },
  { name: "Service Provider", value: "provider_id" },
  { name: "From(Location)", value: "from_location" },
  { name: "To(Location)", value: "to_location" },
  { name: "Truck Type", value: "truck_type" },
  { name: "Commodity", value: "goods_type" },
  { name: "Weight(Tons)", value: "weight" },
];

export const OrderHistoryRequesterSerachFieldList = [
  { name: "Order ID", value: "order_id" },
  { name: "Provider", value: "provider_id" },
  { name: "From (Location)", value: "from_location" },
  { name: "To (Location)", value: "to_location" },
  { name: "Order Type", value: "order_type" },
  { name: "Truck Type", value: "truck_type" },
  { name: "Commodities", value: "goods_type" },
  { name: "Weight(Tons)", value: "weight" },
];
export const OrderHistoryProviderSerachFieldList = [
  { name: "Order ID", value: "order_id" },
  { name: "Requester", value: "requester_id" },
  { name: "From (Location)", value: "from_location" },
  { name: "To (Location)", value: "to_location" },
  { name: "Order Type", value: "order_type" },
  { name: "Truck Type", value: "truck_type" },
  { name: "Commodities", value: "goods_type" },
  { name: "Weight(Tons)", value: "weight" },
];
export const OrderHistoryContractSerachFieldList = [
  { name: "Order ID", value: "order_id" },
  { name: "Trip ID", value: "trip_id" },
  { name: "From (Location)", value: "from_location" },
  { name: "To (Location)", value: "to_location" },
  { name: "Truck No", value: "truck_no" },
];
export const contractOrderSerachFields = [
  { name: "Order ID", value: "order_id" },
  { name: "Trip ID", value: "trip_id" },
  { name: "From (Location)", value: "from_location" },
  { name: "To (Location)", value: "to_location" },
  { name: "Truck Type", value: "truck_type" },
  { name: "Commodities", value: "goods_type" },
  { name: "Weight(Tons)", value: "weight" },
];
export const reasonForRejection = [
  { name: "Address" },
  { name: "Legal documents" },
];

export const RequesterUserTypeKey = "requesterType";

export const requesterStatusList = [
  { name: "Activated", status: "activated" },
  { name: "InActive", status: "inactive" },
  { name: "Pending", status: "pending" },
  { name: "Suspended", status: "suspended" },
  { name: "Pending for Updates", status: "pending_for_updates" },
];

export const priceFilterList = [
  { name: "Goods", field: "goods_types" },
  { name: "Weight (Tons)", field: "weight" },
];

export const OrderStatusCollection = [
  OrderStatus.DELIVERED,
  OrderStatus.CANCELLED,
  OrderStatus.INVOICE_SENT_SP,
];

export const ProviderOrderStatusCollection = [
  OrderStatus.SHIPMENT_IN_PROGRESS,
  OrderStatus.SHIPMENT_ON_THE_WAY,
];
export const ContractProviderCollection = [
  OrderStatus.NEW_CONTRACT,
  OrderStatus.SHIPMENT_IN_PROGRESS,
  OrderStatus.SHIPMENT_ON_THE_WAY,
];

export const subAdminFilterList = [
  { name: "Employee Code", value: "employee_code" },
  { name: "Email", value: "email" },
  { name: "Mobile Number", value: "mobile" },
];

export enum ShorterUserRole {
  SERVICE_REQUESTER = "sr",
  SERVICE_PROVIDER = "sp",
  ADMIN_USER = "admin",
}

export enum SectionType {
  ORDER = "order",
  SPECIAL_ORDER = "special_order",
  SERVICE_PROVIDER = "sp",
  SERVICE_REQUESTER = "sr",
  ADMIN = "admin",
  MEMBERSHIP = "membership",
  CHAT = "chat",
  ALL = "all",
  waiting_for_approval = "waiting_for_approval",
  OFFERS = "offer",
}

export const AddDriverTruckVisibleStatus = [
  OrderStatus.SHIPMENT_IN_PROGRESS,
  OrderStatus.SHIPMENT_ON_THE_WAY,
  OrderStatus.SR_INVOICE_SENT,
];

export const SrSpCancelOrderHideCollection = [
  OrderStatus.NEW,
  OrderStatus.CANCELLED,
  OrderStatus.SHIPMENT_IN_PROGRESS,
  OrderStatus.SHIPMENT_ON_THE_WAY,
  OrderStatus.DELIVERED,
  OrderStatus.INVOICE_SENT_SP,
  OrderStatus.INVOICE_PAID_SP,
  OrderStatus.INVOICE_PAID_SR,
  OrderStatus.INVOICE_PAID,
  OrderStatus.INVOICE_APPROVED,
  OrderStatus.INVOICE_RECEIVED,
];

export const AdminContractCancelOrderHideCollection = [
  OrderStatus.CANCELLED,
  OrderStatus.DELIVERED,
  OrderStatus.INVOICE_SENT_SP,
  OrderStatus.INVOICE_PAID_SP,
  OrderStatus.INVOICE_PAID_SR,
];

export const isInvoiceVisibleStatus = [
  OrderStatus.SHIPMENT_IN_PROGRESS,
  OrderStatus.SHIPMENT_ON_THE_WAY,
  OrderStatus.SR_INVOICE_SENT,
  OrderStatus.INVOICE_APPROVED,
  OrderStatus.INVOICE_SENT,
];
export const isInvoiceVisibleContractStatus = [
  OrderStatus.SHIPMENT_ON_THE_WAY,
  OrderStatus.SR_INVOICE_SENT,
  OrderStatus.INVOICE_APPROVED,
  OrderStatus.INVOICE_SENT,
];
export const invoicePaidYesNo = [
  { name: "Yes", value: "Yes" },
  { name: "No", value: "No" },
];

export const dashboardOrderTypes = [
  {
    key: "order",
    label: "Direct Orders",
  },
  {
    key: "special_order",
    label: "Special Orders",
  },
  {
    key: "membership",
    label: "Contract Orders",
  },
];

export const exportDataNames = [
  { label: "Excel", value: "excel" },
  { label: "PDF", value: "pdf" },
];

export const commodityMessage: ICommodityMessage = {
  daily: "ORDERS_THIS_DAY",
  weekly: "ORDERS_THIS_WEEK",
  monthly: "ORDERS_THIS_MONTH",
  yearly: "ORDERS_THIS_YEAR",
};

export const supportDropdown = [
  { id: "id", name: "ID" },
  { id: "name", name: "SP/SR" },
  { id: "email", name: "Email ID" },
];

export const adminSpecialOrderDropdown = [
  { id: "order_id", name: "Order ID" },
  { id: "requester_id", name: "Service Requester" },
  { id: "provider_id", name: "Service Provider" },
  { id: "from_location", name: "From(Location)" },
  { id: "to_location", name: "To(Location)" },
  { id: "truck_type", name: "Truck Type" },
  { id: "goods_type", name: "Commodities" },
  { id: "offer_title", name: "Offer Title" },
  { id: "weight", name: "Weight" },
];
export const offerOrderFilterFieldList = [
  { name: "Offer Title", value: "offer_title" },
  { name: "Service Provider", value: "service_provider" },
  { name: "Truck Type", value: "truck_type" },
  { name: "From(Location)", value: "from_location" },
  { name: "To(Location)", value: "to_location" },
];

export const offerStatusList = [
  { name: "Active", value: "active" },
  { name: "Expired", value: "expired" },
];

export enum subGoodTypeId {
  thirtyFive = 35,
}

export const noDataFound = "No Data Found";

export const orderId = "order_id";
export const vat = "vat";
export const isCanceled = 1;

export const siteImages = {
  SIDE_IMAGE_WEBP: "/login/side-image.webp",
  SIDE_IMAGE_JPG: "/login/side-image.jpg",
  SIDE_BANNER_IMAGE_JPG: "/images/login/side-image.jpg",
};

export const waslStatus = [
  { key: "Active", value: true },
  { key: "Inactive", value: false },
];

export const chatIcons: { [key: string]: string } = {
  "Direct Order": "icon-Direct-Orders",
  "Contract Order": "icon-Contracts",
  "Offer Order": "icon-Offers",
  "Special Order": "icon-Special-Orders",
};

export const noProfileImage = "assets/images/common/profile.png";

export const staticRoutes = {
  ADMIN: {
    LOGIN: "/admin/login",
    DASHBOARD: "/admin/dashboard",
    SERVICE_REQUESTER_LIST: "/admin/service-requester/list",
    SERVICE_REQUESTER_ADD: "/admin/service-requester/add",
    SERVICE_PROVIDER_LIST: "/admin/service-provider/list",
    SERVICE_PROVIDER_ADD: "/admin/service-provider/add",
    DRIVER_LIST: "/admin/driver-list/list",
    DRIVER_ADD: "/admin/driver-list/add",
    VEHICLE_LIST: "/admin/manage-vehicle/list",
    VEHICLE_ADD: "/admin/manage-vehicle/add",
    PROVIDER_PRICING: "/admin/manage-provider-pricing",
    REQUESTER_PRICING: "/admin/manage-requester-pricing",
    DIRECT_ORDERS: "/admin/direct-orders",
    ORDER_CONTRACTS: "/admin/order-contracts",
    SHAHEN_CONTRACT_ORDERS: "/admin/shahen-contract-orders",
    CITY_MASTER: "/admin/city-master",
    ADMIN_MANAGER_LIST: "/admin/admin-manager/list",
    ADMIN_MANAGER_ADD: "/admin/admin-manager/add",
    CHAT: "/admin/chat",
    SETTINGS: "/admin/admin-settings",
    SUPPORT_MESSAGES: "/admin/support-messages",
  },
  DEFAULT: {
    DASHBOARD: "/dashboard",
    DIRECT_ORDERS: "/direct-orders",
  },
  PROVIDER: {
    MANAGE_DRIVER: "/provider/manage-driver",
    MANAGE_VEHICLE: "/provider/manage-vehicle/list",
    CONTRACTS: "/provider/contracts",
    ORDER_HISTORY: "/provider/order-history",
    CHAT: "/provider/chat",
  },
  REQUESTER: {
    MY_CONTRACTS: "/requester/my-contracts",
    ORDER_HISTORY: "/requester/order-history",
    CHAT: "/requester/chat",
  },
};

export enum routesConstant {
  contractsOrderDetails = "/contracts/order-details/",
  myContractsOrderDetails = "/my-contracts/order-details/",
  directOrdersView = "/direct-orders/view/",
  orderContracts = "/order-contracts/",
  chat = "/chat",
  specialOrderOrderDetails = "/special-orders/order-details/",
  offerOrders = "/offer-orders/",
  offerListOfferOrders = "/offers-list/offer-orders/",
}

export enum notificationIconBgClass {
  bgDirectOrder = "bg-direct-order",
  bgContract = "bg-contract",
  bgSpecialOrder = "bg-special-order",
  bgOffers = "bg-offers",
  bgBell = "bg-bell",
}

export enum notificationIconClass {
  iconDirectOrders = "icon-Direct-Orders",
  iconContracts = "icon-Contracts",
  iconChat = "icon-chat",
  iconSpecialOrders = "icon-Special-Orders",
  iconOffers = "icon-Offers",
}

export const iconImage = "icon-image";

export const staticNoChatImage =
  "../../../../../assets/images/common/no-chat.webp";
