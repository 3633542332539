<div class="top-bar">
  <div class="d-flex just-end">
    <div class="detail"></div>
  </div>
</div>
<div class="error-section">
  <h2>{{ "PAGE_NOT_FOUND" | translate }}</h2>
  <a (click)="goback()" class="btn btn-secondary btn-small">{{
    "GO_BACK" | translate
  }}</a>
  <picture>
    <source srcset="assets/images/common/404.webp" type="image/webp" />
    <source srcset="assets/images/common/404.jpg" type="image/png" />
    <img src="assets/images/common/404.jpg" alt="404" title="404" />
  </picture>
</div>
