import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { reasonForRejection } from "@config/app-constants";
import { BaseComponent } from "@components/base.component";
import { AutoUnsubscribe } from "@decorator/autounsubscribe";

@Component({
  selector: "app-admin-handle-approve-reject",
  templateUrl: "./admin-handle-approve-reject.component.html",
  styleUrls: ["./admin-handle-approve-reject.component.scss"],
})
@AutoUnsubscribe()
export class AdminHandleApproveRejectComponent extends BaseComponent {
  reasonForRejection = reasonForRejection;
  @Input() isReasonVisible!: boolean;
  @Input() isApproveButton!: boolean;
  @Input() isDetailedReason = false;
  @Output() handleApproveReject: EventEmitter<{
    action: string;
    selectedReason?: string;
    detailedReason: string;
  }> = new EventEmitter<{
    action: string;
    selectedReason?: string;
    detailedReason: string;
  }>();

  selectedReason = "Account details";
  detailedReason = "";
  formValid = true;

  /**
   * Handle open modal
   */
  openModal(template: TemplateRef<string>) {
    this.modalRef = this.modalService.show(template, { class: "modal-md" });
  }

  /**
   * Handle decline order
   * @form form
   */
  decline(form: NgForm | undefined): void {
    if (this.modalRef) {
      this.modalRef.hide();
    }
    setTimeout(() => {
      form?.resetForm();
      this.selectedReason = "Account details";
    }, 200);
  }

  /**
   * Handle approve or reject order
   * @form form
   */
  approveReject(action: string, form?: NgForm) {
    if (form?.invalid) {
      return;
    }
    const eventData = {
      action: action,
      selectedReason: this.selectedReason,
      detailedReason: this.detailedReason,
    };
    this.handleApproveReject.emit(eventData);
    this.decline(form);
  }
}
