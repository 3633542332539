// @ts-nocheck
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { first } from "rxjs/operators";

// Import specific symbols instead of importing the entire file
import { CONFIG } from "@config/app-config";
import {
  CONFIGCONSTANTS,
  noProfileImage,
  SectionType,
  staticRoutes,
} from "@config/app-constants";
import { IErrorResponse } from "@model/errorResponse";
import { INotification, INotificationResponse } from "@model/notification";
import { ISuccessResponse } from "@model/successResponse";
import { errorHandler } from "@utils/common";
import {
  AllFilePath,
  languageStorage,
  OrderType,
  RoutesRole,
  StatusCode,
  storageUserKeys,
  TimePrefix,
  userDeviceToken,
  userStorageKeys,
} from "../../utils/enum-const";
import { AuthenticationService } from "../../_services/authentication.service";
import { ChatMessageService } from "../../_services/chatMessage.service";
import { CommonApiService } from "../../_services/common-api.service";
import { EncrDecrService } from "../../_services/encr-decr.service";
import { SpinnerService } from "../../_services/spinner.service";
export type AliasStrUndNull = string | undefined | null;

@Component({
  selector: "app-admin-layout",
  templateUrl: "./admin-layout.component.html",
  styleUrls: ["./admin-layout.component.scss"],
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
  modalRef!: BsModalRef;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  public adminLogo = AllFilePath.ADMIN_LOGO_PNG;
  public adminLogoWebp = AllFilePath.ADMIN_LOGO_WEBP;
  appTitle = CONFIGCONSTANTS.siteName;
  favImg = AllFilePath.FAV_IMG;
  logoImg = AllFilePath.LOGO_IMG;
  public currYear = moment().format("YYYY");
  fullname = "";
  currentState: AliasStrUndNull;
  prevState: AliasStrUndNull;
  prevUrl: AliasStrUndNull;
  adminRoleURL: AliasStrUndNull;
  currentAdmin: AliasStrUndNull;
  currentSubAdmin: AliasStrUndNull;
  isAdminRoute = false;
  adminName = "";
  logoUrl = "";
  role = "";
  staticRoutes = staticRoutes;
  notificationData: INotification[] = [];
  OrderType = OrderType;
  notificaitonDateFormat = CONFIGCONSTANTS.dateFormatListing;
  notificationTime = CONFIGCONSTANTS.momentTime12Format;
  timePrefix = TimePrefix;
  SectionType = SectionType;
  unReadCount = 0;
  private interval: number | undefined;
  protected errorHandlerResponse = errorHandler;
  public noProfileImage = noProfileImage;

  languageOption: boolean = CONFIGCONSTANTS.languageOption;
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private EncrDecr: EncrDecrService,
    private ManageCommonService: CommonApiService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private spinner: SpinnerService,
    private chatCountService: ChatMessageService
  ) {
    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = document.body.classList.contains(
        "sidebar-minimized"
      );
    });

    this.changes.observe(<Element>this.element, {
      attributes: true,
    });

    const url = this.router.url;
    const adminData = localStorage.getItem(storageUserKeys.CURRENT_ADMIN_USER);
    const subAdminData = localStorage.getItem(
      storageUserKeys.CURRENT_SUB_ADMIN_USER
    );
    this.currentAdmin = adminData
      ? JSON.parse(
          this.EncrDecr.get(
            CONFIG.EncrDecrKey,
            localStorage.getItem(storageUserKeys.CURRENT_ADMIN_USER)
          )
        )
      : null;

    this.currentSubAdmin = subAdminData
      ? JSON.parse(
          this.EncrDecr.get(
            CONFIG.EncrDecrKey,
            localStorage.getItem(storageUserKeys.CURRENT_SUB_ADMIN_USER)
          )
        )
      : null;

    if (
      url.startsWith(RoutesRole.ADMIN_ROUTE) &&
      (this.currentAdmin || this.currentSubAdmin)
    ) {
      this.isAdminRoute = true;

      const userInfo = this.currentAdmin
        ? JSON.parse(localStorage.getItem(userStorageKeys.ADMIN_INFO))
        : JSON.parse(localStorage.getItem(userStorageKeys.SUB_ADMIN_INFO));

      this.adminName = userInfo?.name;
      this.logoUrl = userInfo?.logo;

      this.role = this.currentAdmin
        ? this.currentAdmin.user.roles[0].display_name
        : this.currentSubAdmin.user.roles[0].display_name;
      this.adminRoleURL = RoutesRole.ADMIN_ROUTE;
    }
  }

  /**
   * Initial function
   */
  ngOnInit(): void {
    this.fullname = localStorage.getItem("fullName") ?? "";
    const classname = document.getElementsByClassName("dropdown");
    const onDropdownClick = function (e) {
      this.classList.toggle("active");
    };
    const elementsArray = Array.from(classname);

    for (const element of elementsArray) {
      element.addEventListener("click", onDropdownClick, false);
    }

    this.getNotifications();
    this.initTimeOutForNotificationApi();
  }

  /**
   * Handle admin logout event
   */
  onAdminLogout(deviceToken: string) {
    const payload = {
      device_token: deviceToken,
    };
    this.spinner.showLoader();
    this.authenticationService
      .logoutFromAccount(payload)
      .pipe()
      .subscribe(
        (resp: ISuccessResponse) => {
          if (resp.status_code === StatusCode.OK) {
            this.authenticationService.adminLogout();
            this.authenticationService.clearStorage(
              userStorageKeys.ADMIN_INFO,
              languageStorage.ADMIN_LAN,
              userDeviceToken.ADMIN_TOKEN
            );
            this.router.navigate([staticRoutes.ADMIN.LOGIN]);
            window.location.reload();
            this.spinner.hideLoader();
          }
        },
        (error: IErrorResponse) => {
          errorHandler(this.toastr, this.translateService, error);
          this.spinner.hideLoader();
        }
      );
    clearInterval(this.interval);
  }

  /**
   * Get Notifications Listing
   */
  private getNotifications() {
    this.ManageCommonService.getAllNotification()
      .pipe(first())
      .subscribe((resp: INotificationResponse) => {
        if (resp.status_code === StatusCode.OK) {
          this.notificationData = resp.data.data;
          this.unReadCount = resp.data?.unread_count;
          this.unReadMessageCount = resp?.data?.unread_message_count;
        }
      });
  }

  /**
   * Every 1 minutes notification API will call
   */
  public initTimeOutForNotificationApi() {
    this.interval = setInterval(() => {
      this.getNotifications();
    }, 60000);
  }

  /**
   * Format Notification Time
   */
  public formatNotificationDate(
    date: string,
    format = CONFIGCONSTANTS.ApiRequestFormat
  ) {
    return moment(date).format(format);
  }

  /**
   * Open notification box
   */
  openNotification() {
    const notificationBox = document.getElementById("notification-box");
    if (notificationBox) {
      notificationBox.classList.add("show");
    }
  }

  /**
   * Handle close notification box
   */
  closeNotification() {
    const notificationBox = document.getElementById("notification-box");
    if (notificationBox) {
      notificationBox.classList.remove("show");
    }
  }

  /**
   * Handle error profile image when image file or url is missing
   * @returns image URL
   */
  public onErrorProfileImage(event: Event): void {
    const imageElement = event.target as HTMLImageElement;
    imageElement.src = this.noProfileImage;
  }

  /**
   * Get user role type
   * @returns string
   */
  getRoutesRole() {
    return RoutesRole.ADMIN_ROUTE;
  }

  /**
   * Handle notification read
   * @params notificationId Integer
   * @returns boolean
   */
  notificationRead(notificationId: number) {
    this.closeNotification();
    this.ManageCommonService.notificationSeen(notificationId)
      .pipe(first())
      .subscribe((resp: ISuccessResponse) => {
        if (resp.status_code === StatusCode.OK) {
          return;
        }
      });
  }

  /**
   *
   * @param date : convert UTC to local date
   * @param format : YYYY-MM-DD HH:MM:SS
   * @returns Date
   */
  getNotificationTimeConvert(
    date: string,
    format = CONFIGCONSTANTS.dateFormatListing
  ) {
    const utcDate = new Date(date);
    const localDate = moment(utcDate).format(format);
    return localDate;
  }

  /**
   * Handle function for seen all notifications
   */
  seenAllAdminNotifications() {
    this.ManageCommonService.notificationSeenAll(UserRole.ADMIN_USER)
      .pipe(first())
      .subscribe(
        (response: ISuccessResponse) => {
          if (response.status_code === StatusCode.OK) {
            this.getNotifications();
          }
        },
        (error: IErrorResponse) => {
          this.errorHandlerResponse(
            this.toastr,
            this.translateService,
            error,
            () => {
              this.spinner.hideLoader();
            }
          );
        }
      );
  }

  /**
   * @param msg string
   * @returns string
   */
  getAdminDecodeMessage(msg: string) {
    if (msg) {
      const message = JSON.parse(msg);
      return message?.chat_data?.message;
    }
    return "";
  }

  /**
   * Destroy method - Clear Interval
   */
  ngOnDestroy() {
    // Clear the interval when the component is destroyed
    clearInterval(this.interval);
  }
}
