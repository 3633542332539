import { Injectable } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: "root",
})
export class SpinnerService {
  constructor(private ngxSpinner: NgxSpinnerService) {}

  /**
   * Show spinner common use for entire project
   */
  showLoader() {
    this.ngxSpinner.show();
  }

  /**
   * Hide spinner common use for entire project
   */
  hideLoader() {
    this.ngxSpinner.hide();
  }
}
