import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { first } from "rxjs/operators";
import {
  IProviderData,
  IProviderResponse,
  IServiceOrderProvider,
} from "@model/directOrdersDetails";
import { IErrorResponse } from "@model/errorResponse";
import { BaseComponent } from "@components/base.component";
import { DirectOrdersService } from "@services/direct-orders.service";

@Component({
  selector: "app-select-provider",
  templateUrl: "./select-provider.component.html",
  styleUrls: ["./select-provider.component.scss"],
})
export class SelectProviderComponent extends BaseComponent implements OnInit {
  providerList: IProviderData[] = [];
  loading = false;
  @Input() payloadData!: IServiceOrderProvider;
  @Output() getProviderData = new EventEmitter();
  @Output() noProviderFound = new EventEmitter();
  @Output() clickBackButton = new EventEmitter();
  @Output() showSummar = new EventEmitter();
  message = "";
  constructor(
    private ManageDirectOrderService: DirectOrdersService,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getProviderList(this.payloadData);
  }

  /**
   * Get Provider List
   */
  getProviderList(providerPayload: IServiceOrderProvider) {
    this.spinner.showLoader();
    this.ManageDirectOrderService.getProviderList(providerPayload)
      .pipe(first())
      .subscribe(
        (response: IProviderResponse) => {
          this.spinner.hideLoader();
          if (response.status_code === this.statusCode.OK) {
            this.providerList = response.data;
            this.showSummar.emit();
            if (this.providerList.length === 0) {
              this.noProviderFoundCheck();
            }
          }
        },
        (error: IErrorResponse) => {
          this.spinner.hideLoader();
          this.errorHandler(this.toastr, this.translateService, error);
        }
      );
  }

  /**
   * Get Selected Provider
   */
  getSelectedProvider(selectedProvider: IProviderData) {
    this.getProviderData.emit(selectedProvider);
  }

  /*
   * Handle case when no provider found
   */
  async noProviderFoundCheck() {
    this.message = await this.translate.get("NO_PROVIDER_FOUND").toPromise();
  }

  /*
   * Handle back to order create
   */
  backToOrderCreate() {
    this.clickBackButton.emit();
  }
}
