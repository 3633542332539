import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

// Import specific symbols instead of importing the entire file
import { CONFIG } from "@config/app-config";
import { AuthenticationService } from "@services/authentication.service";
import { EncrDecrService } from "@services/encr-decr.service";
import { RoutesRole, storageUserKeys, UserRole } from "@utils/enum-const";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  isServiceRequesterRoute = false;
  isServiceProviderRoute = false;
  isAdminRoute = false;

  constructor(
    private EncrDecr: EncrDecrService,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  /**
   * Get Current Loged in User Details
   * @returns Object
   */
  getCurrentUserDetails() {
    const decrypted = localStorage.getItem("currentUser");
    const currentUser = this.EncrDecr.get(
      CONFIG.EncrDecrKey as string,
      decrypted
    );
    if (currentUser) {
      const currentUserJson = JSON.parse(currentUser);
      return currentUserJson;
    }
    return null;
  }

  /**
   * Get Current User Access Token
   * @returns String
   */
  getAccessToken() {
    const decrypted = localStorage.getItem("currentUser");
    const currentUser = this.EncrDecr.get(
      CONFIG.EncrDecrKey as string,
      decrypted
    );
    if (currentUser) {
      const currentUserJson = JSON.parse(currentUser);
      return currentUserJson.access_token;
    }
    return null;
  }

  /**
   * Get Current User Third Party Token
   * @returns String
   */
  getThirdpartyId() {
    const decrypted = localStorage.getItem("currentUser");
    const currentUser = this.EncrDecr.get(
      CONFIG.EncrDecrKey as string,
      decrypted
    );
    if (currentUser) {
      const currentUserJson = JSON.parse(currentUser);
      return currentUserJson.third_party_id;
    }
    return null;
  }

  /**
   * Check Confirm Password
   * @returns String
   */
  checkPassword(pass: string) {
    return pass
      .replace(/\*/g, "[*]")
      .replace(/\^/g, "[^]")
      .replace(/\$/g, "[$]");
  }

  /**
   * Get Current User Third Party Token
   * @returns String
   */
  getLoginUserType() {
    const url = this.router.url;
    if (url.startsWith(RoutesRole.SERVICE_PROVIDER)) {
      this.isServiceProviderRoute = true;
      return UserRole.SERVICE_PROVIDER;
    } else if (url.startsWith(RoutesRole.SERVICE_REQUESTER)) {
      this.isServiceRequesterRoute = true;
      return UserRole.SERVICE_REQUESTER;
    } else {
      this.isAdminRoute = true;
      return UserRole.SUPER_ADMIN_USER
        ? UserRole.SUPER_ADMIN_USER
        : UserRole.ADMIN_USER;
    }
  }

  logoutUser() {
    const loginUserKey = this.isServiceRequesterRoute
      ? storageUserKeys.CURRENT_USER_REQUESTER
      : storageUserKeys.CURRENT_USER_PROVIDER;
    this.authenticationService.logout(loginUserKey);
  }

  /**
   * Get Current User Third Party Token
   * @returns String
   */
  getUserType() {
    const url = this.router.url;
    if (url.startsWith(RoutesRole.SERVICE_PROVIDER)) {
      this.isServiceProviderRoute = true;
      return RoutesRole.SERVICE_PROVIDER;
    } else if (url.startsWith(RoutesRole.SERVICE_REQUESTER)) {
      this.isServiceRequesterRoute = true;
      return RoutesRole.SERVICE_REQUESTER;
    } else {
      this.isAdminRoute = true;
      return RoutesRole.ADMIN_ROUTE;
    }
  }

  getRequestrerType() {
    if (this.isAdminRoute) {
      return UserRole.ADMIN_USER;
    } else if (this.isServiceRequesterRoute) {
      return UserRole.SERVICE_REQUESTER;
    } else {
      return UserRole.SERVICE_PROVIDER;
    }
  }

  /**
   * Get Current User Third Party Token
   * @returns String
   */
  getUserStorageKey() {
    const url = this.router.url;
    if (url.startsWith(RoutesRole.SERVICE_PROVIDER)) {
      return storageUserKeys.CURRENT_USER_PROVIDER;
    } else if (url.startsWith(RoutesRole.SERVICE_REQUESTER)) {
      return storageUserKeys.CURRENT_USER_REQUESTER;
    } else {
      return storageUserKeys.CURRENT_ADMIN_USER;
    }
  }

  /**
   * Handle active user get
   * @returns Return active user object
   */
  currentActiveUser() {
    const url = this.router.url;
    const loginUser =
      localStorage.getItem(this.getUserStorageKey()) ||
      (url.startsWith(RoutesRole.ADMIN_ROUTE) &&
        localStorage.getItem(storageUserKeys.CURRENT_SUB_ADMIN_USER));
    return loginUser;
  }
}
