import { Injectable, Inject, PLATFORM_ID, OnDestroy } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class WindowWidthService implements OnDestroy {
  private readonly windowWidthSubject = new BehaviorSubject<number>(0);
  windowWidth$ = this.windowWidthSubject.asObservable();

  constructor(@Inject(PLATFORM_ID) private readonly platformId: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.setInitialWindowWidth();
      window.addEventListener("resize", this.handleResize);
    }
  }

  private setInitialWindowWidth() {
    if (isPlatformBrowser(this.platformId)) {
      this.windowWidthSubject.next(window.innerWidth);
    }
  }

  private readonly handleResize = () => {
    if (isPlatformBrowser(this.platformId)) {
      this.windowWidthSubject.next(window.innerWidth);
    }
  };

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      window.removeEventListener("resize", this.handleResize);
    }
  }
}
