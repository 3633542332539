import { Component } from "@angular/core";
import { NgForm } from "@angular/forms";
import { first } from "rxjs/operators";
import { IErrorResponse } from "../../../model/errorResponse";
import { ISuccessResponse } from "../../../model/successResponse";
import {
  languageStorage,
  userDeviceToken,
  userStorageKeys,
} from "../../../utils/enum-const";
import { BaseComponent } from "../../../_components/base.component";
import { AutoUnsubscribe } from "../../../_decorator/autounsubscribe";
import { AuthenticationService } from "../../../_services/authentication.service";
import { CommonService } from "../../../_services/common.service";
import { ProfileService } from "../../../_services/profile.service";

@Component({
  selector: "app-profile-password-change",
  templateUrl: "./profile-password-change.component.html",
  styleUrls: ["./profile-password-change.component.scss"],
})
@AutoUnsubscribe()
export class ProfilePasswordChangeComponent extends BaseComponent {
  loading = false;
  submitted = false;
  showPassword = false;
  showConfirmPassword = false;
  currentPassword = false;
  conf_pass_match = "";

  public model = {
    current_password: "",
    password: "",
    confirm_password: "",
  };
  constructor(
    private profileService: ProfileService,
    private commonService: CommonService,
    private authenticationService: AuthenticationService
  ) {
    super();
  }

  /**
   * Password Change
   * @param form Password change form
   */
  public onChangePassword(form: NgForm) {
    this.submitted = true;
    // stop here if form is invalid
    if (form.invalid) {
      return;
    }
    this.spinner.showLoader();
    this.profileService
      .changePassword(this.model)
      .pipe(first())
      .subscribe(
        (data: ISuccessResponse) => {
          if (data.status_code === this.statusCode.OK) {
            this.spinner.hideLoader();
            this.toastr.success(data.message);
            if (this.isAdminRoute) {
              this.authenticationService.adminLogout();
              this.authenticationService.clearStorage(
                userStorageKeys.ADMIN_INFO,
                languageStorage.ADMIN_LAN,
                userDeviceToken.ADMIN_TOKEN
              );
              this.router.navigate(["/admin/login"]);
            } else {
              this.commonService.logoutUser();
              this.router.navigate([this.getRoutesRole() + "/login"]);
            }
            window.location.reload();
          }
        },
        (error: IErrorResponse) => {
          this.spinner.hideLoader();
          this.errorHandler(this.toastr, this.translateService, error);
        }
      );
  }

  /**
   * Password Validation
   * @param passForm Password change form
   */
  passwordValidation() {
    this.conf_pass_match = this.commonService.checkPassword(
      this.model.password
    );
  }

  /**
   * Handle toggle password visiblity
   */
  togglePasswordVisibility(field: string) {
    if (field === "password") {
      this.showPassword = !this.showPassword;
    } else if (field === "confirm_password") {
      this.showConfirmPassword = !this.showConfirmPassword;
    } else if (field === "current_password") {
      this.currentPassword = !this.currentPassword;
    }
  }
}
