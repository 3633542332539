<div class="d-col">
  <div class="map-area google-map" [ngClass]="googleMapId">
    <div
      class="form-group map-search"
      [class.active]="isTyping"
      (mouseenter)="isHovering = true"
      (mouseleave)="isHovering = false"
    >
      <input
        type="text"
        name="search_location"
        class="form-control"
        placeholder="{{ 'SEARCH_LOCATION' | translate }}"
        [attr.id]="'autocomplete_search_' + googleMapId"
        required
        (focus)="isTyping = true"
        (blur)="onBlur()"
        [dir]="isEnglish ? 'ltr' : 'rtl'"
        [ngClass]="isHideSearch ? 'element-hide' : ''"
      />
      <i class="icon-search" [ngClass]="isHideSearch ? 'element-hide' : ''"></i>
    </div>
    <a
      *ngIf="!isMapModalView"
      class="map-modal"
      (click)="openModal(openMapModal)"
    >
      <i class="icon-map-zoom"></i>
    </a>
    <input id="pac-input" hidden />
    <div
      class="map"
      [ngClass]="googleMapId ? 'destination-graph' : ''"
      [attr.id]="googleMapId"
    ></div>
  </div>
</div>

<ng-template #openMapModal>
  <app-map-details-contract-direct-orders
    [googleMapId]="'modal-map'"
    (getMapData)="getMapLocation($event)"
    [lat]="this.lat"
    [long]="this.long"
    [isMapModalView]="true"
    [isHideSearch]="this.isHideSearch"
  >
  </app-map-details-contract-direct-orders>
  <div id="save-map">
    <div class="d-col just-end modal-footer">
      <a role="button" (click)="decline()" class="btn bordered-btn btn-small">{{
        "CANCEL" | translate
      }}</a>
      <a
        role="button"
        *ngIf="!isHideSearch"
        (click)="submitMapDetails()"
        class="btn btn-secondary btn-small"
        >{{ "SAVE" | translate }}</a
      >
    </div>
  </div>
</ng-template>
