import { Injectable } from "@angular/core";
import { persistPageWiseFilter } from "@config/app-constants";

@Injectable({
  providedIn: "root",
})
export class FilterStorageService {
  /**
   * Get filter state
   */
  getState(key: string, defaultValue: any, forceFullyGet: boolean = false) {
    if (!persistPageWiseFilter && forceFullyGet === false) return defaultValue;
    const data = sessionStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    } else {
      return defaultValue;
    }
  }

  /**
   * Save single filter state
   */
  saveSingleState(key: string, value: string, forceFullySave: boolean = false) {
    if (!persistPageWiseFilter && forceFullySave === false) return;
    sessionStorage.setItem(key, value);
  }

  /**
   * get single saved filter state
   */
  getSingleState(
    key: string,
    defaultValue: string | number,
    forceFullyGet: boolean = false
  ) {
    if (!persistPageWiseFilter && forceFullyGet === false) return defaultValue;
    const data = sessionStorage.getItem(key);
    if (data) {
      return data;
    } else {
      return defaultValue;
    }
  }
}
