<app-language-selection>
</app-language-selection>
<div class="form-data">
    <app-left-register-panel>
    </app-left-register-panel>
    <div class="right-section">
        <div class="logo">
            <picture>
                <app-right-register-panel>
                </app-right-register-panel>    
            </picture>
        </div>
        <div class="form-area">
            <h1>{{ 'SERVICE_REQUESTER_REGISTRATION' | translate }}</h1>
            <p class="subtitle">{{ 'EXISTING_SERVICE_REQUESTER' | translate }}
                <a [routerLink]="['/requester/login/']">{{ 'LOGIN' | translate }}</a>
            </p>
            
            <div class="registration">
                <span class="box individual">
                    <div class="icon-area">
                        <i class="icon-Individual"></i>
                    </div>
                    <h3>{{ 'INDIVIDUAL' | translate }}</h3>
                    <a (click)="setRequesterUserType(this.UserRequesterType.INDIVIDUAL)"></a>
                </span>
                <span class="box company">
                    <div class="icon-area">
                        <i class="icon-Company"></i>
                    </div>
                    <h3>{{ 'COMPANY' | translate }}</h3>
                    <a (click)="setRequesterUserType(this.UserRequesterType.COMPANY)"></a>
                </span>
            </div>
        </div>
    </div>
</div>
