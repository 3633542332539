import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { CONFIG } from "@config/app-config";
import {
  AdminAddOfferPayload,
  IGetOfferDetailsResponse,
  IOffer,
  IOfferResponse,
} from "@model/offer";
import { IOfferOrderFilter, IOfferOrderResponse } from "@model/offerOrders";
import { ISuccessResponse } from "@model/successResponse";
import { UserRole } from "@utils/enum-const";
import { CommonService } from "@services/common.service";

@Injectable({
  providedIn: "root",
})
export class AdminOfferOrdersService {
  constructor(private http: HttpClient, private commonService: CommonService) {
    this.userType = this.commonService.getLoginUserType();
  }
  userType = "";

  /**
   * Handle admin offer order list
   * @data Get admin offer order details
   */
  getAdminOfferOrderList(data: IOfferOrderFilter) {
    return this.http
      .post<IOfferOrderResponse>(CONFIG.adminOfferOrdersList, data)
      .pipe(
        map((response: IOfferOrderResponse) => {
          return response;
        })
      );
  }

  /**
   * Handle admin offer list
   * @data Get admin offer listing payload
   */
  getAdminOffersList(data: IOfferOrderFilter) {
    return this.http.post<IOfferResponse>(CONFIG.adminViewOfferList, data).pipe(
      map((response: IOfferResponse) => {
        return response;
      })
    );
  }

  /**
   * Handle offer details based on offer ID
   * @offerId Offer id
   */
  getOfferDetails(offerId: number) {
    return this.http
      .get<IGetOfferDetailsResponse>(CONFIG.adminOfferDetails + offerId)
      .pipe(
        map((response: IGetOfferDetailsResponse) => {
          return response;
        })
      );
  }

  /**
   * Add new offer
   * @offerPayload Add new offer payload
   */
  addNewOffer(offerPayload: AdminAddOfferPayload) {
    return this.http.post<IOffer>(CONFIG.addNewOffer, offerPayload).pipe(
      map((response: IOffer) => {
        return response;
      })
    );
  }

  /**
   * Edit new offer
   * @editOfferPayload edit new offer payload
   * @offerId offer ID
   */
  editOffer(editOfferPayload: AdminAddOfferPayload, offerId: number) {
    return this.http
      .post<IOffer>(CONFIG.editOffer + offerId + "/update", editOfferPayload)
      .pipe(
        map((response: IOffer) => {
          return response;
        })
      );
  }

  /**
   * Get order details
   * @offerOrderId offer order ID
   */
  getOrderDetails(offerOrderId: number) {
    let getDirectOrderURL: string;
    if (this.userType === UserRole.SUPER_ADMIN_USER) {
      getDirectOrderURL = CONFIG.offerOrderDetails;
    } else if (this.userType === UserRole.SERVICE_PROVIDER) {
      getDirectOrderURL = CONFIG.getProviderDirectOrderDetials;
    } else {
      getDirectOrderURL = CONFIG.getRequesterDirectOrderDetials;
    }

    return this.http.get<IOffer>(getDirectOrderURL + offerOrderId).pipe(
      map((response: IOffer) => {
        return response;
      })
    );
  }

  /**
   * Delete offer
   * @offerId delete offer ID
   */
  deleteOffers(offerId: number) {
    return this.http
      .get<ISuccessResponse>(CONFIG.deleteOffer + offerId + "/destroy")
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }
}
