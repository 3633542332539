import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AdminOrderDetailsComponent } from "@views/admin/admin-direct-orders/admin-order-details/admin-order-details.component";
import { OrderHistoryComponent } from "@views/order-history/order-history.component";

const routes: Routes = [
  {
    path: "",
    component: OrderHistoryComponent,
    data: {
      title: "ORDER_HISTORY",
    },
  },
  {
    path: "new",
    component: OrderHistoryComponent,
    data: {
      title: "ORDER_HISTORY",
    },
  },
  {
    path: "order-details/:id",
    component: AdminOrderDetailsComponent,
    data: {
      title: "ORDER_DETAILS",
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OrderHistoryRoutingModule {}
