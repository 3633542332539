<ul>
  <li routerLinkActive="active" (click)="openModal(logoutTemplate)">
    {{ "LOGOUT" | translate }}
  </li>
</ul>
<ng-template #logoutTemplate>
  <app-confirmation-popup-modal
    [popupMessage]="'CONFIRM_LOGOUT_MESSAGE'"
    (submit)="handleLogout()"
    (close)="decline()"
  >
  </app-confirmation-popup-modal>
</ng-template>
