import { Component, EventEmitter, Output } from "@angular/core";
import { NgForm } from "@angular/forms";
import { BaseComponent } from "@components/base.component";

@Component({
  selector: "app-reject-order-popup",
  templateUrl: "./reject-order-popup.component.html",
  styleUrls: ["./reject-order-popup.component.scss"],
})
export class RejectOrderPopupComponent extends BaseComponent {
  @Output() orderReject = new EventEmitter<any>();
  @Output() close = new EventEmitter();
  model = {
    comment: "",
  };
  submitted = false;
  isCancel = false;

  /**
   * Cancel order submit
   */
  cancelOrderSubmit(form: NgForm) {
    this.submitted = true;
    if (form.invalid) {
      return;
    }
    this.orderReject.emit(this.model);
  }

  /**
   * Close Popup
   */
  closePopup() {
    this.close.emit();
  }

  /**
   * Handle cancel event
   */
  cancel() {
    this.isCancel = true;
  }
}
