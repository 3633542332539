import {
  Component,
  Input,
  OnChanges,
  OnInit,
  TemplateRef,
} from "@angular/core";
import { BaseComponent } from "@components/base.component";
import { AutoUnsubscribe } from "@decorator/autounsubscribe";
import { IMapProps } from "@model/common";
import { mapProperties } from "@utils/common";

@Component({
  selector: "app-multiple-markers-map",
  templateUrl: "./multiple-markers-map.component.html",
  styleUrls: ["./multiple-markers-map.component.scss"],
})
@AutoUnsubscribe()
export class MapMultipleMarkersComponent extends BaseComponent
  implements OnInit, OnChanges {
  @Input() latlong!: IMapProps[];
  @Input() googleMapId = "googleMap";
  @Input() isMapModalView = false;

  public markers: google.maps.Marker[] = [];
  public map!: google.maps.Map;

  ngOnInit(): void {
    setTimeout(() => {
      this.initMap();
    }, 1000);
  }

  ngOnChanges(): void {
    if (this.map && this.latlong?.length > 0) {
      this.addMarkersToMap();
    }
  }

  /**
   * Initialize the map
   */
  public initMap(): void {
    const southArabiaCenter = new google.maps.LatLng(24.7136, 46.6753); // Default center
    const mapProp: google.maps.MapOptions = {
      ...mapProperties,
      center: southArabiaCenter,
      zoom: 5, // Set a fixed zoom level
      zoomControlOptions: {
        position: google?.maps?.ControlPosition?.LEFT_CENTER,
      },
      mapTypeControlOptions: {
        position: google?.maps?.ControlPosition?.LEFT_TOP,
      },
    };

    const nodeElement = document.getElementById(this.googleMapId);
    if (nodeElement) {
      this.map = new google.maps.Map(nodeElement, mapProp);
    }

    // Add markers if latlong data is already available
    if (this.latlong?.length > 0) {
      this.addMarkersToMap();
    }
  }

  /**
   * Add markers to the map based on latlong input
   */
  private addMarkersToMap(): void {
    this.clearMarkers(); // Clear any existing markers

    const bounds = new google.maps.LatLngBounds(); // Create bounds object

    this.latlong.forEach((markerData: IMapProps) => {
      if (markerData.lat && markerData.lng) {
        const position = new google.maps.LatLng(
          parseFloat(markerData.lat as any),
          parseFloat(markerData.lng as any)
        );

        const marker = new google.maps.Marker({
          position: position,
          map: this.map,
        });

        this.markers.push(marker);
        bounds.extend(position); // Extend the bounds to include this marker
      }
    });

    // Check if there are any markers added
    if (this.markers.length > 0) {
      // Fit the map to the bounds of all markers
      this.map.fitBounds(bounds);

      // Enforce a minimum zoom level
      const listener = google.maps.event.addListener(this.map, "idle", () => {
        if (this.map.getZoom() < 10) {
          this.map.setZoom(5); // Set minimum zoom level
        }
        google.maps.event.removeListener(listener);
      });
    }
  }

  /**
   * Clear all existing markers from the map
   */
  private clearMarkers(): void {
    this.markers.forEach((marker) => marker.setMap(null));
    this.markers = [];
  }

  /**
   * Handle modal popup open
   * @params template string
   */
  openModal(template?: TemplateRef<string>) {
    this.isMapModalView = true;
    this.modalRef = this.modalService.show(template, { class: "modal-xxl" });
  }

  /**
   * Handle modal popup close
   */
  closeMapModal(): void {
    this.isMapModalView = false;
    this.modalRef.hide();
  }
}
