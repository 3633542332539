import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { RoutesRole } from "@utils/enum-const";
import { AuthenticationService } from "@services/authentication.service";
import { CommonService } from "@services/common.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    private ManageCommonService: CommonService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          this.translate
            .get("SESSION_EXPIRED")
            .subscribe((msg) => this.toastr.error(msg));

          const userKey = this.ManageCommonService.getUserStorageKey();
          const url = this.router.url;
          if (url.startsWith(RoutesRole.ADMIN_ROUTE)) {
            this.authenticationService.adminLogout();
          } else {
            this.authenticationService.logout(userKey);
          }
          this.router.navigate([
            this.ManageCommonService.getUserType() + "/login",
          ]);
          window.location.reload();
        }
        const error = err.error || err.statusText;
        return throwError(error);
      })
    );
  }
}
