<div class="body-part">
  <div class="top-area">
    <div class="top-left">
      <h1>{{ "ORDER_HISTORY" | translate }}</h1>
      <span class="breadcrum"
        ><a [routerLink]="[this.getRoutesRole() + '/dashboard']">{{
          "HOME" | translate
        }}</a>
        <a
          *ngIf="isContract"
          [routerLink]="[this.getRoutesRole() + '/contracts']"
          >{{ "CONTRACT" | translate }}</a
        >
        {{ "ORDER_HISTORY" | translate }}</span
      >
    </div>
  </div>
  <div class="content-area">
    <div class="search-bar">
      <form
        class="space-between flex-col"
        (ngSubmit)="filterApply()"
        #orderHistoryFilter="ngForm"
        novalidate
      >
        <div class="search-form-data">
          <div class="search d-flex">
            <div class="offer-id-box">
              <div class="form-group">
                <div class="select-dropdown">
                  <select
                    class="form-control-select"
                    [(ngModel)]="filter.fields"
                    name="fields"
                  >
                    <option
                      *ngFor="let item of orderHistorySearchFields"
                      class="select-dropdown__list-item"
                      [value]="item.value"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
                <i class="icon-arrow-down icon-arrow"></i>
              </div>
            </div>
            <div
              [ngClass]="filter.search_txt ? 'floatingLabel' : ''"
              class="form-group search-group"
            >
              <span>
                <input
                  appSpaceRemove
                  [(ngModel)]="filter.search_txt"
                  type="text"
                  name="search_txt"
                  class="form-control"
                  placeholder=" "
                  [dir]="isEnglish ? 'ltr' : 'rtl'"
                  appFloatingLabelInput
                />
                <i class="icon-search"></i>
                <label class="floating-label">{{ "SEARCH" | translate }}</label>
              </span>
            </div>
          </div>

          <div class="dropdown">
            <div class="form-group">
              <ng-select
                id="p_type"
                name="p_type"
                class="form-control single"
                #p_type="ngModel"
                [items]="orderHistorystatusList"
                [notFoundText]="noDataFound"
                bindLabel="label"
                bindValue="key"
                [searchable]="filter.p_type ? false : true"
                placeholder="{{ 'STATUS' | translate }}"
                [(ngModel)]="filter.p_type"
              >
              </ng-select>
            </div>
          </div>
          <div class="dropdown" *ngIf="isContract">
            <div class="form-group">
              <ng-select
                id="goods_type"
                name="goods_type"
                class="form-control single"
                #goods_type="ngModel"
                [items]="goodsTypeList"
                [notFoundText]="noDataFound"
                bindLabel="name"
                bindValue="id"
                [searchable]="filter.goods_type ? false : true"
                placeholder="{{ 'SELECT_GOODS' | translate }}"
                [(ngModel)]="filter.goods_type"
              >
                <ng-template ng-option-tmp let-item="item">
                  <div title="{{ item.name }}">{{ item.name }}</div>
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="dropdown" *ngIf="isContract">
            <div class="form-group">
              <ng-select
                id="truck_type"
                [items]="truckTypesList | keyvalue"
                bindLabel="value.name"
                bindValue="value.id"
                [notFoundText]="noDataFound"
                [(ngModel)]="filter.truck_type"
                placeholder="{{ 'TRUCK_TYPE' | translate }}"
                [searchable]="filter.truck_type ? false : true"
                name="truck_type"
              >
              </ng-select>
            </div>
          </div>
          <div class="input-date">
            <div
              class="form-group"
              [ngClass]="filter.date_from ? 'floatingLabel' : ''"
            >
              <input
                [(ngModel)]="filter.date_from"
                class="form-control"
                placeholder=" "
                #date_from="ngModel"
                #DateFromDob="bsDatepicker"
                placement="bottom"
                bsDatepicker
                autocomplete="off"
                [bsConfig]="dateRangeConfig"
                (keydown)="(true)"
                (paste)="(false)"
                [dir]="isEnglish ? 'ltr' : 'rtl'"
                appNumberOnly
                name="date_from"
                type="text"
              />
              <label class="floating-label">{{ "FROM" | translate }}</label>
              <i class="icon-calendar" (click)="DateFromDob.toggle()"></i>
            </div>
          </div>
          <div class="input-date">
            <div
              [ngClass]="filter.date_to ? 'floatingLabel' : ''"
              class="form-group"
            >
              <input
                [(ngModel)]="filter.date_to"
                placeholder=" "
                #date_to="ngModel"
                #DateToDob="bsDatepicker"
                placement="bottom"
                bsDatepicker
                autocomplete="off"
                [dir]="isEnglish ? 'ltr' : 'rtl'"
                [bsConfig]="dateRangeConfig"
                (keydown)="(true)"
                (paste)="(false)"
                name="date_to"
                type="text"
                class="form-control"
                [minDate]="filter.date_from"
                appNumberOnly
              />
              <label class="floating-label">{{ "TO" | translate }}</label>
              <i class="icon-calendar" (click)="DateToDob.toggle()"></i>
            </div>
          </div>
          <button type="submit" class="btn btn-primary btn-small">
            {{ "APPLY" | translate }}
          </button>
          <button
            class="btn bordered-btn btn-small"
            type="button"
            (click)="resetOrderHistoryFilter()"
          >
            {{ "RESET" | translate }}
          </button>
        </div>
      </form>
    </div>
    <div class="order-list">
      <div class="pos-rel">
        <div class="processing" *ngIf="loadingIndicator">
          {{ "PROCESSING" | translate }}
        </div>
        <ngx-datatable
          rowHeight="rowHeight"
          class="material striped row-pointer"
          [rows]="orderHistoryList"
          [reorderable]="reorderable"
          [columnMode]="ColumnMode.force"
          #datatable
          headerHeight="headerHeight"
          footerHeight="footerHeight"
          [scrollbarH]="scrollbarH"
          [externalSorting]="serverSorting"
          [externalPaging]="serverPaging"
          [limit]="size"
          [messages]="dtMessages"
          [count]="totalReords"
          (page)="setPage($event)"
          [offset]="pageNumber"
          (activate)="onActivate($event)"
        >
          <ngx-datatable-column
            prop="order_id"
            name="{{ 'ID' | translate }}"
            [sortable]="false"
            [width]="140"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span title="{{ row.order_id }}" class="orderId">{{
                row.order_id
              }}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="{{ 'STATUS' | translate }}"
            [sortable]="false"
            prop="orderStatus"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span
                [ngStyle]="{ color: row?.status_color?.hex }"
                class="whitespace-pre-wrap"
              >
                {{ value !== undefined ? row?.orderStatus : "-" }}
              </span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            [sortable]="false"
            prop="provider.company_name"
            name="{{ 'PROVIDER' | translate }}"
            *ngIf="isServiceRequesterRoute"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span class="text-center text-wrap">{{
                row.shahen_order === shahenOrder.isShahen ||
                this.orderType[row.section] === this.orderType.special_order
                  ? orderTypes.SHAHEN
                  : value
                  ? value
                  : "-"
              }}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="{{ 'REQUESTER' | translate }}"
            [sortable]="false"
            *ngIf="isServiceProviderRoute"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span>{{
                row?.shahen_order === 1
                  ? orderTypes.SHAHEN
                  : row?.requester?.service_requester?.company_name || "-"
              }}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="{{ 'LOADING_DATE' | translate }}"
            prop="shipment_date"
            [sortable]="false"
          >
            <ng-template let-value="value" ngx-datatable-cell-template>
              <div class="text-center text-wrap">
                {{ value | customDate: dateFormatListing }}
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="{{ 'FROM_LOCATION' | translate }}"
            [sortable]="false"
            prop="city_from_details.name"
          >
          </ngx-datatable-column>
          <ngx-datatable-column
            [sortable]="false"
            name="{{ 'INTERMEDIATE_LOCATION' | translate }}"
            [width]="200"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <div [title]="getRemainingLocationsTooltip(row?.order_locations)">
                <span
                  class="whitespace-pre-wrap orderListStatusTruncate vertical-middle character-truncate"
                >
                  {{ displayIntermediateLocations(row?.order_locations) }}
                </span>
                <span class="display-location vertical-middle">
                  <ng-container *ngIf="row?.order_locations?.length > 2">
                    +{{ row?.order_locations?.length - 2 }}
                  </ng-container>
                </span>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            [sortable]="false"
            prop="city_to_details.name"
            name="{{ 'TO_LOCATION' | translate }}"
          >
          </ngx-datatable-column>
          <ngx-datatable-column
            name="{{ 'TRUCK_TYPE' | translate }}"
            prop="truck.name"
            [sortable]="false"
          >
          </ngx-datatable-column>
          <ngx-datatable-column
            prop="goods_type.name"
            [sortable]="false"
            name="{{ 'COMMODITIES' | translate }}"
          >
          </ngx-datatable-column>
          <ngx-datatable-column
            [sortable]="false"
            prop="weight"
            [width]="100"
            name="{{ 'WEIGHT' | translate }}"
          >
          </ngx-datatable-column>
          <ngx-datatable-column
            name="{{ 'TRUCKS_QUANTITY' | translate }}"
            [sortable]="false"
            [width]="80"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span>
                {{
                  row?.providers && row?.providers?.length > 0
                    ? row?.total_provider_quantity
                    : row?.quantity
                }}
              </span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="{{ 'PRIC1E' | translate }}"
            [sortable]="false"
            *ngIf="this.isServiceProviderRoute"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span [ngStyle]="{ color: '#2da5de' }">
                {{ this.measurementUnits.SAR }}
                {{
                  row.providers.length > 0
                    ? row?.price
                    : row?.order_prices?.price
                }}
                /{{ "EACH" | translate }}
              </span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            [sortable]="false"
            *ngIf="this.isServiceRequesterRoute"
            name="{{ 'PRICE' | translate }}"
          >
            <ng-template ngx-datatable-cell-template let-row="row">
              <span [ngStyle]="{ color: '#2da5de' }">
                {{ this.measurementUnits.SAR }}
                {{
                  row.section === this.orderTypes.MEMBERSHIP ||
                  this.orderTypes.ORDER === row.section
                    ? row?.order_prices?.price
                    : row.section === this.orderTypes.SPECIAL_ORDER
                    ? row?.sr_price_special_order
                    : row?.order_prices?.price
                }}
                /{{ "EACH" | translate }}
              </span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            [sortable]="false"
            name="{{ 'ORDER_TYPE' | translate }}"
          >
            <ng-template
              ngx-datatable-cell-template
              let-value="value"
              let-row="row"
            >
              <span>{{
                row?.offer_id
                  ? this.orderType.offer_order
                  : this.orderType[row.section]
              }}</span>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
        <div class="show-records">
          <div class="form-group">
            <select
              name="showRecords"
              [(ngModel)]="size"
              class="form-control-select"
              (change)="changeOrderListingLimit(size)"
            >
              <option [value]="limit" *ngFor="let limit of limitList">{{
                limit
              }}</option>
            </select>
            <i class="icon-arrow-down"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-spinner></app-spinner>
