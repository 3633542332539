<div class="body-part">
  <div class="top-area">
    <div class="top-left">
      <h1>{{ "CHANGE_PASSWORD" | translate }}</h1>
      <span class="breadcrum"
        ><a [routerLink]="[this.getRoutesRole() + '/dashboard']">{{
          "HOME" | translate
        }}</a>
        <a [routerLink]="[this.getRoutesRole() + '/profile']">{{
          "ACCOUNT_DETAILS" | translate
        }}</a>
        {{ "CHANGE_PASSWORD" | translate }}</span
      >
    </div>
  </div>

  <div class="content-area">
    <div class="order-list">
      <div class="add-truck-form">
        <form
          (ngSubmit)="onChangePassword(changePassword)"
          novalidate
          #changePassword="ngForm"
          InvalidFormScrollNGFormDirective
        >
          <div class="row">
            <div class="d-col">
              <div
                class="form-group"
                [ngClass]="model.current_password ? 'floatingLabel' : ''"
              >
                <span>
                  <input
                    [dir]="isEnglish ? 'ltr' : 'rtl'"
                    type="{{ currentPassword ? 'text' : 'password' }}"
                    [(ngModel)]="model.current_password"
                    name="current_password"
                    class="form-control"
                    required
                    minlength="8"
                    maxlength="30"
                    id="current_password"
                    #current_password="ngModel"
                    (change)="passwordValidation()"
                    placeholder=" "
                    appFloatingLabelInput
                  />
                  <i
                    title="{{
                      this.currentPassword
                        ? ('HIDE' | translate)
                        : ('SHOW' | translate)
                    }}"
                    [ngClass]="
                      this.currentPassword ? 'icon-lock' : 'icon-unlock'
                    "
                    (click)="togglePasswordVisibility('current_password')"
                  ></i>
                  <div
                    class="form-control-feedback text-danger"
                    *ngIf="current_password.errors && submitted"
                  >
                    <span *ngIf="current_password.errors.required">
                      {{ "PLEASE_ENTER_CURRENT_PASSWORD" | translate }}
                    </span>
                  </div>
                  <label class="floating-label">{{
                    "CURRENT_PASSWORD" | translate
                  }}</label>
                </span>
              </div>
            </div>
            <div class="d-col">
              <div
                class="form-group"
                [ngClass]="model.password ? 'floatingLabel' : ''"
              >
                <span>
                  <input
                    type="{{ showPassword ? 'text' : 'password' }}"
                    [(ngModel)]="model.password"
                    name="password"
                    class="form-control"
                    required
                    id="password"
                    #password="ngModel"
                    [dir]="isEnglish ? 'ltr' : 'rtl'"
                    (change)="passwordValidation()"
                    minlength="8"
                    maxlength="30"
                    [pattern]="CommonRegx.passwordRegx"
                    placeholder=" "
                    appFloatingLabelInput
                  />
                  <i
                    title="{{
                      this.showPassword
                        ? ('HIDE' | translate)
                        : ('SHOW' | translate)
                    }}"
                    [ngClass]="this.showPassword ? 'icon-lock' : 'icon-unlock'"
                    (click)="togglePasswordVisibility('password')"
                  ></i>
                  <div
                    class="form-control-feedback text-danger"
                    *ngIf="password.errors && submitted"
                  >
                    <span *ngIf="password.errors.required">
                      {{ "PLEASE_ENTER_PASSWORD" | translate }}
                    </span>
                    <span
                      *ngIf="
                        password.errors.maxlength || password.errors.minlength
                      "
                    >
                      {{ "PASSWORD_LENGTH_ERROR" | translate }}
                    </span>
                    <span
                      *ngIf="
                        password.errors.pattern &&
                        !password.errors.maxlength &&
                        !password.errors.minlength
                      "
                    >
                      {{ "PLEASE_ENTER_NEW_PASSWORD_VALIDATION" | translate }}
                    </span>
                  </div>
                  <label class="floating-label">{{
                    "NEW_PASSWORD" | translate
                  }}</label>
                </span>
              </div>
            </div>
            <div class="d-col">
              <div
                class="form-group"
                [ngClass]="model.confirm_password ? 'floatingLabel' : ''"
              >
                <span>
                  <input
                    type="{{ showConfirmPassword ? 'text' : 'password' }}"
                    [(ngModel)]="model.confirm_password"
                    name="confirm_password"
                    class="form-control"
                    required
                    [dir]="isEnglish ? 'ltr' : 'rtl'"
                    [pattern]="conf_pass_match"
                    id="confirm_password"
                    #confirm_password="ngModel"
                    placeholder=" "
                    minlength="8"
                    maxlength="30"
                    appFloatingLabelInput
                  />
                  <i
                    title="{{
                      this.showConfirmPassword
                        ? ('HIDE' | translate)
                        : ('SHOW' | translate)
                    }}"
                    [ngClass]="
                      this.showConfirmPassword ? 'icon-lock' : 'icon-unlock'
                    "
                    (click)="togglePasswordVisibility('confirm_password')"
                  ></i>
                  <div
                    class="form-control-feedback text-danger"
                    *ngIf="confirm_password.errors && submitted"
                  >
                    <span *ngIf="confirm_password.errors.required">
                      {{ "PLEASE_ENTER_CONFIRM_PASSWORD" | translate }}
                    </span>
                    <span *ngIf="confirm_password.errors.pattern">
                      {{ "CONFIRM_PASSWORD_NOT_MATCHED" | translate }}
                    </span>
                  </div>
                  <label class="floating-label">{{
                    "CONFIRM_PASSWORD" | translate
                  }}</label>
                </span>
              </div>
            </div>
            <div class="d-col just-end">
              <a
                [routerLink]="[this.getRoutesRole() + '/profile']"
                class="btn bordered-btn btn-small"
                >{{ "CANCEL" | translate }}</a
              >
              <button type="submit" class="btn btn-secondary btn-small">
                {{ "UPDATE" | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<app-spinner></app-spinner>
