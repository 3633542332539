import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import { first } from "rxjs/operators";
import { IStatusApiResponse, IStatusItem } from "@model/directOrdersDetails";
import { IErrorResponse } from "@model/errorResponse";
import { IDownloadInvoiceResponse } from "@model/payment";
import { OrderFilterKey, userType } from "@utils/enum-const";
import { BaseComponent } from "@components/base.component";
import { AutoUnsubscribe } from "@decorator/autounsubscribe";
import { CommonApiService } from "@services/common-api.service";
import { OrdersCommonService } from "@services/orders-common-service";
import { IDownloadInvoicePayload, IOrderDetails } from "@model/common";

@Component({
  selector: "app-admin-order-info",
  templateUrl: "./admin-order-info.component.html",
  styleUrls: ["./admin-order-info.component.scss"],
})
@AutoUnsubscribe()
export class AdminOrderInfoComponent extends BaseComponent
  implements OnInit, OnChanges {
  @Input() orderDetails!: IOrderDetails;
  @Input() isDirectOrderDropVisible: any;
  @Output() changeStatus = new EventEmitter();
  userType = userType;
  orderStatusList: IStatusItem[] = [];
  model = {
    order_status: "",
  };
  storeProgressBarClass!: string;
  constructor(
    private ManageOrdersCommonService: OrdersCommonService,
    private commonService: CommonApiService
  ) {
    super();
  }
  ngOnChanges(): void {
    this.handleProgressBar();
  }

  /**
   * Handle init function
   */
  ngOnInit(): void {
    this.getOrderStatusList();
    this.model.order_status = this.orderDetails?.status;
  }

  /**
   * Handle get order status
   */
  getOrderStatusList() {
    this.spinner.showLoader();
    const orderPayload = {
      section: OrderFilterKey.order,
    };
    this.ManageOrdersCommonService.getOrderStatus(orderPayload)
      .pipe(first())
      .subscribe(
        (resp: IStatusApiResponse) => {
          if (resp.status_code === this.statusCode.OK) {
            this.orderStatusList = resp.data;
            this.spinner.hideLoader();
          }
        },
        (error: IErrorResponse) => {
          this.spinner.hideLoader();
          this.errorHandler(this.toastr, this.translateService, error);
        }
      );
  }

  /**
   * Handle download invoice
   */
  downloadInvoice(invoiceType: any) {
    this.spinner.showLoader();
    const payload: IDownloadInvoicePayload = {
      orderid: this.orderDetails?.order_id as number,
      type: invoiceType,
    };
    this.commonService
      .downloadInvoice(payload)
      .pipe(first())
      .subscribe(
        (data: IDownloadInvoiceResponse) => {
          this.spinner.hideLoader();
          if (data.status_code === this.statusCode.OK) {
            window.open(data?.data?.report_url, "_blank");
          }
        },
        (error: IErrorResponse) => {
          this.spinner.hideLoader();
          this.errorHandler(this.toastr, this.translateService, error);
        }
      );
  }

  /**
   * Handle open file in new tab
   */
  openFileInNewTab(url: string | undefined): void {
    if (url) {
      window.open(url, "_blank");
    }
  }

  /**
   * Change Order Status
   */
  changeOrderStatus() {
    this.changeStatus.emit(this.model.order_status);
  }

  /**
   * Open Change Status Confrimation Modal
   */
  openConfirmationModal(modalContext: TemplateRef<string>) {
    this.modalRef = this.modalService.show(modalContext, { class: "modal-md" });
  }

  /**
   * Approve Status Change
   */
  approveChange() {
    this.modalRef.hide();
    this.changeOrderStatus();
  }

  /**
   * Decline Status Change
   */
  declineChange() {
    this.modalRef.hide();
    this.model.order_status = this.orderDetails?.status;
  }
  handleProgressBar() {
    let quantityClass: string;
    if (
      (this.orderDetails?.quantity as number) < 10 ||
      ((this.orderDetails?.quantity as number) >= 10 &&
        (this.orderDetails?.quantity as number) < 100)
    ) {
      quantityClass = this.TypeOfProgressBar.TWODIGIT;
    } else {
      quantityClass = this.TypeOfProgressBar.THREEDIGIT;
    }
    this.storeProgressBarClass = quantityClass;
  }
}
