import { Component, OnInit } from "@angular/core";
import { UserRequesterType } from "@config/app-constants";
import { BaseComponent } from "@components/base.component";

@Component({
  selector: "app-services-requester-type",
  templateUrl: "./services-requester-type.component.html",
  styleUrls: ["./services-requester-type.component.scss"],
})
export class ServicesRequesterTypeComponent extends BaseComponent
  implements OnInit {
  constructor() {
    super();
    this.checkCurrentUserAndRedirect();
  }

  requesterTypeURL = "";

  ngOnInit(): void {
    this.setRequesterUserType(this.UserRequesterType.COMPANY);
  }

  /*
   * Set requester user type
   */
  setRequesterUserType(userType: UserRequesterType): void {
    sessionStorage.setItem("requesterType", userType);
    this.router.navigate(["/requester/registration"]);
  }
}
