import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import { first } from "rxjs/operators";
import { isInvoiceVisibleStatus } from "@config/app-constants";
import { IErrorResponse } from "@model/errorResponse";
import { IDownloadInvoiceResponse } from "@model/payment";
import { ISuccessResponse } from "@model/successResponse";
import { orderTypes, userType } from "@utils/enum-const";
import { BaseComponent } from "@components/base.component";
import { AutoUnsubscribe } from "@decorator/autounsubscribe";
import { CommonApiService } from "@services/common-api.service";
import { OrdersCommonService } from "@services/orders-common-service";
import { IDownloadInvoicePayload, IOrderDetails } from "@model/common";

@Component({
  selector: "app-requester-order-info",
  templateUrl: "./requester-order-info.component.html",
  styleUrls: ["./requester-order-info.component.scss"],
})
@AutoUnsubscribe()
export class RequesterOrderInfoComponent extends BaseComponent
  implements OnInit, OnChanges {
  @Input() orderDetails!: IOrderDetails;
  @Output() selectProvider = new EventEmitter();
  truck_quantity = 0;
  vehicle_count = 0;
  @Output() acceptTruckOrder = new EventEmitter();
  @Output() detailsForm = new EventEmitter();
  @Output() viewTripDetails = new EventEmitter();
  @Output() close = new EventEmitter();
  isInvoiceTruckVisible = false;
  orderTypes = orderTypes;
  userType = userType;
  typeOfOrder: any;
  isInvoiceVisibleStatus = isInvoiceVisibleStatus;
  storeProgressBarClass!: string;

  constructor(
    private ManageOrdersCommonService: OrdersCommonService,
    private commonService: CommonApiService
  ) {
    super();
  }
  ngOnChanges(): void {
    this.handleProgressBar();
  }

  /**
   * Handle init function
   */
  ngOnInit(): void {
    this.checkRoute();
    if (this.isInvoiceVisibleStatus.includes(this.orderDetails?.status)) {
      this.isInvoiceTruckVisible = true;
    } else {
      this.isInvoiceTruckVisible = false;
    }
  }

  /**
   * Handle check route
   */
  checkRoute() {
    if (this.router.url.includes("offer")) {
      this.typeOfOrder = this.orderType.offer_order;
    }
  }

  /**
   * Handle provider selection confirmation
   */
  selectNewProviderConfirmation() {
    this.modalRef.hide();
    this.selectProvider.emit();
  }

  /**
   * Open Truck Modal
   */
  openTruckModal(template: TemplateRef<string>) {
    this.truck_quantity = this.orderDetails?.quantity as number;
    this.vehicle_count = this.orderDetails?.vehicle_count as number;

    if (this.truck_quantity > 0) {
      this.modalRef = this.modalService.show(template, { class: "modal-md" });
    }
  }

  /**
   * Handle close modal
   */
  decline() {
    this.close.emit();
    this.modalRef.hide();
  }

  /**
   * Handle accept order
   */
  acceptOrder() {
    this.spinner.showLoader();
    this.ManageOrdersCommonService.acceptOrder(
      this.orderDetails?.order_id as number
    )
      .pipe(first())
      .subscribe(
        (data: ISuccessResponse) => {
          this.detailsForm.emit();
          this.spinner.hideLoader();
          this.modalRef.hide();
          if (data.status_code === this.statusCode.OK) {
            this.toastr.success(data.message);
          }
        },
        (error: IErrorResponse) => {
          this.detailsForm.emit();
          this.spinner.hideLoader();
          this.modalRef.hide();
          this.errorHandler(
            this.toastr,
            this.translateService,
            error,
            () => {}
          );
        }
      );
  }

  /**
   * Download Invoice
   */
  downloadInvoice() {
    const payload: IDownloadInvoicePayload = {
      orderid: this.orderDetails?.order_id as number,
      type: this.userType.REQUESTER,
    };
    this.spinner.showLoader();
    this.commonService
      .downloadInvoice(payload)
      .pipe(first())
      .subscribe(
        (data: IDownloadInvoiceResponse) => {
          this.spinner.hideLoader();
          window.open(data?.data?.report_url, "_blank");
        },
        (error: IErrorResponse) => {
          this.spinner.hideLoader();
          this.errorHandler(
            this.toastr,
            this.translateService,
            error,
            () => {}
          );
        }
      );
  }

  /**
   * Open Modal
   */
  openModal() {
    this.viewTripDetails.emit();
  }

  /**
   * Select provider confirmation
   */
  selectProviderConfirmation(template: TemplateRef<string>) {
    this.modalRef = this.modalService.show(template, { class: "modal-md" });
  }

  /**
   * Close select provider modal
   */
  closeSelectProviderModal() {
    this.modalRef.hide();
  }
  handleProgressBar() {
    let quantityClass: string;
    if (this.orderDetails?.quantity) {
      if (
        this.orderDetails?.quantity < 10 ||
        (this.orderDetails?.quantity >= 10 && this.orderDetails?.quantity < 100)
      ) {
        quantityClass = this.TypeOfProgressBar.TWODIGIT;
      } else {
        quantityClass = this.TypeOfProgressBar.THREEDIGIT;
      }
      this.storeProgressBarClass = quantityClass;
    }
  }
}
