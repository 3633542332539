import { Component, EventEmitter, Output } from "@angular/core";
import { BaseComponent } from "@components/base.component";

@Component({
  selector: "app-select-new-provider-popup",
  templateUrl: "./select-new-porivder.component.html",
  styleUrls: ["./select-new-porivder.component.scss"],
})
export class SelectNewProviderPopupComponent extends BaseComponent {
  @Output() close = new EventEmitter();
  @Output() accept = new EventEmitter();

  /**
   * Handle cancel click
   */
  cancel() {
    this.close.emit();
  }

  /**
   * Handle accept provider
   */
  acceptProvider() {
    this.accept.emit();
  }
}
