import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { CONFIG, PROVIDER, REQUESTER } from "@config/app-config";
import {
  GetProviderDetailResponse,
  ServiceProviderInput,
} from "@model/serviceProvider";
import {
  GetRequesterDetailResponse,
  RequesterRegistrationPayload,
} from "@model/serviceRequester";
import { ISuccessResponse } from "@model/successResponse";
import { IAction } from "@model/common";

@Injectable({
  providedIn: "root",
})
export class RequesterProviderService {
  constructor(private http: HttpClient) {}

  /**
   * Requster data add by admin side
   */
  adminRequesterRegister(requesterData: RequesterRegistrationPayload) {
    return this.http
      .post<ISuccessResponse>(CONFIG.adminRegstRequester, requesterData)
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }

  /**
   * Change status of requester user from admin
   */
  changeStatusRequester(status: string, id: number) {
    return this.http
      .post<ISuccessResponse>(CONFIG.changeStatusRequester + id, {
        status: status,
      })
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }

  /**
   * Get single requester details
   */
  getSingleRequester(id: number) {
    return this.http
      .get<GetRequesterDetailResponse>(
        CONFIG.getSingleRequesterData + id + REQUESTER
      )
      .pipe(
        map((response: GetRequesterDetailResponse) => {
          return response;
        })
      );
  }

  /**
   * Update requester data from admin
   */
  updateRequester(requesterData: RequesterRegistrationPayload, id: number) {
    return this.http
      .post<ISuccessResponse>(CONFIG.updateRequesterData + id, requesterData)
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }

  /**
   * Provider register by admin
   */
  adminProviderRegister(providerData: ServiceProviderInput) {
    return this.http
      .post<ISuccessResponse>(CONFIG.adminProviderRegister, providerData)
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }

  /**
   * Get single provider
   */
  getSingleProvider(id: number | null) {
    return this.http
      .get<GetProviderDetailResponse>(
        CONFIG.getSingleRequesterData + id + PROVIDER
      )
      .pipe(
        map((response: GetProviderDetailResponse) => {
          return response;
        })
      );
  }

  /**
   * Update provider from admin
   */
  updateProvider(requesterData: ServiceProviderInput, id: number) {
    return this.http
      .post<ISuccessResponse>(CONFIG.updateProviderData + id, requesterData)
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }

  /**
   * Requester approve or reject
   */
  approverRejectReqPro(payload: IAction) {
    return this.http
      .post<ISuccessResponse>(CONFIG.approveRejectSRSP, payload)
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }
}
