import { Directive, ElementRef, HostListener, Input } from "@angular/core";
import { CommonRegx, numberOnlyRegex } from "@validators/common.validator";

@Directive({
  selector: "input[appNumberOnly]",
})
export class NumberOnlyDirective {
  @Input() allowFloatingPoint = false;
  @Input() decimals = 0;

  constructor(private el: ElementRef) {}

  @HostListener("keypress", ["$event"]) onInputChange(
    event: KeyboardEvent
  ): boolean {
    const key = event.key; // Modern replacement for `which` and `keyCode`

    // Allow control keys like Backspace, Delete, Arrow keys, etc.
    if (
      key === "Backspace" ||
      key === "Delete" ||
      key === "ArrowLeft" ||
      key === "ArrowRight"
    ) {
      return true;
    }

    // Allow numbers
    if (CommonRegx.digitMatchRegex.test(key)) {
      return true;
    }

    // Allow a single decimal point if floating-point numbers are allowed
    if (this.allowFloatingPoint && key === ".") {
      const value = this.el.nativeElement.value;
      if (!value.includes(".")) {
        return true;
      }
    }

    // Validate against decimals if floating-point numbers are allowed
    if (this.allowFloatingPoint && this.decimals > 0) {
      const value = this.el.nativeElement.value + key;
      const regExpString = numberOnlyRegex.replace(
        /\{decimals\}/g,
        this.decimals.toString()
      );
      return new RegExp(regExpString).test(value);
    }

    // Prevent all other keys
    return false;
  }
}
