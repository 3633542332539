import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { first } from "rxjs/operators";
import {
  ITermAndPolicyData,
  ITermsAndConditionAndPrivacyPolicy,
} from "@model/contact";
import { IErrorResponse } from "@model/errorResponse";
import { languageTypes, sectionType } from "@utils/enum-const";
import { BaseComponent } from "@components/base.component";
import { AutoUnsubscribe } from "@decorator/autounsubscribe";
import { CommonApiService } from "@services/common-api.service";
import { LanguageService } from "@services/language.service";

@Component({
  selector: "app-terms-and-condtion-section",
  templateUrl: "./terms-and-condition.component.html",
  styleUrls: ["./terms-and-condition.component.scss"],
})
@AutoUnsubscribe()
export class TermsAndConditionComponent extends BaseComponent
  implements OnInit {
  @Input() section = "";
  @Output() closeModal = new EventEmitter();
  sectionType = sectionType;
  termsAndCondition!: ITermAndPolicyData;
  isEnglish = false;
  routeSub!: Subscription;
  _id!: string;
  constructor(
    private commonService: CommonApiService,
    private languageSwitcher: LanguageService,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {
    super();
  }
  ngOnInit() {
    this.routeSub = this.route.queryParams.subscribe((params) => {
      this._id = params["lang"];
      setTimeout(() => {}, 100);
    });
    if (this._id) {
      this.translate.use(this._id);
      // Set enlish langugage
      this.isEnglish = this._id === languageTypes.DEFAULT_ADMIN;
    } else {
      const userKey = this.getUser() + "_lan";
      const currentLanguage = this.languageSwitcher.getUserDefaultLanguage(
        userKey
      );
      this.isEnglish = currentLanguage === languageTypes.DEFAULT_ADMIN;
    }
    this.commonService
      .getTermsAndCondition()
      .pipe(first())
      .subscribe(
        (response: ITermsAndConditionAndPrivacyPolicy) => {
          if (response) {
            this.spinner.hideLoader();
            this.termsAndCondition = response?.data;
          }
        },
        (error: IErrorResponse) => {
          this.spinner.hideLoader();
          this.errorHandler(this.toastr, this.translateService, error);
        }
      );
  }

  /*
   * Handle open modal
   */
  openModal(template: TemplateRef<string>) {
    this.spinner.showLoader();
    this.commonService
      .getTermsAndCondition()
      .pipe(first())
      .subscribe(
        (response: ITermsAndConditionAndPrivacyPolicy) => {
          if (response) {
            this.spinner.hideLoader();
            this.termsAndCondition = response?.data;
          }
        },
        (error: IErrorResponse) => {
          this.spinner.hideLoader();
          this.errorHandler(this.toastr, this.translateService, error);
        }
      );
    this.modalRef = this.modalService.show(template);
  }

  /*
   * Handle close modal
   */
  decline(): void {
    this.closeModal.emit(true);
  }

  /*
   * Handle change language
   */
  changeLang() {
    if (!this.isEnglish) {
      this.isEnglish = true;
    } else {
      this.isEnglish = false;
    }
  }

  /*
   * Handle delete button
   */
  handleDelete() {
    this.decline();
  }
}
