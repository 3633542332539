import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CONFIG } from "@config/app-config";
import { CommonService } from "@services/common.service";
import { EncrDecrService } from "@services/encr-decr.service";
import {
  languageTypes,
  RoutesRole,
  storageUserKeys,
  UserRole,
} from "@utils/enum-const";
import { Observable } from "rxjs";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private EncrDecr: EncrDecrService,
    private router: Router,
    private commonServices: CommonService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const url = this.router.url;
    const userRoles = this.getUserRoles();
    const currentLanguage = this.getCurrentLanguage();

    for (const role of userRoles) {
      const storedUser = localStorage.getItem(role.key);
      if (storedUser) {
        const currentUser = this.getDecryptedUser(storedUser);
        if (currentUser?.access_token && url.startsWith(role.role)) {
          request = this.setAuthorizationHeaders(
            request,
            currentUser,
            currentLanguage
          );
          break;
        }
      }
    }
    return next.handle(request);
  }

  private getUserRoles() {
    return [
      {
        key: storageUserKeys.CURRENT_USER_PROVIDER,
        role: RoutesRole.SERVICE_PROVIDER,
      },
      {
        key: storageUserKeys.CURRENT_USER_REQUESTER,
        role: RoutesRole.SERVICE_REQUESTER,
      },
      { key: storageUserKeys.CURRENT_ADMIN_USER, role: RoutesRole.ADMIN_ROUTE },
      {
        key: storageUserKeys.CURRENT_SUB_ADMIN_USER,
        role: RoutesRole.ADMIN_ROUTE,
      },
    ];
  }

  private getCurrentLanguage(): string {
    const getUser = this.commonServices.getRequestrerType();
    const userKey = getUser + "_lan";
    if (getUser === UserRole.ADMIN_USER) {
      return languageTypes.DEFAULT_ADMIN;
    }
    return localStorage.getItem(userKey) ?? languageTypes.DEFAULT_ADMIN;
  }

  private getDecryptedUser(storedUser: string) {
    return JSON.parse(
      this.EncrDecr.get(CONFIG.EncrDecrKey as string, storedUser)
    );
  }

  private setAuthorizationHeaders(
    request: HttpRequest<any>,
    currentUser: any,
    currentLanguage: string
  ): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${currentUser?.access_token ?? ""}`,
        "Accept-Language": currentLanguage ?? languageTypes.DEFAULT,
        Lang: currentLanguage ?? languageTypes.DEFAULT,
      },
    });
  }
}
