<div>
  <div class="text-center">
    <a
      (click)="
        currentStatus !== requesterStatusEnum.pending &&
          openModal(templateStatus)
      "
      title="{{ 'CHANGE_STATUS' | translate }}"
      [class.disabled-link]="currentStatus === requesterStatusEnum.pending"
      [style.cursor]="
        currentStatus === requesterStatusEnum.pending
          ? 'not-allowed'
          : 'pointer'
      "
      ><i
        [ngClass]="{
          'icon-toggle-on': currentStatus === requesterStatusEnum.activated,
          'icon-toggle-off':
            currentStatus === requesterStatusEnum.pending ||
            currentStatus === requesterStatusEnum.inactive
        }"
        aria-hidden="true"
      ></i>
    </a>
  </div>

  <ng-template #templateStatus>
    <app-confirmation-popup-modal
      [popupMessage]="'CONFIRM_STATUS_MSG'"
      (submit)="changeRequesterStatus()"
      (close)="decline()"
    >
    </app-confirmation-popup-modal>
  </ng-template>
</div>
