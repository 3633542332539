<div class="d-col {{ this.googleMapId }}">
  <div class="map-area google-map {{ this.googleMapId }}">
    <div
      class="form-group map-search"
      [class.active]="isMapTyping"
      (mouseenter)="isMapHovering = true"
      (mouseleave)="isMapHovering = false"
    >
      <input
        type="text"
        name="search_location"
        class="form-control {{ this.googleMapId }}"
        placeholder="{{ 'SEARCH_LOCATION' | translate }}"
        id="autocomplete_search_{{ this.googleMapId }}"
        required
        (focus)="isMapTyping = true"
        (blur)="onMapBlur()"
        [dir]="isEnglish ? 'ltr' : 'rtl'"
        [ngClass]="isHideSearch ? 'element-hide' : ''"
      />
      <i class="icon-search" [ngClass]="isHideSearch ? 'element-hide' : ''"></i>
    </div>
    <input id="pac-input" hidden />
    <div
      class="map {{ this.destinationGraph ? 'destination-graph' : '' }}"
      id="{{ this.googleMapId }}"
    ></div>
  </div>
</div>
