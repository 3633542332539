import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "dateMonthFormat",
})
export class DateMonthFormatPipe implements PipeTransform {
  transform(value: string): string {
    const date = new Date(value);
    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    return date.toLocaleDateString(undefined, options);
  }
}
