<app-language-selection *ngIf="!this.isAdminRoute"> </app-language-selection>
<div class="form-data">
  <app-left-register-panel> </app-left-register-panel>

  <div class="right-section">
    <div class="logo">
      <picture>
        <app-right-register-panel> </app-right-register-panel>
      </picture>
    </div>
    <div class="form-area">
      <h1>{{ "RESET_PASSWORD" | translate }}</h1>
      <div class="forgot-password-form">
        <form
          (ngSubmit)="onResetPassword(resetPassword)"
          novalidate
          #resetPassword="ngForm"
          InvalidFormScrollNGFormDirective
        >
          <div
            class="form-group"
            [ngClass]="model.password ? 'floatingLabel' : ''"
          >
            <span>
              <input
                [dir]="isEnglish ? 'ltr' : 'rtl'"
                type="{{ showPassword ? 'text' : 'password' }}"
                [(ngModel)]="model.password"
                name="password"
                class="form-control"
                required
                id="password"
                #password="ngModel"
                minlength="8"
                maxlength="30"
                placeholder=" "
                (change)="checkPass()"
                [pattern]="CommonRegx.passwordRegx"
                appFloatingLabelInput
              />
              <i
                title="{{
                  this.showPassword
                    ? ('HIDE' | translate)
                    : ('SHOW' | translate)
                }}"
                [ngClass]="this.showPassword ? 'icon-lock' : 'icon-unlock'"
                (click)="togglePasswordVisibility('password')"
              ></i>
              <div
                class="form-control-feedback text-danger"
                *ngIf="password.errors && submitted"
              >
                <span *ngIf="password.errors.required">
                  {{ "PLEASE_ENTER_PASSWORD" | translate }}
                </span>
                <span
                  *ngIf="password.errors.maxlength || password.errors.minlength"
                >
                  {{ "PASSWORD_LENGTH_ERROR" | translate }}
                </span>
                <span
                  *ngIf="
                    password.errors.pattern &&
                    !password.errors.maxlength &&
                    !password.errors.minlength
                  "
                >
                  {{ "PLEASE_ENTER_NEW_PASSWORD_VALIDATION" | translate }}
                </span>
              </div>
              <label class="floating-label">{{
                "NEW_PASSWORD" | translate
              }}</label>
            </span>
          </div>
          <div class="form-group">
            <div
              class="form-group"
              [ngClass]="model.confirm_password ? 'floatingLabel' : ''"
            >
              <span>
                <input
                  type="{{ showConfirmPassword ? 'text' : 'password' }}"
                  [(ngModel)]="model.confirm_password"
                  name="confirm_password"
                  class="form-control"
                  required
                  [dir]="isEnglish ? 'ltr' : 'rtl'"
                  [pattern]="conf_pass_match"
                  id="confirm_password"
                  #confirm_password="ngModel"
                  minlength="8"
                  maxlength="30"
                  placeholder=" "
                  appFloatingLabelInput
                />
                <i
                  title="{{
                    this.showPassword
                      ? ('HIDE' | translate)
                      : ('SHOW' | translate)
                  }}"
                  [ngClass]="
                    this.showConfirmPassword ? 'icon-lock' : 'icon-unlock'
                  "
                  (click)="togglePasswordVisibility('confirm_password')"
                ></i>
                <div
                  class="form-control-feedback text-danger"
                  *ngIf="confirm_password.errors && submitted"
                >
                  <span *ngIf="confirm_password.errors.required">
                    {{ "PLEASE_ENTER_CONFIRM_PASSWORD" | translate }}
                  </span>
                  <span *ngIf="confirm_password.errors.pattern">
                    {{ "CONFIRM_PASSWORD_NOT_MATCHED" | translate }}
                  </span>
                </div>
                <label class="floating-label">{{
                  "CONFIRM_PASSWORD" | translate
                }}</label>
              </span>
            </div>
          </div>
          <div class="row">
            <div class="d-col space-between">
              <a
                [routerLink]="[this.getRoutesRole() + '/login']"
                class="btn bordered-btn btn-small"
                >{{ "CANCEL" | translate }}</a
              >
              <button type="submit" class="btn btn-secondary btn-small">
                {{ "SAVE" | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<app-spinner></app-spinner>
