import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "[appDivCloseOnClick]",
})
export class DivCloseOnClickDirective {
  private isNotificationBoxOpen = false;
  constructor(private el: ElementRef) {}
  @HostListener("document:click", ["$event"])
  onClick(): void {
    if (this.isNotificationBoxOpen) {
      this.el.nativeElement.classList.remove("show");
      this.isNotificationBoxOpen = false;
    } else {
      this.isNotificationBoxOpen = true;
    }
  }
}
