import { Component, Input, OnInit } from "@angular/core";
import { BaseComponent } from "../../_components/base.component";

@Component({
  selector: "app-right-register-panel",
  templateUrl: "./right-register-panel.component.html",
})
export class RightRegisterPanelComponent extends BaseComponent
  implements OnInit {
  @Input() isLoginScreen!: boolean;

  requesterTypeURL = "";
  isAdmin = false;
  ngOnInit(): void {
    this.requesterTypeURL = this.requesterType();
    this.isAdmin = this.isAdminRoute;
  }
}
