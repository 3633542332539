<div class="d-flex flex-col">
  <h2>{{ title | translate }}</h2>
  <div class="edit-date-time-modal">
    <div class="d-col d-col-2">
      <div
        class="form-group"
        [ngClass]="model.shipment_date ? 'floatingLabel' : ''"
      >
        <span>
          <input
            type="date"
            [dir]="isEnglish ? 'ltr' : 'rtl'"
            [(ngModel)]="model.shipment_date"
            name="shipment_date"
            type="text"
            class="form-control"
            required
            [minDate]="minDate"
            [maxDate]="maxDate"
            #shipment_date="ngModel"
            #Dob="bsDatepicker"
            placement="bottom"
            bsDatepicker
            autocomplete="off"
            [bsConfig]="dateRangeConfig"
            (paste)="(false)"
            appNumberOnly
            placeholder=" "
          />
          <label class="floating-label">{{
            getPlaceHolder("date") | translate
          }}</label>
        </span>
        <i class="icon-calendar" (click)="Dob.toggle()"></i>
        <div
          class="form-control-feedback text-danger"
          *ngIf="shipment_date.errors && isSubmitted"
        >
          <span
            *ngIf="shipment_date.errors.required || shipment_date.errors.valid"
          >
            {{ "PLEASE_SELECT_DATE" | translate }}
          </span>
        </div>
      </div>
    </div>
    <div class="d-col d-col-2">
      <div
        class="form-group"
        [ngClass]="model.shipment_time ? 'floatingLabel' : ''"
      >
        <input
          autocomplete="off"
          [dir]="isEnglish ? 'ltr' : 'rtl'"
          [(ngModel)]="model.shipment_time"
          name="shipment_time"
          type="time"
          class="form-control select-time"
          placeholder=" "
          required
          #shipment_time="ngModel"
          (paste)="(false)"
          appNumberOnly
        />
        <label class="floating-label">{{
          getPlaceHolder("time") | translate
        }}</label>
        <i class="icon-clock"></i>
        <div
          class="form-control-feedback text-danger"
          *ngIf="shipment_time.errors && isSubmitted"
        >
          <span *ngIf="shipment_time.errors.required">
            {{ "PLEASE_SELECT_TIME" | translate }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex buttonDiv">
    <a (click)="this.closePopup()" class="btn bordered-btn btn-small">{{
      "CANCEL" | translate
    }}</a>
    <a (click)="this.submitDateTime()" class="btn btn-secondary btn-small">{{
      "SUBMIT" | translate
    }}</a>
  </div>
</div>
