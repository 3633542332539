import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseComponent } from "@components/base.component";
import { CONFIG } from "@config/app-config";
import { IFileUpload } from "@model/successResponse";
import { fileUploadFormat, UserRole } from "@utils/enum-const";
import { first, map } from "rxjs/operators";
@Injectable({
  providedIn: "root",
})
export class FileUploadService extends BaseComponent {
  imageChangedEvent: Event | string = "";
  isRemoveAPI = false;
  fileType: fileUploadFormat | null | undefined | string =
    fileUploadFormat.IMAGE;
  fileName = "";
  fileData = [];
  url: string | undefined = this.noProfileImage as string;
  constructor(private http: HttpClient) {
    super();
  }

  /**
   * Handle file upload common function
   */
  async uploadServiceFile(
    event: Event,
    fileType = fileUploadFormat.IMAGE,
    getUserType = UserRole.SERVICE_PROVIDER
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const img = event;
      const userType = getUserType;
      const inputElement = event.target as HTMLInputElement;
      if (inputElement && inputElement.files && inputElement.files[0]) {
        const files = inputElement.files[0];
        let validation;

        if (fileType === fileUploadFormat.IMAGE) {
          validation = this.CONSTANT.imageUpload;
        } else if (fileType === fileUploadFormat.EXCEL) {
          validation = this.CONSTANT.ExcelFileUpload;
        } else {
          validation = this.CONSTANT.documentUpload;
        }

        const imgSize = validation.MAX_FILE_SIZE;
        const temp = "";
        const fileTypes = validation.ALLOW_FILE_TYPE;
        if (fileTypes.includes(files.type) === false) {
          const message = await this.getTranslation(
            validation.MESSAGE,
            validation.FILE
          );
          this.toastr.error(message);
          reject("INVALID_FILE_TYPE | translate");
        } else {
          if (
            temp === undefined ||
            temp === "" ||
            files.size / 1024 / 1024 < imgSize
          ) {
            const reader = new FileReader();
            reader.onload = async (e: ProgressEvent<FileReader>) => {
              this.fileType = (img?.target as HTMLInputElement)?.files?.[0]?.type;
              this.imageChangedEvent = img;
              this.url = e.target?.result as string;
              const formdata: FormData = new FormData();
              formdata.append("uploadFile", files);
              formdata.append("user", userType);
              try {
                const response = await this.uploadFileApi(formdata)
                  .pipe(first())
                  .toPromise();
                if (response.status === true) {
                  const fileData = response.data;
                  resolve(fileData); // Resolve the Promise with the fileData
                }
              } catch (error) {
                this.errorHandler(this.toastr, this.translateService, error);
                reject(error); // Reject the Promise with the error
              }
            };
            reader.readAsDataURL(files);
          } else {
            const message = await this.getTranslation(
              validation.SIZE_MESSAGE,
              imgSize
            );
            this.toastr.error(message);
            throw new Error("FILE_SIZE_EXCEED | translate ");
          }
        }
      } else {
        this.spinner.hideLoader();
        reject("No files selected");
      }
    });
  }

  /**
   * Handle upload file
   */
  async uploadFile(
    event: Event,
    fileType = fileUploadFormat.IMAGE,
    getUserType = this.getUser()
  ) {
    return new Promise(async (resolve, reject) => {
      const userType = getUserType;
      const inputElement = event.target as HTMLInputElement;
      if (inputElement?.files?.[0]) {
        let validation;

        if (fileType === fileUploadFormat.IMAGE) {
          validation = this.CONSTANT.imageUpload;
        } else if (fileType === fileUploadFormat.EXCEL) {
          validation = this.CONSTANT.ExcelFileUpload;
        } else {
          validation = this.CONSTANT.documentUpload;
        }
        const imgSize = validation.MAX_FILE_SIZE;
        const temp = "";
        const fileTypes = validation.ALLOW_FILE_TYPE;
        if (fileTypes.includes(inputElement.files[0].type) === false) {
          const message = await this.getTranslation(
            validation.MESSAGE,
            validation.FILE
          );
          this.toastr.error(message);
          reject(new Error("INVALID_FILE_TYPE | translate"));
        } else {
          if (
            temp === "" ||
            imgSize === void 0 ||
            inputElement.files[0].size / 1024 / 1024 < imgSize
          ) {
            const reader = new FileReader();
            reader.onload = async (e: ProgressEvent<FileReader>) => {
              if (inputElement?.files?.[0]) {
                this.fileType = inputElement.files[0].type;
                this.imageChangedEvent = event;
                this.url = e?.target?.result as string | undefined;
                const formdata = new FormData();
                formdata.append("uploadFile", inputElement.files[0]);
                formdata.append("user", userType);

                try {
                  const response = await this.uploadFileApi(formdata)
                    .pipe(first())
                    .toPromise();
                  if (response.status === true) {
                    const fileData = response.data;
                    resolve(fileData); // Resolve the Promise with the fileData
                  }
                } catch (error) {
                  this.errorHandler(this.toastr, this.translateService, error);
                  reject(error); // Reject the Promise with the error
                }
              }
            };
            reader.readAsDataURL(inputElement.files[0]);
          } else {
            const message = await this.getTranslation(
              validation.SIZE_MESSAGE,
              imgSize
            );
            this.toastr.error(message);
            reject(new Error("FILE_SIZE_EXCEED | translate"));
          }
        }
      } else {
        this.spinner.hideLoader();
      }
    });
  }

  /**
   * Handle upload file API Call
   */
  uploadFileApi(payload: FormData) {
    return this.http.post<IFileUpload>(CONFIG.addFileUpload, payload).pipe(
      map((response: IFileUpload) => {
        return response;
      })
    );
  }
}
