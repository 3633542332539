import { Component } from "@angular/core";

@Component({
  selector: "app-loader",
  template: `<ngx-ui-loader
    [fgsColor]="config.fgsColor"
    [pbColor]="config.pbColor"
  ></ngx-ui-loader>`,
})
export class LoaderComponent {
  config = {
    fgsColor: "#2da5de",
    pbColor: "#2da5de",
    bgsOpacity: 1,
  };
}
