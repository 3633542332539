<header>
  <div class="icon-area">
    <i class="icon-truck"></i>
  </div>
  <h1>{{ "SELECT_NEW_PROVIDER" | translate }}</h1>
  <p class="header-data">
    {{ "CHOOSE_PROVIDER_MESSAGE" | translate }}
  </p>
</header>

<footer>
  <div class="btn-area">
    <a (click)="this.cancel()" class="btn bordered-btn btn-small">
      {{ "CANCEL" | translate }}
    </a>
    <a (click)="this.acceptProvider()" class="btn btn-secondary btn-small">
      {{ "CONFIRM" | translate }}
    </a>
  </div>
</footer>
