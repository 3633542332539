import { Directive, ElementRef, HostListener, OnDestroy } from "@angular/core";

@Directive({
  selector: "[appDropdown]",
})
export class DropdownDirective implements OnDestroy {
  private documentClickHandler: EventListenerOrEventListenerObject;

  constructor(private el: ElementRef) {
    // Create a function to handle document clicks and bind it to the current instance of the directive.
    this.documentClickHandler = this.onDocumentClick.bind(this);
  }

  @HostListener("click") onClick() {
    const select = this.el.nativeElement.querySelector(".select");
    const caret = this.el.nativeElement.querySelector(".caret");
    const menu = this.el.nativeElement.querySelector(".menu");

    if (select && caret && menu) {
      select.classList.toggle("select-clicked");
      caret.classList.toggle("caret-rotate");
      menu.classList.toggle("menu-open");

      // Add a click event listener to the document body to close the dropdown when clicked outside.
      document.body.addEventListener("click", this.documentClickHandler);
    }
  }

  ngOnDestroy() {
    // Remove the document click event listener when the directive is destroyed.
    document.body.removeEventListener("click", this.documentClickHandler);
  }

  onDocumentClick(event: Event) {
    const isClickInsideDropdown = this.el.nativeElement.contains(
      event.target as Node
    );

    if (!isClickInsideDropdown) {
      // Click occurred outside the dropdown, so close it.
      const select = this.el.nativeElement.querySelector(".select");
      const caret = this.el.nativeElement.querySelector(".caret");
      const menu = this.el.nativeElement.querySelector(".menu");

      if (select && caret && menu) {
        select.classList.remove("select-clicked");
        caret.classList.remove("caret-rotate");
        menu.classList.remove("menu-open");
      }

      // Remove the document click event listener as the dropdown is now closed.
      document.body.removeEventListener("click", this.documentClickHandler);
    }
  }
}
