import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { URWAYResponseCode } from "@config/urway-response";
import { IErrorResponse } from "@model/errorResponse";
import {
  PaymentStatusProps,
  PAYMETRESPONSESTATUS,
} from "@model/payment-status";
import { ISuccessResponse } from "@model/successResponse";
import { OrderType, StatusCode } from "@utils/enum-const";
import { BaseComponent } from "@components/base.component";
import { AutoUnsubscribe } from "@decorator/autounsubscribe";
import { OrdersCommonService } from "@services/orders-common-service";

@Component({
  selector: "app-payment-common-status",
  templateUrl: "./payment-common-status.component.html",
  styleUrls: ["./payment-common-status.component.scss"],
})
@AutoUnsubscribe()
export class PaymentCommonStatusComponent extends BaseComponent
  implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private ManageOrdersCommonService: OrdersCommonService
  ) {
    super();
  }
  paymentResponse = {} as PaymentStatusProps;
  statusOfPayment = PAYMETRESPONSESTATUS;
  urwayPaymentMSG: undefined | string = "";
  modeVal = "web";
  curLan = localStorage.getItem("lan");
  orderId = localStorage.getItem("orderId");
  paymentUuid = localStorage.getItem("payment_uuid");
  token = localStorage.getItem("token");
  mode = localStorage.getItem("mode");
  paymentOrderType: null | string = localStorage.getItem("orderType");

  /**
   * Handle init function
   */
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.paymentResponse = params as PaymentStatusProps;
      const responseCode = params["ResponseCode"] as string;
      this.urwayPaymentMSG = URWAYResponseCode.responseMessage[responseCode];
    });
    this.spinner.showLoader();
    if (this.paymentResponse.Result === this.statusOfPayment.Successful) {
      this.checkout();
    }
    if (this.paymentResponse.Result !== this.statusOfPayment.Successful) {
      this.redirectToOrders();
    }
  }

  /**
   * Handle checkout method call
   */
  public checkout() {
    this.ManageOrdersCommonService.paymentCheckout(
      this.paymentResponse.UserField1,
      this.paymentResponse.TranId,
      this.paymentResponse.ResponseCode,
      this.paymentResponse.responseHash,
      undefined,
      this.token,
      this.curLan
    ).subscribe(
      (response: ISuccessResponse) => {
        if (response.status_code === StatusCode.OK) {
          this.redirectToOrders();
          localStorage.removeItem("token");
          localStorage.removeItem("mode");
          localStorage.removeItem("orderId");
        }
      },
      (error: IErrorResponse) => {
        this.errorHandler(this.toastr, this.translateService, error, () => {
          this.redirectToOrders();
          localStorage.removeItem("token");
          localStorage.removeItem("mode");
        });
      }
    );
  }

  /**
   * Handle redirection on order after successfull payment
   */
  redirectToOrders() {
    setTimeout(() => {
      if (this.mode === this.modeVal) {
        this.spinner.hideLoader();
        this.handleRouting();
      }
      if (this.mode !== this.modeVal) {
        this.spinner.hideLoader();
        window.location.href = window.location.href.replace(
          "payment-status",
          "payment-intercept"
        );
      }
    }, 3000);
  }

  /**
   * Handle routing
   */
  handleRouting() {
    if (this.paymentOrderType === OrderType.order) {
      this.router.navigate([
        this.getRoutesRole() + "/direct-orders/view/" + this.orderId,
      ]);
    } else {
      this.router.navigate([
        this.getRoutesRole() + "/offers-list/offer-orders/" + this.orderId,
      ]);
    }
  }
}
