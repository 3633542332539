import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ChatMessageService {
  private unreadMessageCountSubject = new BehaviorSubject<string>("0");
  unreadMessageCount$ = this.unreadMessageCountSubject.asObservable();

  /**
   *
   * @param count string
   */
  updateUnreadMessageCount(count: string | undefined) {
    this.unreadMessageCountSubject.next(count as string);
  }
}
