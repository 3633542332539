import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { BaseComponent } from "../../../_components/base.component";
import moment from "moment";

@Component({
  selector: "app-edit-shipment-date-time-popup",
  templateUrl: "./edit-shipment-date-time.component.html",
  styleUrls: ["./edit-shipment-date-time.component.scss"],
})
export class EditShipmentDateTimePopupComponent extends BaseComponent
  implements OnInit {
  @Output() close = new EventEmitter();
  @Output() submitDate = new EventEmitter();
  @Input() title!: string;
  @Input() tripId!: number;
  @Input() dateAndTime!: string;
  @Input() loadingDateAndTime!: string;

  isSubmitted = false;
  model = {
    comment: "",
    shipment_date: "",
    shipment_time: "",
  };
  minDate: Date | null = null;
  maxDate: Date | null = null;
  submitted = false;
  isInvalidQuantity = true;

  ngOnInit() {
    const defaultDateAndTime = this.loadingDateAndTime;
    const currentTimeZone = this.dateAndTime
      ? moment.utc(this.dateAndTime, "YYYY-MM-DD HH:mm:ss").local()
      : moment.utc(defaultDateAndTime, "YYYY-MM-DD HH:mm:ss").local();

    const extractedDate = moment(currentTimeZone).format("DD/MM/YYYY"); // Extract the date part
    const extractedTime = moment(currentTimeZone).format("HH:mm"); // Extract the time part

    this.model.shipment_date =
      !this.dateAndTime && this.title.includes("DELIVERED")
        ? ""
        : extractedDate;
    this.model.shipment_time =
      !this.dateAndTime && this.title.includes("DELIVERED")
        ? ""
        : extractedTime;
  }

  /**
   * Close Popup
   */
  closePopup() {
    this.close.emit();
  }

  getPlaceHolder(type: string) {
    if (this.title.includes("LOADING")) {
      return type === "date" ? "LOADING_DATE" : "LOADING_TIME";
    }
    if (this.title.includes("ETA")) {
      return type === "date" ? "ETA_DATE" : "ETA_TIME";
    }
    if (this.title.includes("DELIVERED")) {
      return type === "date" ? "DELIVERED_DATE" : "DELIVERED_TIME";
    }
    return "LOADING_DATE";
  }

  submitDateTime() {
    this.isSubmitted = true;
    if (!this.model.shipment_date || !this.model.shipment_time) {
      return;
    }

    // Parse and format the date
    const parsedDate =
      typeof this.model.shipment_date === "string"
        ? moment(this.model.shipment_date, "DD/MM/YYYY").format("YYYY-MM-DD")
        : moment(this.model.shipment_date).format("YYYY-MM-DD");

    // Combine date and time into a single string
    const combinedDateTime = `${parsedDate} ${this.model.shipment_time}:00`;

    // Convert combined date-time to UTC
    const utcDateTime = moment(combinedDateTime, "YYYY-MM-DD HH:mm:ss")
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");

    // Emit the UTC date-time
    this.submitDate.emit(utcDateTime);
  }
}
