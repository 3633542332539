import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { first } from "rxjs/operators";
import { IErrorResponse } from "@model/errorResponse";
import { IForgotPassword } from "@model/userRegistrationLogin";
import { BaseComponent } from "@components/base.component";
import { AutoUnsubscribe } from "@decorator/autounsubscribe";
import { RegistrationService } from "@services/registration.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
@AutoUnsubscribe()
export class ForgotPasswordComponent extends BaseComponent implements OnInit {
  loading = false;
  isServiceRequester: boolean;
  submitted = false;
  requesterTypeURL: string;
  userRole = this.getRoutesRole();
  public model = {
    email: "",
  };

  constructor(private registrationService: RegistrationService) {
    super();
    this.isServiceRequester = this.isServiceRequesterRoute;
    this.requesterTypeURL = this.requesterType();
    this.checkCurrentUserAndRedirect();
  }

  ngOnInit(): void {
    this.isServiceRequester = this.isServiceRequesterRoute;
  }

  /**
   * Forget Password Click
   */
  public onForgetPasswordClick(form: NgForm) {
    this.submitted = true;
    // stop here if form is invalid
    if (form.invalid) {
      return;
    }
    this.spinner.showLoader();
    this.registrationService
      .forgetPassword(this.model)
      .pipe(first())
      .subscribe(
        (data: IForgotPassword) => {
          this.spinner.hideLoader();
          if (data.status_code === this.statusCode.OK) {
            this.toastr.success(data.message);
            const encodedEmail = encodeURIComponent(this.model.email);
            const encodedToken = encodeURIComponent(data.data.token);
            this.router.navigateByUrl(
              this.getRoutesRole() +
                `/otp-verification?email=${encodedEmail}&token=${encodedToken}`
            );
          }
        },
        (error: IErrorResponse) => {
          this.spinner.hideLoader();
          this.errorHandler(this.toastr, this.translateService, error);
        }
      );
  }
}
