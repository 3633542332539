import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { circulationDirectOrderColor } from "@utils/enum-const";

@Directive({
  selector: "[appCircularProgress]",
})
export class CircularProgressDirective implements OnChanges {
  @Input() currentTrips = 0;
  @Input() totalTrips = 0;
  private progressInterval: any = null;

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    // Detect changes in the @Input properties and update progress when they change
    if (changes["currentTrips"] || changes["totalTrips"]) {
      this.init();
    }
  }

  init() {
    if (this.totalTrips) {
      const circularProgress = this.el.nativeElement;
      let progressStartValue = 0;

      const circleColor = circulationDirectOrderColor.noValueColor;
      const progressValue = this.getAverageCirculation(
        this.currentTrips,
        this.totalTrips
      );

      let fillColor: string;

      if (progressValue >= 100) {
        fillColor = circulationDirectOrderColor.completeColor;
      } else if (progressValue === 0) {
        fillColor = circulationDirectOrderColor.noValueColor;
      } else {
        fillColor = circulationDirectOrderColor.halfCompleteColor;
      }

      const progressValueElement = circularProgress.querySelector(
        ".progress-value"
      );
      progressValueElement.style.color = fillColor;

      const progressEndValue = progressValue;
      const speed = 10;

      // Clear the previous interval before starting a new one
      if (this.progressInterval) {
        clearInterval(this.progressInterval);
      }

      this.progressInterval = setInterval(() => {
        if (progressStartValue < progressEndValue) {
          progressStartValue++;
          circularProgress.style.background = `conic-gradient(${fillColor} ${
            progressStartValue * 3.6
          }deg, ${circleColor} 0deg)`;
        } else {
          clearInterval(this.progressInterval);
        }
      }, speed);
    }
  }

  /**
   * Get Average Circulation Value
   * @returns number
   */
  getAverageCirculation(value1: number, value2: number) {
    const getCalculation = Math.trunc((value1 / value2) * 100);
    return getCalculation > 0 ? getCalculation : 0;
  }
}
