import { Injector } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { NgxPermissionsService } from "ngx-permissions";
import { ToastrService } from "ngx-toastr";
import { EncrDecrService } from "@services/encr-decr.service";
import { FilterStorageService } from "@services/filter-storage.service";
import { MultilingualService } from "@services/multilingual.service";
import { SpinnerService } from "@services/spinner.service";

export class BaseServiceInjector {
  static injector: Injector;
  protected filterService: FilterStorageService;
  protected modalService: BsModalService;
  protected spinner: SpinnerService;
  protected toastr: ToastrService;
  protected router: Router;
  public translateService: TranslateService;
  protected multilingualService: MultilingualService;
  protected EncrDecr: EncrDecrService;
  protected permissionService: NgxPermissionsService;

  constructor() {
    this.modalService = BaseServiceInjector.injector.get(BsModalService);
    this.filterService = BaseServiceInjector.injector.get(FilterStorageService);
    this.spinner = BaseServiceInjector.injector.get(SpinnerService);
    this.toastr = BaseServiceInjector.injector.get(ToastrService);
    this.router = BaseServiceInjector.injector.get(Router);
    this.translateService = BaseServiceInjector.injector.get(TranslateService);
    this.multilingualService = BaseServiceInjector.injector.get(
      MultilingualService
    );
    this.EncrDecr = BaseServiceInjector.injector.get(EncrDecrService);
    this.permissionService = BaseServiceInjector.injector.get(
      NgxPermissionsService
    );
  }
}
