import { NgModule } from "@angular/core";
import { LoaderComponent } from "@containers/loader/loader.component";
import { NgxUiLoaderModule } from "ngx-ui-loader";

@NgModule({
  imports: [NgxUiLoaderModule],
  declarations: [LoaderComponent],
  exports: [LoaderComponent],
})
export class LoaderModule {
  static forRoot() {
    return {
      NgModule: LoaderModule,
    };
  }
}
