import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { CONFIG } from "@config/app-config";
import {
  ICurrentOrdersPayload,
  IOrderHistoryPayload,
  IOrderHistoryResponse,
} from "@model/order-history";
import { UserRole } from "@utils/enum-const";
import { CommonService } from "@services/common.service";

@Injectable({
  providedIn: "root",
})
export class OrderHistoryService {
  userType = "";
  constructor(private http: HttpClient, private commonService: CommonService) {
    this.userType = this.commonService.getLoginUserType();
  }

  /**
   * Order history listing
   */
  getOrderHistory(requestPayload: IOrderHistoryPayload) {
    return this.http
      .post<IOrderHistoryResponse>(CONFIG.getOrderHistory, requestPayload)
      .pipe(
        map((response: IOrderHistoryResponse) => {
          return response;
        })
      );
  }

  /**
   * Contract order history listing
   */
  getContractOrderHistory(requestPayload: IOrderHistoryPayload) {
    return this.http
      .post<IOrderHistoryResponse>(
        CONFIG.getProviderContractOrders,
        requestPayload
      )
      .pipe(
        map((response: IOrderHistoryResponse) => {
          return response;
        })
      );
  }

  /**
   * Order history view for requester
   */
  getOrderHistoryForRequester(requestPayload: IOrderHistoryPayload) {
    return this.http
      .post<IOrderHistoryResponse>(
        CONFIG.getOrderHistoryForRequester,
        requestPayload
      )
      .pipe(
        map((response: IOrderHistoryResponse) => {
          return response;
        })
      );
  }

  /**
   * Get current orders listing data
   */
  getCurrentOrders(requestPayload: ICurrentOrdersPayload) {
    const currentorderUrl =
      this.userType === UserRole.SERVICE_PROVIDER
        ? CONFIG.getCurrentOrders
        : CONFIG.getRequesterCurrentOrders;
    return this.http
      .post<IOrderHistoryResponse>(currentorderUrl, requestPayload)
      .pipe(
        map((response: IOrderHistoryResponse) => {
          return response;
        })
      );
  }
}
