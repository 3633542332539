import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from "@angular/core";
import { actionMessage } from "@utils/enum-const";
import { BaseComponent } from "@components/base.component";

@Component({
  selector: "app-handle-delete",
  templateUrl: "./handle-delete.component.html",
  styleUrls: ["./handle-delete.component.scss"],
})
export class HandleDeleteComponent extends BaseComponent {
  @Input() recordId!: string;
  @Input() platNumber!: string;
  @Input() confirmDeleteMessage = actionMessage.DELETE_RECORD_MESSAGE;
  @Output() confirmDelete: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Handle modal open
   */
  openModal(template: TemplateRef<string>) {
    this.modalRef = this.modalService.show(template, { class: "modal-md" });
  }

  /**
   * Handle close modal
   */
  decline(): void {
    this.modalRef.hide();
  }

  /**
   * Handke delete modal
   */
  handleDelete() {
    this.decline();
    this.confirmDelete.emit(this.recordId);
  }

  /**
   * Handle get popup message
   */
  get popupMessage(): string {
    let message = this.translateService.instant(this.confirmDeleteMessage);
    message += " ";
    if (this.platNumber) {
      message += " " + this.platNumber + "?";
    }
    return message;
  }
}
