import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ISelectedTripDetails } from "@model/directOrderTripDetails";
import { IErrorResponse } from "@model/errorResponse";
import { CommonService } from "@services/common.service";
import { OrdersCommonService } from "@services/orders-common-service";
import { UserRole } from "@utils/enum-const";
import { first } from "rxjs/operators";
import { BaseComponent } from "../../../_components/base.component";

@Component({
  selector: "app-container-add-edit-popup",
  templateUrl: "./container-add-edit.component.html",
  styleUrls: ["./container-add-edit.component.scss"],
})
export class ContainerAddEditComponent extends BaseComponent
  implements OnInit, OnChanges {
  @Input() selectedTrip!: ISelectedTripDetails;
  @Input() showAddEdit = false;
  @Input() editMode = false;
  @Output() refreshData: EventEmitter<{}> = new EventEmitter();
  @Output() close: EventEmitter<{}> = new EventEmitter();
  showAddSection = false;

  model!:
    | Array<{
        container_number: string;
        seal_number: string | null;
        id: number;
        order_id: number;
        trip_id: number;
        editSubmitted?: boolean;
      }>
    | [];
  isAddSubmitted = false;
  addContainer = "";
  addSeal = "";
  addPermission: string[] = [];
  editPermission: string[] = [];
  deletePermission: string[] = [];

  constructor(
    private readonly ManageOrdersCommonService: OrdersCommonService,
    private readonly commonService: CommonService
  ) {
    super();
    if (
      this.commonService.getLoginUserType() === UserRole.SUPER_ADMIN_USER ||
      this.commonService.getLoginUserType() === UserRole.ADMIN_USER
    ) {
      this.addPermission = ["SUPER_ADMIN", "create_trip"];
      this.editPermission = ["SUPER_ADMIN", "edit_trip"];
      this.deletePermission = ["SUPER_ADMIN", "delete_trip"];
    }
  }

  ngOnInit(): void {
    this.model = JSON.parse(
      JSON.stringify(this.selectedTrip?.trip_containers ?? [])
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["selectedTrip"]) {
      this.model = JSON.parse(
        JSON.stringify(this.selectedTrip?.trip_containers ?? [])
      );
    }
  }

  /**
   * Remove space on type
   */
  removeSpaces(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/\s/g, "");
  }

  /**
   * Show add section
   */
  handleShowAdd() {
    this.showAddSection = true;
  }

  /**
   * Close Modal
   */
  closeModal() {
    this.close.emit();
  }

  /**
   * Refresh data
   */
  refresh() {
    this.refreshData.emit();
  }

  /**
   * Edit Container
   */
  editTripContainer(containerInd: number) {
    if (this.model?.[containerInd] !== undefined) {
      (this.model[containerInd] as any).editSubmitted = true;
    }
    if (!this.model?.[containerInd]?.container_number) {
      return;
    }
    this.spinner.showLoader();
    const addPayload = {
      container_number: (this.model[containerInd] as any).container_number,
      seal_number: this.model[containerInd]?.seal_number ?? "",
    };
    this.ManageOrdersCommonService.editTripContainer(
      this.selectedTrip.id,
      this.model[containerInd]?.id,
      addPayload
    )
      .pipe(first())
      .subscribe(
        (response) => {
          this.isAddSubmitted = false;
          if (response.status_code === this.statusCode.OK) {
            this.toastr.success(response.message);
            this.refresh();
            this.spinner.hideLoader();
            if (this.editMode) this.close.emit();
          }
        },
        (error: IErrorResponse) => {
          this.isAddSubmitted = false;
          this.spinner.hideLoader();
          this.errorHandler(this.toastr, this.translateService, error);
        }
      );
  }

  /**
   * Delete Container
   */
  deleteTripContainer(containerInd: number) {
    this.spinner.showLoader();
    this.ManageOrdersCommonService.deleteTripContainer(
      this.selectedTrip.id,
      this.model[containerInd]?.id
    )
      .pipe(first())
      .subscribe(
        (response) => {
          this.isAddSubmitted = false;
          if (response.status_code === this.statusCode.OK) {
            this.toastr.success(response.message);
            this.refresh();
            this.spinner.hideLoader();
          }
        },
        (error: IErrorResponse) => {
          this.isAddSubmitted = false;
          this.spinner.hideLoader();
          this.errorHandler(this.toastr, this.translateService, error);
        }
      );
    // this.spinner.showLoader()
  }

  /**
   * Create Container
   */
  addTripContainer() {
    this.isAddSubmitted = true;
    if (!this.addContainer) {
      return;
    }
    this.spinner.showLoader();
    const addPayload = {
      container_number: this.addContainer,
      seal_number: this.addSeal,
    };
    this.ManageOrdersCommonService.createTripContainer(
      this.selectedTrip.id,
      addPayload
    )
      .pipe(first())
      .subscribe(
        (response) => {
          this.isAddSubmitted = false;
          if (response.status_code === this.statusCode.OK) {
            this.toastr.success(response.message);
            this.refresh();
            this.addContainer = "";
            this.addSeal = "";
            this.spinner.hideLoader();
          }
        },
        (error: IErrorResponse) => {
          this.isAddSubmitted = false;
          this.spinner.hideLoader();
          this.errorHandler(this.toastr, this.translateService, error);
        }
      );
  }
}
