import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment-timezone";

@Pipe({ name: "customDate" })
export class CustomDate implements PipeTransform {
  transform(date: string, format: string): string {
    if (!date) {
      return date;
    }
    if (date === "0000-00-00" || date === "0000-00-00 00:00:00") {
      return "";
    }

    // Parse the date in UTC format
    const utcDateTime = moment.utc(date, "YYYY-MM-DD HH:mm:ss");
    // Get the user's local time zone dynamically
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Convert to user's local time zone
    const localDateTime = utcDateTime.clone().tz(userTimeZone).format(format);
    return localDateTime;
  }
}
