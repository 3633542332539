import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { BaseComponent } from "@components/base.component";
import { isCanceled } from "@config/app-constants";
import { AutoUnsubscribe } from "@decorator/autounsubscribe";
import { DownloadFileResponse } from "@model/adminDirectOrders";
import { IBayanTrips } from "@model/common";
import { IStatusItem } from "@model/directOrdersDetails";
import { IErrorResponse } from "@model/errorResponse";
import { DirectOrdersService } from "@services/direct-orders.service";
import { orderTypes } from "@utils/enum-const";
import { first } from "rxjs/operators";

@Component({
  selector: "app-trip-details-popup",
  templateUrl: "./trip-details.component.html",
  styleUrls: ["./trip-details.component.scss"],
})
@AutoUnsubscribe()
export class TripDetailsPopupComponent extends BaseComponent implements OnInit {
  @Input() orderTripDetails: IBayanTrips[] = [];
  @Input() orderData!: {
    order_id: string;
    weight: string;
    section: string | number;
    detail_address_from: string;
    detail_address_to: string;
  };
  @Input() tripStatus!: IStatusItem[];
  listStatus: IStatusItem[] = [];
  listTripData: IBayanTrips[] = [];
  @Output() close = new EventEmitter();

  isInvalidQuantity = false;
  public queryParam = {
    orderNo: "",
    tripId: "",
    truck_type: "",
    weight: "",
    laoding_point: "",
    offloading_point: "",
    driver: "",
    driver_mobile: "",
    truck_plate_no: "",
    orderType: "",
  };
  orderTypes = orderTypes;
  isCanceled = isCanceled;

  constructor(private ManageDirectOrders: DirectOrdersService) {
    super();
  }

  /**
   * Handle init method
   */
  ngOnInit(): void {
    this.listTripData = this.orderTripDetails;
    this.listStatus = this.tripStatus;
    this.queryParam.orderNo = this.orderData?.order_id.toString();
    this.queryParam.weight =
      this.orderData?.weight + " " + this.measurementUnits.TON;
    this.queryParam.orderType = this.orderType[
      this.orderData?.section as string
    ];
    this.queryParam.laoding_point = this.orderData?.detail_address_from;
    this.queryParam.offloading_point = this.orderData?.detail_address_to;
  }

  /**
   * Close Popup
   */
  closePopup() {
    this.close.emit();
  }

  /**
   * Downlaod Bayal - Waybill
   */
  downloadBayanWaybillFile(tripId: number, fileType: string) {
    this.spinner.showLoader();
    const payload = { type: fileType };
    this.ManageDirectOrders.downloadBayanWaybill(tripId, payload)
      .pipe(first())
      .subscribe(
        (data: DownloadFileResponse) => {
          this.spinner.hideLoader();
          if (data?.status_code === this.statusCode.OK) {
            if (data?.data?.link) {
              window.open(data?.data?.link, "_blank");
            }
          }
        },
        (error: IErrorResponse) => {
          this.spinner.hideLoader();
          this.errorHandler(this.toastr, this.translateService, error);
        }
      );
  }

  /**
   * Based on trip ID redirect to chat
   */
  redirectToChat(tripId: string) {
    const orderTrip = this.listTripData.filter((m) => m?.trip_id === tripId)[0];
    this.queryParam.orderNo = this.orderData?.order_id;
    this.queryParam.driver = orderTrip?.driver?.name as string;
    this.queryParam.driver_mobile = (orderTrip?.driver?.absher_mobile
      ? orderTrip?.driver?.absher_mobile
      : orderTrip?.driver?.mobile) as string;
    this.queryParam.tripId = orderTrip?.trip_id as string;
    this.queryParam.truck_plate_no = orderTrip?.vehicle?.full_plate_number
      ? orderTrip?.vehicle?.full_plate_number
      : "-";
    this.queryParam.truck_type = orderTrip?.vehicle?.truck?.name
      ? orderTrip?.vehicle?.truck?.name
      : "-";
    this.closePopup();
    this.router.navigateByUrl(this.getRoutesRole() + "/chat", {
      state: { tripDetails: this.queryParam },
    });
  }

  /**
   * Redirect to trip detail
   */
  redirectToTripDetail(id: number) {
    const redirectLink = this.getRoutesRole() + "/trip/view/" + id;
    window.open(redirectLink, "_blank");
  }
}
