export enum announcementTypeValue {
  push = "push",
  email = "email",
  sms = "sms",
}
export enum announcementInclusionValue {
  exclude_selected = "exclude_selected",
  only_selected = "only_selected",
  all = "all",
}
export enum subscriptionTrialPlanValue {
  yes = "1",
  no = "0",
}
export enum StatusValue {
  active = "Active",
  inactive = "Inactive",
}

export enum ChangeStatusValue {
  activated = "activated",
  pending = "pending",
  inactive = "inactive",
  rejected = "rejected",
}

export enum ApproveRejectState {
  approve = "approve",
  reject = "reject",
}

export enum AccpetRejectStatus {
  accepted = "accepted",
  rejected = "rejected",
}

export enum StatusCode {
  Continue = 100,
  SwitchingProtocols = 101,
  Processing = 102,
  OK = 200,
  Created = 201,
  Accepted = 202,
  NoContent = 204,
  PartialContent = 206,
  MultipleChoices = 300,
  MovedPermanently = 301,
  Found = 302,
  NotModified = 304,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  MethodNotAllowed = 405,
  Conflict = 409,
  InternalServerError = 500,
  NotImplemented = 501,
  BadGateway = 502,
  ServiceUnavailable = 503,
  GatewayTimeout = 504,
  HTTPVersionNotSupported = 505,
}

export enum storageUserKeys {
  CURRENT_USER_PROVIDER = "currentUserProvider",
  CURRENT_USER_REQUESTER = "currentUserRequester",
  CURRENT_ADMIN_USER = "currentAdmin",
  CURRENT_SUB_ADMIN_USER = "currentSubAdmin",
}

export enum actionMessage {
  DELETE_RECORD_MESSAGE = "ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_RECORD",
  STATUS_CHANGE_MESSAGE = "ARE_YOU_SURE_YOU_WANT_TO_CHANGE_STATUS",
  APPROVE_USER_MESSAGE = "ARE_YOU_SURE_YOU_WANT_TO_APPROVE",
}

export enum actionStatusList {
  ACTIVE = 1,
  IN_ACTIVE = 0,
}

export enum adminExportFilter {
  ADMIN_VEHICLE_LIST = "export_admin_vehicle",
  ADMIN_DIRECT_ORDERS_LIST = "export_direct_orders",
  ADMIN_SPECIAL_ORDERS_LIST = "export_special_orders",
  SUB_ADMIN_LIST = "export_subAdmin_list",
  ADMIN_CONTRACT_LIST = "export_adminContract_list",
  ADMIN_SHAHEN_CONTRACT_LIST = "export_shahen_contract_list",
  ADMIN_OFFER_ORDERS_LIST = "export_adminOfferOrders_list",
  ADMIN_DRIVER_LIST = "export_admin_driver",
  ADMIN_MASTER_TRIP = "export_master_trip",
  REGISTER = "requester",
  PROVIDER = "provider",
}

export enum exportTypes {
  "PDF" = "pdf",
  "EXCEL" = "excel",
}

export enum languageTypes {
  DEFAULT = "ar",
  DEFAULT_ADMIN = "en",
  ENGLISH = "English",
  ARABIC = "Arabic",
}

export enum statusTypes {
  "activated" = "Active",
  "pending" = "Under Approval",
  "inactive" = "Inactive",
}

export enum priceRouting {
  ADMIN_REQUETSER = "/admin/manage-requester-pricing",
  ADMIN_PROVIDER = "/admin/manage-provider-pricing",
}

export enum userType {
  PROVIDER = "provider",
  REQUESTER = "requester",
}

export enum filterKeys {
  SEARCH_TEXT = "search_txt",
  REQUESTER = "R",
  PROVIDER = "P",
  ALL = "A",
}

export enum directOrdersStatusTypes {
  "waiting_for_approval" = "Waiting for Approval",
  "invoice_sent_SR" = "Invoice Sent",
  "invoice_paid_SR" = "Invoice Paid",
  "shipment_in_progress" = "Shipment in Progress",
  "shipment_on_the_way" = "Shipment on the Way",
  "delivered" = "Delivered",
  "waiting_for_approval_SR" = "Waiting for Approval",
  "invoice_sent_SP" = "Invoice Sent",
  "invoice_paid_SP" = "Invoice Paid",
  "cancelled" = "Cancelled",
  "invoice_sent" = "Invoice Sent",
  "new" = "New",
}

export enum fileUploadFormat {
  IMAGE = "image",
  DOCUMENT = "document",
  EXCEL = "excel",
}

export enum AbshersMobilePrefixNumber {
  ABSHERS = "+966",
}

export enum MeasurementUnits {
  SAR = "SAR",
  TON = "Ton",
  TONS = "Tons",
}

export enum OrderType {
  order = "Direct Order",
  special_order = "Special Order",
  contract_order = "Contract Order",
  membership = "Contract Order",
  offer_order = "Offer Order",
  admin_contract_order = "Admin Contract Order",
  chat = "Chat",
  current = "current",
  offer = "Offer Order",
  Shahen_contract = "Shahen Contract",
}

export enum OrderStatus {
  CANCELLED = "cancelled",
  directOrdersStatusTypes = "delivered",
  INVOICE_SENT_SP = "invoice_sent_SP",
  INVOICE_PAID = "invoice_paid",
  INVOICE_PAID_SP = "invoice_paid_SP",
  INVOICE_PAID_SR = "invoice_paid_SR",
  NEW = "new",
  WAITING_FOR_APPROVAL = "waiting_for_approval",
  INVOICE_SENT = "invoice_sent",
  INVOICE_RECEIVED = "invoice_received",
  INVOICE_APPROVED = "invoice_approved",
  SR_INVOICE_SENT = "invoice_sent_SR",
  SHIPMENT_IN_PROGRESS = "shipment_in_progress",
  SHIPMENT_ON_THE_WAY = "shipment_on_the_way",
  DELIVERED = "delivered",
  WAITING_FOR_APPROVAL_SR = "waiting_for_approval_SR",
  IN_PROGRESS = "in_process",
  NEW_CONTRACT = "new",
  Delivered = "Delivered",
  PAYMENT_PENDING = "payment_pending",
  WAITING_FOR_APPROVAL_SP = "waiting_for_approval_SP",
}

export enum userForResetPassword {
  PROVIDER = "Provider",
  REQUESTER = "Requester",
}

export enum circulationDirectOrderColor {
  completeColor = "#0FB836",
  noValueColor = "#D6D6DC",
  halfCompleteColor = "#2da5de",
  circleColor = "#ededed",
}

export enum mapSothArabicProperties {
  north = 30.0, // Adjust the bounds to cover South Arabia
  south = 10.0, // Adjust the bounds to cover South Arabia
  east = 60.0, // Adjust the bounds to cover South Arabia
  west = 35.0, // Adjust the bounds to cover South Arabia
}

export enum mapErrorIssue {
  locationIssue = "We are not providing service here",
}

export enum fileIcon {
  pdf = "icon-pdf",
  image = "icon-image",
}

export enum OrderFilterKey {
  order = "order",
  membership = "membership",
  all = "all",
  special_order = "special_order",
  offer = "offer",
}

export enum directOrderTabs {
  PICK_UP_DETAILS = "pick_up_details",
  LOCATION_DETAILS = "location_details",
  LOADING_SCHEDULE = "loading_schedules",
}

export enum oprationsParams {
  ADD = "add",
  MINUS = "minus",
}
export enum TypeOfRequester {
  INDIVIDUAL = "Individual",
  COMPANY = "Company",
}

export enum ShipmentStatus {
  in_process = "Shipment in progress",
  on_way = "Shipment on the way",
  delivered = "Delivered",
  invoice_sent = "Invoice sent",
  invoice_paid = "Invoice paid",
  waiting_for_approval = "Waiting for approval",
  new = "New",
  shipment_in_progress = "Shipment in progress",
  shipment_on_the_way = "Shipment on the way",
  invoice_paid_SR = "Invoice paid SR",
}

export enum PaymentStatus {
  PENDING = 0,
  PAID = 1,
}

export enum NotificationPayload {
  PER_PAGE = 50,
  PAGE = 1,
}

export enum ShahenOrder {
  Order = 1,
}

export enum TimePrefix {
  HRS = "hrs",
}

export enum NotificationInterval {
  ONE_MINUTE = 60000,
}

export enum NoDriver {
  MESSAGE = "NO_VEHICLE_DRIVER_FOUND",
}

export enum OrderFileType {
  BAYAN = "bayan",
  WAYBILL = "waybill",
}

export enum AdminOrderStatus {
  WAITTING_FOR_APPROVAL_SR = "waitting_for_approval",
}

export enum ProviderOrderStatus {
  WAITTING_FOR_APPROVAL_SR = "waitting_for_approval",
}
export enum RequesterOrderStatus {
  WAITTING_FOR_APPROVAL_SR = "waitting_for_approval",
}

export enum requetserUserTypes {
  INDIVIDUAL = "individual",
  COMPANY = "company",
}

export enum userStorageKeys {
  SR_INFO = "SR_INFO",
  SP_INFO = "SP_INFO",
  ADMIN_INFO = "ADMIN_INFO",
  SUB_ADMIN_INFO = "SUB_ADMIN_INFO",
}

export enum qtyValidation {
  MAX_TRUCK = 10,
}

export enum languageStorage {
  PROVIDER_LAN = "service_provider_lan",
  REQUESTER_LAN = "service_requester_lan",
  ADMIN_LAN = "admin_lan",
}

export enum dashboardProviderTabs {
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
  YEARLY = "yearly",
}

export enum orderTypes {
  ORDER = "order",
  SPECIAL_ORDER = "special_order",
  MEMBERSHIP = "membership",
  OFFERS = "offer",
  CURRENT = "current",
  SHAHEN = "Shahen Express",
  SHAHEN_EXP = "Shahen",
}

export enum RequesterContractStatus {
  PENDING = "pending",
  INACTIVE = "inactive",
}

export enum orderTypeRedirection {
  "order" = "direct-orders/view/",
  "special_order" = "special-orders/order-details/",
  "membership" = "contracts/order-details/",
  "offer" = "offer-orders/",
}

export enum srOrderTypeRedirection {
  "order" = "direct-orders/view/",
  "special_order" = "special-orders/order-details/",
  "membership" = "my-contracts/order-details/",
  "offer" = "offers-list/offer-orders/",
}

export enum shipmentId {
  SHIPMENT_ID = 1,
}
export enum sectionType {
  DASHBOARD = "dashboard",
  REGISTER = "register",
}
export enum shahenOrder {
  isShahen = 1,
}

export const orderId = "order_id";
export const vat = "vat";
export const isCanceled = 1;

export enum fieldName {
  commodity_name = "commodity_name",
  truck_name = "truck_name",
  weight = "weight",
  from_city = "fromCity",
  sub_commodity_name = "sub_commodity_name",
  dangerous_code_name = "dangerous_code_name",
}
export enum errorMsg {
  city_not_available = "city not available",
}
export enum shahenOrderFlag {
  one = 1,
  zero = 0,
}
export enum cancelledTrip {
  one = 1,
  zero = 0,
}
export enum routerUrl {
  DIRECT_ORDER = "direct-orders",
  CONTRACTS = "contracts",
  MY_CONTRACTS = "my-contracts",
  SPECIAL_ORDER = "special-orders",
  ORDER_HISTORY = "order-history",
  OFFER_ORDER = "offer",
  ORDER_CONTRACTS = "order-contracts",
  CONTRACT = "contract",
  SHAHEN_CONTRACTS_ORDERS = "shahen-contract-orders",
}

export enum role {
  REQUESTER = "requester",
  PROVIDER = "provider",
}

export enum roleDisplay {
  REQUESTER = "SR",
  PROVIDER = "SP",
  ADMIN = "ADMIN",
}

export enum shahenName {
  SHAHEN_EXPRESS = "Shahen Express",
}

export enum spCancelOrderTypeRedirection {
  "order" = "direct-orders/",
  "special_order" = "special-orders/",
  "membership" = "contracts/",
}

export enum orderDefaultFilterKey {
  ORDER_ID = "order_id",
}

export enum manageAllOrderKeys {
  ORDER = "order",
  SPECIAL_ORDER = "special_order",
  MEMBERSHIP = "membership",
  OFFERS = "offer",
}

export enum TodayYesterday {
  Today = "Today",
  Yesterday = "Yesterday",
}

export enum tripSyncStatus {
  DELIVERED = "at_unloading_location",
}

export enum mapFields {
  FROM_CITY = "fromCity",
  TO_CITY = "toCity",
  FROM = "from",
  TO = "to",
}

export enum TypeOfOrder {
  DIRECT_ORDER = "Direct Order",
  SPECIAL_ORDER = "Special Order",
  CONTRACT_ORDER = "Contract Order",
  SHAHEN_CONTRACT_ORDER = "Shahen Contract Order",
  OFFER_ORDER = "Offer Order",
}

export enum TypeOfProfit {
  FIXED = "fixed",
  PERCENTAGE = "percentage",
  PERCENTAGE_CAPITAL = "Percentage",
  PERCENTAGE_SIGN = "%",
}

export enum ChartType {
  Doughnut = "doughnut",
}

export enum AllFilePath {
  NO_IMAGE = "assets/no-image.png",
  NO_PROFILEIMAGE = "assets/default-user-image.png",
  NO_DOCUMENT = "assets/default-document-image.png",
  ADMIN_LOGO_WEBP = "assets/images/admin/logo.webp",
  ADMIN_LOGO_PNG = "assets/images/admin/logo.png",
  FAV_IMG = "assets/img/brand/angular_logo_small.png",
  LOGO_IMG = "assets/img/brand/shahen_logo.png",
  LOGIN_SIDE_IMAGE_WEBP = "/login/side-image.webp",
  LOGIN_SIDE_IMAGE_JPG = "/login/side-image.jpg",
  IMAGES_LOGIN_SIDE_IMAGE_JPG = "/images/login/side-image.jpg",
}

export enum ExportFileType {
  SAMPLE = "sample",
  USER = "user",
  ADMIN = "admin",
}

export enum FileType {
  EXCEL = "excel",
  PDF = "pdf",
  CSV = "csv",
}

export enum GASCRIPTLINK {
  GASCRIPT = "https://www.googletagmanager.com/gtag/js?id=",
}

export enum TypeOfProgressBar {
  TWODIGIT = "twoDigitData",
  THREEDIGIT = "threeDigitData",
}

export enum ValueInString {
  Zero = "0",
  No = "No",
}

export const noDataFound = "No Data Found";

export enum adminTypes {
  ADMIN = "admin",
  SUPER_ADMIN = "Super Admin",
  SUB_ADMIN = "Sub Admin",
  SUPER_ADMIN_ROLE = "super_admin",
  SERVICE_REQUESTER_ROLE = "service_requester",
  SERVICE_REQUESTER = "Service Requester",
  SERVICE_PROVIDER_ROLE = "service_provider",
  SERVICE_PROVIDER = "Service Provider",
}

export const defaultAdminNonAssignFilte = "new";

export enum LogLevel {
  All = 0,
  Debug = 1,
  Info = 2,
  Warn = 3,
  Error = 4,
  Off = 5,
}

export enum ChatType {
  ONE_TO_ONE = "one-to-one",
  GROUP = "group",
}
export enum ModuleType {
  FULL_SCREEN = "full-screen",
  POPUP = "popup",
}

export enum UserRole {
  SERVICE_REQUESTER = "service_requester",
  SERVICE_PROVIDER = "service_provider",
  SUPER_ADMIN_USER = "super_admin",
  ADMIN_USER = "admin",
}

export enum RoutesRole {
  SERVICE_REQUESTER = "/requester",
  SERVICE_PROVIDER = "/provider",
  ADMIN_ROUTE = "/admin",
}

export enum RoleBaseImageURL {
  SERVICE_PROVIDER = "assets/images/service_provider",
  SERVICE_REQUESTER = "assets/images/service_requester",
}

export enum redirectionKeys {
  ORDER_ID = "order_id",
  ID = "id",
}

export enum AccountType {
  "WEB" = "web",
}

export enum userDeviceToken {
  PROVIDER_TOKEN = "service_provider_browser_token",
  REQUESTER_TOKEN = "service_requester_browser_token",
  ADMIN_TOKEN = "admin_browser_token",
}

export enum userPermissionsGet {
  SUPER_ADMIN = "SUPER_ADMIN",
  SHOW_CONTRACT_PRICE = "price_show_cancel_contract_order",
  CHANGE_SP_PROVIDER = "change_sp_assign_sh_contract_order",
  CHANGE_STATUS_DIRECT_ORDER = "status_direct_order",
  CHANGE_STATUS_SH_ORDER = "status_sh_contract_order",
  CHANGE_STATUS_SR_ORDER = "status_sr_contract_order",
  VIEW_DASHBOARD = "view_dashbaord",
}

export enum contractOrderPermission {
  SH_CONTRACT_ORDER = "SH_contract_order",
  SR_CONTRACT_ORDER = "SR_contract_order",
}

export enum returnContractOrderPermission {
  SH_CONTRACT_ORDER = "SH Contract Order",
  SR_CONTRACT_ORDER = "SR Contract Order",
}

export enum locationTypes {
  LOADING_LOCATION = "Loading Location",
  INTERMEDIATE_LOCATION = "Intermediate Location",
  OFFLOADING_LOCATION = "Offloading Location",
}
