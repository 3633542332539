import { Component, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BaseComponent } from "@components/base.component";
import { CONFIG } from "@config/app-config";
import { SrSpCancelOrderHideCollection } from "@config/app-constants";
import { AutoUnsubscribe } from "@decorator/autounsubscribe";
import {
  DownloadFileResponse,
  PaymentInitiate,
} from "@model/adminDirectOrders";
import { IDownloadInvoicePayload, IMapProps } from "@model/common";
import { ICancelTripRequest } from "@model/contract";
import {
  IChangeProviderPayloadPopup,
  IOrderDetailData,
  IRejectOrder,
  IStatusApiResponse,
  IStatusItem,
} from "@model/directOrdersDetails";
import { IErrorResponse } from "@model/errorResponse";
import { IDownloadInvoiceResponse } from "@model/payment";
import { ISuccessResponse } from "@model/successResponse";
import { AdminContractsService } from "@services/admin-contracts.service";
import { AdminOfferOrdersService } from "@services/admin-offer-orders.service";
import { CommonApiService } from "@services/common-api.service";
import { DirectOrdersService } from "@services/direct-orders.service";
import { WindowWidthService } from "@services/dynamic-width";
import { OrdersCommonService } from "@services/orders-common-service";
import {
  cancelledTrip,
  isCanceled,
  orderTypes,
  routerUrl,
  UserRole,
} from "@utils/enum-const";
import { Subscription } from "rxjs";
import { first } from "rxjs/operators";

@Component({
  selector: "app-admin-order-details",
  templateUrl: "./admin-order-details.component.html",
  styleUrls: ["./admin-order-details.component.scss"],
})
@AutoUnsubscribe()
export class AdminOrderDetailsComponent extends BaseComponent
  implements OnInit, OnDestroy {
  loadingIndicator = false;
  routeSub!: Subscription;
  _id!: number;
  orderDetails!: any;
  editMode = false;
  isSelectServiceProvider = false;
  loadingLatLong = {
    lat: "",
    lng: "",
  };
  offLoadingLatLong = {
    lat: "",
    lng: "",
  };
  tripDetails: any[] = [];
  isHideSearch = true;
  orderStatusList: IStatusItem[] = [];
  orderTripStatusList: IStatusItem[] = [];
  model = {
    order_status: "",
    trip_status: "",
  };
  isSrSpCancelVisibleStatus = SrSpCancelOrderHideCollection;
  getProviderPayload = {};
  truck_quantity = 0;
  vehicle_count = 0;
  tripId!: number;
  isCanceled = isCanceled;
  isCancelOrderButtonHide = true;
  orderTypes = orderTypes;
  userDetails = {
    user_id: "",
    user_type: "",
    driver: "",
    driver_mobile: "",
    laoding_point: "",
    offloading_point: "",
    orderNo: "",
    tripId: "",
    truck_plate_no: "",
    truck_type: "",
    weight: "",
    orderType: this.orderType.order,
  };
  userType = UserRole;
  routerUrl = routerUrl;
  isTripCancel = false;
  quantity!: number;
  tripCancelStatus = cancelledTrip;
  showTruckQuantity!: number;
  orderTypeRoute!: string;
  isChangeDescription = false;
  descriptionModel = {
    description: "",
  };
  hasChangeStatusPermission = false;
  markers: IMapProps[] = [];

  windowWidth: number = window.innerWidth;
  private windowWidthSubscription!: Subscription;

  constructor(
    private route: ActivatedRoute,
    private CommonService: CommonApiService,
    private ManageDirectOrders: DirectOrdersService,
    private ManageOrdersCommonService: OrdersCommonService,
    private adminContractOrderService: AdminContractsService,
    private adminOfferService: AdminOfferOrdersService,
    private windowWidthService: WindowWidthService
  ) {
    super();
  }

  /**
   * Init finction
   */
  ngOnInit() {
    this.windowWidthSubscription = this.windowWidthService.windowWidth$.subscribe(
      (width) => {
        this.windowWidth = width;
      }
    );
    if (this.router.url.includes("offer")) {
      this.orderTypeRoute = this.orderType.offer_order;
    } else if (this.router.url.includes("contract")) {
      this.orderTypeRoute = this.orderType.contract_order;
    } else {
      this.orderTypeRoute = this.orderType.order;
    }
    this.spinner.showLoader();
    this.routeSub = this.route.params.subscribe((params) => {
      this.editMode = this._id = params["id"];
      setTimeout(() => {
        this.initForm();
      }, 100);
    });
    this.hasChangeStatusPermission = this.checkSubAdminPermission(
      this.allPermissons.CHANGE_STATUS_DIRECT_ORDER
    );
  }

  ngOnDestroy(): void {
    if (this.windowWidthSubscription) {
      this.windowWidthSubscription.unsubscribe();
    }
  }

  /**
   * Init form
   */
  private initForm() {
    if (this._id) {
      this.getOrderDetails();
    }
    this.getOrderTripStatusList();
  }

  /**
   * Get order details based on order id
   */
  private getOrderDetails() {
    if (this.orderTypeRoute === this.orderType.order) {
      this.ManageDirectOrders.getOrderDetails(this._id)
        .pipe(first())
        .subscribe(
          (response: IOrderDetailData) => {
            this.processOrderDetails(response);
            this.spinner.hideLoader();
          },
          (error: IErrorResponse) => {
            this.spinner.hideLoader();
            this.errorHandler(this.toastr, this.translateService, error);
          }
        );
    } else {
      this.adminOfferService
        .getOrderDetails(this._id)
        .pipe(first())
        .subscribe(
          (response: any) => {
            this.processOrderDetails(response);
            this.spinner.hideLoader();
          },
          (error: IErrorResponse) => {
            this.spinner.hideLoader();
            this.errorHandler(this.toastr, this.translateService, error);
          }
        );
    }
  }

  /**
   * Based on order type set data
   */
  private processOrderDetails(response: IOrderDetailData) {
    this.orderDetails = response?.data.order
      ? response?.data.order
      : response?.data;
    this.assignSrSpLocationData();
    if (this.isSpecialOrderDetail() && this.orderDetails.providers) {
      this.processSpecialOrderDetails();
    }

    this.setLoadingOffloadingLatLong();
    this.model.order_status = this.orderDetails?.orderStatus;
    this.tripDetails = this.orderDetails?.bayan_trips;

    this.checkCancelOrderButtonVisibility();
    this.tripDetails?.forEach((item: any) => {
      item[item?.nextStatus] = false;
    });

    this.setUserDetails();
    this.processOrderCancelByUser();
  }

  /**
   * Check order type is special
   */
  private isSpecialOrderDetail() {
    return (
      this.orderDetails?.providers &&
      (this.orderDetails?.shahen_order === 1 ||
        this.orderDetails?.section === this.orderTypes.SPECIAL_ORDER)
    );
  }

  /**
   * Special order details set data
   */
  private processSpecialOrderDetails() {
    this.showTruckQuantity = this.orderDetails.total_provider_quantity;
    this.orderDetails.sp_approve = this.orderDetails.providers.find(
      (m: any) => m.provider_id === this.userId
    )?.sp_approve;
    this.quantity = this.orderDetails.providers.find(
      (m: any) => m.provider_id === this.userId
    )?.total_provider_quantity;
  }

  /**
   * Set location lat long
   */
  private setLoadingOffloadingLatLong() {
    this.loadingLatLong.lng = this.orderDetails?.lng_from;
    this.loadingLatLong.lat = this.orderDetails?.lat_from;
    this.offLoadingLatLong.lng = this.orderDetails?.lng_to;
    this.offLoadingLatLong.lat = this.orderDetails?.lat_to;
  }

  /**
   * Check cancel order button visiblity
   */
  private checkCancelOrderButtonVisibility() {
    if (
      this.isServiceProviderRoute &&
      this.orderType.membership !== this.orderType[this.orderDetails?.section]
    ) {
      this.isCancelOrderButtonHide = this.isSrSpCancelVisibleStatus.includes(
        this.orderDetails?.sp_status
      );
    }
    if (this.isServiceRequesterRoute) {
      this.isCancelOrderButtonHide = this.isSrSpCancelVisibleStatus.includes(
        this.orderDetails?.sr_status
      );
    }
  }

  /**
   * Set user details
   */
  private setUserDetails() {
    this.userDetails.weight =
      this.orderDetails?.weight + " " + this.measurementUnits.TON;
    this.userDetails.offloading_point = this.orderDetails?.detail_address_to;
    this.userDetails.laoding_point = this.orderDetails?.detail_address_from;

    this.orderDetails?.providers.forEach((e: any) => {
      if (e.provider_id === this.userId) {
        this.orderDetails.sp_approve = e.sp_approve;
        this.orderDetails.quantity = e.quantity;
        this.orderDetails.price = e.price;
        this.orderDetails.total_price = e.total_price;
      }
    });
  }

  /**
   * Cancel order by user and view that user details
   */
  private processOrderCancelByUser() {
    if (this.orderDetails?.order_cancel_by?.user) {
      this.orderDetails.order_cancel_by.user.role = this.getShortCompanyName(
        this.orderDetails?.order_cancel_by?.user?.roles[0]?.guard
      );
    }
  }

  /**
   * Change Order Status
   * @orderStatus string change order status based on selection
   */
  changeOrderStatus(orderStatus: string) {
    if (orderStatus) {
      this.spinner.showLoader();
      this.ManageOrdersCommonService.changeOrderStatus(orderStatus, this._id)
        .pipe(first())
        .subscribe(
          (data: ISuccessResponse) => {
            if (data.status_code === this.statusCode.OK) {
              this.toastr.success(data.message);
              this.initForm();
            }
          },
          (error: IErrorResponse) => {
            this.initForm();
            this.errorHandler(this.toastr, this.translateService, error);
          }
        );
    }
  }

  /**
   * Cancel Order
   * @cancelOrderPayload cancel order with reason
   */
  cancelOrder(cancelOrderPayload: IRejectOrder) {
    const TripCancelReasonpayload: ICancelTripRequest = {
      reason: cancelOrderPayload.comment,
    };
    if (this.isTripCancel) {
      this.spinner.showLoader();

      this.adminContractOrderService
        .cancelTrip(this.tripId, TripCancelReasonpayload)
        .pipe(first())
        .subscribe(
          (data: ISuccessResponse) => {
            if (data.status_code === this.statusCode.OK) {
              this.toastr.success(data.message);
              this.initForm();
              this.spinner.hideLoader();
            }
          },
          (error: IErrorResponse) => {
            this.errorHandler(this.toastr, this.translateService, error, () => {
              this.initForm();
            });
            this.spinner.hideLoader();
          }
        );
    } else {
      this.spinner.showLoader();
      const OrderCancelReasonpayload: IRejectOrder = {
        comment: cancelOrderPayload.comment,
      };
      this.ManageOrdersCommonService.cancelOrder(
        this._id,
        OrderCancelReasonpayload
      )
        .pipe(first())
        .subscribe(
          (data: ISuccessResponse) => {
            if (data.status_code === this.statusCode.OK) {
              this.toastr.success(data.message);
              this.initForm();
              this.spinner.hideLoader();
            }
          },
          (error: IErrorResponse) => {
            this.errorHandler(this.toastr, this.translateService, error, () => {
              this.initForm();
            });
            this.spinner.hideLoader();
          }
        );
    }
  }

  /**
   * Get order trip display list
   */
  getOrderTripStatusList() {
    this.spinner.showLoader();
    this.ManageOrdersCommonService.getOrderTripStatusList()
      .pipe(first())
      .subscribe(
        (resp: IStatusApiResponse) => {
          this.spinner.hideLoader();
          if (resp.status_code === this.statusCode.OK) {
            this.orderTripStatusList = resp.data;
          }
        },
        (error: IErrorResponse) => {
          this.spinner.hideLoader();
          this.errorHandler(this.toastr, this.translateService, error);
        }
      );
  }

  /**
   * Change order trip status
   * @tripId number which we want to update
   * @tripStatus string which we want to update
   */
  changeTripStatus(tripId: number, tripStatus: string) {
    this.spinner.showLoader();
    if (this.router.url.includes(this.routerUrl.SPECIAL_ORDER)) {
      this.ManageOrdersCommonService.specialOrderChangeOrderTripStatus(
        tripStatus,
        tripId
      )
        .pipe(first())
        .subscribe(
          (data: ISuccessResponse) => {
            this.spinner.hideLoader();
            if (data.status_code === this.statusCode.OK) {
              this.handleTripStatusChange(tripId, tripStatus);
              this.toastr.success(data.message);
              this.initForm();
            }
          },
          (error: IErrorResponse) => {
            this.spinner.hideLoader();
            this.errorHandler(this.toastr, this.translateService, error, () => {
              this.initForm();
            });
          }
        );
    } else {
      this.ManageOrdersCommonService.changeOrderTripStatus(tripStatus, tripId)
        .pipe(first())
        .subscribe(
          (data: ISuccessResponse) => {
            this.spinner.hideLoader();
            if (data.status_code === this.statusCode.OK) {
              this.handleTripStatusChange(tripId, tripStatus);
              this.toastr.success(data.message);
              this.initForm();
            }
          },
          (error: IErrorResponse) => {
            this.spinner.hideLoader();
            this.errorHandler(this.toastr, this.translateService, error, () => {
              this.initForm();
            });
          }
        );
    }
  }

  /**
   * Handle Trip Status
   */
  handleTripStatusChange(tripId: number, status: string) {
    const findData = this.tripDetails.findIndex(
      (ele: any) => ele.id === tripId
    );
    if (findData !== -1) {
      this.tripDetails[findData][status] = !this.tripDetails[findData][status];
    }
  }

  /**
   * Handle close modal
   */
  decline() {
    this.modalRef.hide();
    this.initForm();
  }

  /**
   * Set truck assignment what ever quanity accept or set
   * @quantity Integer
   */
  getTruckAssignment(quantity: number) {
    this.spinner.showLoader();

    this.ManageOrdersCommonService.setVehicleCount(
      quantity,
      this.orderDetails?.order_id
    )
      .pipe(first())
      .subscribe(
        (data: ISuccessResponse) => {
          this.spinner.hideLoader();
          this.modalRef.hide();
          if (data.status_code === this.statusCode.OK) {
            this.toastr.success(data.message);
            this.initForm();
          }
        },
        (error: IErrorResponse) => {
          this.spinner.hideLoader();
          this.modalRef.hide();
          this.errorHandler(this.toastr, this.translateService, error, () => {
            this.initForm();
          });
        }
      );
  }

  /**
   * Handle accpet order
   */
  acceptOrder() {
    this.spinner.showLoader();
    this.ManageOrdersCommonService.acceptOrder(this.orderDetails?.order_id)
      .pipe(first())
      .subscribe(
        (data: ISuccessResponse) => {
          this.spinner.hideLoader();
          this.modalRef.hide();
          if (data.status_code === this.statusCode.OK) {
            this.toastr.success(data.message);
            this.initForm();
          }
        },
        (error: IErrorResponse) => {
          this.spinner.hideLoader();
          this.modalRef.hide();
          this.errorHandler(this.toastr, this.translateService, error, () => {
            this.initForm();
          });
        }
      );
  }

  /**
   * Select provider incase quantity doesn't match or provider refuse order
   */
  selectProvider() {
    this.getProviderPayload = {
      section: this.orderDetails.section,
      truck_id: this.orderDetails.truck_id,
      quantity: this.orderDetails.quantity,
      city_from: this.orderDetails.city_from,
      order_id: this.orderDetails.order_id,
      city_to: this.orderDetails.city_to,
    };
    this.isSelectServiceProvider = !this.isSelectServiceProvider;
  }

  /**
   * Get provider API incase quantity doesn't match or provider refuse order
   * @providerData
   */
  getProviderData(providerData: IChangeProviderPayloadPopup) {
    this.spinner.showLoader();
    this.isSelectServiceProvider = false;
    const providerPayload = {
      provider_id: providerData.provider_id,
      price: providerData.price,
    };
    this.ManageOrdersCommonService.changePorvider(
      providerPayload,
      this.orderDetails?.order_id
    )
      .pipe(first())
      .subscribe(
        (data: ISuccessResponse) => {
          this.spinner.hideLoader();
          if (data.status_code === this.statusCode.OK) {
            this.toastr.success(data.message);
            this.initForm();
          }
        },
        (error: IErrorResponse) => {
          this.spinner.hideLoader();
          this.errorHandler(this.toastr, this.translateService, error, () => {
            this.initForm();
          });
        }
      );
  }

  /**
   * Open trip modal
   */
  openViewTripsModal(template: TemplateRef<string>) {
    this.modalRef = this.modalService.show(template, {
      class: "trip-modal-popup",
    });
  }

  /**
   * Open Provider Truck Modal
   */
  openProviderTruckModal(template: TemplateRef<string>) {
    this.truck_quantity = this.orderDetails?.quantity;
    this.vehicle_count =
      this.orderDetails?.vehicle_count > 0
        ? this.orderDetails?.vehicle_count
        : 0;

    if (this.truck_quantity > 0) {
      this.modalRef = this.modalService.show(template, { class: "modal-md" });
    }
  }

  /**
   * Open edit trips modal
   */
  openEditTripsModal(template: TemplateRef<string>) {
    this.modalRef = this.modalService.show(template, { class: "modal-lg" });
  }

  /**
   * Open Modal
   */
  openModal(template: TemplateRef<string>, module: string, tripId: number) {
    if (
      module === "reschedule" ||
      module === "cancelOrderPopup" ||
      module === "cancelTripPopup"
    ) {
      if (module === "cancelTripPopup") {
        this.tripId = tripId;
        this.isTripCancel = true;
      } else {
        this.isTripCancel = false;
      }
      if (module === "cancelOrderPopup") {
        this.modalRef = this.modalService.show(template, { class: "modal-md" });
      } else {
        this.modalRef = this.modalService.show(template, { class: "modal-ml" });
      }
    } else {
      this.modalRef = this.modalService.show(template, { class: "modal-md" });
    }
  }

  /**
   * Handle order payment shipment
   */
  paymentShipment() {
    // Payment Initiation
    this.spinner.showLoader();
    this.ManageOrdersCommonService.paymentInitiate(this.orderDetails?.order_id)
      .pipe(first())
      .subscribe(
        (data: PaymentInitiate) => {
          this.spinner.hideLoader();
          if (data.status_code === this.statusCode.OK) {
            const curLan = localStorage.getItem("service_requester_lan");
            const currentUserRequester = this.EncrDecr.get(
              CONFIG.EncrDecrKey as string,
              localStorage.getItem("currentUserRequester")
            );
            localStorage.setItem("lan", curLan ?? "");
            localStorage.setItem("orderType", this.orderTypeRoute);
            localStorage.setItem("mode", "web");
            localStorage.setItem("orderId", this.orderDetails?.order_id);
            localStorage.setItem(
              "token",
              JSON.parse(currentUserRequester).access_token
            );
            window.location.href = data.data.data.url;
          }
        },
        (error: IErrorResponse) => {
          this.spinner.hideLoader();
          this.errorHandler(this.toastr, this.translateService, error);
        }
      );
  }

  /**
   * Handle when no provider found
   */
  noProviderFound() {
    this.isSelectServiceProvider = false;
  }

  /**
   * Downlaod Bayal - Waybill
   */
  downloadBayanWaybillFile(tripId: number, fileType: string) {
    this.spinner.showLoader();
    const payload = { type: fileType };
    this.ManageDirectOrders.downloadBayanWaybill(tripId, payload)
      .pipe(first())
      .subscribe(
        (data: DownloadFileResponse) => {
          this.spinner.hideLoader();
          if (data.status_code === this.statusCode.OK) {
            if (data.data.link) {
              window.open(data.data.link, "_blank");
            }
          }
        },
        (error: IErrorResponse) => {
          this.spinner.hideLoader();
          this.errorHandler(this.toastr, this.translateService, error);
        }
      );
  }

  /**
   * When no provider back button handle redirection
   */
  backToAdd() {
    this.isSelectServiceProvider = false;
  }

  manageBayanAction(tripData: any): void {
    if (tripData.is_bayan_sync === 0) {
      this.bayanSyncing(tripData?.id);
    } else if (
      tripData.is_canceled === 1 &&
      tripData.is_bayan_sync === 1 &&
      tripData.closed === 1
    ) {
      this.cancelBayanTrip(tripData?.id);
    } else if (tripData.is_bayan_sync === 1 && tripData.closed !== 1) {
      this.closeBayanTrip(tripData?.id);
    } else {
      return;
    }
  }

  /**
   * Handle bayan sync button
   */

  bayanSyncing(tripId: number) {
    this.spinner.showLoader();
    this.CommonService.syncBayanTrip(tripId, this._id)
      .pipe(first())
      .subscribe(
        (data: ISuccessResponse) => {
          this.spinner.hideLoader();
          if (data.status_code === this.statusCode.OK) {
            this.toastr.success(data.message);
            this.initForm();
          }
        },
        (error: IErrorResponse) => {
          this.spinner.hideLoader();
          this.errorHandler(this.toastr, this.translateService, error, () => {
            this.initForm();
          });
        }
      );
  }

  /**
   * Chat redirection with default order data
   */
  redirectToChat(
    typeOfUser: string,
    id: string,
    driverName: string,
    driverMobile: string,
    tripId: string,
    plateNumber: string,
    truckName: string
  ) {
    this.userDetails.user_id = id;
    this.userDetails.user_type = typeOfUser;
    this.userDetails.driver = driverName;
    this.userDetails.driver_mobile = driverMobile;
    this.userDetails.tripId = tripId;
    this.userDetails.truck_plate_no = plateNumber;
    this.userDetails.truck_type = truckName;
    this.userDetails.orderNo = this.orderDetails?.order_id;

    this.router.navigateByUrl(this.getRoutesRole() + "/chat", {
      state: { userData: this.userDetails },
    });
  }

  /**
   * Handle close bayan trip
   */

  closeBayanTrip(tripId: number) {
    this.spinner.showLoader();
    this.CommonService.closeBayanTrip(tripId, this._id)
      .pipe(first())
      .subscribe(
        (data: ISuccessResponse) => {
          this.spinner.hideLoader();
          if (data.status_code === this.statusCode.OK) {
            this.toastr.success(data.message);
            this.initForm();
          }
        },
        (error: IErrorResponse) => {
          this.spinner.hideLoader();
          this.errorHandler(this.toastr, this.translateService, error, () => {
            this.initForm();
          });
        }
      );
  }

  /**
   * Cancel Bayan trips
   */
  cancelBayanTrip(tripId: number) {
    this.spinner.showLoader();
    this.CommonService.cancelBayanTrip(tripId, this._id)
      .pipe(first())
      .subscribe(
        (data: ISuccessResponse) => {
          this.spinner.hideLoader();
          if (data.status_code === this.statusCode.OK) {
            this.toastr.success(data.message);
            this.initForm();
          }
        },
        (error: IErrorResponse) => {
          this.spinner.hideLoader();
          this.errorHandler(this.toastr, this.translateService, error, () => {
            this.initForm();
          });
        }
      );
  }

  /**
   * Handle change desctiption view
   */
  handleChangeDescriptionView() {
    this.descriptionModel.description = this.orderDetails?.description;
    this.isChangeDescription = !this.isChangeDescription;
  }

  /**
   * Handle change description API call
   */
  handleChangeDescription() {
    this.spinner.showLoader();
    this.CommonService.changeOrderDescription(this._id, this.descriptionModel)
      .pipe(first())
      .subscribe(
        (data: ISuccessResponse) => {
          this.spinner.hideLoader();
          if (data?.status_code === this.statusCode.OK) {
            this.toastr.success(data.message);
            this.initForm();
          }
        },
        (error: IErrorResponse) => {
          this.spinner.hideLoader();
          this.errorHandler(this.toastr, this.translateService, error, () => {
            this.initForm();
          });
        }
      );
    this.handleChangeDescriptionView();
  }

  /**
   * Handle set marker data
   */
  assignSrSpLocationData() {
    if (this.orderDetails?.order_locations) {
      this.orderDetails.order_locations.forEach(
        (location: any, locationNumber: number, locationArray: any[]) => {
          this.markers.push({
            lat: parseFloat(location.loading_lat),
            lng: parseFloat(location.loading_lng),
          });
          // Add the offloading location for the last step
          if (locationNumber === locationArray.length - 1) {
            this.markers.push({
              lat: parseFloat(location.offloading_lat),
              lng: parseFloat(location.offloading_lng),
            });
          }
        }
      );
    }
  }

  /**
   * Download Invoice
   */
  downloadInvoice() {
    const payload: IDownloadInvoicePayload = {
      orderid: this.orderDetails?.order_id as number,
      type: this.userType.REQUESTER,
    };
    this.spinner.showLoader();
    this.CommonService.downloadInvoice(payload)
      .pipe(first())
      .subscribe(
        (data: IDownloadInvoiceResponse) => {
          this.spinner.hideLoader();
          window.open(data?.data?.report_url, "_blank");
        },
        (error: IErrorResponse) => {
          this.spinner.hideLoader();
          this.errorHandler(
            this.toastr,
            this.translateService,
            error,
            () => {}
          );
        }
      );
  }
}
