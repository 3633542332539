import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from "@angular/core";
import { actionMessage, actionStatusList } from "@utils/enum-const";
import { BaseComponent } from "@components/base.component";

@Component({
  selector: "app-handle-status",
  templateUrl: "./handle-status.component.html",
  styleUrls: ["./handle-status.component.scss"],
})
export class HandleStatusComponent extends BaseComponent {
  @Input() currentStatus!: number;
  @Input() recordId!: number;
  @Input() confirmStatusMessage = actionMessage.STATUS_CHANGE_MESSAGE;
  @Output() confirmStatusChange: EventEmitter<number> = new EventEmitter<
    number
  >();
  actionStatusValues = actionStatusList;

  /**
   * Handle open modal
   */
  openModal(template: TemplateRef<string>) {
    this.modalRef = this.modalService.show(template, { class: "modal-md" });
  }

  /**
   * Handle decline modal
   */
  decline(): void {
    this.modalRef.hide();
  }

  /**
   * Handle delete modal
   */
  handleDelete() {
    this.decline();
    this.confirmStatusChange.emit(this.recordId);
  }
}
