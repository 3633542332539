<div class="provider-list-area">
  <div class="d-flex space-between align-center">
    <h3>{{ "SELECT_PROVDER" | translate }}</h3>
    <a class="btn bordered-btn btn-small" (click)="this.backToOrderCreate()">{{
      "BACK" | translate
    }}</a>
  </div>
  <div class="empty-row" *ngIf="providerList.length === 0">
    {{ message }}
  </div>
  <ng-container *ngFor="let item of this.providerList">
    <div class="list-box">
      <div class="company-detail">
        <div class="company-name">
          {{ item?.provider?.company_name }}
        </div>
      </div>
      <div class="distance">
        <div class="distance-area">
          <div class="d-flex">
            <div class="location">
              <div class="from">
                <span class="icon-area">
                  <span class="point"><span class="fill"></span></span>
                </span>
                <div class="details d-flex flex-col">
                  <span class="title"> {{ "FROM" | translate }}: </span>
                  <span class="subtitle">
                    {{ item?.group_from_city?.name }}
                  </span>
                </div>
              </div>
              <div class="to">
                <span class="icon-area">
                  <span class="point"><span class="fill"></span></span>
                </span>
                <div class="details d-flex flex-col">
                  <span class="title"> {{ "TO" | translate }}: </span>
                  <span class="subtitle">
                    {{ item?.group_to_city?.name }}
                  </span>
                </div>
              </div>
            </div>
            <div class="trucks">
              <div class="from flex-col">
                <span class="title"> {{ "TRUCK_TYPE" | translate }}: </span>
                <span class="subtitle"> {{ item?.truck?.name }} </span>
              </div>
              <div class="to flex-col">
                <span class="title">
                  {{ "AVAILABLE_TRUCKS" | translate }}:
                </span>
                <span class="subtitle"> {{ item?.available_trucks }} </span>
              </div>
            </div>
          </div>
          <div class="select-provider">
            <span class="price"
              >{{ "SAR" | translate }} {{ item?.price }} /{{
                "EACH" | translate
              }}</span
            >
            <a (click)="getSelectedProvider(item)" class="btn btn-secondary">{{
              "SELECT_PROVIDER" | translate
            }}</a>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template *ngIf="this.providerList.length === 0">
    <div class="list-box">
      {{ "NO_PROVIDER_FOUND" | translate }}
    </div>
  </ng-template>
</div>
