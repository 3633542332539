import { Directive, ElementRef, Renderer2, HostListener } from "@angular/core";

@Directive({
  selector: "[appCaretRotation]",
})
export class CaretRotationDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  private isOpen = false; // Add a variable to track the state

  @HostListener("click") onClick() {
    // Toggle the CSS class for rotation
    this.isOpen = !this.isOpen;
    const iconElement = this.el.nativeElement.querySelector(".icon-arrow-down");
    if (iconElement) {
      if (this.isOpen) {
        this.renderer.addClass(iconElement, "rotate-caret");
      } else {
        this.renderer.removeClass(iconElement, "rotate-caret");
      }
    }
  }
}
