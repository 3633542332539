<div class="wrapper">
  <div class="sidebar">
    <div class="logo-area">
      <picture>
        <source [srcset]="this.adminLogoWebp" type="image/webp" />
        <source [srcset]="this.adminLogo" type="image/png" />
        <img
          [src]="this.adminLogo"
          alt="Profile"
          title="Profile"
          width="118px"
          height="70px"
        />
      </picture>
    </div>
    <ul>
      <li routerLinkActive="active">
        <a [routerLink]="['/admin/dashboard']">
          <i class="icon-dashboard"></i>
          {{ "DASHBOARD" | translate }}
        </a>
      </li>
      <li
        class="nav-item nav-dropdown"
        routerLinkActive="open"
        appNavDropdown
        *ngxPermissionsOnly="[
          'SUPER_ADMIN',
          'show_requesters',
          'create_requester'
        ]"
      >
        <a class="nav-link nav-dropdown-toggle" appNavDropdownToggle
          ><i class="icon-SR"></i> {{ "SERVICE_REQUESTERS" | translate }}</a
        >
        <ul class="nav-dropdown-items">
          <li
            *ngxPermissionsOnly="['SUPER_ADMIN', 'show_requesters']"
            class="nav-item"
          >
            <a
              routerLinkActive="active"
              class="nav-link menu-pl"
              [routerLink]="['/admin/service-requester/list']"
            >
              <i class="icon-minus"></i>
              {{ "ALL_SERVICE_REQUESTERS" | translate }}
            </a>
          </li>
          <li
            *ngxPermissionsOnly="['SUPER_ADMIN', 'create_requester']"
            class="nav-item"
          >
            <a
              class="nav-link menu-pl"
              routerLinkActive="active"
              [routerLink]="['/admin/service-requester/add']"
              ><i class="icon-minus"></i>
              {{ "ADD_NEW_SERVICE_REQUESTER" | translate }}</a
            >
          </li>
        </ul>
      </li>
      <li
        class="nav-item nav-dropdown"
        routerLinkActive="open"
        appNavDropdown
        *ngxPermissionsOnly="[
          'SUPER_ADMIN',
          'show_providers',
          'create_provider'
        ]"
      >
        <a class="nav-link nav-dropdown-toggle" appNavDropdownToggle
          ><i class="icon-SP"></i> {{ "SERVICE_PROVIDERS" | translate }}</a
        >
        <ul class="nav-dropdown-items">
          <li
            *ngxPermissionsOnly="[
              'SUPER_ADMIN',
              'show_providers',
            ]"
            class="nav-item"
          >
            <a
              routerLinkActive="active"
              class="nav-link menu-pl"
              [routerLink]="['/admin/service-provider/list']"
            >
              <i class="icon-minus"></i
              >{{ "ALL_SERVICE_PROVIDERS" | translate }}
            </a>
          </li>
          <li
            class="nav-item"
            *ngxPermissionsOnly="['SUPER_ADMIN', 'create_provider']"
          >
            <a
              class="nav-link menu-pl"
              routerLinkActive="active"
              [routerLink]="['/admin/service-provider/add']"
              ><i class="icon-minus"></i>
              {{ "ADD_NEW_SERVICE_PROVIDER" | translate }}</a
            >
          </li>
        </ul>
      </li>
      <li
        *ngxPermissionsOnly="[
          'SUPER_ADMIN',
          'USER',
          'show_drivers',
          'add_driver'
        ]"
        class="nav-item nav-dropdown"
        routerLinkActive="open"
        appNavDropdown
      >
        <a class="nav-link nav-dropdown-toggle" appNavDropdownToggle
          ><i class="icon-Drivers"></i>{{ "DRIVERS" | translate }}</a
        >
        <ul class="nav-dropdown-items">
          <li
            *ngxPermissionsOnly="['SUPER_ADMIN', 'USER', 'show_drivers']"
            class="nav-item"
          >
            <a
              routerLinkActive="active"
              class="nav-link menu-pl"
              [routerLink]="['/admin/driver-list/list']"
            >
              <i class="icon-minus"></i>{{ "ALL_DRIVERS" | translate }}
            </a>
          </li>
          <li
            *ngxPermissionsOnly="['SUPER_ADMIN', 'USER', 'add_driver']"
            class="nav-item"
          >
            <a
              class="nav-link menu-pl"
              routerLinkActive="active"
              [routerLink]="['/admin/driver-list/add']"
              ><i class="icon-minus"></i> {{ "ADD_NEW_DRIVER" | translate }}</a
            >
          </li>
        </ul>
      </li>
      <li
        *ngxPermissionsOnly="[
          'SUPER_ADMIN',
          'USER',
          'show_vehicles',
          'add_vehicles'
        ]"
        class="nav-item nav-dropdown"
        routerLinkActive="open"
        appNavDropdown
      >
        <a class="nav-link nav-dropdown-toggle" appNavDropdownToggle
          ><i class="icon-Trucks"></i>{{ "VEHICLES" | translate }}</a
        >
        <ul class="nav-dropdown-items">
          <li
            *ngxPermissionsOnly="['SUPER_ADMIN', 'USER', 'show_vehicles']"
            class="nav-item"
          >
            <a
              routerLinkActive="active"
              class="nav-link menu-pl"
              [routerLink]="['/admin/manage-vehicle/list']"
            >
              <i class="icon-minus"></i>{{ "ALL_VEHICLES" | translate }}
            </a>
          </li>
          <li
            *ngxPermissionsOnly="['SUPER_ADMIN', 'USER', 'add_vehicles']"
            class="nav-item"
          >
            <a
              class="nav-link menu-pl"
              routerLinkActive="active"
              [routerLink]="['/admin/manage-vehicle/add']"
              ><i class="icon-minus"></i> {{ "ADD_NEW_VEHICLE" | translate }}</a
            >
          </li>
        </ul>
      </li>
      <li
        class="nav-item nav-dropdown"
        routerLinkActive="open"
        appNavDropdown
        *ngxPermissionsOnly="[
          'SUPER_ADMIN',
          'USER',
          'show_price_option_sp',
          'show_price_option_sr'
        ]"
      >
        <a class="nav-link nav-dropdown-toggle" appNavDropdownToggle
          ><i class="icon-Price-Options"></i
          >{{ "PRICE_OPTIONS" | translate }}</a
        >
        <ul class="nav-dropdown-items">
          <li class="nav-item">
            <a
              class="nav-link menu-pl"
              routerLinkActive="active"
              *ngxPermissionsOnly="[
                'SUPER_ADMIN',
                'USER',
                'show_price_option_sp'
              ]"
              [routerLink]="['/admin/manage-provider-pricing']"
              ><i class="icon-minus"></i>
              {{ "PROVIDER_PRICE_OPTIONS" | translate }}</a
            >
          </li>
          <li class="nav-item">
            <a
              routerLinkActive="active"
              class="nav-link menu-pl"
              *ngxPermissionsOnly="[
                'SUPER_ADMIN',
                'USER',
                'show_price_option_sr'
              ]"
              [routerLink]="['/admin/manage-requester-pricing']"
            >
              <i class="icon-minus"></i
              >{{ "REQUESTER_PRICE_OPTIONS" | translate }}
            </a>
          </li>
        </ul>
      </li>
      <li
        *ngxPermissionsOnly="['SUPER_ADMIN', 'USER', 'show_direct_order']"
        routerLinkActive="active"
      >
        <a [routerLink]="['/admin/direct-orders']">
          <i class="icon-Direct-Orders"></i>
          {{ "DIRECT_ORDERS" | translate }}
        </a>
      </li>
      <li
        *ngxPermissionsOnly="['SUPER_ADMIN', 'show_sr_contract_order']"
        routerLinkActive="active"
      >
        <a [routerLink]="['/admin/order-contracts']">
          <i class="icon-Contracts"></i>
          {{ "CONTRACTS_ORDERS" | translate }}
        </a>
      </li>

      <li
        *ngxPermissionsOnly="['SUPER_ADMIN', 'show_sh_contract_order']"
        routerLinkActive="active"
      >
        <a [routerLink]="['/admin/shahen-contract-orders']">
          <i class="icon-Contracts"></i>
          {{ "SHAHEN_CONTRACT_ORDERS" | translate }}
        </a>
      </li>
      <li
        *ngxPermissionsOnly="['SUPER_ADMIN', 'view_location_master']"
        routerLinkActive="active"
      >
        <a [routerLink]="['/admin/city-master']">
          <i class="icon-map"></i>
          {{ "CITY_MASTER" | translate }}
        </a>
      </li>
      <li
        *ngxPermissionsOnly="['SUPER_ADMIN', 'view_trip']"
        routerLinkActive="active"
      >
        <a [routerLink]="['/admin/master-trip']">
          <i class="icon-Trucks"></i>
          {{ "MASTER_TRIP_LISTING" | translate }}
        </a>
      </li>
      <li
        *ngxPermissionsOnly="[
          'SUPER_ADMIN',
          'show_admin_manager',
          'add_admin_manager'
        ]"
        class="nav-item nav-dropdown"
        routerLinkActive="open"
        appNavDropdown
      >
        <a class="nav-link nav-dropdown-toggle" appNavDropdownToggle
          ><i class="icon-admin"></i>{{ "ADMIN_MANAGER" | translate }}</a
        >
        <ul class="nav-dropdown-items">
          <li
            *ngxPermissionsOnly="['SUPER_ADMIN', 'show_admin_manager']"
            class="nav-item"
          >
            <a
              routerLinkActive="active"
              class="nav-link menu-pl"
              [routerLink]="['/admin/admin-manager/list']"
            >
              <i class="icon-minus"></i>{{ "SUB_ADMIN_LIST" | translate }}
            </a>
          </li>
          <li
            *ngxPermissionsOnly="['SUPER_ADMIN', 'add_admin_manager']"
            class="nav-item"
          >
            <a
              class="nav-link menu-pl"
              routerLinkActive="active"
              [routerLink]="['/admin/admin-manager/add']"
              ><i class="icon-minus"></i>
              {{ "ADD_NEW_SUB_ADMIN" | translate }}</a
            >
          </li>
        </ul>
      </li>
      <li
        routerLinkActive="active"
        *ngxPermissionsOnly="['SUPER_ADMIN', 'show_settings']"
      >
        <a [routerLink]="['/admin/admin-settings']">
          <i class="icon-settings"></i>
          {{ "SETTINGS" | translate }}
        </a>
      </li>
      <li routerLinkActive="active">
        <a
          [routerLink]="['/admin/support-messages']"
          *ngxPermissionsOnly="[
          'SUPER_ADMIN',
          'show_support_message',
        ]"
        >
          <i class="icon-support"></i>
          {{ "SUPPORT_MESSAGES" | translate }}
        </a>
      </li>
    </ul>
  </div>
  <main>
    <div class="top-bar">
      <div class="d-flex just-end">
        <div class="detail">
          <a
            class="chat-message"
            [routerLink]="['chat']"
            *ngxPermissionsOnly="[
            'SUPER_ADMIN',
            'show_chat',
          ]"
          >
            <i class="icon-chat"></i>
            <span class="chat-message-count">
              {{ this.unReadMessageCount }}
            </span>
          </a>
          <a class="bell" (click)="this.openNotification()">
            <i class="icon-notification"></i>
            <span class="notification-count">
              {{ this.unReadCount }}
            </span>
            <div
              class="notification-box d-flex"
              id="notification-box"
              appDivCloseOnClick
            >
              <div class="notification-title d-flex space-between">
                <h3>{{ "NOTIFICATIONS" | translate }}</h3>
              </div>

              <div class="d-flex notification-list">
                <ng-container
                  *ngFor="let notification of this.notificationData"
                >
                  <a
                    [routerLink]="[
                      notification.type === this.SectionType.MEMBERSHIP
                        ? this.getRoutesRole() +
                          '/order-contracts/' +
                          notification?.model_id
                        : notification.type === this.SectionType.ORDER
                        ? this.getRoutesRole() +
                          '/direct-orders/view/' +
                          notification?.model_id
                        : notification.type === this.SectionType.CHAT
                        ? this.getRoutesRole() + '/chat'
                        : notification.type === this.SectionType.SPECIAL_ORDER
                        ? this.getRoutesRole() +
                          '/special-orders/order-details/' +
                          notification.model_id
                        : notification.type === this.SectionType.OFFERS
                        ? this.getRoutesRole() +
                          '/offers-orders/' +
                          notification?.model_id
                        : ''
                    ]"
                    (click)="
                      notification.seen === 0
                        ? this.notificationRead(notification?.id)
                        : null
                    "
                  >
                    <div class="notification d-flex space-between">
                      <div class="d-flex notification-icon-text">
                        <div
                          class="notification-icon"
                          [ngClass]="
                            notification.type === this.SectionType.MEMBERSHIP
                              ? 'bg-contract'
                              : notification.type === this.SectionType.ORDER
                              ? 'bg-direct-order'
                              : notification.type === this.SectionType.CHAT
                              ? 'bg-bell'
                              : notification.type ===
                                this.SectionType.SPECIAL_ORDER
                              ? 'bg-special-order'
                              : notification.type === this.SectionType.OFFERS
                              ? 'bg-offers'
                              : ''
                          "
                        >
                          <i
                            [ngClass]="
                              notification.type === this.SectionType.MEMBERSHIP
                                ? 'icon-Contracts'
                                : notification.type === this.SectionType.ORDER
                                ? 'icon-Direct-Orders'
                                : notification.type === this.SectionType.CHAT
                                ? 'icon-chat'
                                : notification.type ===
                                  this.SectionType.SPECIAL_ORDER
                                ? 'icon-Special-Orders'
                                : notification.type === this.SectionType.OFFERS
                                ? 'icon-Offers'
                                : ''
                            "
                          ></i>
                        </div>
                        <div class="notification-detail">
                          <h5>
                            {{ this.OrderType[notification?.type] }}:
                            {{
                              notification?.model_id
                                ? notification?.model_id
                                : notification?.title
                            }}
                          </h5>
                          <p>
                            {{
                              notification.type === "chat"
                                ? this.getAdminDecodeMessage(
                                    notification?.extra
                                  )
                                : notification.body
                            }}
                          </p>
                        </div>
                      </div>

                      <div class="notification-date-time d-flex">
                        <span>{{
                          notification?.created_at
                            ? this.getNotificationTimeConvert(
                                notification?.created_at
                              )
                            : ""
                        }}</span>
                        <span>{{
                          this.getNotificationTimeConvert(
                            notification?.created_at,
                            this.notificationTime
                          )
                        }}</span>
                      </div>
                    </div>
                  </a>
                </ng-container>
                <div
                  *ngIf="this.notificationData.length < 1"
                  class="no-notification"
                >
                  <h3>{{ "NO_NOTIFICATION_YET" | translate }}</h3>
                  <i class="icon-notification"></i>
                  <span>{{ "NOTIFY_MSG" | translate }}</span>
                </div>
              </div>

              <div class="view-notification">
                <div
                  class="view-notification-inner border-right d-flex align-center"
                >
                  <a
                    (click)="this.closeNotification()"
                    [routerLink]="['notification-list']"
                    class="notify"
                    >{{ "VIEW_ALL_NOTIFICATIONS" | translate }}</a
                  >
                </div>
                <div class="view-notification-inner d-flex align-center">
                  <span
                    title="{{ 'MARK_ALL_AS_READ' | translate }}"
                    (click)="this.seenAllAdminNotifications()"
                    class="mark_as_read"
                  >
                    {{ "MARK_ALL_AS_READ" | translate }}
                  </span>
                </div>
              </div>
            </div>
          </a>
          <div class="company-detail">
            <div class="company_logo">
              <picture>
                <img
                  [src]="logoUrl"
                  alt="Profile"
                  title="Profile"
                  width="40px"
                  height="40px"
                  id="profileLogo"
                  (error)="this.onErrorProfileImage($event)"
                />
              </picture>
            </div>
            <div class="profile-detail dropdown" appDropdown>
              <div class="select">
                <span class="selected">
                  <span class="profile-title" id="headerName">{{
                    adminName
                  }}</span>
                  <span class="profile-type">{{ role }}</span>
                </span>
                <div class="caret">
                  <i class="icon-arrow-down"></i>
                </div>
              </div>
              <ul class="menu">
                <li routerLinkActive="active" [routerLink]="['/admin/profile']">
                  {{ "ACCOUNT_DETAILS" | translate }}
                </li>
                <app-logout-section (confirmLogout)="onAdminLogout($event)">
                </app-logout-section>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-outlet></router-outlet>
  </main>
  <ng-template #logoutTemplate>
    <div class="modal-header bg-primary">
      <h5 class="modal-title">
        <i class="fa fa-info-circle modal-icon"></i>
        {{ "CONFIRMATION" | translate }}
      </h5>
      <button type="button" class="close" (click)="decline()">
        <span aria-hidden="true"><i class="icon-close"></i></span>
      </button>
    </div>
    <div class="modal-body text-center">
      <p>{{ "CONFIRM_LOGOUT" | translate }}</p>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-small btn-primary"
        (click)="onLogout()"
      >
        {{ "YES" | translate }}
      </button>
      <button
        type="button"
        class="btn btn-small btn-warning"
        (click)="decline()"
      >
        {{ "NO" | translate }}
      </button>
    </div>
  </ng-template>
</div>
<app-spinner></app-spinner>
