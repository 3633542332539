import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from "@angular/core";
import { BaseComponent } from "@components/base.component";
import { AutoUnsubscribe } from "@decorator/autounsubscribe";
import { IMapProps } from "@model/common";
import { MapService } from "@services/map.service";
import { mapProperties } from "@utils/common";
import {
  languageTypes,
  mapErrorIssue,
  mapSothArabicProperties,
} from "@utils/enum-const";
import { first } from "rxjs/operators";

@Component({
  selector: "app-map-address-details",
  templateUrl: "./map-address-details.component.html",
  styleUrls: [
    "../provider-registration/provider-registration.component.scss",
    "./map-address-details.component.scss",
  ],
})
@AutoUnsubscribe()
export class MapAddressDetailsComponent extends BaseComponent
  implements OnInit, OnChanges {
  isMapSelected = false;
  @Input() destinationGraph: any;
  @Input() latlong!: IMapProps;
  @Input() googleMapId = "googleMap";
  @Input() isHideSearch = false;
  @Output() getMapData = new EventEmitter<IMapProps>();
  @Input() isToLocation!: number;
  @Input() isFromLocation!: number;
  public markers: any = [];
  public map!: google.maps.Map;
  searchLocationLatLong!: google.maps.LatLng;
  postalCode: any;
  isMapTyping = false; // Tracks whether the user is typing
  isMapHovering = false; // Tracks whether the mouse is hovering
  @Input() isMapModalView = false;
  mapDetails!: IMapProps;

  constructor(private mapService: MapService) {
    super();
  }

  ngOnInit(): void {
    // Binding Dynamic ID on Div Element after that intialize map
    // Incase removing timeout id will get null so map data binding issue
    setTimeout(() => {
      this.initMap();
    }, 1000);
  }

  /**
   * Map View
   */
  async ngOnChanges(changes: SimpleChanges) {
    if (this.isHideSearch) {
      this.initSearchLocationAutoComplete();
    }
    console.log(changes, "changes");
    setTimeout(async () => {
      if (changes["lat"]?.currentValue && changes["long"]?.currentValue) {
        const latlng = new google.maps.LatLng(
          changes["lat"].currentValue,
          changes["long"].currentValue
        );
        this.onLatLngEvent(latlng);
        this.createMarker(latlng);
        this.isMapSelected = true;
      }
      this.spinner.hideLoader();
    }, 2500);
    setTimeout(() => {
      if (this.map) {
        this.map.setZoom(10);
      }
    }, 1000);
  }

  /**
   * init map view
   */
  public initMap() {
    const southArabiaCenter = new google.maps.LatLng(24.7136, 46.6753);
    const mapProp: google.maps.MapOptions = {
      ...mapProperties,
      center: southArabiaCenter, // Set the map's initial center to South Arabia for restrict
      zoom: 10,
      zoomControlOptions: {
        position: google?.maps?.ControlPosition?.LEFT_CENTER,
      },
      mapTypeControlOptions: {
        position: google?.maps?.ControlPosition?.LEFT_TOP,
      },
      restriction: {
        latLngBounds: {
          north: mapSothArabicProperties.north,
          south: mapSothArabicProperties.south,
          east: mapSothArabicProperties.east,
          west: mapSothArabicProperties.west,
        },
        strictBounds: true,
      },
    };
    const nodeElement = document.getElementById(this.googleMapId);
    if (nodeElement) {
      this.map = new google.maps.Map(
        document.getElementById(this.googleMapId) as HTMLElement,
        mapProp
      );
      google.maps.event.addListener(this.map, "center_changed", () => {
        this.mapService.checkBounds(this.map);
      });
    }
    this.initSearchLocationAutoComplete();
    if (this.latlong?.lat && this.latlong?.lng) {
      const latlng = new google.maps.LatLng(
        this.latlong.lat as number,
        this.latlong.lng as number
      );
      this.createMarker(latlng);
      this.onLatLngEvent(latlng);
    } else {
      navigator["geolocation"].getCurrentPosition(
        async (position: GeolocationPosition) => {
          const currentPosition = new google.maps.LatLng(
            position.coords.latitude,
            position.coords.longitude
          );

          if (this.isPlaceInSouthArabia(currentPosition)) {
            this.createMarker(currentPosition);
            this.onLatLngEvent(currentPosition);

            // const detailedAddress: any = await this.getDetailedAddress(
            //   currentPosition.lat(),
            //   currentPosition.lng()
            // );

            // this.getMapData.emit({
            //   lat: currentPosition.lat(),
            //   lng: currentPosition.lng(),
            //   address: detailedAddress.address,
            //   city_name: detailedAddress.city_name.long_name,
            // });
            this.isMapSelected = true;
          }
        }
      );
    }
  }

  /**
   * Initial Search Location
   */
  initSearchLocationAutoComplete() {
    const that = this;
    const input = document.getElementById(
      "autocomplete_search_" + this.googleMapId
    ) as HTMLInputElement;
    const autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.addListener("place_changed", function () {
      const place = autocomplete.getPlace();
      if (that.isPlaceInSouthArabia(place)) {
        if (place.geometry?.location) {
          that.searchLocationLatLong = new google.maps.LatLng(
            place.geometry.location.lat(),
            place.geometry.location.lng()
          );
          const cityName = place?.address_components?.find((component) =>
            component.types.includes("locality")
          );
          const countryName = place?.address_components?.find((component) =>
            component.types.includes("country")
          );

          const postalCode = place?.address_components?.find((component) =>
            component.types.includes("postal_code")
          );

          const streetName = place?.address_components?.find((component) =>
            component.types.includes("route")
          );

          const districtName = place?.address_components?.find(
            (component) =>
              component.types.includes("locality") ||
              component.types.includes("administrative_area_level_2")
          );

          const provinceName = place?.address_components?.find((component) =>
            component.types.includes("administrative_area_level_1")
          );

          that.getMapData.emit({
            lng: place.geometry?.location.lng(),
            lat: place.geometry?.location.lat(),
            address: place?.formatted_address,
            country: (countryName?.long_name as unknown) as string,
            postal_code: (postalCode?.long_name as unknown) as string,
            city_name: (cityName?.long_name as unknown) as string,
            street_name: (streetName?.long_name as unknown) as string,
            district: (districtName?.long_name as unknown) as string,
            province_name: (provinceName?.long_name as unknown) as string,
          });
          that.onLatLngEvent(that.searchLocationLatLong);
          that.createMarker(that.searchLocationLatLong);
          that.isMapSelected = true;
        }
      } else {
        that.toastr.error(mapErrorIssue.locationIssue);
        input.value = "";
      }
    });
  }

  /**
   * create marker
   */
  createMarker(latLong: google.maps.LatLng | null = null): void {
    if (this.markers.length) {
      this.setMapOnAll();
    }
    const marker: any = new google.maps.Marker({
      position: {
        lat: latLong?.lat() as number,
        lng: latLong?.lng() as number,
      },
      map: this.map,
      draggable: !this.isHideSearch,
    });
    this.markers.push(marker);
    const bounds = new google.maps.LatLngBounds();
    bounds.extend(marker.getPosition());
    this.map?.fitBounds(bounds);
    this.map?.setZoom(10);
    google.maps.event.addListener(marker, "dragend", async () => {
      const position = marker.getPosition();
      if (position) {
        const detailedAddress: any = await this.getDetailedAddress(
          position.lat(),
          position.lng()
        );
        this.getMapData.emit({
          lat: marker.getPosition()?.lat() as number,
          lng: marker.getPosition()?.lng() as number,
          address: detailedAddress.address,
          street_name: detailedAddress?.streetName,
          city_name: detailedAddress?.city,
          country: detailedAddress?.countryName,
          postal_code: detailedAddress?.postalCode,
          district: detailedAddress?.districtName,
        });
        this.isMapSelected = true;
      }
    });
  }

  /**
   * set map marker removed
   */
  setMapOnAll() {
    for (const marker of this.markers) {
      marker.setMap(null);
    }
    this.markers = [];
  }

  /**
   * on gecoding latlng event
   * @param latlng google.maps.LatLng object
   */
  onLatLngEvent(latlng: google.maps.LatLng) {
    if (latlng && this.map) {
      this.map?.setCenter(latlng);
      // Set Timeout for zoom controlling
      setTimeout(() => {
        this.map.setZoom(10);
      }, 1500);
    }
  }

  /**
   * Get Address Details Save
   */
  private async getDetailedAddress(lat: number, lng: number) {
    try {
      const getDetails = await this.mapService
        .getFormattedAddress({
          latlng: `${lat},${lng}`,
          language: languageTypes.DEFAULT_ADMIN,
        })
        .pipe(first())
        .toPromise();

      if (getDetails.status_code !== this.statusCode.OK) {
        return "";
      }

      const result = getDetails?.data.results[0];

      if (!result?.formatted_address) {
        return "";
      }

      return this.extractAddressDetails(result);
    } catch (error) {
      return "";
    }
  }

  private extractAddressDetails(result: any) {
    const address = result.formatted_address || "";
    const components = result.address_components || [];

    const city = this.getComponentLongName(components, "locality");
    const countryName = this.getComponentLongName(components, "country");
    const postalCode = this.getComponentLongName(components, "postal_code");
    const streetName = this.getComponentLongName(components, "route");
    const districtName = this.getComponentLongName(
      components,
      "administrative_area_level_1"
    );

    return {
      address,
      city,
      countryName,
      postalCode,
      streetName,
      districtName,
    };
  }

  private getComponentLongName(components: any[], type: string): string {
    const component = components.find((comp) => comp.types.includes(type));
    return component ? component.long_name : "";
  }

  /**
   * Check if a place is within the bounds of South Arabia
   */
  isPlaceInSouthArabia(place: any) {
    if (!place?.geometry?.location) {
      // Handle the case where the place or its geometry is undefined
      return false;
    }

    const southArabiaBounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(10.0, 35.0), // Southwest corner of South Arabia
      new google.maps.LatLng(30.0, 60.0) // Northeast corner of South Arabia
    );

    // Extract latitude and longitude from place.geometry.location
    const placeLatLng = new google.maps.LatLng(
      place.geometry.location.lat(),
      place.geometry.location.lng()
    );

    return southArabiaBounds.contains(placeLatLng);
  }

  /**
   * Handle map on blur
   */
  onMapBlur() {
    // Collapse the input box only when the mouse is not hovering
    if (!this.isMapHovering) {
      this.isMapTyping = false;
    }
  }

  /**
   * Handle modal popup open
   * @params template string
   */
  openModal(template?: TemplateRef<string>) {
    console.log(this.latlong, "prashank");
    this.isMapModalView = true;
    const input = document.getElementById(
      "autocomplete_search_" + this.googleMapId
    ) as HTMLInputElement;
    input.value = "";
    this.modalRef = this.modalService.show(template, { class: "modal-xxl" });
  }

  /**
   * Handle modal popup close
   */
  decline(): void {
    this.isMapModalView = false;
    this.modalRef.hide();
  }

  /**
   * Handle submit map details
   */
  submitMapDetails(): void {
    this.getMapData.emit({
      lat: this.mapDetails?.lat as any,
      lng: this.mapDetails?.lng as any,
      address: this.mapDetails?.address,
      city_name: this.mapDetails?.city_name,
    });
    this.initMap();
    this.decline();
  }

  /**
   * Handle map location
   */
  getMapLocation(mapData: IMapProps) {
    this.mapDetails = mapData;
  }
}
