import { AfterViewInit, Directive, ElementRef } from "@angular/core";

@Directive({
  selector: "[appInputWithSpanLabel]",
})
export class InputWithSpanLabelDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    const inputElement = this.el.nativeElement;

    // Create a new span element
    const spanElement = document.createElement("span");

    // Copy the attributes from the input element to the span element
    for (const attr of inputElement.attributes) {
      spanElement.setAttribute(attr.name, attr.value);
    }

    // Move the input element inside the span element
    while (inputElement.firstChild) {
      spanElement.appendChild(inputElement.firstChild);
    }

    // Replace the input element with the span element in its parent
    const parent = inputElement.parentNode;
    parent.insertBefore(spanElement, inputElement);
    parent.removeChild(inputElement);
  }
}
