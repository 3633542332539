import { Directive, ElementRef, HostListener } from "@angular/core";
import { CommonRegx } from "@validators/common.validator";

@Directive({
  selector: "[appApplettersonly]",
})
export class ApplettersonlyDirective {
  constructor(private el: ElementRef) {}
  @HostListener("input", ["$event"])
  onInputChange(event: Event) {
    const input = this.el.nativeElement as HTMLInputElement;
    const regex = CommonRegx.appApplettersonlyRegex; // Regular expression to allow letters and spaces

    const value = input.value;
    if (!regex.test(value)) {
      input.value = value.replace(CommonRegx.appApplettersDetectRegex, ""); // Remove non-letter and non-space characters
      event.preventDefault();
    }
  }
}
