import { Component, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { first } from "rxjs/operators";
import {
  notificationIconBgClass,
  notificationIconClass,
  routesConstant,
  SectionType,
  staticRoutes,
  CONFIGCONSTANTS,
} from "@config/app-constants";
import { CONFIG } from "@config/app-config";
import { IErrorResponse } from "@model/errorResponse";
import { INotification, INotificationResponse } from "@model/notification";
import { ISuccessResponse } from "@model/successResponse";
import { errorHandler } from "@utils/common";
import { UserRole } from "@utils/enum-const";
import {
  languageStorage,
  OrderType,
  RoutesRole,
  sectionType,
  StatusCode,
  storageUserKeys,
  TimePrefix,
  userDeviceToken,
  userStorageKeys,
} from "../../utils/enum-const";
import { CommonApiService } from "../../_services/common-api.service";
import { EncrDecrService } from "../../_services/encr-decr.service";
import { SpinnerService } from "../../_services/spinner.service";
import { AuthenticationService } from "./../../_services/authentication.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./default-layout.component.html",
  styleUrls: ["./default-layout.component.scss"],
})
export class DefaultLayoutComponent implements OnInit, OnDestroy {
  modalRef!: BsModalRef;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  appTitle = CONFIGCONSTANTS.siteName;
  notificationTime = CONFIGCONSTANTS.momentTime12Format;
  favImg = "assets/img/brand/angular_logo_small.png";
  logoImg = "assets/img/brand/shahen_logo.png";
  public currYear = moment().format("YYYY");
  fullname = "";
  currentState!: string;
  prevState!: string;
  prevUrl!: string;
  languageOption: boolean = CONFIGCONSTANTS.languageOption;
  isServiceRequesterRoute = false;
  isServiceProviderRoute = false;
  isAdminRoute = false;
  userName = "";
  userRoleURL = "";
  userLogo = "";
  notificationData: INotification[] = [];
  unReadCount = 0;
  unReadMessageCount = "0";
  OrderType = OrderType;
  SectionType = SectionType;
  routesConstant = routesConstant;
  staticRoutes = staticRoutes;
  notificationIconBgClass = notificationIconBgClass;
  notificationIconClass = notificationIconClass;
  notificaitonDateFormat = CONFIGCONSTANTS.dateFormatListing;
  timePrefix = TimePrefix;
  private interval!: NodeJS.Timeout;
  protected errorHandlerResponse = errorHandler;
  public noProfileImage = "assets/images/common/profile.png";
  sectionType = sectionType.DASHBOARD;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private EncrDecr: EncrDecrService,
    protected modalService: BsModalService,
    private ManageCommonService: CommonApiService,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {
    this.changes = new MutationObserver(() => {
      this.sidebarMinimized = document.body.classList.contains(
        "sidebar-minimized"
      );
    });

    this.changes.observe(<Element>this.element, {
      attributes: true,
    });

    const url = this.router.url;
    const proivderData = localStorage.getItem(
      storageUserKeys.CURRENT_USER_PROVIDER
    );
    const currentUserProvider = proivderData
      ? JSON.parse(
          this.EncrDecr.get(
            CONFIG.EncrDecrKey as string,
            localStorage.getItem(storageUserKeys.CURRENT_USER_PROVIDER)
          )
        )
      : null;
    const requesterData = localStorage.getItem(
      storageUserKeys.CURRENT_USER_REQUESTER
    );
    const currentUserRequester = requesterData
      ? JSON.parse(
          this.EncrDecr.get(
            CONFIG.EncrDecrKey as string,
            localStorage.getItem(storageUserKeys.CURRENT_USER_REQUESTER)
          )
        )
      : null;
    if (url.startsWith(RoutesRole.SERVICE_REQUESTER) && currentUserRequester) {
      const userInfo = JSON.parse(
        localStorage.getItem(userStorageKeys.SR_INFO) ?? ""
      );
      this.userName = userInfo?.name;
      this.userLogo = userInfo?.logo;
      this.isServiceRequesterRoute = true;
      this.userRoleURL = RoutesRole.SERVICE_REQUESTER;
    }
    if (url.startsWith(RoutesRole.SERVICE_PROVIDER) && currentUserProvider) {
      const userInfo = JSON.parse(
        localStorage.getItem(userStorageKeys.SP_INFO) ?? ""
      );
      this.isServiceProviderRoute = true;
      this.userRoleURL = RoutesRole.SERVICE_PROVIDER;
      this.userName = userInfo.name;
      this.userLogo = userInfo.logo;
    }
    if (url.startsWith(RoutesRole.ADMIN_ROUTE)) {
      this.isAdminRoute = true;
    }
  }

  /**
   * Init function
   */
  ngOnInit(): void {
    this.fullname = localStorage.getItem("fullName") ?? "";
    const classname = document.getElementsByClassName("dropdown");
    const onDropdownClick = (e: MouseEvent | Event) => {
      const target = e.currentTarget as HTMLElement;
      // Ensure that the 'active' class is toggled on the clicked element
      target?.classList?.toggle("active");
    };
    const elementsArray = Array.from(classname);
    for (const element of elementsArray) {
      element.addEventListener("click", onDropdownClick, false);
    }
    this.getNotifications();
    this.initTimeOutForNotificationApi();
  }

  /**
   * Handle logout
   */
  onLogout(deviceToken: string) {
    const loginUserKey = this.isServiceRequesterRoute
      ? storageUserKeys.CURRENT_USER_REQUESTER
      : storageUserKeys.CURRENT_USER_PROVIDER;
    const userInfoKey = this.isServiceRequesterRoute
      ? userStorageKeys.SR_INFO
      : userStorageKeys.SP_INFO;
    const payload = {
      device_token: deviceToken,
    };
    this.authenticationService
      .logoutFromAccount(payload)
      .pipe()
      .subscribe(
        (resp: ISuccessResponse) => {
          const languageKey = this.isServiceRequesterRoute
            ? languageStorage.REQUESTER_LAN
            : languageStorage.PROVIDER_LAN;
          const getDeviceToken = this.isServiceRequesterRoute
            ? userDeviceToken.REQUESTER_TOKEN
            : userDeviceToken.PROVIDER_TOKEN;

          if (resp.status_code === StatusCode.OK) {
            this.authenticationService.logout(loginUserKey);
            this.authenticationService.clearStorage(
              userInfoKey,
              languageKey,
              getDeviceToken
            );
            this.router.navigate([this.userRoleURL + "/login"]);
            window.location.reload();
            this.spinner.hideLoader();
          }
        },
        (error: IErrorResponse) => {
          errorHandler(this.toastr, this.translateService, error);
          this.spinner.hideLoader();
        }
      );
    clearInterval(this.interval);
  }

  /**
   * Handle modal popup open
   * @params template string
   */
  openModal(template: TemplateRef<string>) {
    this.modalRef = this.modalService.show(template);
  }

  /**
   * Handle modal popup close
   */
  decline(): void {
    this.modalRef.hide();
  }

  /**
   * Handle modal popup close on delete action
   */
  handleDelete() {
    this.decline();
  }

  /**
   * Get Notifications Listing
   */
  private getNotifications() {
    this.ManageCommonService.getAllNotification()
      .pipe(first())
      .subscribe((resp: INotificationResponse) => {
        if (resp.status_code === StatusCode.OK) {
          this.notificationData = resp.data.data;
          this.unReadCount = resp.data?.unread_count;
          this.unReadMessageCount = resp?.data?.unread_message_count;
        }
      });
  }

  /**
   * Every 1 minutes notification API will call for sr/sp
   */
  public initTimeOutForNotificationApi() {
    this.interval = setInterval(() => {
      this.getNotifications();
    }, 20000);
  }

  /**
   * Format Notification Time
   */
  public formatSrSpNotificationDate(
    date: string,
    format = CONFIGCONSTANTS.ApiRequestFormat
  ) {
    return moment(date).format(format);
  }

  /**
   * Get User Role Type
   * @returns string
   */
  getRoutesRole() {
    if (this.isServiceProviderRoute) {
      return RoutesRole.SERVICE_PROVIDER;
    } else if (this.isAdminRoute) {
      return RoutesRole.ADMIN_ROUTE;
    } else {
      return RoutesRole.SERVICE_REQUESTER;
    }
  }

  /**
   * Open notification box
   */
  openNotification() {
    const notificationBox = document.getElementById("notification-box");
    if (notificationBox) {
      notificationBox.classList.add("show");
    }
  }

  /**
   * Close notification box
   */
  closeNotification() {
    const notificationBox = document.getElementById("notification-box");
    if (notificationBox) {
      notificationBox.classList.remove("show");
    }
  }

  /**
   * Handle error profile image when image file or url is missing
   * @event Event
   * @returns image URL
   */
  public onErrorProfileImage(event: Event): void {
    const imageElement = event.target as HTMLImageElement;
    imageElement.src = this.noProfileImage;
  }

  /**
   * Handle notification read and mark
   * @params notificationId Integer
   * @returns boolean
   */
  notificationRead(notificationId: number) {
    this.closeNotification();
    this.ManageCommonService.notificationSeen(notificationId)
      .pipe(first())
      .subscribe((resp: ISuccessResponse) => {
        if (resp.status_code === StatusCode.OK) {
          return;
        }
      });
  }

  /**
   *
   * @param date : convert UTC to local date
   * @param format : YYYY-MM-DD HH:MM:SS
   * @returns Date
   */
  getNotificationTimeConvert(
    date: string,
    format = CONFIGCONSTANTS.dateFormatListing
  ) {
    const utcDate = new Date(date);
    const localDate = moment(utcDate).format(format);
    return localDate;
  }

  /**
   * Handle function for seen all notifications
   */
  seenAllNotifications() {
    if (this.getUserType()) {
      this.ManageCommonService.notificationSeenAll(this.getUserType())
        .pipe(first())
        .subscribe(
          (response: ISuccessResponse) => {
            if (response.status_code === StatusCode.OK) {
              this.getNotifications();
            }
          },
          (error: IErrorResponse) => {
            this.errorHandlerResponse(
              this.toastr,
              this.translateService,
              error,
              () => {
                this.spinner.hideLoader();
              }
            );
          }
        );
    }
  }

  /**
   * Clear Interval destroy method
   */
  ngOnDestroy() {
    // Clear the interval when the component is destroyed
    clearInterval(this.interval);
  }

  /**
   * Get user type
   */
  getUserType() {
    if (this.isServiceProviderRoute) {
      return UserRole.SERVICE_PROVIDER;
    } else if (this.isAdminRoute) {
      return UserRole.ADMIN_USER;
    } else {
      return UserRole.SERVICE_REQUESTER;
    }
  }

  /**
   * @param msg string
   * @returns string
   */
  getDecodeMsg(msg: string) {
    if (msg) {
      const message = JSON.parse(msg);
      return message?.chat_data?.message;
    }
    return "";
  }
}
