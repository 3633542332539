import { KeyValue } from "@angular/common";
import { Component, TemplateRef, ViewChild } from "@angular/core";
import { CONFIG } from "@config/app-config";
import { ColumnMode, DatatableComponent } from "@swimlane/ngx-datatable";
import moment from "moment";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";

// Import specific constants instead of importing the entire file
import {
  activeStatusList,
  chatIcons,
  CONFIGCONSTANTS,
  dashboardOrderTypes,
  ExportDataType,
  noDataFound,
  offerOrderFilterFieldList,
  offerOrderSearchFieldList,
  offerStatusList,
  ShorterUserRole,
  siteImages,
  UserRequesterType,
  waslStatus,
} from "@config/app-constants";

// Import specific models instead of importing the entire file
import { AdminOrderData } from "@model/adminContract";
import { OrderDetails } from "@model/adminDashboard";
import {
  IOrderInfoData,
  RowType,
  StatusColor,
} from "@model/directOrdersDetails";

// Import specific enums and constants instead of importing the entire file
import {
  AbshersMobilePrefixNumber,
  AccpetRejectStatus,
  adminTypes,
  AllFilePath,
  ApproveRejectState,
  ChangeStatusValue,
  directOrdersStatusTypes,
  exportTypes,
  fileIcon,
  FileType,
  languageTypes,
  locationTypes,
  manageAllOrderKeys,
  mapFields,
  MeasurementUnits,
  oprationsParams,
  orderDefaultFilterKey,
  OrderFileType,
  OrderFilterKey,
  OrderStatus,
  OrderType,
  orderTypeRedirection,
  PaymentStatus,
  role,
  RoleBaseImageURL,
  roleDisplay,
  routerUrl,
  RoutesRole,
  shahenName,
  shahenOrderFlag,
  spCancelOrderTypeRedirection,
  srOrderTypeRedirection,
  StatusCode,
  statusTypes,
  StatusValue,
  tripSyncStatus,
  TypeOfProfit,
  TypeOfProgressBar,
  TypeOfRequester,
  userPermissionsGet,
  UserRole,
  userStorageKeys,
} from "@utils/enum-const";

import {
  downloadFile,
  errorHandler,
  isEmpty,
  successHandler,
} from "@utils/common";
import { CommonRegx } from "@validators/common.validator";

import {
  IBayanTrips,
  IDtMessages,
  IOrderDetails,
  IOrderLocation,
  IOrderTypeRedirection,
  IRole,
  ITripData,
} from "@model/common";
import { ChatHistorySingleData } from "@model/srspChat";
import { BaseServiceInjector } from "./base.injector";

@Component({
  selector: "app-base-component",
  template: ``,
})
export class BaseComponent extends BaseServiceInjector {
  public dashboardInterval: any;
  dataTableIndexColumnWidth = CONFIGCONSTANTS.dataTableIndexColumnWidth;
  public CONSTANT = CONFIGCONSTANTS;
  public dateFormat = CONFIGCONSTANTS.dateFormat;
  public dateShortFormat = CONFIGCONSTANTS.dateShortFormat;
  public dateRangeConfig = CONFIGCONSTANTS.dateRangeConfig;
  public momentDateFormat = CONFIGCONSTANTS.momentDateFormat;
  public momentDateTime24Format = CONFIGCONSTANTS.momentDateTime24Format;
  public momentDateMonthFormat = CONFIGCONSTANTS.momentDateMonthFormat;
  public dateFormatListing = CONFIGCONSTANTS.dateFormatListing;
  public momentChatTimeFormat = CONFIGCONSTANTS.momentChatTimeFormat;
  public noData = "-";
  public CommonRegx = CommonRegx;
  public currentDate: Date;
  public momentDateMonthYearFormat = CONFIGCONSTANTS.momentDateMonthYearFormat;
  public momentYYYYMMDDFormat = CONFIGCONSTANTS.ApiRequestFormat;
  public orderDefaultFilterKey = orderDefaultFilterKey;
  public momentDateTimeFormat = CONFIGCONSTANTS.momentDateTimeFormat;
  public momentNoDashDateFormat = CONFIGCONSTANTS.momentNoDashDateFormat;
  public momentyearmonthdateFormat = CONFIGCONSTANTS.momentyearmonthdateFormat;
  public moemnethourminwithdateFormat =
    CONFIGCONSTANTS.moemnethourminwithdateFormat;
  public moment24DateTimeFormat = CONFIGCONSTANTS.moment24DateTimeFormat;
  public moment24TimeOnlyFormat = CONFIGCONSTANTS.moment24TimeOnlyFormat;
  public moment24DateOnlyFormat = CONFIGCONSTANTS.moment24DateOnlyFormat;
  public momentDateTimeSecondFormat =
    CONFIGCONSTANTS.momentDateTimeSecondFormat;

  public FileType = FileType;

  public modalRef: BsModalRef = new BsModalRef();
  public noImage = AllFilePath.NO_IMAGE;
  public noProfileImage = AllFilePath.NO_PROFILEIMAGE;
  public noDocument = AllFilePath.NO_DOCUMENT;
  protected errorHandler = errorHandler;
  protected successHandler = successHandler;
  protected downloadFile = downloadFile;
  public isEmpty = isEmpty;
  public statusTypes = statusTypes;
  public directOrdersStatusTypes = directOrdersStatusTypes;
  public statusEnum = StatusValue;
  public requesterStatus = ChangeStatusValue;
  public approveRejectStatusName = ApproveRejectState;
  public orderAccpetStatus = AccpetRejectStatus;
  public statusList = activeStatusList;
  public statusCode = StatusCode;
  public AbsherMobilePre = AbshersMobilePrefixNumber.ABSHERS;
  public fileExtensionType = exportTypes;
  public fileIconType = fileIcon;
  public typeOfRequester = TypeOfRequester;
  public paymentStatus = PaymentStatus;
  public orderFileType = OrderFileType;
  public noDataFound = noDataFound;
  offerFilterStatusList = offerOrderFilterFieldList;
  offerStatusList = offerStatusList;
  offerOrderSearchFieldList = offerOrderSearchFieldList;
  public TypeOfProfit = TypeOfProfit;
  public TypeOfProgressBar = TypeOfProgressBar;
  public waslStatus = waslStatus;
  public loginCompanyName = this.getLoginCompanyName();
  //#region Datatable configuration
  @ViewChild(DatatableComponent) datatable!: DatatableComponent;
  public loadingIndicator = false;
  ColumnMode = ColumnMode;
  reorderable = CONFIGCONSTANTS.datatableConfig.reorderable;
  scrollbarH = CONFIGCONSTANTS.datatableConfig.scrollbarH;
  serverSorting = CONFIGCONSTANTS.datatableConfig.serverSorting;
  serverPaging = CONFIGCONSTANTS.datatableConfig.serverPaging;
  piningRight = CONFIGCONSTANTS.datatableConfig.piningRight;
  headerHeight = CONFIGCONSTANTS.datatableConfig.headerHeight;
  footerHeight = CONFIGCONSTANTS.datatableConfig.footerHeight;
  rowHeight = CONFIGCONSTANTS.datatableConfig.rowHeight;
  limitList: number[] = CONFIGCONSTANTS.datatableConfig.limitList;
  totalReords = CONFIGCONSTANTS.datatableConfig.page.totalReords;
  pageNumber = CONFIGCONSTANTS.datatableConfig.page.pageNumber;
  size = CONFIGCONSTANTS.datatableConfig.page.size;
  dtMessages: IDtMessages = {
    ...CONFIGCONSTANTS.datatableConfig.dtMessages,
  };
  sortParam = "created_at";
  sortOrder = "desc";
  //#endregion
  languages = [];
  protected lan = ["en"];
  mapFields = mapFields;
  public languageSubscription: Subscription;
  locationTypes = locationTypes;
  isServiceRequesterRoute = false;
  isServiceProviderRoute = false;
  isAdminRoute = false;
  UserRequesterType = UserRequesterType;
  exportDataType = ExportDataType;
  requesterUserType = UserRequesterType;
  measurementUnits = MeasurementUnits;
  orderType: any = OrderType;
  orderStatus = OrderStatus;
  oprations = oprationsParams;
  orderRedirection: IOrderTypeRedirection = orderTypeRedirection;
  srCancelOrderTypeRedirection = spCancelOrderTypeRedirection;
  srOrderRedirection = srOrderTypeRedirection;
  manageOrdersType = dashboardOrderTypes;
  userId: number | null = null;
  orderSection = OrderFilterKey;
  isShahen = false;
  roles = role;
  shahenName = shahenName;
  isEnglish = false;
  isSpecialOrder = false;
  isDirectOrder = false;
  isOfferOrder = false;
  isContractOrder = false;
  isContractAdmin = false;
  routerUrl = routerUrl;
  getAllOrdersTypes = manageAllOrderKeys;
  typeOfFile = exportTypes;
  allFilePath = AllFilePath;

  tripSyncFlag = shahenOrderFlag;
  tripBayanWaybillStatus = tripSyncStatus;
  adminTypes = adminTypes;
  notes = "";
  currentTime = "";
  imageUploadConfig = CONFIGCONSTANTS.imageUpload;
  documentUploadConfig = CONFIGCONSTANTS.documentUpload;
  siteImages = siteImages;
  isSuperAdmin = false;
  allPermissons = userPermissionsGet;

  constructor() {
    super();
    this.loginCompanyName = this.getLoginCompanyName();
    this.languages = this.multilingualService.getLanguage();
    this.currentDate = new Date();
    this.initDataTableMessages();
    this.languageSubscription = this.translateService.onLangChange.subscribe(
      () => {
        this.initDataTableMessages();
      }
    );

    const url = this.router.url;
    this.isAdminRoute = url.startsWith(RoutesRole.ADMIN_ROUTE);
    this.isServiceRequesterRoute = url.startsWith(RoutesRole.SERVICE_REQUESTER);
    this.isServiceProviderRoute = url.startsWith(RoutesRole.SERVICE_PROVIDER);
    if (url.startsWith(RoutesRole.SERVICE_REQUESTER)) {
      this.isServiceRequesterRoute = true;
      this.userId = Number(localStorage.getItem("requester_id"));
    }
    if (url.startsWith(RoutesRole.SERVICE_PROVIDER)) {
      this.isServiceProviderRoute = true;
      this.userId = Number(localStorage.getItem("provider_id"));
    }
    if (url.startsWith(RoutesRole.ADMIN_ROUTE)) {
      this.isAdminRoute = true;
    }
    this.checkIsSahen();
    this.checkSuperAdmin();
    this.currentTime = this.currentDate.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
  }

  /**
   * Handle function for check contract order type
   */
  public checkIsSahen() {
    if (this.router.url.includes("shahen-contract")) {
      this.isShahen = true;
    }
    const userKey = this.getUser() + "_lan";
    this.isEnglish =
      this.getUserCurrentLanguage(userKey) === languageTypes.DEFAULT_ADMIN;
  }

  public async initDataTableMessages() {
    const keys = Object.keys(
      CONFIGCONSTANTS.datatableConfig.dtMessages
    ) as Array<keyof typeof CONFIGCONSTANTS.datatableConfig.dtMessages>;
    for (const key of keys) {
      this.dtMessages[key] = await this.translateService
        .get(CONFIGCONSTANTS.datatableConfig.dtMessages[key])
        .toPromise();
    }
  }

  public onErrorImage(event: { target: { src: AllFilePath } }) {
    event.target.src = this.noImage;
  }

  /**
   * Handle errors on profile image
   */
  public onErrorProfileImage(event: Event) {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = this.noProfileImage;
  }

  /**
   * Handle on document error
   */
  public onErrorDocument(event: Event) {
    if (event.target instanceof HTMLImageElement) {
      const document = event.target;
      document.src = this.noDocument;
    }
  }

  /**
   * Handle get translation common
   * @key string
   * @param
   */
  public async getTranslation(
    key: string,
    params: string | null | number = null
  ) {
    const resp = await this.translateService
      .get(key, { 1: params })
      .toPromise();
    return resp;
  }

  /**
   * Handle format date function
   * @date string
   * @param format Format
   * @returns Date
   */
  public formatDate(
    date: string | null,
    format = CONFIGCONSTANTS.ApiRequestFormat
  ) {
    return moment(date).format(format);
  }

  /**
   * Sort status by value
   * @param a Active key value object
   * @param b Inaction key value object
   * @returns
   */
  public orderbyValueAsc = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    const val = a.value > b.value ? 0 : -1;
    return a.value > b.value ? 1 : val;
  };

  /**
   * Get User Type
   * @returns string
   */
  public requesterType() {
    const url = this.router.url;
    if (url.startsWith(RoutesRole.SERVICE_PROVIDER)) {
      return RoleBaseImageURL.SERVICE_PROVIDER;
    } else {
      return RoleBaseImageURL.SERVICE_REQUESTER;
    }
  }

  /**
   * Get User Type
   * @returns string
   */
  getUser() {
    const user = this.isAdminRoute
      ? UserRole.ADMIN_USER
      : UserRole.SERVICE_REQUESTER;
    return this.isServiceProviderRoute ? UserRole.SERVICE_PROVIDER : user;
  }

  /**
   * Get User Data
   * @Id: number
   * @returns string
   */
  getUserData(id: string) {
    if (this.isServiceProviderRoute) {
      localStorage.setItem("provider_id", id);
    } else if (this.isServiceRequesterRoute) {
      localStorage.setItem("requester_id", id);
    }
  }

  /**
   * Get User Role Type
   * @returns string
   */
  getRoutesRole() {
    if (this.isServiceProviderRoute) {
      return RoutesRole.SERVICE_PROVIDER;
    } else if (this.isAdminRoute) {
      return RoutesRole.ADMIN_ROUTE;
    } else {
      return RoutesRole.SERVICE_REQUESTER;
    }
  }

  /**
   * Check User Redirect
   * @returns redirect
   */
  checkCurrentUserAndRedirect() {
    const currentUserProvider = this.EncrDecr.get(
      CONFIG.EncrDecrKey as string,
      localStorage.getItem("currentUserProvider")
    );
    const currentUserRequester = this.EncrDecr.get(
      CONFIG.EncrDecrKey as string,
      localStorage.getItem("currentUserRequester")
    );
    const currentAdmin = this.EncrDecr.get(
      CONFIG.EncrDecrKey as string,
      localStorage.getItem("currentAdmin")
    );
    const currentSubAdmin = this.EncrDecr.get(
      CONFIG.EncrDecrKey as string,
      localStorage.getItem("currentSubAdmin")
    );

    if (currentUserProvider && this.isServiceProviderRoute) {
      this.router.navigate(["/provider/dashboard"]);
    }

    if (currentUserRequester && this.isServiceRequesterRoute) {
      this.router.navigate(["/requester/dashboard"]);
    }
    if ((currentAdmin || currentSubAdmin) && this.isAdminRoute) {
      this.router.navigate(["/admin/dashboard"]);
    }

    return false;
  }

  /**
   * Handle scroll view
   */
  scrollView(id: string) {
    document?.getElementById(id)?.scrollIntoView({
      behavior: "smooth",
    });
  }

  /**
   * Profit Calculation or Price Differentiation
   * @returns number
   */
  calculateVariation(value1: number, value2: number) {
    return value1 - value2;
  }

  /**
   * Profit Calculation or Price Differentiation
   * filePath URL
   * @return string
   */
  getFileExtension(filePath: string): string | undefined {
    if (filePath) {
      const parts = filePath?.split(".");
      if (parts.length > 1) {
        return parts[parts.length - 1];
      } else {
        return "";
      }
    }
    return "";
  }

  /**
   * Get shorter username
   * @return string
   */
  getShorterUser(): ShorterUserRole {
    return this.determineUserRole();
  }

  /**
   * Determin user role
   * filePath URL
   */
  private determineUserRole(): ShorterUserRole {
    let userRole: ShorterUserRole;

    if (this.isServiceProviderRoute) {
      userRole = ShorterUserRole.SERVICE_PROVIDER;
    } else if (this.isAdminRoute) {
      userRole = ShorterUserRole.ADMIN_USER;
    } else {
      userRole = ShorterUserRole.SERVICE_REQUESTER;
    }

    return userRole;
  }

  /**
   * Handle error message
   */
  async handleErrorMessage() {
    const message = await this.translateService
      .get("PLEASE_SELECT_MAP_LOCATION")
      .toPromise();
    this.toastr.error(message);
  }

  /**
   * @param yearsAgo
   * @returns
   */
  calculateMaxDate(yearsAgo: number) {
    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - yearsAgo);
    return minDate;
  }

  /**
   * Handle cost calculate
   */
  calculateCost(
    row: OrderDetails,
    isSrSell: boolean
  ): string | number | undefined {
    switch (row.section) {
      case OrderFilterKey.order:
      case OrderFilterKey.offer:
      case OrderFilterKey.membership:
        if (row.providers.length > 0) {
          return isSrSell
            ? row?.order_prices?.sr_price
            : row?.sp_price_without_vat;
        } else {
          return isSrSell
            ? row?.order_prices?.sr_price
            : row?.order_prices?.sp_price;
        }

      case OrderFilterKey.special_order:
        return isSrSell
          ? row?.sr_price_without_vat_special_order
          : row.sp_price_without_vat;

      default:
        // Handle other order types or return a default value
        return 0;
    }
  }

  /**
   * Handle provider names
   */
  displayProviderNames(row: AdminOrderData): string | undefined {
    if (row?.provider) {
      // If provider object is available
      return row?.provider?.company_name;
    } else if (row?.providers && row?.providers?.length > 0) {
      // If only providers array is available
      const primaryProvider = row?.providers[0]?.user?.company_name;
      return row?.providers?.length > 1
        ? `${primaryProvider}`
        : primaryProvider;
    } else {
      // No provider information available
      return "-";
    }
  }

  /**
   * Handle tooltip for providers
   */
  getRemainingProvidersTooltip(row: AdminOrderData): string | undefined {
    if (row?.providers?.length ?? 0 > 1) {
      const additionalProviderNames = row?.providers
        ?.slice(1)
        .map((provider) => provider.user?.company_name)
        .filter((name) => name);
      return additionalProviderNames?.join(", ");
    } else {
      return "";
    }
  }

  /**
   * Handle all order status color
   */
  public getAllOrderStatusColor(order: RowType): StatusColor {
    if (order?.status_color) {
      return order?.status_color;
    }
    return { main: "black", hex: "#000000" };
  }

  /**
   * @param order
   * @returns redirection
   */
  handleOrderRedirection(order: IOrderInfoData) {
    let routeSegment = "";

    if (this.router.url.includes("contract")) {
      routeSegment = this.orderRedirection.membership;
    } else if (this.router.url.includes("offer")) {
      routeSegment = this.orderRedirection.offer;
    } else if (this.router.url.includes("direct")) {
      routeSegment = this.orderRedirection.order;
    } else {
      routeSegment = this.orderRedirection.special_order;
    }
    const route = this.getRoutesRole() + "/" + routeSegment + order?.order_id;
    this.router.navigate([route]);
  }

  /**
   * Handle common data table status color
   */
  public getStatusColor(statusColor: StatusColor): StatusColor | string {
    if (statusColor) {
      return statusColor;
    }
    // Default color if status_color is not defined in the row
    return { main: "black", hex: "#000000" };
  }

  /**
   * @param cancelPersonRole
   * @returns string
   */
  getShortCompanyName(cancelPersonRole: string) {
    if (cancelPersonRole === this.roles.REQUESTER) {
      return roleDisplay.REQUESTER;
    } else if (cancelPersonRole === this.roles.PROVIDER) {
      return roleDisplay.PROVIDER;
    }
    return roleDisplay.ADMIN;
  }

  /**
   * @userKey string
   * Get user current selected language
   */
  getUserCurrentLanguage(userKey: string) {
    const CurrentLanguage = localStorage.getItem(userKey);
    return CurrentLanguage ?? languageTypes.DEFAULT;
  }

  /**
   * @returns map property object
   */
  setNullMap() {
    return {
      lat: null,
      lng: null,
      address: "",
    };
  }

  /**
   * @orderDetails order data
   * Handle cancel order details
   */
  getCancelOrderAuthorDetails(orderDetails: IOrderDetails) {
    const userRoles = orderDetails?.order_cancel_by?.user?.roles;
    const roleName = userRoles?.[0]?.name;

    if (roleName) {
      switch (roleName) {
        case this.adminTypes.SUPER_ADMIN_ROLE:
          return userRoles?.[0]?.display_name;
        case this.adminTypes.ADMIN:
          return this.adminTypes.SUB_ADMIN;
        case this.adminTypes.SERVICE_REQUESTER_ROLE:
        case this.adminTypes.SERVICE_PROVIDER_ROLE:
          return userRoles?.[0]?.display_name;
      }
    }

    return "";
  }

  /**
   * @tripData order data
   * Handle trip author details
   */
  getCancelTripAuthorDetails(tripData: IBayanTrips) {
    const roles = tripData?.canceled_by?.roles as IRole[];
    const roleName = roles?.[0]?.name;

    if (roleName) {
      switch (roleName) {
        case this.adminTypes.SUPER_ADMIN_ROLE:
          return roles[0]?.display_name;
        case this.adminTypes.ADMIN:
          return this.adminTypes.SUB_ADMIN;
        case this.adminTypes.SERVICE_REQUESTER_ROLE:
        case this.adminTypes.SERVICE_PROVIDER_ROLE:
          return roles[0]?.display_name;
      }
    }

    return "";
  }

  /**
   * Handle get cancel trip for service requester or provider
   */
  getCancelTripDetailsforSpSr(tripinfo: IBayanTrips) {
    const roleInfo = tripinfo?.canceled_by?.roles as IRole[];
    const roleInfoName = roleInfo?.[0]?.name;

    if (roleInfoName) {
      switch (roleInfoName) {
        case this.adminTypes.SUPER_ADMIN_ROLE:
        case this.adminTypes.ADMIN:
          return this.shahenName.SHAHEN_EXPRESS;

        case this.adminTypes.SERVICE_REQUESTER_ROLE:
        case this.adminTypes.SERVICE_PROVIDER_ROLE:
          return roleInfo[0]?.display_name;
      }
    }
    return "";
  }

  /**
   *
   * @param template open invoice template-modal
   * @param InvoiceNotes invoice notes
   */
  openInvoiceModal(template: TemplateRef<string>, InvoiceNotes: string) {
    this.modalRef = this.modalService.show(template, { class: "modal-lg" });
    this.notes = InvoiceNotes;
  }

  /**
   *
   * @param date Date which we need to convert
   * @param time Optional Date which we need to convert
   * @param format Optional format in which we want to convert
   * @returns
   */
  getUTCDateFormat(
    date: string,
    time = "00:00:00",
    format = CONFIGCONSTANTS.ApiRequestFormat
  ) {
    // Combine date and time into a single string
    const combinedDateTime = `${this.formatDate(date)} ${time}`;
    // Get the user's local time zone dynamically
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const parsedDateTime = moment(
      combinedDateTime,
      CONFIGCONSTANTS.Api24RequestFormat
    ).tz(userTimeZone);
    // Convert the parsed date and time to UTC
    const utcDateTime = parsedDateTime.utc().format(format);
    return utcDateTime;
  }

  /**
   *
   * @param date : Date which we need to convert for filter payload
   * @returns
   */
  public formatDateForFilterPayload(date: string) {
    const convertedDateTime = this.getUTCDateFormat(date, this.currentTime);
    return convertedDateTime;
  }

  /**
   *
   * @param date convert date into UTC
   * @param format format YYYY-MM-DD HH:MM:SS
   * @returns
   */
  public formatDateForCreateOffer(
    date: string,
    format = CONFIGCONSTANTS.Api24RequestFormat
  ) {
    const concatDateTime = this.getUTCDateFormat(
      date,
      this.currentTime,
      format
    );
    return concatDateTime;
  }

  /**
   *
   * @param date : convert UTC to local date
   * @param format : YYYY-MM-DD HH:MM:SS
   * @returns
   */
  convertUTCtoLOCAL(date: string, format = CONFIGCONSTANTS.ApiRequestFormat) {
    const utcDate = new Date(date);
    const combinedlocalDateTime = new Date(
      utcDate.getTime() + utcDate.getTimezoneOffset() * 60000
    );
    const localDate = moment(combinedlocalDateTime).format(format);
    return localDate;
  }

  /**
   * Get button label
   */
  buttonLabel(tripData: ITripData): string {
    switch (true) {
      case tripData.is_bayan_sync === 0 && tripData.is_canceled !== 1:
        return "BAYAN_SYNC";
      case tripData.is_canceled === 1 && tripData.is_bayan_sync === 1:
        return "BAYAN_CANCEL";
      case tripData.is_bayan_sync === 1:
        return "BAYAN_CLOSE";
      default:
        return "No_DATA";
    }
  }

  /**
   *
   * @param date : convert UTC to local date
   * @param format : YYYY-MM-DD HH:MM:SS
   * @returns
   */
  getNotificationTimeConvert(
    date: string,
    format = CONFIGCONSTANTS.dateFormatListing
  ) {
    const utcDate = new Date(date);
    const localDate = moment(utcDate).format(format);
    return localDate;
  }

  /**
   * Check button visiblity
   * @returns boolean
   */
  isButtonVisible(tripData: IBayanTrips): boolean {
    switch (true) {
      case tripData.is_bayan_sync === 0 && tripData.is_canceled !== 1:
        return true;
      case tripData.is_canceled === 1 &&
        tripData.is_bayan_sync === 1 &&
        tripData.closed === 1 &&
        tripData.is_bayan_cancel === 1:
        return false;
      case tripData.is_canceled === 1 &&
        tripData.is_bayan_sync === 1 &&
        tripData.closed === 1:
        return true;
      case tripData.is_canceled === 1 && tripData.is_bayan_cancel === 1:
        return false;
      case tripData.is_bayan_sync === 1 && tripData.closed === 1:
        return false;
      case tripData.is_bayan_sync === 1:
        return true;
      default:
        return false;
    }
  }

  /*
   * Handle toster error reponse
   */
  async handleTosterErrorMessage(messageTxt: string) {
    const message = await this.translateService.get(messageTxt).toPromise();
    this.toastr.error(message);
  }

  /*
   * Get order type
   */
  getChatOrderType(chatData: ChatHistorySingleData): string {
    const parsedMessage = JSON.parse(chatData?.message);
    let orderTypeIcon: string | undefined = "";
    if (parsedMessage) {
      const orderType = parsedMessage.orderType;
      orderTypeIcon = chatIcons[orderType];
    }
    return orderTypeIcon as string;
  }

  /*
   * Get Login Compnay Name
   */
  getLoginCompanyName() {
    const userInfoKey = this.isServiceRequesterRoute
      ? userStorageKeys.SR_INFO
      : userStorageKeys.SP_INFO;
    const userInfo = JSON.parse(localStorage.getItem(userInfoKey) as string);
    return userInfo;
  }

  /**
   *
   * @param msg string
   * @returns string
   */
  decodeJsonMsg(msg: string) {
    if (msg) {
      const message = JSON.parse(msg);
      return message?.chat_data?.message;
    }
    return "";
  }

  /**
   * Check super admin is login or not
   */
  checkSuperAdmin() {
    this.isSuperAdmin = !!this.permissionService.getPermission(
      userPermissionsGet.SUPER_ADMIN
    )?.name;
  }

  /**
   * Check sub admin has permission or not
   */
  checkSubAdminPermission(permission: string) {
    return this.permissionService.getPermission(permission) !== undefined;
  }

  /**
   * Handle copy location
   * @content string
   * @author prashank.shah@brainvire.com
   */
  async copyLocation(content: string) {
    if (content) {
      navigator.clipboard.writeText(content).then(async () => {
        const message = await this.translateService
          .get("TEXT_COPIED_SUCCESSFULLY")
          .toPromise();
        this.toastr.success(message);
      });
    }
  }

  /**
   * Handle open google maps
   * @latitude number
   * @longitude number
   * @author prashank.shah@brainvire.com
   */
  openGoogleMaps(latitude: number, longitude: number, label: string): void {
    if (latitude && longitude) {
      const encodedLabel = encodeURIComponent(label);
      const googleMapsUrl = `https://www.google.com/maps/place/${encodedLabel}/@${latitude},${longitude},15z?entry=ttu`;
      window.open(googleMapsUrl, "_blank"); // Opens the link in a new tab
    }
  }

  /**
   * Handle full location copy
   * @param locationNumber number
   * @param totalLocations number
   * @author prashank.shah@brainvire.com
   */
  getFullLocationText(locationNumber: number): string {
    if (locationNumber === 0) {
      return locationTypes.LOADING_LOCATION;
    }
    return locationTypes.INTERMEDIATE_LOCATION;
  }

  /**
   * Handle tooltip for intermediate locations
   */
  getRemainingLocationsTooltip(locationData: IOrderLocation[]) {
    if (locationData?.length > 1) {
      const loadingLocations = locationData
        .filter((_: any, index: number) => index !== 1 && index !== 0) // Skip index 1
        .map((item) => item.loading_location.name) // Extract the name field
        .join(", ");
      return loadingLocations;
    } else {
      return "";
    }
  }

  /**
   * Handle intermediate locations
   */
  displayIntermediateLocations(locationData: IOrderLocation[]): string {
    if (locationData?.length > 1) {
      return locationData[1]?.loading_location.name;
    } else {
      return "-";
    }
  }
}
