import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BaseComponent } from "@components/base.component";
import { AutoUnsubscribe } from "@decorator/autounsubscribe";

@Component({
  selector: "app-delete-record-confirmation-modal",
  templateUrl: "./delete-record-confirmation-modal.component.html",
  styleUrls: ["./delete-record-confirmation-modal.component.scss"],
})
@AutoUnsubscribe()
export class DeleteRecordConfirmationModalComponent extends BaseComponent {
  @Input() popupMessage!: string;
  @Output() ConfirmAction = new EventEmitter<string>();
  @Output() close: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Handle close modal
   */
  decline(): void {
    this.close.emit();
  }

  /**
   * Handle logout
   */
  onConfirmActionClick() {
    this.ConfirmAction.emit();
  }
}
