import { environment } from "environments/environment";

const BASE_URL = environment.apiEndpoint;
const API = "/api";
const ADMIN_API = "/api/admin";
const BAYAN_SYNC = "/sync/bayan/trip";
export const REQUESTER = "/requester";
export const PROVIDER = "/provider";
export const ENABLE_GOOGLE_ANALYTICS = true;

export const CONFIG = {
  EncrDecrKey: process.env["EncrDecrKey"],
  // oauth
  userAuthURL: BASE_URL + API + "/login",
  // Provider Registeration
  providerRegistrationURL: BASE_URL + API + "/provider/registration",
  // Requester Registration
  requesterRegistrationURL: BASE_URL + API + "/requester/registration",
  // Provider Update Account Data
  updateProviderAccountData: BASE_URL + API + "/provider/update/",
  // Requester Update Account Data
  updateRequesterAccountData: BASE_URL + API + "/requester/update/",

  // Provider Upload Documents
  updateProviderDocuments: BASE_URL + API + "/provider/upload/",
  // Requester Upload Documents
  updateRequsterDocuments: BASE_URL + API + "/requester/upload/",
  // update password
  updateUserPassword: BASE_URL + API + "/update/password/",
  // forgot password send
  forgotPassword: BASE_URL + API + "/forgot",
  // update password
  otpVerify: BASE_URL + API + "/verify",
  // Reset Password
  resetPassword: BASE_URL + API + "/reset/password",

  // Fetch Truck Details
  fetchAllTruckData: BASE_URL + API + "/provider/vehicles",
  changeTruckStatusURL: BASE_URL + API + "/provider/vehicle/",
  deleteTruckURL: BASE_URL + API + "/provider/vehicle/",
  getTruckTypesURL: BASE_URL + API + "/provider/truck/list",
  getPlatTypesURL: BASE_URL + API + "/provider/plate_types/list",
  donwloadTruckData: BASE_URL + API + "/provider/vehicles/export/data",
  saveTruckDetailsURL: BASE_URL + API + "/provider/vehicle/add",
  updateTruckDetailsURL: BASE_URL + API + "/provider/vehicle/",
  getTruckDetailURL: BASE_URL + API + "/provider/vehicle/",
  truckTemplateURL: BASE_URL + API + "/provider/vehicles/export",
  importTruckCSVFileURL: BASE_URL + API + "/provider/vehicles/import",

  // Fetch Truck Details
  fetchAllDriverData: BASE_URL + API + "/provider/drivers",
  changeDriverStatusURL: BASE_URL + API + "/provider/driver/",
  changeDriverStatusViaAdminURL: BASE_URL + ADMIN_API + "/drivers/status/",
  deleteDriverURL: BASE_URL + API + "/provider/driver/",
  deleteAdminDriverURL: BASE_URL + ADMIN_API + "/drivers/delete/",
  donwloadDriverData: BASE_URL + API + "/provider/drivers/export/data",
  saveDriverDetailsURL: BASE_URL + API + "/provider/driver/store",
  updateDriverDetailsURL: BASE_URL + API + "/provider/driver/",
  getDriverDetailURL: BASE_URL + API + "/provider/driver/",

  // Profile Details
  getProviderProfile: BASE_URL + API + "/me",
  changeProviderPasswordURL: BASE_URL + API + "/provider/user/profile/password",
  changeRequesterPasswordURL: BASE_URL + API + "/user/profile/password",
  // Fetch Service-Requester
  fetchAllServiceRequesters: BASE_URL + ADMIN_API + "/get/requesters",
  adminRegstRequester: BASE_URL + ADMIN_API + "/add/servicerequester",
  changeStatusRequester: BASE_URL + ADMIN_API + "/change/status/",
  providerUpdateProfileURL: BASE_URL + API + "/provider/user/profile/update",
  getSingleRequesterData: BASE_URL + ADMIN_API + "/get/user/",
  updateRequesterData: BASE_URL + ADMIN_API + "/update/servicerequester/",

  // Fetch Service-Provider
  fetchAllServiceProviders: BASE_URL + ADMIN_API + "/get/provider",
  adminProviderRegister: BASE_URL + ADMIN_API + "/add/provider",
  getSingleProviderData: BASE_URL + ADMIN_API + "/get/user/",
  updateProviderData: BASE_URL + ADMIN_API + "/update/provider/",

  // Fetch Admin Driver Data
  fetchAdminDriverList: BASE_URL + ADMIN_API + "/drivers",
  getProvidersForListing: BASE_URL + ADMIN_API + "/get/providers",
  addAdminDriver: BASE_URL + ADMIN_API + "/drivers/add",
  updateAdminDriver: BASE_URL + ADMIN_API + "/drivers/update/",
  getsingleDriverAdmin: BASE_URL + ADMIN_API + "/drivers/details/",
  // Export Csv / Pdf
  exportCsvPdf: BASE_URL + ADMIN_API + "/export/user/data",
  exportAdminDriver: BASE_URL + ADMIN_API + "/export/drivers",

  // Approve/Reject Requester/Provider
  approveRejectSRSP: BASE_URL + ADMIN_API + "/approve/reject",

  // Fetch Truck Details
  fetchAllAdminVehicleData: BASE_URL + ADMIN_API + "/vehicles",
  changeAdminVehcileStatusURL: BASE_URL + ADMIN_API + "/vehicle/",
  deleteAdminVehcileURL: BASE_URL + ADMIN_API + "/vehicle/",
  saveAdminVehicleDetailsURL: BASE_URL + ADMIN_API + "/vehicle/add",
  updateAdminvehcileDetailsURL: BASE_URL + ADMIN_API + "/vehicle/",
  getAdminvehcileDetailURL: BASE_URL + ADMIN_API + "/vehicle/",

  // Get Map Detailed Address
  getRequesterDetailedAddress: BASE_URL + API + "/google/map",
  getProviderDetailedAddress: BASE_URL + API + "/provider/google/map",

  // Upload File/Image
  addFileUpload: BASE_URL + API + "/local/upload",

  // Export Admin vehicle
  exportAdminVehiclURL: BASE_URL + ADMIN_API + "/vehicles/export/data",

  // Fetch Price Details
  getPriceListURL: BASE_URL + ADMIN_API + "/price/options",

  // Fetch Price Details
  getGoodsTypesURL: BASE_URL + API + "/goods_types",
  getCitiesURL: BASE_URL + API + "/cities",
  saveAdminPriceURL: BASE_URL + ADMIN_API + "/price/store",
  updateAdminPriceURL: BASE_URL + ADMIN_API + "/price/update/",
  deleteAdminPriceURL: BASE_URL + ADMIN_API + "/price/delete/",
  getPriceDetailURL: BASE_URL + ADMIN_API + "/price/detail/",
  exportPriceDataURL: BASE_URL + ADMIN_API + "/price/export",
  getWeightTypes: BASE_URL + API + "/weights",

  // Admin Direct Order Details
  getAdminDirectOrderDetials: BASE_URL + ADMIN_API + "/order/",
  getAdminOrderStatusURL: BASE_URL + API + "/status/admin",
  getOrderTripStatusListURL: BASE_URL + API + "/trip/status",
  changeOrderStatusURL: BASE_URL + API + "/order/change_status/",
  cancelOrderURL: BASE_URL + ADMIN_API + "/order/refuse/",
  changeOrderTripStatusURL:
    BASE_URL + API + "/provider/order/change_trip_status/",
  getAdminDirectOrdersList: BASE_URL + ADMIN_API + "/orders",
  getDirectOrderStatusFilterList: BASE_URL + API + "/status",
  exportAdminDirectOrdersList: BASE_URL + ADMIN_API + "/order/export/order",

  // Export Sub Admin List
  getSubAdminList: BASE_URL + ADMIN_API + "/subadmins",
  deleteSubAdmin: BASE_URL + ADMIN_API + "/subadmin/delete/",
  getIndividualSubAmin: BASE_URL + ADMIN_API + "/subadmin/read/",
  exportSubAdminList: BASE_URL + ADMIN_API + "/subadmin/export",
  getDesignationList: BASE_URL + API + "/designation",
  registerSubAdmin: BASE_URL + ADMIN_API + "/subadmin/user",
  readSubAdminDetails: BASE_URL + ADMIN_API + "/subadmin/read/",
  updateSubAdminDetails: BASE_URL + ADMIN_API + "/subadmin/user/update/",

  // Admin Profile
  updateAdminProfile: BASE_URL + ADMIN_API + "/profile/update",

  // Send Password link
  resetPasswordLink: BASE_URL + ADMIN_API + "/send/resetpassword/mail",

  // contract dashboard
  getContractDashboardList: BASE_URL + API + "/provider/contracts",
  getMyContractList: BASE_URL + API + "/contracts",
  getAdminMyContractList: BASE_URL + API + "/contracts",
  getRequesterMyContractList: BASE_URL + API + "/requester/contracts",
  addNewProviderContract: BASE_URL + API + "/requester/add/provider/contract",
  addNewPrices: BASE_URL + API + "/contracts/price/store",
  addNewPricesAdmin: BASE_URL + API + "/admin/contracts/price/store",
  addNewPricesProvider: BASE_URL + API + "/provider/contracts/price/store",
  addNewPricesRequester: BASE_URL + API + "/requester/contracts/price/store",
  reuqesterUpdateProfileURL: BASE_URL + API + "/requester/profile/update",
  getServicePriceList: BASE_URL + API + "/admin/contracts/price/options",
  getProviderServicePriceList:
    BASE_URL + API + "/provider/contracts/price/options",
  getRequesterServicePriceList:
    BASE_URL + API + "/requester/contracts/price/options",
  getProviderDirectOrdersList: BASE_URL + API + "/provider/orders",
  getRequesterDirectOrdersList: BASE_URL + API + "/requester/orders",
  getShipmentTypeURL: BASE_URL + API + "/shipment_types",
  getAccessoriesURL: BASE_URL + API + "/accessories",
  saveDirectOrderURL: BASE_URL + API + "/shipment/request",
  getProviderListURL: BASE_URL + API + "/requester/search/order/offers",
  getShipmentTrucks: BASE_URL + API + "/trucks/",
  getTrucks: BASE_URL + API + "/trucks",
  getContractProvider: BASE_URL + API + "/requester/get/contracts/providers",
  getAdminContractProvider: BASE_URL + API + "/admin/get/contracts/providers",
  getRequesterContractProvider:
    BASE_URL + API + "/requester/get/contracts/providers",
  getProviderContractProvider:
    BASE_URL + API + "/provider/get/contracts/providers",
  getShipmentType: BASE_URL + API + "/shipment_types",
  getServicePriceDetails: BASE_URL + API + "/contracts/price/detail",
  getServicePriceDetailsAdmin: BASE_URL + API + "/admin/contracts/price/detail",
  getServicePriceDetailsProvider:
    BASE_URL + API + "/provider/contracts/price/detail",
  getServicePriceDetailsRequester:
    BASE_URL + API + "/requester/contracts/price/detail",
  getWeight: BASE_URL + API + "/weights",
  deleteServicePrice: BASE_URL + API + "/requester/contracts/price/delete",
  updateServicePrice: BASE_URL + API + "/requester/contracts/price/update",
  updateServicePriceAdmin: BASE_URL + API + "/admin/contracts/price/update",
  updateServicePriceProvider:
    BASE_URL + API + "/provider/contracts/price/update",
  updateServicePriceRequester:
    BASE_URL + API + "/requester/contracts/price/update",
  getAccessories: BASE_URL + API + "/accessories",
  getContractOrders: BASE_URL + API + "/orders",
  getAdminContractOrders: BASE_URL + API + "/admin/orders",
  addAdminCreateContractOrder:
    BASE_URL + API + "/admin/membership/shipment/create",
  addRequesterCreateContractOrder:
    BASE_URL + API + "/requester/membership/shipment/create",
  addProviderCreateContractOrder:
    BASE_URL + API + "/provider/membership/shipment/create",
  getContractOrderById: BASE_URL + API + "/order",
  // Admin permissions
  getAdminPermissions: BASE_URL + ADMIN_API + "/permissions",
  setVehcileCountURL: BASE_URL + API + "/provider/order/accept/",
  getTripDriverProviderTruckURL: BASE_URL + API + "/provider/order/",
  getDriverDetailsURL: BASE_URL + API + "/provider/drivers/active",
  getVehicleDetailsURL: BASE_URL + API + "/provider/vehicles/active",
  saveUpdateTruckDriver: BASE_URL + API + "/provider/bayan/",
  acceptOrderURL: BASE_URL + API + "/order/accept/",
  changeProviderURL: BASE_URL + API + "/order/change/provider/",
  getRequesterDirectOrderDetials: BASE_URL + API + "/requester/order/",
  getProviderDirectOrderDetials: BASE_URL + API + "/provider/order/",
  rejectOrderURL: BASE_URL + API + "/provider/order/refuse/",
  getProviderOrderStatusURL: BASE_URL + API + "/status/sp",
  getRequesterOrderStatusURL: BASE_URL + API + "/status/sr",
  cancelSrOrderURL: BASE_URL + API + "/requester/order/",
  cancelSpOrderURL: BASE_URL + API + "/provider/order/refuse/",

  // Admin Contracts
  getAdminContractsList: BASE_URL + ADMIN_API + "/orders",
  editTripDateTime: BASE_URL + ADMIN_API + "/bayan/update/tripdate/",
  providerEditTripDateTime: BASE_URL + API + "/provider/bayan/update/tripdate/",
  exportCsvPdfAdminContracts: BASE_URL + ADMIN_API + "/order/export/membership",

  // Admin Chat
  getChatList: BASE_URL + ADMIN_API + "/chat/user",
  getChatSession: BASE_URL + ADMIN_API + "/chat/session",
  createChat: BASE_URL + ADMIN_API + "/chat/create",
  sendMessage: BASE_URL + ADMIN_API + "/chat/send",
  getChatHistory: BASE_URL + ADMIN_API + "/chat/list",
  srspchatSeen: BASE_URL + API + "/chat/seen",
  chatSeen: BASE_URL + ADMIN_API + "/chat/seen",

  // provider orders
  getProviderContractOrders: BASE_URL + API + "/provider/contracts/list",
  getProviderContractOrderById: BASE_URL + API + "/provider/order",
  getDropdownField: BASE_URL + API + "/get/field/dropdown",
  getStatusChangeForOrder: BASE_URL + API + "/order/change_status",
  shipmentPaymentURL: BASE_URL + API + "/order/",
  getNotificationURL: BASE_URL + API + "/notifications",
  getPricingDetails: BASE_URL + API + "/provider/price/",
  getAllNotificationURL: BASE_URL + API + "/notifications/list",
  downloadContractInvoice: BASE_URL + API + "/order/invoice",
  getOrderHistory: BASE_URL + API + "/provider/order/history",
  getOrderHistoryForRequester: BASE_URL + API + "/requester/order/history",
  savePricingURL: BASE_URL + API + "/provider/price/store",
  updatePricingURL: BASE_URL + API + "/provider/price/update/",

  // Direct Order Pricing list
  getSpDirectOrderPricing: BASE_URL + API + "/provider/price/list",
  getTrucksType: BASE_URL + API + "/trucks",
  deleteSpServicePricing: BASE_URL + API + "/provider/price/destroy/",
  downloadSpServicePriceExcel: BASE_URL + API + "/provider/price/export/data",

  // Contract Orders
  orderDetailsById: BASE_URL + ADMIN_API + "/order/",
  postAssignProviders: BASE_URL + ADMIN_API + "/orders/assign/provider",
  acceptRejectContractOrder: BASE_URL + ADMIN_API + "/order/accept/reject/",
  getDriverListContractOrder: BASE_URL + ADMIN_API + "/active/drivers",
  getVehicleListContractOrder: BASE_URL + ADMIN_API + "/provider/vehicles",
  getTripListDetails: BASE_URL + ADMIN_API + "/bayan/trips/",
  getTripDetailsById: BASE_URL + ADMIN_API + "/bayan/trip/",
  getProviderTripDetailsById: BASE_URL + API + "/provider/bayan/trip/",
  getRequesterTripDetailsById: BASE_URL + API + "/requester/bayan/trip/",
  adminContractInVoiceData: BASE_URL + ADMIN_API + "/order/invoice/notes/",
  cancelContractOrder: BASE_URL + ADMIN_API + "/order/refuse/",
  rescheduleContractOrder: BASE_URL + ADMIN_API + "/orders/reschedule/",
  updateDriverTruckAdmin: BASE_URL + ADMIN_API + "/bayan/",
  updateDriverTruckPrefixUrl: "/trip/update",
  createNewAdminContractOrder:
    BASE_URL + API + "/admin/membership/shipment/create",
  createNewRequesterContractOrder:
    BASE_URL + API + "/requester/membership/shipment/create",
  createNewProviderContractOrder:
    BASE_URL + API + "/provider/membership/shipment/create",
  requesterListForNewContract: BASE_URL + ADMIN_API + "/requesters",
  addDriverTruckAdmin: BASE_URL + ADMIN_API + "/bayan/",
  createDriverTruckPrefixUrl: "/trip/create",
  changeContractOrderTripStatusURL:
    BASE_URL + ADMIN_API + "/order/change_trip_status/",
  reAssignProvider: BASE_URL + ADMIN_API + "/orders/re-assign/provider",

  // Notification
  notificationRead: BASE_URL + API + "/notifications/seen/",

  // Admin Dashboard
  getDashboardDetails: BASE_URL + API + "/admin/dashboard",

  // Dashboard
  getProviderDashboardURL: BASE_URL + API + "/provider/dashboard",
  // Logout
  logOutFromAccount: BASE_URL + API + "/logout",

  // SP & SR Chat
  createSpSrChat: BASE_URL + API + "/chat/create",
  getSpSrChatHistory: BASE_URL + API + "/chat/list",
  sendSpSrMessage: BASE_URL + API + "/chat/send",

  // Requester Dashboard
  getRequesterDashboardURL: BASE_URL + API + "/requester/requester/dashboard",
  // Dashboarc Current Orders
  getCurrentOrders: BASE_URL + API + "/provider/order/history",
  getRequesterCurrentOrders: BASE_URL + API + "/requester/order/history",

  // Public IP URL
  // httpBinApiUrl: "https://httpbin.org/ip",

  // Payment initiate and checkout
  paymentInitiateURL: BASE_URL + API + "/payment/initiate",
  paymentCheckoutURL: BASE_URL + API + "/payment/checkout",
  // Contact Us API
  saveProviderContactUs: BASE_URL + API + "/provider/contact",
  saveRequesterContactUs: BASE_URL + API + "/contact",
  // Terms and Condition APU
  getProviderTermsCondition:
    BASE_URL + API + "/provider/page/terms_and_conditions",
  getRequesterTermsCondition: BASE_URL + API + "/page/terms_and_conditions",
  // Privacy Policy API
  getProviderPrivacyPolicy:
    BASE_URL + API + "/provider/page/privacy_and_policy",
  getRequesterPrivacyPolicy: BASE_URL + API + "/page/privacy_and_policy",
  // delete account
  deleteAccount: BASE_URL + API + "/delete/account",

  // special orders API
  getSpecialOrderList: BASE_URL + API + "/orders",
  getProviderSpecialOrderList: BASE_URL + API + "/provider/special_orders",

  driverTemplateURL: BASE_URL + API + "/provider/drivers/download/template",
  importDriverCSVFileURL: BASE_URL + API + "/provider/drivers/import",
  importAdminTruckCSVFileURL: BASE_URL + API + "/admin/vehicles/import",
  importAdminDriverCSVFileURL: BASE_URL + API + "/admin/drivers/import",
  AdmindriverTemplateURL: BASE_URL + API + "/admin/drivers/download/template",
  AdminVehicleTemplateURL: BASE_URL + API + "/admin/vehicles/download/template",
  // settings API
  getSettings: BASE_URL + API + "/admin/get/settings",
  addSettings: BASE_URL + API + "/admin/settings",
  // Support messages API
  getSupport: BASE_URL + API + "/admin/support/list",
  addNote: BASE_URL + API + "/admin/support/add_note/",
  getSupportDetails: BASE_URL + API + "/admin/support/details/",
  // special Order API
  specialOrderChangeStatus:
    BASE_URL + API + "/provider/special_order/change_trip_status/",
  createSpecialOrder: BASE_URL + API + "/shipment/request/special",
  adminSpecialOrderList: BASE_URL + API + "/admin/special/orders",

  cancelTrip: BASE_URL + API + "/admin/bayan/cancel/trip/",
  fetchProviderListToAssign: BASE_URL + ADMIN_API + "/get/provide/price",
  // export Special Order API
  exportSpecialOrder: BASE_URL + API + "/admin/order/export/special_order",
  downloadInvoice: BASE_URL + API + "/odoo_invoice",
  RequesterOfferOrdersListURL: BASE_URL + API + "/orders",
  ProviderOfferOrdersListURL: BASE_URL + API + "/provider/orders",

  // admin offer-orders
  adminViewOfferList: BASE_URL + ADMIN_API + "/offers",
  adminOfferOrdersList: BASE_URL + ADMIN_API + "/orders",
  adminOfferDetails: BASE_URL + API + "/offer/",
  addNewOffer: BASE_URL + ADMIN_API + "/offer/store",
  editOffer: BASE_URL + ADMIN_API + "/offer/",

  // SR/SP Offers
  providerOffersListing: BASE_URL + API + "/provider/offers",
  requesterOffersListing: BASE_URL + API + "/offers",
  exportOfferOrders: BASE_URL + ADMIN_API + "/order/export/offer",
  offerOrderDetails: BASE_URL + ADMIN_API + "/order/",
  deleteOffer: BASE_URL + ADMIN_API + "/offer/",
  deleteOfferBySp: BASE_URL + API + "/provider/offer/",
  addNewOfferViaProvider: BASE_URL + API + "/provider/offer/store",
  editOfferViaProvider: BASE_URL + API + "/provider/offer/",
  // Approve/Reject Provider Contract Order
  acceptProviderContract: BASE_URL + API + "/provider/order/accept/reject/",
  createOffersSr: BASE_URL + API + "/order/request/offer",
  reAssignTripProviderURL: BASE_URL + ADMIN_API + "/change/provider/",
  bayanWaybillDownloadURL: BASE_URL + API + "/download/order/trip/",

  // Dangeours good type
  dangerousGoodTyeps: BASE_URL + API + "/dangerous_goodtype",

  // Account details Step verifications
  validateNewProvider: BASE_URL + API + "/requester/validate/provider/request",
  getProvidersForInvoiceNotes: BASE_URL + ADMIN_API + "/get/providers/",

  // Get truck and plate type for admin

  getAdminTruckTypesURL: BASE_URL + ADMIN_API + "/truck/list",
  getAdminPlatTypesURL: BASE_URL + ADMIN_API + "/plate_types/list",
  // Bayan Syncing
  bayanSyncCloseCancelTrip: BASE_URL + API + BAYAN_SYNC,
  updateOrderDescription: BASE_URL + ADMIN_API + "/change/description/",
  getProviderPrice: BASE_URL + ADMIN_API + "/get/trip/price",
  getProviderTruckPrice: BASE_URL + API + "/provider/get/trip/price",
  waslAdminSyncVehicle: BASE_URL + ADMIN_API + "/sync/wasl/vehicle/",
  waslProviderSyncVehicle: BASE_URL + API + "/sync/wasl/vehicle/",
  waslAdminSyncDriver: BASE_URL + ADMIN_API + "/sync/wasl/driver/",
  waslProviderSyncDriver: BASE_URL + API + "/sync/wasl/driver/",
  getChatUnreadCount: BASE_URL + ADMIN_API + "/get/chat/unread/count",
  updateDeviceToken: BASE_URL + API + "/updatetoken/",

  seenAllRequesterAPI: BASE_URL + API + "/notifications/seen",
  seenAllProviderAPI: BASE_URL + API + "/provider/notifications/seen",
  seenAllAdminAPI: BASE_URL + ADMIN_API + "/notifications/seen",
  changeAdminPasswordURL: BASE_URL + ADMIN_API + "/user/profile/password",

  // Container trip
  createContainerAdminPrefix: BASE_URL + API + "/admin/trip/",
  createContainerProviderPrefix: BASE_URL + API + "/provider/trip/",

  // Admin/Sub-Admin City Master
  getCityMaterList: BASE_URL + API + "/admin/city",
  addCityToMaster: BASE_URL + API + "/admin/city/store",
  deleteLocation: BASE_URL + API + "/admin/city/delete",
  getCityEditData: BASE_URL + API + "/admin/city/view",
  updateCity: BASE_URL + API + "/admin/city/update",

  // Admin/Sub-Admin Master Trip
  getMasterTripList: BASE_URL + API + "/admin/bayan/trips",
  exportMasterTripList: BASE_URL + API + "/admin/bayan/export",
  exportMasterTripDetail: BASE_URL + API + "/admin/bayan/trip/export/pdf",

  getAdminAdditionalChargesTypes: BASE_URL + API + "/admin/additional/charges",
  getProviderAdditionalChargesTypes:
    BASE_URL + API + "/provider/additional/charges",
  saveAdminAdditionalCharges: BASE_URL + API + "/admin/additional/charges/add",
  saveProviderAdditionalCharges:
    BASE_URL + API + "/provider/additional/charges/add",

  adminAdditionalChargesTripViewList:
    BASE_URL + API + "/admin/additional/pending/",
  providerAdditionalChargesTripViewList:
    BASE_URL + API + "/provider/additional/pending/",
  requesterAdditionalChargesTripViewList:
    BASE_URL + API + "/requester/additional/pending/",

  adminApproveRejectUrl: BASE_URL + API + "/admin/additional/accept/reject/",
  requesterApproveRejectUrl:
    BASE_URL + API + "/requester/additional/accept/reject/",

  adminEditAdditionalChargeUrl:
    BASE_URL + API + "/admin/additional/charges/update/",
  providerEditAdditionalChargeUrl:
    BASE_URL + API + "/provider/additional/charges/update/",
  requesterEditAdditionalChargeUrl:
    BASE_URL + API + "/requester/additional/charges/update/",
  fetchAdminPendingAdditionalCharges:
    BASE_URL + ADMIN_API + "/additional/pending",
  fetchProviderPendingAdditionalCharges:
    BASE_URL + API + "provider/additional/pending",

  additionalChargesDownload: BASE_URL + API + "/additional/charges/download",

  adminAdditionalChargesDelete:
    BASE_URL + API + "/admin/additional/charges/delete/",
  providerAdditionalChargesDelete:
    BASE_URL + API + "/provider/additional/charges/delete/",
  requesterAdditionalChargesDelete:
    BASE_URL + API + "/requester/additional/charges/delete/",
};
