<!-- User Logo -->
<picture *ngIf="!isAdmin">
  <source srcset="assets/images/common/logo.webp" type="image/webp" />
  <source srcset="assets/images/common/logo.png" type="image/png" />
  <img
    src="{{ requesterTypeURL }}/login/side-image.png"
    alt="Side Banner"
    title="Side Banner"
    width="224px"
    height="133px"
  />
</picture>

<!-- Admin Panel Logo  -->
<picture *ngIf="isAdmin && isLoginScreen">
  <source srcset="assets/images/admin/logo.webp" type="image/webp" />
  <source srcset="assets/images/admin/logo.png" type="image/png" />
  <img
    src="{{ requesterTypeURL }}/logo.png"
    alt="Side Banner"
    title="Side Banner"
    width="224px"
    height="133px"
  />
</picture>

<picture *ngIf="isAdmin && !isLoginScreen">
  <source srcset="assets/images/common/logo.webp" type="image/webp" />
  <source srcset="assets/images/common/logo.png" type="image/png" />
  <img
    src="{{ requesterTypeURL }}/logo.png"
    alt="Side Banner"
    title="Side Banner"
    width="224px"
    height="133px"
  />
</picture>
