<div class="body-part">
  <div class="top-area">
    <div class="top-left">
      <h1>{{ "SUPPORT" | translate }}</h1>
      <span class="breadcrum"
        ><a [routerLink]="[this.getRoutesRole() + '/dashboard']">{{
          "HOME" | translate
        }}</a>
        {{ "SUPPORT" | translate }}
      </span>
    </div>
  </div>
  <div class="content-area">
    <div class="provider-list-area">
      <div class="list-box">
        <div class="registration-form">
          <form
            class="space-between flex-col"
            (ngSubmit)="submitSupportNo(support)"
            novalidate
            #support="ngForm"
          >
            <div class="d-col d-col-2">
              <div class="dropdown">
                <div
                  class="form-group"
                  [ngClass]="model.supportNumber ? 'floatingLabel' : ''"
                >
                  <span>
                    <input
                      [dir]="isEnglish ? 'ltr' : 'rtl'"
                      type="text"
                      id="supportNumber"
                      name="supportNumber"
                      class="form-control"
                      [(ngModel)]="model.supportNumber"
                      appNumberOnly
                      #supportNumber="ngModel"
                      required
                      appFloatingLabelInput
                      appSpaceRemove
                    />
                    <div
                      class="form-control-feedback text-danger"
                      *ngIf="supportNumber.errors && submitted"
                    >
                      <span *ngIf="supportNumber.errors.required">
                        {{ "PLEASE_ENTER_SUPPORT_NO" | translate }}
                      </span>
                    </div>
                    <label class="floating-label">{{
                      "SUPPORT_NUMBER" | translate
                    }}</label>
                  </span>
                </div>
              </div>
              <div class="d-col btn-wrapper price-end">
                <div class="btn-area d-flex">
                  <a class="btn bordered-btn btn-small">{{
                    "CANCEL" | translate
                  }}</a>
                  <button type="submit" class="btn btn-secondary btn-small">
                    {{ "SAVE" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <app-spinner></app-spinner>
</div>
