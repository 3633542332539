import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import { first } from "rxjs/operators";
import {
  AddDriverTruckVisibleStatus,
  ContractProviderCollection,
  isInvoiceVisibleContractStatus,
  isInvoiceVisibleStatus,
  ProviderOrderStatusCollection,
  SectionType,
} from "@config/app-constants";
import {
  IRejectOrder,
  IStatusApiResponse,
  IStatusItem,
} from "@model/directOrdersDetails";
import { IErrorResponse } from "@model/errorResponse";
import { IDownloadInvoiceResponse } from "@model/payment";
import { ISuccessResponse } from "@model/successResponse";
import {
  OrderFilterKey,
  orderTypes,
  spCancelOrderTypeRedirection,
  userType,
} from "@utils/enum-const";
import { BaseComponent } from "@components/base.component";
import { AutoUnsubscribe } from "@decorator/autounsubscribe";
import { CommonApiService } from "@services/common-api.service";
import { OrdersCommonService } from "@services/orders-common-service";
import { IApproveRejectOrderPayload, IorderStatusPayload } from "@model/common";
import { IProviderOrderInfo } from "@model/provider";

@Component({
  selector: "app-provider-order-info",
  templateUrl: "./provider-order-info.component.html",
  styleUrls: ["./provider-order-info.component.scss"],
})
@AutoUnsubscribe()
export class ProviderOrderInfoComponent extends BaseComponent
  implements OnInit, OnChanges {
  @Input() orderDetails: any;
  @Input() tripDetails: any;
  @Input() showTruckQuantity: any;
  @Output() handleTruckQuantity = new EventEmitter();
  @Output() approveContractOrder = new EventEmitter();
  @Output() changeStatus = new EventEmitter();
  @Output() editTripDetails = new EventEmitter();
  @Output() detailsForm = new EventEmitter();
  isReasonVisible = false;

  userType = userType;
  model = {
    order_status: "",
  };
  isDirectOrderDropVisibleStatus = ProviderOrderStatusCollection;
  isContractOrderDropVisibleStatus = ContractProviderCollection;
  orderTypes = orderTypes;
  isAddUpdateDriverTruckStatus = AddDriverTruckVisibleStatus;
  isInvoiceVisibleContractStatus = isInvoiceVisibleContractStatus;
  isInvoiceVisibleStatus = isInvoiceVisibleStatus;
  userId!: number;
  orderStatusList: IStatusItem[] = [];
  isReasonDropDownVisible = false;
  showQuantity!: number;
  showTripQuantityForContract!: number;
  storeProgressBarClass!: string;
  isDetailedReason = true;
  constructor(
    private ManageOrdersCommonService: OrdersCommonService,
    private commonService: CommonApiService
  ) {
    super();
    this.ngOnInit();
  }

  /**
   * Handle init function for page load
   */
  ngOnInit(): void {
    if (
      (this.orderDetails &&
        this.orderTypes.MEMBERSHIP === this.orderDetails?.section) ||
      this.orderDetails?.section === this.orderTypes.SPECIAL_ORDER
    ) {
      this.multipleProviderDropDown();
      if (
        this.orderDetails?.providers &&
        this.orderDetails?.shahen_order === 1
      ) {
        this.updateQuantityBasedOnmutliDropDown();
      }
      if (this.orderDetails?.section === this.orderTypes.SPECIAL_ORDER) {
        this.orderDetails?.providers.forEach(
          (e: {
            provider_id: number;
            price: any;
            quantity: any;
            total_price: any;
            sp_approve: any;
            sub_total: any;
          }) => {
            if (e.provider_id === this.userId) {
              this.orderDetails.price = e.price;
              this.orderDetails.quantity = e.quantity;
              this.orderDetails.total_price = e.total_price;
              this.orderDetails.sp_approve = e.sp_approve;
              this.orderDetails.sub_total = e.sub_total;
            }
          }
        );
      }
    }
    this.getOrderStatusList();
  }
  ngOnChanges() {
    this.handleProgressBar();
    this.multipleProviderDropDown();
    if (this.orderDetails?.providers && this.orderDetails?.shahen_order === 1) {
      this.updateQuantityBasedOnmutliDropDown();
    }
  }
  openProviderTruckModal() {
    this.handleTruckQuantity.emit();
  }

  /**
   * Reject order with reason
   */
  rejectOrder(commentPayload: IRejectOrder) {
    this.spinner.showLoader();
    this.ManageOrdersCommonService.rejectOrder(
      this.orderDetails?.order_id,
      commentPayload
    )
      .pipe(first())
      .subscribe(
        (data: ISuccessResponse) => {
          this.spinner.hideLoader();
          this.modalRef.hide();
          if (data.status_code === this.statusCode.OK) {
            this.toastr.success(data.message);
            this.detailsForm.emit();
          }
        },
        (error: IErrorResponse) => {
          this.spinner.hideLoader();
          this.errorHandler(this.toastr, this.translateService, error, () => {
            this.router.navigate([this.getRoutesRole() + "/direct-orders"]);
          });
        }
      );
  }

  /**
   * Get Order Display List
   */
  getOrderStatusList() {
    this.spinner.showLoader();
    let orderPayload: IorderStatusPayload;
    if (
      this.orderType.membership === this.orderType[this.orderDetails?.section]
    ) {
      orderPayload = {
        section: SectionType.MEMBERSHIP,
      };
    } else {
      orderPayload = {
        section: OrderFilterKey.order,
      };
    }

    this.ManageOrdersCommonService.getOrderStatus(orderPayload)
      .pipe(first())
      .subscribe(
        (resp: IStatusApiResponse) => {
          this.spinner.hideLoader();
          if (resp.status_code === this.statusCode.OK) {
            this.orderStatusList = resp.data;
          }
        },
        (error: IErrorResponse) => {
          this.spinner.hideLoader();
          this.errorHandler(this.toastr, this.translateService, error);
        }
      );
  }

  /**
   * Handle download invoice
   */
  downloadInvoice() {
    this.spinner.showLoader();
    const payload: IProviderOrderInfo = {
      orderid: this.orderDetails?.order_id,
      type: this.userType.PROVIDER,
      provider_id:
        this.orderDetails?.providers &&
        (this.orderDetails?.shahen_order === 1 ||
          this.orderDetails?.section === this.orderTypes.SPECIAL_ORDER)
          ? Number(this.userId)
          : "",
    };
    if (this.orderDetails?.providers.length === 0) {
      const singleProviderPayload = Object.keys(payload).reduce(
        (object: { [key: string]: any }, key: string) => {
          if (key !== "provider_id") {
            object[key] = payload[key as keyof typeof payload] ?? "";
          }
          return object;
        },
        {}
      );
      this.commonService
        .downloadInvoice(singleProviderPayload)
        .pipe(first())
        .subscribe(
          (data: IDownloadInvoiceResponse) => {
            this.spinner.hideLoader();
            if (data.status_code === this.statusCode.OK) {
              window.open(data?.data?.report_url, "_blank");
            }
          },
          (error: IErrorResponse) => {
            this.spinner.hideLoader();
            this.errorHandler(this.toastr, this.translateService, error);
          }
        );
    } else {
      this.commonService
        .downloadInvoice(payload)
        .pipe(first())
        .subscribe(
          (data) => {
            this.spinner.hideLoader();
            if (data.status_code === this.statusCode.OK) {
              window.open(data?.data?.report_url, "_blank");
            }
          },
          (error: IErrorResponse) => {
            this.spinner.hideLoader();
            this.errorHandler(this.toastr, this.translateService, error);
          }
        );
    }
  }

  /**
   * Open Modal
   */
  openModal() {
    this.editTripDetails.emit();
  }

  /**
   * Handle change provider status
   */
  changeProviderStatus() {
    this.changeStatus.emit(this.model.order_status);
    this.getOrderStatusList();
  }

  /**
   * Handle download invoice
   */
  openRejectPopUpModal(template: TemplateRef<string>) {
    this.modalRef = this.modalService.show(template, { class: "modal-lg" });
  }

  /**
   * Handle close modal
   */
  decline(): void {
    this.modalRef?.hide();
  }

  /**
   * Handle approve/reject order
   */
  public approveRejectOrder({ action, detailedReason }: any) {
    this.spinner.showLoader();
    if (this.orderDetails?.order_id) {
      const payload: IApproveRejectOrderPayload = {
        status:
          action === this.approveRejectStatusName.approve
            ? this.orderAccpetStatus.accepted
            : this.orderAccpetStatus.rejected,
      };
      if (action === this.approveRejectStatusName.reject) {
        payload.status_notes = detailedReason;
      }
      this.commonService
        .approveRejectOrder(this.orderDetails?.order_id, payload)
        .pipe()
        .subscribe(
          (data: ISuccessResponse) => {
            this.spinner.hideLoader();
            if (data.status_code === this.statusCode.OK) {
              this.toastr.success(data.message);
              this.detailsForm.emit();
              if (payload.status === this.orderAccpetStatus.rejected) {
                this.router.navigate([
                  this.getRoutesRole() +
                    "/" +
                    this.srCancelOrderTypeRedirection[
                      this.orderDetails
                        ?.section as keyof typeof spCancelOrderTypeRedirection
                    ],
                ]);
              }
            }
          },
          (error: IErrorResponse) => {
            this.errorHandler(this.toastr, this.translateService, error, () => {
              this.spinner.hideLoader();
            });
          }
        );
    }
  }
  multipleProviderDropDown() {
    const filteredProviders = this.orderDetails?.providers.filter(
      (provider: { provider_id: number }) =>
        provider.provider_id === this.userId
    );
    this.orderDetails.providers = filteredProviders;
  }

  updateQuantityBasedOnmutliDropDown() {
    const firstProvider = this.orderDetails?.providers[0];
    this.orderDetails.price = firstProvider?.price;
    this.orderDetails.quantity = firstProvider?.quantity;
    this.showQuantity = firstProvider?.quantity;
    this.orderDetails.total_price = firstProvider?.total_price;
    this.orderDetails.sub_total = firstProvider?.sub_total;
    this.orderDetails.sp_approve = firstProvider?.sp_approve;
  }
  handleProgressBar() {
    let quantityClass: string;
    if (
      this.orderDetails?.quantity < 10 ||
      (this.orderDetails?.quantity >= 10 && this.orderDetails?.quantity < 100)
    ) {
      quantityClass = this.TypeOfProgressBar.TWODIGIT;
    } else {
      quantityClass = this.TypeOfProgressBar.THREEDIGIT;
    }
    this.storeProgressBarClass = quantityClass;
  }
}
