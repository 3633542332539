import { Directive, ElementRef, HostListener, Renderer2 } from "@angular/core";

@Directive({
  selector: "[appFloatingLabelInput]",
})
export class FloatingLabelInputDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  // with span - admin manager
  @HostListener("focus") onFocus() {
    this.addFloatingLabelClass();
  }

  @HostListener("blur") onBlur() {
    this.removeFloatingLabelClass();
  }

  private addFloatingLabelClass() {
    this.renderer.addClass(
      this.el.nativeElement.parentElement,
      "floatingLabel"
    );
  }

  private removeFloatingLabelClass() {
    this.renderer.removeClass(
      this.el.nativeElement.parentElement,
      "floatingLabel"
    );
  }
}
