import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgForm } from "@angular/forms";
import { BaseComponent } from "@components/base.component";

@Component({
  selector: "app-cancel-order-popup",
  templateUrl: "./cancel-order.component.html",
  styleUrls: ["./cancel-order.component.scss"],
})
export class CancelOrderPopupComponent extends BaseComponent {
  @Output() orderCancel = new EventEmitter<any>();
  @Output() close = new EventEmitter();
  @Input() isTripCancel = false;
  isCancel = false;
  model = {
    comment: "",
  };
  submitted = false;
  isInvalidQuantity = true;

  /**
   * Close Popup
   */
  closePopup() {
    this.close.emit();
  }

  /**
   * Handle cancel click event
   */
  cancel() {
    this.isCancel = true;
  }

  /**
   * Handle cancel order submit
   */
  cancelOrderSubmit(form: NgForm) {
    this.submitted = true;
    if (form.invalid) {
      return;
    }
    this.close.emit();
    this.orderCancel.emit(this.model);
  }
}
