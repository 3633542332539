<a
  class="icon-edit-box"
  (click)="openModal(deleteRecordTemplate)"
  title="{{ 'DELETE' | translate }}"
>
  <i class="icon-delete"></i>
</a>

<ng-template #deleteRecordTemplate>
  <app-confirmation-popup-modal
    [popupMessage]="popupMessage"
    (submit)="handleDelete()"
    (close)="decline()"
  >
  </app-confirmation-popup-modal>
</ng-template>
