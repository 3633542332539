import { Component } from "@angular/core";
import { NgForm } from "@angular/forms";
import { BaseComponent } from "@components/base.component";

@Component({
  selector: "app-support",
  templateUrl: "./support.component.html",
  styleUrls: ["./support.component.scss"],
})
export class SupportComponent extends BaseComponent {
  submitted = false;
  public model = {
    supportNumber: null,
  };

  /*
   * Handle support form submit
   */
  submitSupportNo(form: NgForm) {
    this.submitted = true;
    if (form.invalid) {
      return;
    }
  }
}
