import { NgModule } from "@angular/core";
import { NgxSpinnerModule } from "ngx-spinner";
import { SpinnerComponent } from "./spinner.component";

@NgModule({
  imports: [NgxSpinnerModule],
  declarations: [SpinnerComponent],
  exports: [SpinnerComponent],
})
export class SpinnerModule {
  static forRoot() {
    return {
      NgModule: SpinnerModule,
    };
  }
}
