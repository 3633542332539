import { CommonModule } from "@angular/common";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { Injector, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppFooterModule,
  AppHeaderModule,
  AppSidebarModule,
} from "@coreui/angular";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { ChartsModule } from "ng2-charts";
// Import 3rd party components
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { TabsModule } from "ngx-bootstrap/tabs";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { NgxPermissionsModule } from "ngx-permissions";
import { ToastrModule } from "ngx-toastr";
import { TreeviewModule } from "ngx-treeview";
// Import routing module
// Import containers
import { AngularFireModule } from "@angular/fire";
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { NgSelectModule } from "@ng-select/ng-select";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { DefaultLayoutComponent } from "@containers/default-layout";
import { AppRoutingModule } from "app/app.routing";
import { OrderHistoryModule } from "@views/order-history/order-history.module";
import { AppComponent } from "app/app.component";
import { P403Component } from "@views/error/403.component";
import { P404Component } from "@views/error/404.component";
import { P500Component } from "@views/error/500.component";
import { ForgotPasswordComponent } from "@views/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "@views/reset-password/reset-password.component";
import { OtpVerificationComponent } from "@views/otp-verification/otp-verification.component";
import { ServicesRequesterTypeComponent } from "@views/requester-registration/services-requester-type/services-requester-type.component";
import { AdminLayoutComponent } from "@containers/admin-layout";
import { InputWithSpanLabelDirective } from "@directives/input-with-span-label.directive";
import { PaymentCommonStatusComponent } from "@views/payment-common-status/payment-common-status.component";
import { SupportComponent } from "@views/support/support.component";
import { PaymentInterceptComponent } from "@views/payment-intercept/payment-intercept.component";
import { AuthGuard } from "app/_guards/auth.guard";
import { JwtInterceptor } from "@helpers/jwt.interceptor";
import { ErrorInterceptor } from "@helpers/error.interceptor";
import { BaseServiceInjector } from "@components/base.injector";
import { HttpLoaderFactory, SharedModule } from "@module/shared.module";

import { NgxSpinnerModule } from "ngx-spinner";
import { environment } from "../environments/environment";
import { SpinnerModule } from "./containers";

const APP_CONTAINERS = [DefaultLayoutComponent];

const BASE_MODULES = [CommonModule, FormsModule, ReactiveFormsModule];

@NgModule({
  imports: [
    BrowserModule,
    ...BASE_MODULES,
    HttpClientModule,
    AppRoutingModule,
    AppAsideModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    TabsModule.forRoot(),
    ChartsModule,
    ModalModule.forRoot(),
    NgbModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      closeButton: true,
      preventDuplicates: true,
    }), // ToastrModule added
    TreeviewModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    SpinnerModule,
    NgxDatatableModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SharedModule,
    OrderHistoryModule,
    NgSelectModule,
    NgxSpinnerModule,
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P403Component,
    P404Component,
    P500Component,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    OtpVerificationComponent,
    ServicesRequesterTypeComponent,
    AdminLayoutComponent,
    InputWithSpanLabelDirective,
    PaymentCommonStatusComponent,
    SupportComponent,
    PaymentInterceptComponent,
  ],
  entryComponents: [],
  providers: [
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector) {
    // Create global Service Injector.
    BaseServiceInjector.injector = this.injector;
  }
}
