import { Component, OnInit } from "@angular/core";
import { first } from "rxjs/operators";
import {
  OrderHistoryContractSerachFieldList,
  OrderHistoryProviderSerachFieldList,
  OrderHistoryRequesterSerachFieldList,
  SectionType,
} from "@config/app-constants";
import { StatusData } from "@model/adminDirectOrders";
import { IGoodsType, IGoodsTypeResponse } from "@model/adminPricing";
import { ITruckData } from "@model/contract";
import { IStatusItem } from "@model/directOrdersDetails";
import { IErrorResponse } from "@model/errorResponse";
import {
  IOrderHistoryData,
  IOrderHistoryPayload,
  IOrderHistoryResponse,
} from "@model/order-history";
import {
  IShipmentType,
  IShipmentTypeResponse,
  ITrucksTypeResponse,
} from "@model/truck";
import {
  OrderFilterKey,
  orderTypes,
  redirectionKeys,
  shahenOrder,
  shipmentId,
} from "@utils/enum-const";
import { BaseComponent } from "@components/base.component";
import { AutoUnsubscribe } from "@decorator/autounsubscribe";
import { CommonApiService } from "@services/common-api.service";
import { OrderHistoryService } from "@services/order-history.service";
import { ActivatedRoute } from "@angular/router";
import { ICustomMouseEvent, IPaginationPayload } from "@model/common";
@Component({
  selector: "app-order-history",
  templateUrl: "./order-history.component.html",
  styleUrls: ["./order-history.component.scss"],
})
@AutoUnsubscribe()
export class OrderHistoryComponent extends BaseComponent implements OnInit {
  OrderFilterKey = OrderFilterKey;
  optionsWithFeatures: any;
  filter = this.defaultOrderHistoryFilter;
  offset = 0;
  text = "";
  submitted = false;
  truckId!: number;
  changeStatusType!: string;
  orderHistorySearchFields = OrderHistoryRequesterSerachFieldList;
  orderHistoryList: IOrderHistoryData[] = [];
  goodsTypeList: IGoodsType[] = [];
  shipmentTypeList: IShipmentType[] = [];
  truckTypesList: ITruckData[] = [];
  orderHistorystatusList: IStatusItem[] = [];
  orderTypes = orderTypes;
  shahenOrder = shahenOrder;
  isContract = false;
  orderRoutes: any;
  constructor(
    private orderService: OrderHistoryService,
    private commonService: CommonApiService,
    private route: ActivatedRoute
  ) {
    super();
  }

  /**
   * Handle init function call
   */
  ngOnInit() {
    if (this.router.url.includes("contracts")) {
      this.orderRoutes = this.orderType.membership;
      this.orderHistorySearchFields = OrderHistoryContractSerachFieldList;
    } else if (this.router.url.includes("offer")) {
      this.orderRoutes = this.orderType.offer_order;
      this.searchFieldlist();
    } else {
      this.route.paramMap.subscribe(() => {
        const statusFilter = history?.state;
        if (statusFilter?.filterStatus) {
          this.filter.p_type = statusFilter?.filterStatus;
        }
      });
      this.orderRoutes = this.orderType.order;
      this.searchFieldlist();
    }

    this.size = this.filterService.getSingleState(
      "orderHistorySize",
      this.size
    ) as number;
    this.getOrderHistory();
    this.getStatus();
    this.getGoodTypes();
    this.getTruckTypes();
  }

  /**
   * Search field list
   */
  searchFieldlist() {
    this.orderHistorySearchFields = this.isServiceProviderRoute
      ? OrderHistoryProviderSerachFieldList
      : OrderHistoryRequesterSerachFieldList;
  }

  /**
   * Handle get goods types
   */
  getGoodTypes() {
    this.commonService
      .getGoodsTypesList()
      .pipe(first())
      .subscribe(
        (response: IGoodsTypeResponse) => {
          if (response.status_code === this.statusCode.OK) {
            this.goodsTypeList = response?.data?.data;
          }
        },
        (error: IErrorResponse) => {
          this.errorHandler(this.toastr, this.translateService, error);
        }
      );
  }

  /**
   * Handle get status
   */
  getStatus() {
    const typePayload = {
      type: this.isServiceProviderRoute
        ? SectionType.SERVICE_PROVIDER
        : SectionType.SERVICE_REQUESTER,
      section: this.router.url.includes("contracts")
        ? SectionType.MEMBERSHIP
        : SectionType.ALL,
    };
    this.commonService
      .getStatusFilterList(typePayload)
      .pipe(first())
      .subscribe(
        (resp: StatusData) => {
          if (resp.status === true) {
            this.orderHistorystatusList = resp.data;
          }
        },
        (error: IErrorResponse) => {
          this.errorHandler(this.toastr, this.translateService, error);
        }
      );
  }

  /**
   * Handle get shipment types
   */
  getShipmentTypes() {
    this.commonService
      .getShipmentTypes()
      .pipe(first())
      .subscribe((response: IShipmentTypeResponse) => {
        if (response.status_code === this.statusCode.OK) {
          this.shipmentTypeList = response?.data?.data;
        }
      });
  }

  /**
   * Handle get truck types
   */
  getTruckTypes() {
    this.commonService
      .getTrucks(shipmentId.SHIPMENT_ID)
      .pipe(first())
      .subscribe(
        (response: ITrucksTypeResponse) => {
          if (response.status_code === this.statusCode.OK) {
            this.truckTypesList = response?.data?.data;
          }
        },
        (error: IErrorResponse) => {
          this.errorHandler(this.toastr, this.translateService, error);
        }
      );
  }

  /**
   * Populate the table with new data based on the page number
   * @param page The page to select
   */
  public setPage(pageInfo: IPaginationPayload) {
    this.pageNumber = pageInfo.offset;
    this.rerender(false);
  }

  /**
   * Show number of records in datatable on Limit Change
   * @param value show total entries value 10,25,50,100
   */
  public changeOrderListingLimit(value: number) {
    this.size = value;
    this.rerender(true);
  }

  /**
   * Get orderHistory Listing
   */
  private getOrderHistory() {
    this.loadingIndicator = true;
    this.spinner.showLoader();
    this.saveFilterState();

    const requestPayload = this.historyPayload();

    if (this.isServiceProviderRoute) {
      this.handleServiceProviderRoute(requestPayload);
    } else if (this.isServiceRequesterRoute) {
      this.handleServiceRequesterRoute(requestPayload);
    }
  }

  /**
   * Handle save filter state
   */
  private saveFilterState() {
    const stateMapping = {
      orderHistorySortParam: this.sortParam,
      orderHistorySortOrder: this.sortOrder,
      orderHistoryPageNo: this.pageNumber,
      orderHistorySize: this.size,
    };

    for (const [key, value] of Object.entries(stateMapping)) {
      this.filterService.saveSingleState(key, value.toString());
    }
  }

  /**
   * Handle history payload
   */
  private historyPayload() {
    const selectedField = this.filter.fields;
    const searchKey = selectedField ? [selectedField] : "search_text";

    return {
      order_id: "",
      trip_id: "",
      truck_no: "",
      from_location: "",
      to_location: "",
      p_type: "",
      order_type: "",
      goods_type: "",
      truck_type: "",
      shipment_type: "",
      date_from: this.filter.date_from
        ? this.formatDateForFilterPayload(this.filter.date_from)
        : this.filter.date_from,
      date_to: this.filter.date_to
        ? this.formatDateForFilterPayload(this.filter.date_to)
        : this.filter.date_to,
      per_page: Number(this.size),
      page: Number(this.pageNumber) + 1,
      section: this.router.url.includes("contracts")
        ? this.OrderFilterKey.membership
        : SectionType.ALL,
      [searchKey as string]: this.filter.search_txt,
    };
  }

  /**
   * Handle service provider route
   */
  private handleServiceProviderRoute(requestPayload: IOrderHistoryPayload) {
    if (this.isContract) {
      this.handleContractOrderHistory(requestPayload);
    } else {
      this.handleRegularOrderHistory(requestPayload);
    }
  }

  /**
   * Handle contract order history
   */
  private handleContractOrderHistory(requestPayload: IOrderHistoryPayload) {
    requestPayload = {
      ...requestPayload,
      goods_type: this.filter.goods_type ?? "",
      shipment_type: this.filter.shipment_type,
      truck_type: this.filter.truck_type ?? "",
      order_type: this.filter.order_type,
      section: this.OrderFilterKey.membership,
      p_type: this.filter.p_type ?? "",
    };

    this.orderService
      .getContractOrderHistory(requestPayload)
      .pipe(first())
      .subscribe(
        (resp: IOrderHistoryResponse) => this.handleOrderHistoryResponse(resp),
        (error: IErrorResponse) => this.handleError(error)
      );
  }

  /**
   * Handle order history
   */
  private handleRegularOrderHistory(requestPayload: IOrderHistoryPayload) {
    requestPayload = {
      ...requestPayload,
      p_type: (!this.filter.p_type && this.router.url.includes("order-history")
        ? "history"
        : this.filter.p_type) as string,
    };

    this.orderService
      .getOrderHistory(requestPayload)
      .pipe(first())
      .subscribe(
        (resp: IOrderHistoryResponse) => this.handleOrderHistoryResponse(resp),
        (error: IErrorResponse) => this.handleError(error)
      );
  }

  /**
   * Handle service requester route
   */
  private handleServiceRequesterRoute(requestPayload: IOrderHistoryPayload) {
    requestPayload = {
      ...requestPayload,
      p_type: this.filter.p_type ?? "",
    };
    this.orderService
      .getOrderHistoryForRequester(requestPayload)
      .pipe(first())
      .subscribe(
        (resp: IOrderHistoryResponse) => this.handleOrderHistoryResponse(resp),
        (error: IErrorResponse) => this.handleError(error)
      );
  }

  /**
   * Handle order history response
   */
  private handleOrderHistoryResponse(resp: IOrderHistoryResponse) {
    if (resp.status_code === this.statusCode.OK) {
      this.loadingIndicator = false;
      this.spinner.hideLoader();
      this.orderHistoryList = resp.data.data;
      this.updateOrderHistoryList();
      this.totalReords = resp.data.total;
    }
  }

  /**
   * Handle update history list
   */
  private updateOrderHistoryList() {
    this.orderHistoryList.forEach((e: any) => {
      if (
        (this.isMembershipOrder(e) &&
          e.shahen_order === 1 &&
          e.providers.length > 0) ||
        this.isOrderSpecial(e)
      ) {
        e.quantity = e.providers.filter(
          (m: { provider_id: number | null }) => m.provider_id === this.userId
        )[0]?.quantity;
        e.price = e.providers.filter(
          (m: { provider_id: number | null }) => m.provider_id === this.userId
        )[0]?.price;
        e.sr_price_special_order = e.providers.filter(
          (m: { provider_id: number | null }) => m.provider_id === this.userId
        )[0]?.price;
      }
    });
  }

  /**
   * Handle check is membership order
   */
  private isMembershipOrder(order: { section: orderTypes }): boolean {
    return this.orderTypes.MEMBERSHIP === order.section;
  }

  private isOrderSpecial(order: { section: orderTypes }): boolean {
    return this.orderTypes.SPECIAL_ORDER === order.section;
  }

  /**
   * Handle listing error
   */
  private handleError(error: IErrorResponse) {
    this.errorHandler(this.toastr, this.translateService, error);
    this.loadingIndicator = false;
    this.spinner.hideLoader();
  }

  /**
   * Handle close modal
   */
  decline() {
    this.modalRef.hide();
  }

  /**
   * Handle search apply
   */
  public searchApply() {
    this.rerender(true);
  }

  /**
   * API call and refresh datatable value
   * @param goFirstPage set first page when param value true
   */
  private rerender(goFirstPage: boolean, isDelete = false) {
    if (goFirstPage) {
      this.pageNumber = 0;
    }
    // Pagination is lost and the panel moves back to the 1st page instead of staying on the same page when delete record
    if (isDelete && this.pageNumber && this.orderHistoryList.length === 1) {
      this.pageNumber = this.pageNumber - 1;
    }
    this.getOrderHistory();
  }

  /**
   * Get Default order Filter
   */
  get defaultOrderHistoryFilter() {
    this.pageNumber = 0;
    return {
      search_txt: "",
      fields: "order_id",
      p_type: this.router.url.includes("new")
        ? SectionType.waiting_for_approval.toString()
        : null,
      order_type: "",
      goods_type: null,
      truck_type: null,
      shipment_type: "",
      date_from: "",
      date_to: "",
    };
  }

  /**
   * Handle filter apply
   */
  filterApply() {
    this.rerender(true);
  }

  /**
   * Handle reset order history
   */
  resetOrderHistoryFilter() {
    this.filter = this.defaultOrderHistoryFilter;
    this.rerender(true);
  }

  /**
   * Handle on click event
   */
  onActivate(event: ICustomMouseEvent) {
    let redirectUrl = "";
    if (event.type === "click") {
      if (
        this.isServiceProviderRoute &&
        this.orderRoutes === this.orderType.membership
      ) {
        redirectUrl =
          "/provider/contracts/order-history/order-details/" +
          event.row.order_id;
      } else if (
        this.isServiceProviderRoute &&
        this.orderRoutes === this.orderType.order
      ) {
        redirectUrl =
          "/provider/order-history/order-details/" + event.row.order_id;
      } else if (
        this.isServiceProviderRoute &&
        this.orderRoutes === this.orderType.offer_order
      ) {
        redirectUrl = "/provider/order-history/" + event.row.order_id;
      } else if (
        this.isServiceRequesterRoute &&
        this.orderRoutes === this.orderType.membership
      ) {
        redirectUrl =
          "/provider/my-contracts/order-history/order-details/" +
          event.row.order_id;
      } else if (
        this.isServiceRequesterRoute &&
        this.orderRoutes === this.orderType.offer_order
      ) {
        redirectUrl = "/provider/order-history/" + event.row.order_id;
      } else {
        redirectUrl =
          "/requester/order-history/order-details/" + event.row.order_id;
      }
      window.open(
        redirectUrl,
        event?.column?.prop === redirectionKeys.ORDER_ID ? "_blank" : "_self"
      );
    }
  }
}
