<header>
  <div class="icon-area">
    <i class="icon-truck"></i>
  </div>
  <h1>{{ "TRUCK_ASSIGNMENT" | translate }}</h1>
  <p class="header-data"></p>
  <p class="header-data" *ngIf="this.isServiceRequesterRoute">
    {{ "SELECTED_SERVIE_PROVIDER_CAN_ONLY_PROVIDE" | translate }}
    {{ this.vehicle_count }}
    {{
      (this.vehicle_count === 1 ? "TRUCK_NAME_SMALL" : "TRUCKS_NAME_SMALL")
        | translate
    }}
    {{ "OUT_OF" | translate }}
    {{ this.truck_quantity }}
    {{
      (this.truck_quantity === 1 ? "TRUCK_NAME_SMALL" : "TRUCKS_NAME_SMALL")
        | translate
    }}
  </p>
</header>
<div class="popup-body-part">
  <div class="truck-info">
    <div class="requested-trucks">
      <span class="title">{{ "REQUESTED" | translate }}</span>
      <span class="data"
        >:<span
          >{{ this.truck_quantity }}
          {{
            (this.truck_quantity === 1 ? "TRUCK_NAME" : "TRUCKS_NAME")
              | translate
          }}
        </span></span
      >
    </div>
    <div class="available-trucks">
      <div class="data-wrapper d-flex">
        <span class="title">{{ "AVAILABLE" | translate }}</span>
        <span class="data"
          >:
          <span *ngIf="isServiceRequesterRoute">
            {{ this.vehicle_count }}
            {{
              (this.vehicle_count === 1 ? "TRUCK_NAME" : "TRUCKS_NAME")
                | translate
            }}
          </span>
          <div class="form-group" *ngIf="isServiceProviderRoute">
            <div class="counter-box">
              <input
                [disabled]="!this.isIncrementDisable"
                type="text"
                name="counter"
                [dir]="isEnglish ? 'ltr' : 'rtl'"
                id="counter-input"
                [value]="vehicle_count"
                class="counter-input"
                maxlength="2"
                (keydown)="(false)"
                (paste)="(false)"
                appNumberOnly
              />
              <a
                (click)="changeVehicleCount(this.oprations.MINUS)"
                class="btn btn-small btn-minus"
                role="button"
                ><i class="icon-minus"></i
              ></a>
              <a
                (click)="changeVehicleCount(this.oprations.ADD)"
                class="btn btn-small btn-plus"
                role="button"
                ><i class="icon-Plus"></i
              ></a>
            </div>
          </div>
        </span>
      </div>
      <div
        class="form-control-feedback text-danger"
        *ngIf="this.isInvalidQuantity"
      >
        <span>
          {{ "INVALID_QUANITY_SELECTION" | translate }}
        </span>
      </div>
      <div
        class="form-control-feedback text-danger"
        *ngIf="this.submitted && this.vehicle_count === 0"
      >
        <span>
          {{ "TRUCK_QUANTITY_NOT_ZERO" | translate }}
        </span>
      </div>
    </div>
  </div>
</div>
<footer>
  <button class="btn bordered-btn" (click)="closePopup()">
    {{ "CANCEL" | translate }}
  </button>
  <button
    class="btn btn-secondary"
    [disabled]="this.isInvalidQuantity && this.isServiceProviderRoute"
    (click)="
      isServiceRequesterRoute ? acceptOrderVehicles() : getVehicleCountData()
    "
  >
    {{ "CONFIRM" | translate }}
  </button>
</footer>
