import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { NgModule } from "@angular/core";
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
  TranslatePipe,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { MapMultipleMarkersComponent } from "@views/multiple-markers-map/multiple-markers-map.component";
import { ConfirmationPopupModalComponent } from "@views/popup/confimation-popup-modal/confimation-popup-modal.component";
import { DeleteRecordConfirmationModalComponent } from "@views/popup/delete-record-confirmation-modal/delete-record-confirmation-modal.component";
import { SpinnerModule } from "../containers";
import { AdminPermissionsComponent } from "../views/admin/admin-permissions/admin-permissions.component";
import { AdminResetPasswordLinkComponent } from "../views/admin/admin-reset-password-link/admin-reset-password-link.component";
import { ChangeStatusComponent } from "../views/change-status/change-status.component";
import { HandleDeleteComponent } from "../views/handle-delete/handle-delete.component";
import { HandleStatusComponent } from "../views/handle-status/handle-status.component";
import { LanguageSelectionComponent } from "../views/language-selection/language-selection.component";
import { LeftRegisterPanelComponent } from "../views/left-register-panel/left-register-panel.component";
import { LogoutComponent } from "../views/logout/logout.component";
import { MapAddressDetailsComponent } from "../views/map-address-details/map-address-details.component";
import { MapDetailsContractDirectOrdersComponent } from "../views/map-details-contract-direct-orders/map-details-contract-direct-orders.component";
import { TruckAssignmentPopupComponent } from "../views/popup/truck-assignment/truck-assignment.component";
import { PrivacyPolicyComponent } from "../views/privacy-policy/privacy-policy.component";
import { RightRegisterPanelComponent } from "../views/right-register-panel/right-register-panel.component";
import { SelectProviderComponent } from "../views/select-provider/select-provider.component";
import { TermsAndConditionComponent } from "../views/terms-and-condition/terms-and-condition.component";
import { BaseComponent } from "../_components/base.component";
import { ApplettersonlyDirective } from "../_directives/applettersonly.directive";
import { CaretRotationDirective } from "../_directives/caretRotation";
import { CircularProgressDirective } from "../_directives/circular-progress";
import { DivCloseOnClickDirective } from "../_directives/div-close-on-click.directive";
import { DropdownDirective } from "../_directives/dropdown.directive";
import { FloatingLabelInputDirective } from "../_directives/floating-label-input.directive";
import { InvalidFormScrollNGFormDirective } from "../_directives/invalid-form-scroll-ngform.directive";
import { NumberOnlyDirective } from "../_directives/number-only.directive";
import { MyMissingTranslationHandler } from "../_helpers/translator";
import { CustomDate } from "../_pipe/customDate.pipe";
import { DateMonthFormatPipe } from "../_pipe/date-month-format.pipe";
import { NewlinesToBrPipe } from "../_pipe/newlinesToBr.pipe";
import { SpaceRemoveDirective } from "./../_directives/space-remove.directive";

@NgModule({
  declarations: [
    BaseComponent,
    CustomDate,
    NumberOnlyDirective,
    DropdownDirective,
    InvalidFormScrollNGFormDirective,
    SpaceRemoveDirective,
    CircularProgressDirective,
    ApplettersonlyDirective,
    FloatingLabelInputDirective,
    CaretRotationDirective,
    DivCloseOnClickDirective,
    DateMonthFormatPipe,
    BaseComponent,
    CustomDate,
    MapAddressDetailsComponent,
    LeftRegisterPanelComponent,
    RightRegisterPanelComponent,
    LanguageSelectionComponent,
    ChangeStatusComponent,
    HandleStatusComponent,
    HandleDeleteComponent,
    LogoutComponent,
    TermsAndConditionComponent,
    PrivacyPolicyComponent,
    AdminPermissionsComponent,
    AdminResetPasswordLinkComponent,
    SelectProviderComponent,
    TruckAssignmentPopupComponent,
    MapDetailsContractDirectOrdersComponent,
    MapMultipleMarkersComponent,
    NewlinesToBrPipe,
    ConfirmationPopupModalComponent,
    DeleteRecordConfirmationModalComponent,
  ],
  imports: [
    CommonModule,
    SpinnerModule,
    TranslateModule.forChild({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler,
      },
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [
    CustomDate,
    TranslatePipe,
    NumberOnlyDirective,
    DropdownDirective,
    InvalidFormScrollNGFormDirective,
    SpaceRemoveDirective,
    MapAddressDetailsComponent,
    LeftRegisterPanelComponent,
    RightRegisterPanelComponent,
    LanguageSelectionComponent,
    ChangeStatusComponent,
    HandleStatusComponent,
    HandleDeleteComponent,
    LogoutComponent,
    TermsAndConditionComponent,
    PrivacyPolicyComponent,
    AdminPermissionsComponent,
    AdminResetPasswordLinkComponent,
    CircularProgressDirective,
    SelectProviderComponent,
    TruckAssignmentPopupComponent,
    ApplettersonlyDirective,
    FloatingLabelInputDirective,
    CaretRotationDirective,
    DateMonthFormatPipe,
    DivCloseOnClickDirective,
    MapDetailsContractDirectOrdersComponent,
    MapMultipleMarkersComponent,
    NewlinesToBrPipe,
    ConfirmationPopupModalComponent,
    DeleteRecordConfirmationModalComponent,
  ],
})
export class SharedModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
