import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { CONFIG } from "@config/app-config";
import {
  DirectOrderApiResponse,
  DirectOrdersFilterPayloadOptions,
  DirectOrdersSPSRFilterPayloadOptions,
  DownloadFileResponse,
  ISelectDropdownResponseData,
  ISuccessDirectOrderSave,
} from "@model/adminDirectOrders";
import {
  IOrderDetailData,
  IProviderResponse,
  IServiceOrderProvider,
  OrderFilter,
} from "@model/directOrdersDetails";
import { UserRole } from "@utils/enum-const";
import { CommonService } from "@services/common.service";
import { IFileTypePayload } from "@model/common";

@Injectable({
  providedIn: "root",
})
export class DirectOrdersService {
  constructor(private http: HttpClient, private commonService: CommonService) {
    this.userType = this.commonService.getLoginUserType();
  }
  userType = "";

  /**
   * Get direct order listing
   * @requesterData - DirectOrdersFilterPayloadOptions Get direct order listing payload
   */
  fetchDirectOrdersList(requesterData: DirectOrdersFilterPayloadOptions) {
    let getDirectOrderURL: string;

    if (this.userType === UserRole.SUPER_ADMIN_USER) {
      getDirectOrderURL = CONFIG.getAdminDirectOrdersList;
    } else if (this.userType === UserRole.SERVICE_PROVIDER) {
      getDirectOrderURL = CONFIG.getProviderDirectOrdersList;
    } else {
      getDirectOrderURL = CONFIG.getRequesterDirectOrdersList;
    }
    return this.http
      .post<DirectOrderApiResponse>(getDirectOrderURL, requesterData)
      .pipe(
        map((response: DirectOrderApiResponse) => {
          return response;
        })
      );
  }

  /**
   * Get admin direct order listing
   * @requesterData - DirectOrdersSPSRFilterPayloadOptions Get admin direct order payload
   */
  fetchDirectOrdersAdminList(
    requesterData: DirectOrdersSPSRFilterPayloadOptions
  ) {
    let getDirectOrderURL;

    if (this.userType === UserRole.SUPER_ADMIN_USER) {
      getDirectOrderURL = CONFIG.getAdminDirectOrdersList;
    } else if (this.userType === UserRole.SERVICE_PROVIDER) {
      getDirectOrderURL = CONFIG.getProviderDirectOrdersList;
    } else {
      getDirectOrderURL = CONFIG.getRequesterDirectOrdersList;
    }

    return this.http
      .post<DirectOrderApiResponse>(getDirectOrderURL, requesterData)
      .pipe(
        map((response: DirectOrderApiResponse) => {
          return response;
        })
      );
  }

  /**
   * Get direct order details
   * @orderId - Direct order details - OrderID
   */
  getOrderDetails(orderId: number) {
    let getDirectOrderURL: string;

    if (this.userType === UserRole.SUPER_ADMIN_USER) {
      getDirectOrderURL = CONFIG.getAdminDirectOrderDetials;
    } else if (this.userType === UserRole.SERVICE_PROVIDER) {
      getDirectOrderURL = CONFIG.getProviderDirectOrderDetials;
    } else {
      getDirectOrderURL = CONFIG.getRequesterDirectOrderDetials;
    }

    return this.http.get<IOrderDetailData>(getDirectOrderURL + orderId).pipe(
      map((response: IOrderDetailData) => {
        return response;
      })
    );
  }

  /**
   * Get shipment types
   */
  getShipmentTypes() {
    return this.http
      .get<ISelectDropdownResponseData>(CONFIG.getShipmentTypeURL)
      .pipe(
        map((response: ISelectDropdownResponseData) => {
          return response;
        })
      );
  }

  /**
   * Save order data
   * @orderPayload - Order payload
   */
  saveOrderData(orderPayload: OrderFilter) {
    return this.http
      .post<ISuccessDirectOrderSave>(CONFIG.saveDirectOrderURL, orderPayload)
      .pipe(
        map((response: ISuccessDirectOrderSave) => {
          return response;
        })
      );
  }

  /**
   * Get provider listing
   * @providerPayload - provider payload
   */
  getProviderList(providerPayload: IServiceOrderProvider) {
    return this.http
      .post<IProviderResponse>(CONFIG.getProviderListURL, providerPayload)
      .pipe(
        map((response: IProviderResponse) => {
          return response;
        })
      );
  }

  /**
   * Get download bayan bill
   */
  downloadBayanWaybill(tripId: number, fileTypePayload: IFileTypePayload) {
    return this.http
      .post<DownloadFileResponse>(
        CONFIG.bayanWaybillDownloadURL + tripId,
        fileTypePayload
      )
      .pipe(
        map((response: DownloadFileResponse) => {
          return response;
        })
      );
  }
}
