import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { CONFIG } from "@config/app-config";
import {
  DesignationResponse,
  IGetSubAdminData,
  ISubAdminListPayload,
  ISubAdminPayload,
  PermissionData,
  Permissions,
  SubAdminDataResponse,
} from "@model/adminManager";
import { ISuccessResponse } from "@model/successResponse";
import { IResetPassword } from "@model/common";

@Injectable({
  providedIn: "root",
})
export class AdminMangerService {
  constructor(private http: HttpClient) {}

  /**
   * Get admin data
   * @data get subadmin data
   */
  getSubAdminData(data: ISubAdminListPayload) {
    return this.http
      .post<SubAdminDataResponse>(CONFIG.getSubAdminList, data)
      .pipe(
        map((response: SubAdminDataResponse) => {
          return response;
        })
      );
  }

  /**
   * Delete subadmin data
   * @subAdminId Subadmin ID
   */
  deleteSubAdminData(subAdminId: number) {
    return this.http
      .get<ISuccessResponse>(CONFIG.deleteSubAdmin + subAdminId)
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }

  /**
   * Get designation data
   */
  getDesignationData() {
    return this.http.get<DesignationResponse>(CONFIG.getDesignationList).pipe(
      map((response: DesignationResponse) => {
        return response;
      })
    );
  }

  /**
   * Register subadmin payloads
   * @subAdminData Subadmin register payload
   */
  registerSubAdmin(subAdminData: ISubAdminPayload) {
    return this.http
      .post<ISuccessResponse>(CONFIG.registerSubAdmin, subAdminData)
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }

  /**
   * Subadmin details get
   * @subAdminId Subadmin details
   */
  readSubAdminDetails(subAdminId: number) {
    return this.http
      .get<IGetSubAdminData>(CONFIG.readSubAdminDetails + subAdminId)
      .pipe(
        map((response: IGetSubAdminData) => {
          return response;
        })
      );
  }

  /**
   * Subadmin details update
   * @subAdminPayload Subadmin details
   * @id Subadmin ID
   */
  updateSubAdmin(subAdminPayload: ISubAdminPayload, id: number) {
    return this.http
      .patch<ISuccessResponse>(
        CONFIG.updateSubAdminDetails + id,
        subAdminPayload
      )
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }

  /**
   * Handle reset password link
   * @dataPayload Reset password link
   */
  resetPasswordLink(dataPayload: IResetPassword) {
    return this.http
      .post<ISuccessResponse>(CONFIG.resetPasswordLink, dataPayload)
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }

  /**
   * Fetch all subadmin permissions
   */
  fetchSubAdminPermissions() {
    return this.http
      .get<Permissions<PermissionData>>(CONFIG.getAdminPermissions)
      .pipe(
        map((response: Permissions<PermissionData>) => {
          return response;
        })
      );
  }
}
