<div class="d-flex flex-col">
  <div class="modal-header">
    <h2>{{ "ADD_CONTAINER_DETAILS" | translate }}</h2>
    <div class="trip-info">
      <span>{{ "TRIP_ID" | translate }}:</span
      ><strong> {{ selectedTrip?.bt_id }}</strong>
      <span class="divider">|</span>
      <span>{{ "TRUCK" | translate }} #:</span
      ><strong> {{ selectedTrip?.vehicle?.full_plate_number }}</strong>
    </div>
  </div>
  <div class="no-data-found" *ngIf="model.length === 0">
    <span>{{ "NO_CONTAINERS_FOUND" | translate }}</span>
  </div>
  <div class="enable-scroll-container">
    <app-container
      class="container-edit-section"
      *ngFor="let item of this.model; index as i"
    >
      <div class="row">
        <div class="input-box">
          <div
            class="form-group"
            [ngClass]="model[i].container_number ? 'floatingLabel' : ''"
          >
            <span>
              <input
                type="text"
                [dir]="isEnglish ? 'ltr' : 'rtl'"
                [id]="'container[' + i + ']'"
                [name]="'container[' + i + ']'"
                class="form-control"
                #container="ngModel"
                required
                [disabled]="!showAddEdit"
                [(ngModel)]="model[i].container_number"
                appFloatingLabelInput
                (input)="removeSpaces($event)"
              />
              <div
                class="form-control-feedback text-danger"
                *ngIf="container.errors && model[i].editSubmitted"
              >
                <span *ngIf="container.errors.required">
                  {{ "CONTAINER_NUMBER_REQUIRED" | translate }}
                </span>
              </div>
              <label class="floating-label">
                {{ "CONTAINER_NUMBER" | translate }}
              </label>
            </span>
          </div>
        </div>
        <div class="input-box no-edit-delete">
          <div
            class="form-group"
            [ngClass]="model[i].seal_number ? 'floatingLabel' : ''"
          >
            <span>
              <input
                type="text"
                [dir]="isEnglish ? 'ltr' : 'rtl'"
                [id]="'serial[' + i + ']'"
                [name]="'serial[' + i + ']'"
                class="form-control"
                [disabled]="!showAddEdit"
                #serial="ngModel"
                [(ngModel)]="model[i].seal_number"
                appFloatingLabelInput
                (input)="removeSpaces($event)"
              />
              <label class="floating-label">
                {{ "SEAL_NUMBER" | translate }}
              </label>
            </span>
          </div>
        </div>
        <div *ngIf="showAddEdit" class="buttons-box">
          <a
            *ngxPermissionsOnly="editPermission"
            title="{{ 'EDIT' | translate }}"
            class="icon-edit-box"
            (click)="editTripContainer(i)"
          >
            <i class="icon-edit"></i>
          </a>
          <ng-container *ngIf="!editMode">
            <a
              *ngxPermissionsOnly="deletePermission"
              title="{{ 'DELETE' | translate }}"
              class="icon-delete-button"
              (click)="deleteTripContainer(i)"
            >
              <i class="icon-delete"></i>
            </a>
          </ng-container>
        </div>
      </div>
    </app-container>
  </div>
  <div class="add-hide-show" *ngIf="!showAddSection">
    <div class="add-button" *ngxPermissionsOnly="addPermission">
      <button
        *ngIf="showAddEdit && !editMode"
        class="btn btn-small show-hide-button"
        (click)="handleShowAdd()"
      >
        +
      </button>
    </div>
    <button class="btn btn-small bordered-btn" (click)="closeModal()">
      {{ "CANCEL" | translate }}
    </button>
  </div>
  <ng-container *ngxPermissionsOnly="addPermission">
    <hr *ngIf="showAddEdit && showAddSection" />
  </ng-container>
  <ng-container *ngIf="showAddEdit && showAddSection">
    <div class="add-container" *ngxPermissionsOnly="addPermission">
      <div class="row">
        <div class="input-box">
          <div
            class="form-group"
            [ngClass]="addContainer ? 'floatingLabel' : ''"
          >
            <span>
              <input
                type="text"
                [dir]="isEnglish ? 'ltr' : 'rtl'"
                id="add-container"
                name="addContainer"
                class="form-control"
                #addContainerValue="ngModel"
                [pattern]="CommonRegx.valueNotNulllCheck"
                required
                [(ngModel)]="addContainer"
                appFloatingLabelInput
                (input)="removeSpaces($event)"
              />
              <div
                class="form-control-feedback text-danger"
                *ngIf="addContainerValue.errors && isAddSubmitted"
              >
                <span *ngIf="addContainerValue.errors.required">
                  {{ "CONTAINER_NUMBER_REQUIRED" | translate }}
                </span>
              </div>
              <label class="floating-label">
                {{ "CONTAINER_NUMBER" | translate }}
              </label>
            </span>
          </div>
        </div>
        <div class="input-box">
          <div class="form-group" [ngClass]="addSeal ? 'floatingLabel' : ''">
            <span>
              <input
                type="text"
                [dir]="isEnglish ? 'ltr' : 'rtl'"
                id="add-seal"
                name="addSeal"
                class="form-control"
                #addSealValue="ngModel"
                [(ngModel)]="addSeal"
                appFloatingLabelInput
                (input)="removeSpaces($event)"
              />
              <label class="floating-label">
                {{ "SEAL_NUMBER" | translate }}
              </label>
            </span>
          </div>
        </div>
      </div>
      <div class="buttons-section">
        <button class="btn btn-small bordered-btn" (click)="closeModal()">
          {{ "CANCEL" | translate }}
        </button>
        <button
          class="btn btn-small btn-secondary btn-download"
          (click)="addTripContainer()"
        >
          {{ "SAVE" | translate }}
        </button>
      </div>
    </div>
  </ng-container>
</div>
