<div class="d-flex btn-area">
  <ng-container>
    <!-- Show Approve and Reject buttons -->
    <a
      class="btn btn-approve bordered-btn btn-small"
      (click)="openModal(approveTemplate)"
    >
      {{
        isApproveButton ? ("APPROVE" | translate) : ("ACCEPT" | translate)
      }}</a
    >
    <a
      class="btn btn-reject btn-small"
      (click)="
        isReasonVisible || isDetailedReason
          ? openModal(rejectTemplate)
          : approveReject('reject')
      "
    >
      {{ "REJECT" | translate }}
    </a>
  </ng-container>
</div>

<ng-template #approveTemplate>
  <div class="modal-header bg-primary">
    <h3 class="modal-title d-flex align-center">
      <i class="fa fa-info-circle modal-icon"></i>
      {{ "CONFIRMATION" | translate }}
    </h3>
    <button type="button" class="close" (click)="decline()">
      <span aria-hidden="true"><i class="icon-close"></i></span>
    </button>
  </div>
  <div class="modal-body text-center">
    <p>{{ "CONFIRM_APPROVE_MSG" | translate }}</p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn bordered-btn btn-small"
      (click)="decline()"
    >
      {{ "NO" | translate }}
    </button>
    <button
      type="button"
      class="btn btn-warning btn-small"
      (click)="approveReject('approve')"
    >
      {{ "YES" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #rejectTemplate>
  <div class="modal-header reject-heading bg-primary">
    <h3 class="modal-title">
      {{ "PLEASE_SELECT_THE_REASON_FOR_REJECTION" | translate }}
    </h3>
  </div>
  <form
    #rejectForm="ngForm"
    (ngSubmit)="approveReject('reject', rejectForm)"
    novalidate
  >
    <div class="form-group" *ngIf="isReasonVisible">
      <div class="select-dropdown">
        <select
          class="form-control-select"
          id="selectedReason"
          name="selectedReason"
          required
          [(ngModel)]="selectedReason"
        >
          <option value="Account details" class="select-dropdown__list-item">{{
            "ACCOUNT_DETAILS" | translate
          }}</option>
          <option
            *ngFor="let reason of reasonForRejection"
            [value]="reason.name"
          >
            {{ reason.name }}
          </option>
        </select>
      </div>
      <i class="icon-arrow-down"></i>
      <label class="floating-label" for="selectedReason">{{
        "SELECT_REASON" | translate
      }}</label>
      <div
        *ngIf="
          rejectForm.submitted &&
          rejectForm.controls.selectedReason.errors?.required
        "
        class="text-danger"
      >
        {{ "PLEASE_SELECT_REASON" | translate }}
      </div>
    </div>
    <div class="form-group">
      <textarea
        class="form-control"
        id="detailedReason"
        name="detailedReason"
        [dir]="isEnglish ? 'ltr' : 'rtl'"
        rows="4"
        required
        [(ngModel)]="detailedReason"
      ></textarea>
      <label class="floating-label" for="detailedReason">{{
        "DETAILED_REASON" | translate
      }}</label>
      <div
        *ngIf="
          rejectForm.submitted &&
          rejectForm.controls.detailedReason.errors?.required
        "
        class="text-danger"
      >
        {{ "PROVIDE_DETAILED_REASON" | translate }}
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn bordered-btn btn-small"
        (click)="decline(rejectForm)"
      >
        {{ "CANCEL" | translate }}
      </button>
      <button type="submit" class="btn btn-warning btn-small">
        {{ "CONFIRM" | translate }}
      </button>
    </div>
  </form>
</ng-template>
<app-spinner></app-spinner>
