import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CONFIG } from "@config/app-config";
import { ILoginUser } from "@model/common";
import { ISuccessResponse } from "@model/successResponse";
import { ILoginSuccessResponse } from "@model/userRegistrationLogin";
import { EncrDecrService } from "@services/encr-decr.service";
import {
  languageStorage,
  requetserUserTypes,
  storageUserKeys,
  userDeviceToken,
  UserRole,
  userStorageKeys,
} from "@utils/enum-const";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  private providerLoggedInStatus = false;
  private requesterLoggedInStatus = false;
  private adminLoggedInStatus = false;
  private subAdminLoggedInStatus = false;

  constructor(private http: HttpClient, private EncrDecr: EncrDecrService) {}

  /**
   * Set requester logged in
   */
  setRequesterLoggedIn(value: boolean) {
    this.requesterLoggedInStatus = value;
  }

  /**
   * Set admin logged in
   */
  setAdminLoggedIN(value: boolean) {
    this.adminLoggedInStatus = value;
  }

  /**
   * Set provider logged in
   */
  setProviderLoggedIn(value: boolean) {
    this.providerLoggedInStatus = value;
  }

  /**
   * Set sub admin logged in
   */
  setSubAdminLoggedIn(value: boolean) {
    this.subAdminLoggedInStatus = value;
  }

  /**
   * Check provider logged in
   */
  get isProviderLoggedIn() {
    return this.providerLoggedInStatus;
  }

  /**
   * Check requester logged in
   */
  get isRequesterLoggedIn() {
    return this.requesterLoggedInStatus;
  }

  /**
   * Check adminn logged in
   */
  get isAdminLoggedIn() {
    return this.adminLoggedInStatus;
  }

  /**
   * Check sub admin logged in
   */
  get isSubAdminLoggedIn() {
    return this.subAdminLoggedInStatus;
  }

  /**
   * Login API call
   * @email string
   * @password string
   */
  login(
    email: string,
    password: string,
    route: string,
    platform: string,
    deviceToken: string
  ) {
    return this.http
      .post<ILoginSuccessResponse>(CONFIG.userAuthURL, {
        email,
        password,
        role: route,
        platform: platform,
        device_token: deviceToken,
      })
      .pipe(
        map((user: ILoginSuccessResponse) => {
          if (user.status === true) {
            const encrypted = this.EncrDecr.set(
              CONFIG.EncrDecrKey as string,
              user.data
            );
            const loginUser = this.createLoginUser();

            if (user.data.role === UserRole.SERVICE_REQUESTER) {
              this.handleServiceRequester(user, encrypted, loginUser);
            } else if (user.data.role === UserRole.SERVICE_PROVIDER) {
              this.handleServiceProvider(user, encrypted, loginUser);
            } else if (user.data.role === UserRole.SUPER_ADMIN_USER) {
              this.handleSuperAdmin(user, encrypted, loginUser);
            } else if (user.data.role === UserRole.ADMIN_USER) {
              this.handleAdminUser(user, encrypted, loginUser);
            }
          }
          return user;
        })
      );
  }

  /**
   * Create login user
   */
  private createLoginUser() {
    return {
      name: "Shahen",
      logo: "assets/images/common/profile.webp",
    };
  }

  /**
   * Handle service requester login response
   */
  private handleServiceRequester(
    user: ILoginSuccessResponse,
    encrypted: string,
    loginUser: ILoginUser
  ): void {
    const userKey = "currentUserRequester";
    localStorage.setItem(userKey, encrypted);

    let userName: string;
    if (user?.data?.user?.requester_type === requetserUserTypes.COMPANY) {
      userName = user?.data?.user?.service_requester?.company_name ?? "";
    } else {
      const firstName = user?.data?.user?.first_name ?? "";
      const lastName = user?.data?.user?.last_name ?? "";
      userName = `${firstName} ${lastName}`;
    }

    loginUser.name = userName;

    loginUser.logo =
      user?.data?.user?.requester_type === requetserUserTypes.COMPANY
        ? user?.data?.user?.service_requester?.logo_url
        : user?.data?.user?.avatar_url;

    localStorage.setItem(userStorageKeys.SR_INFO, JSON.stringify(loginUser));
  }

  /**
   * Handle service provider response
   */
  private handleServiceProvider(
    user: ILoginSuccessResponse,
    encrypted: string,
    loginUser: ILoginUser
  ): void {
    const userKey = "currentUserProvider";
    localStorage.setItem(userKey, encrypted);
    loginUser.name = user?.data?.user?.service_provider?.company_name;
    loginUser.logo = user?.data?.user?.service_provider?.logo_url;
    localStorage.setItem(userStorageKeys.SP_INFO, JSON.stringify(loginUser));
  }

  /**
   * Hanlde suprt admin login
   */
  private handleSuperAdmin(
    user: ILoginSuccessResponse,
    encrypted: string,
    loginUser: ILoginUser
  ): void {
    const adminKey = "currentAdmin";
    localStorage.setItem(adminKey, encrypted);
    loginUser.name =
      user?.data?.user?.first_name +
      " " +
      (user?.data?.user?.last_name ? user?.data?.user?.last_name : "");
    loginUser.logo = user?.data?.user?.avatar_url;
    localStorage.setItem(userStorageKeys.ADMIN_INFO, JSON.stringify(loginUser));
  }

  /**
   * Handle admin user
   */
  private handleAdminUser(
    user: ILoginSuccessResponse,
    encrypted: string,
    loginUser: ILoginUser
  ): void {
    const subAdminKey = "currentSubAdmin";
    localStorage.setItem(subAdminKey, encrypted);
    loginUser.name =
      user?.data?.user?.first_name +
      " " +
      (user?.data?.user?.last_name ? user?.data?.user?.last_name : "");
    loginUser.logo = user?.data?.user?.avatar_url;
    localStorage.setItem(
      userStorageKeys.SUB_ADMIN_INFO,
      JSON.stringify(loginUser)
    );
  }

  /**
   * Handle logout for all user based on login key
   * @loginKey string
   */
  logout(loginKey = "") {
    if (loginKey) {
      // remove user from local storage to log user out
      localStorage.removeItem(loginKey);
    } else {
      localStorage.removeItem(storageUserKeys.CURRENT_USER_PROVIDER);
      localStorage.removeItem(storageUserKeys.CURRENT_USER_REQUESTER);
      localStorage.removeItem(storageUserKeys.CURRENT_ADMIN_USER);
    }
    // localStorage.removeItem("languages");
    // For remove all module sorting, pagination and filter session
    sessionStorage.clear();
  }

  /**
   * Clear store and default language key
   */
  clearStorage(infoKey: string, langugage: string, deviceToken: string) {
    localStorage.removeItem(deviceToken);
    localStorage.removeItem(infoKey);
    localStorage.removeItem(langugage);
  }

  /**
   * Handle admin logout
   */
  adminLogout() {
    localStorage.removeItem("currentAdmin");
    localStorage.removeItem("currentSubAdmin");
    localStorage.removeItem(languageStorage.ADMIN_LAN);
    localStorage.removeItem(userDeviceToken.ADMIN_TOKEN);
    sessionStorage.clear();
  }

  /**
   * Handle account from login
   */
  logoutFromAccount(payload: { device_token: string }) {
    return this.http
      .post<ISuccessResponse>(CONFIG.logOutFromAccount, payload)
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }
}
