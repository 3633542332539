import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BaseComponent } from "@components/base.component";

@Component({
  selector: "app-admin-reset-password-link",
  templateUrl: "./admin-reset-password-link.component.html",
  styleUrls: ["./admin-reset-password-link.component.scss"],
})
export class AdminResetPasswordLinkComponent extends BaseComponent {
  @Input() userType!: string;
  @Input() email!: string;
  isFieldDisabled = false;

  @Output() resetPasswordLink: EventEmitter<{
    user: string;
    email: string;
  }> = new EventEmitter<{
    user: string;
    email: string;
  }>();

  /**
   * Handle reset password link
   */
  handleResetPasswordLink() {
    if (!this.isFieldDisabled) {
      const payload = {
        user: this.userType,
        email: this.email,
      };
      this.resetPasswordLink.emit(payload);
      this.isFieldDisabled = true;
    }
  }
}
