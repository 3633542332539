import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Subscription } from "rxjs";
import { first } from "rxjs/operators";
import { IErrorResponse } from "@model/errorResponse";
import { ISuccessResponse } from "@model/successResponse";
import {
  actionStatusList,
  adminTypes,
  fileUploadFormat,
  UserRole,
  userStorageKeys,
} from "@utils/enum-const";
import { BaseComponent } from "@components/base.component";
import { AutoUnsubscribe } from "@decorator/autounsubscribe";
import { FileUploadService } from "@services/file-upload.service";
import { ProfileService } from "@services/profile.service";
import { IProfileResponse, IUserModel } from "@model/profile";

@Component({
  selector: "app-admin-manager-profile",
  templateUrl: "./admin-manager-profile.component.html",
  styleUrls: ["./admin-manager-profile.component.scss"],
})
@AutoUnsubscribe()
export class AdminManagerProfileComponent extends BaseComponent
  implements OnInit {
  constructor(
    private profileService: ProfileService,
    private FileUpload: FileUploadService
  ) {
    super();
  }
  imageChangedEvent = "";
  isRemoveAPI = false;
  fileType!: string;
  editMode = false;
  submitted = false;
  url = this.noProfileImage as string;
  driverData!: Subscription;
  isAdmin = false;
  actionStatusValues = actionStatusList;
  routeSub!: Subscription;
  is_active: number = this.actionStatusValues.ACTIVE;
  fileUserType = UserRole;

  public model: IUserModel = {
    first_name: "",
    last_name: "",
    national_id: "",
    email: "",
    mobile: "",
    profile_photo: "",
    designation_id: "",
  };

  ngOnInit(): void {
    this.isAdmin = this.isAdminRoute;
    this.getAdminProfileDetails();
  }

  /**
   * Get admin profile details
   */
  private getAdminProfileDetails(isUpdate = false) {
    this.profileService
      .getProfileDetails()
      .pipe(first())
      .subscribe((responseData: IProfileResponse) => {
        const adminProfileData = responseData?.data.user;
        this.model.first_name = adminProfileData?.first_name || "";
        this.model.last_name = adminProfileData?.last_name ?? "";
        this.model.email = adminProfileData?.email || "";
        this.model.national_id = adminProfileData?.national_id || "";
        this.model.profile_photo = adminProfileData?.avatar;
        this.model.mobile = adminProfileData?.mobile || "";
        this.url = adminProfileData?.avatar_url || "";
        if (adminProfileData?.roles?.[0]) {
          this.model.designation_id =
            adminProfileData.roles[0].description === adminTypes.SUPER_ADMIN
              ? adminProfileData.roles[0].description
              : adminProfileData.designation.name || "";
        }
        if (isUpdate) {
          const loginUser = {
            name: this.model.first_name + " " + this.model.last_name,
            logo: adminProfileData?.avatar_url,
          };
          localStorage.setItem(
            userStorageKeys.ADMIN_INFO,
            JSON.stringify(loginUser)
          );
          const headerElement = document.querySelector("#headerName");
          if (headerElement) {
            headerElement.textContent = loginUser.name;
          }
          const profileLogo = document.getElementById("profileLogo");
          profileLogo?.setAttribute("src", loginUser.logo);
        }
      });
  }

  /**
   * Save profile data
   */
  public onSaveProfileData(form: NgForm) {
    const adminProfileDataPayload: IUserModel = {
      first_name: this.model.first_name || "",
      last_name: this.model.last_name || "",
      email: this.model.email || "",
      national_id: this.model.national_id || "",
      mobile: this.model.mobile || "",
      avatar: this.model.profile_photo ?? "",
    };

    this.submitted = true;
    // stop here if form is invalid
    if (form.invalid) {
      return;
    }
    this.spinner.showLoader();
    this.profileService
      .updateAdminProfile(adminProfileDataPayload)
      .pipe(first())
      .subscribe(
        (data: ISuccessResponse) => {
          this.spinner.hideLoader();
          if (data.status_code === this.statusCode.OK) {
            this.toastr.success(data.message);
            this.getAdminProfileDetails(true);
            this.router.navigate(["/admin/admin-manager/list"]);
          }
        },
        (error: IErrorResponse) => {
          this.spinner.hideLoader();
          this.errorHandler(this.toastr, this.translateService, error);
        }
      );
  }

  /* Change and Upload Image in User Module */
  async changeImage(event: Event, fieldName: keyof IUserModel) {
    try {
      this.spinner.showLoader();
      const uploadedFileResult = await this.FileUpload.uploadFile(
        event,
        fileUploadFormat.IMAGE,
        this.fileUserType.ADMIN_USER
      );
      if (uploadedFileResult) {
        this.model[fieldName] = (uploadedFileResult as {
          fileName: string;
        }).fileName;
        this.url = (uploadedFileResult as { filePath: string }).filePath;
      }
    } catch (error) {
      this.errorHandler(this.toastr, this.translateService, error);
    } finally {
      this.spinner.hideLoader();
    }
  }

  /**
   * Admin profile upload image
   */
  openAdminProfileUpload(fieldName: string) {
    document?.getElementById(fieldName)?.click();
  }
}
