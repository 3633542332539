import { Injectable } from "@angular/core";
import { languageTypes } from "@utils/enum-const";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  private subject = new Subject();

  languages = [];

  /**
   * Handle change language service
   */
  changeLanguage(lan: string) {
    localStorage.setItem("lan", lan);
    this.subject.next(lan);
  }

  /**
   * Handle get language service
   */
  getLanguage(): Observable<any> {
    return this.subject.asObservable();
  }

  /**
   * Set default language "AR"
   */
  DefaultLanguage() {
    const CurrentLanguage = localStorage.getItem("lan");
    const IsExist = false;
    return IsExist ? "en" : CurrentLanguage ?? "en";
  }

  /**
   * Set use base language
   */
  setUserTypeLanguage(userType: string, lan: string) {
    localStorage.setItem(userType, lan);
    this.subject.next(lan);
  }

  /**
   * Get user default language
   */
  getUserDefaultLanguage(userKey: string) {
    const CurrentLanguage = localStorage.getItem(userKey);
    return CurrentLanguage ?? languageTypes.DEFAULT;
  }
}
