<div class="body-part">
  <div class="top-area">
    <div class="top-left">
      <h1>
        {{ "PROFILE" | translate }}
      </h1>

      <span class="breadcrum">
        <a [routerLink]="['/admin/dashboard']"> {{ "HOME" | translate }}</a>
        {{ "PROFILE" | translate }}</span
      >
    </div>
  </div>
  <div class="content-area">
    <div class="order-list">
      <div class="add-driver-area">
        <div class="profile-left">
          <div class="form-group">
            <div class="file-upload" id="upload_logo">
              <input
                required
                type="file"
                name="profile_photo"
                [accept]="imageUploadConfig.ACCEPT"
                (change)="changeImage($event, 'profile_photo')"
                #profile_photo
                class="input-file"
                id="profile_photo"
                [dir]="isEnglish ? 'ltr' : 'rtl'"
              />
              <div class="icons">
                <i
                  class="icon-upload"
                  (click)="openAdminProfileUpload('profile_photo')"
                ></i>
              </div>
              <label tabindex="0" for="my-file" class="input-file-trigger">
                <div class="profile-wrapper">
                  <img
                    [src]="url"
                    (error)="onErrorProfileImage($event)"
                    alt=""
                  />
                </div>
              </label>
              <div
                class="form-control-feedback text-danger"
                *ngIf="!this.model.profile_photo && submitted"
              >
                <span *ngIf="!this.model.profile_photo">
                  {{ "UPLOAD_PROFILE_PHOTO" | translate }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="profile-right">
          <form
            novalidate
            #adminProfileUpdateForm="ngForm"
            InvalidFormScrollNGFormDirective
            (ngSubmit)="onSaveProfileData(adminProfileUpdateForm)"
          >
            <div class="row">
              <div class="d-col">
                <h3 class="profile-heading">
                  {{ "PROFILE" | translate }}
                </h3>
              </div>
              <div class="d-col d-col-2">
                <div
                  class="form-group"
                  [ngClass]="model.first_name ? 'floatingLabel' : ''"
                >
                  <span>
                    <input
                      appSpaceRemove
                      type="text"
                      [dir]="isEnglish ? 'ltr' : 'rtl'"
                      [(ngModel)]="model.first_name"
                      name="first_name"
                      class="form-control"
                      [pattern]="CommonRegx.lettersOnlyRegex"
                      required
                      id="first_name"
                      #first_name="ngModel"
                      maxlength="50"
                      placeholder=" "
                      appApplettersonly
                      appFloatingLabelInput
                    />
                    <div
                      class="form-control-feedback text-danger"
                      *ngIf="first_name.errors && submitted"
                    >
                      <span *ngIf="first_name.errors.required">
                        {{ "PLEASE_ENTER_FIRST_NAME" | translate }}
                      </span>
                      <span *ngIf="first_name.errors.pattern">
                        {{ "ENTER_VALID_FIRST_NAME" | translate }}
                      </span>
                      <span *ngIf="first_name.errors.maxlength">
                        {{ "ENTER_VALID_FIRST_NAME_LENGTH" | translate }}
                      </span>
                    </div>
                    <label class="floating-label">{{
                      "FIRST_NAME" | translate
                    }}</label>
                  </span>
                </div>
              </div>
              <div class="d-col d-col-2">
                <div
                  class="form-group"
                  [ngClass]="model.last_name ? 'floatingLabel' : ''"
                >
                  <span>
                    <input
                      appSpaceRemove
                      type="text"
                      [dir]="isEnglish ? 'ltr' : 'rtl'"
                      [(ngModel)]="model.last_name"
                      name="last_name"
                      class="form-control"
                      [pattern]="CommonRegx.lettersOnlyRegex"
                      required
                      id="last_name"
                      #last_name="ngModel"
                      maxlength="50"
                      placeholder=" "
                      appApplettersonly
                      appFloatingLabelInput
                    />
                    <div
                      class="form-control-feedback text-danger"
                      *ngIf="last_name.errors && submitted"
                    >
                      <span *ngIf="last_name.errors.required">
                        {{ "PLEASE_ENTER_LAST_NAME" | translate }}
                      </span>
                      <span *ngIf="last_name.errors.pattern">
                        {{ "ENTER_VALID_LAST_NAME" | translate }}
                      </span>
                      <span *ngIf="last_name.errors.maxlength">
                        {{ "ENTER_VALID_LAST_NAME_LENGTH" | translate }}
                      </span>
                    </div>
                    <label class="floating-label">{{
                      "LAST_NAME" | translate
                    }}</label>
                  </span>
                </div>
              </div>
              <div class="d-col d-col-2">
                <div
                  class="form-group"
                  [ngClass]="model.email ? 'floatingLabel' : ''"
                >
                  <input
                    disabled
                    [dir]="isEnglish ? 'ltr' : 'rtl'"
                    appSpaceRemove
                    type="text"
                    [(ngModel)]="model.email"
                    name="email"
                    class="form-control"
                    [pattern]="CommonRegx.emailRegx"
                    required
                    id="email"
                    #email="ngModel"
                    maxlength="320"
                    placeholder=" "
                    appFloatingLabelInput
                  />
                  <label class="floating-label">{{
                    "EMAIL_ID" | translate
                  }}</label>
                </div>
              </div>

              <div class="d-col d-col-2">
                <div
                  class="form-group"
                  [ngClass]="model.mobile ? 'floatingLabel' : ''"
                >
                  <span>
                    <input
                      appSpaceRemove
                      type="text"
                      [dir]="isEnglish ? 'ltr' : 'rtl'"
                      [(ngModel)]="model.mobile"
                      name="mobile"
                      class="form-control"
                      id="mobile"
                      #mobile="ngModel"
                      required
                      appNumberOnly
                      placeholder=" "
                      maxlength="10"
                      minlength="10"
                      [pattern]="CommonRegx.phoneNumberRegex"
                      appFloatingLabelInput
                    />
                    <div
                      class="form-control-feedback text-danger"
                      *ngIf="mobile.errors && submitted"
                    >
                      <span *ngIf="mobile.errors.required">
                        {{ "PLEASE_ENTER_MOBILE_NUMBER" | translate }}
                      </span>
                      <span
                        *ngIf="
                          mobile.errors.maxlength || mobile.errors.minlength
                        "
                      >
                        {{ "INVALID_MOBILE" | translate }}
                      </span>
                      <span
                        *ngIf="
                          mobile.errors.pattern &&
                          !mobile.errors.maxlength &&
                          !mobile.errors.minlength
                        "
                      >
                        {{ "INVALID_MOBILE_NO_FORMAT" | translate }}
                      </span>
                    </div>
                    <label class="floating-label">
                      {{ "MOBILE_NO" | translate }}</label
                    >
                  </span>
                </div>
              </div>
              <div class="d-col d-col-2">
                <div
                  class="form-group"
                  [ngClass]="model.national_id ? 'floatingLabel' : ''"
                >
                  <span>
                    <input
                      appSpaceRemove
                      type="text"
                      [dir]="isEnglish ? 'ltr' : 'rtl'"
                      [(ngModel)]="model.national_id"
                      name="national_id"
                      class="form-control"
                      required
                      id="national_id"
                      #national_id="ngModel"
                      maxlength="10"
                      minlength="10"
                      [pattern]="CommonRegx.nationalIdRegex"
                      appNumberOnly
                      appFloatingLabelInput
                      placeholder=" "
                    />
                    <div
                      class="form-control-feedback text-danger"
                      *ngIf="national_id.errors && submitted"
                    >
                      <span *ngIf="national_id.errors.required">
                        {{ "PLEASE_ENTER_NATIONAL_ID" | translate }}
                      </span>
                      <span *ngIf="national_id.errors.pattern">
                        {{ "ENTER_VALID_LENGTH_NATIONAL_ID" | translate }}
                      </span>
                    </div>
                    <label class="floating-label">{{
                      "NATIONAL_ID" | translate
                    }}</label>
                  </span>
                </div>
              </div>

              <div class="d-col d-col-2">
                <div
                  class="form-group"
                  [ngClass]="model.designation_id ? 'floatingLabel' : ''"
                >
                  <input
                    disabled
                    appSpaceRemove
                    [dir]="isEnglish ? 'ltr' : 'rtl'"
                    type="text"
                    [dir]="isEnglish ? 'ltr' : 'rtl'"
                    [(ngModel)]="model.designation_id"
                    name="designation_id"
                    class="form-control"
                    id="mobile"
                    #designation_id="ngModel"
                    appNumberOnly
                    placeholder=" "
                    appFloatingLabelInput
                  />
                  <label class="floating-label">{{
                    "Designation" | translate
                  }}</label>
                </div>
              </div>

              <div class="d-col just-end">
                <a
                  [routerLink]="[
                    this.getRoutesRole() + '/profile/password-change'
                  ]"
                  class="btn btn-primary btn-medium"
                >
                  {{ "CHANGE_PASSWORD" | translate }}</a
                >
                <a
                  *ngIf="!isAdmin"
                  [routerLink]="['/provider/dashboard']"
                  class="btn bordered-btn btn-small"
                  >{{ "CANCEL" | translate }}</a
                >
                <a
                  *ngIf="isAdmin"
                  [routerLink]="['/admin/dashboard']"
                  class="btn bordered-btn btn-small"
                  >{{ "CANCEL" | translate }}</a
                >
                <button type="submit" class="btn btn-secondary btn-small">
                  {{ "SAVE" | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<app-spinner></app-spinner>
