import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "@components/base.component";

@Component({
  selector: "app-left-register-panel",
  templateUrl: "./left-register-panel.component.html",
  styleUrls: ["./left-register-panel.component.scss"],
})
export class LeftRegisterPanelComponent extends BaseComponent
  implements OnInit {
  requesterTypeURL = "";

  ngOnInit(): void {
    this.requesterTypeURL = this.requesterType();
  }
}
