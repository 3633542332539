import { Component } from "@angular/core";
import { BaseComponent } from "@components/base.component";
import { Location } from "@angular/common";
import { LanguageService } from "@services/language.service";
import { languageTypes } from "@utils/enum-const";
import { TranslateService } from "@ngx-translate/core";

@Component({
  templateUrl: "404.component.html",
  styleUrls: ["404.component.scss"],
})
export class P404Component extends BaseComponent {
  isEnglish = false;
  constructor(
    public location: Location,
    private languageSwitcher: LanguageService,
    private translate: TranslateService
  ) {
    super();
    const userKey = this.getUser() + "_lan";
    const currentLanguage = this.languageSwitcher.getUserDefaultLanguage(
      userKey
    );
    this.isEnglish = currentLanguage === languageTypes.DEFAULT_ADMIN;
  }

  /**
   * Change languages
   */
  changeLang() {
    if (!this.isEnglish) {
      this.translate.use("en");
      this.isEnglish = true;
    } else {
      this.translate.use("ar");
      this.isEnglish = false;
    }
  }

  goback() {
    this.location.back();
  }
}
