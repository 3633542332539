<div class="provider-order-info">
  <h3>{{ "ORDER_INFO" | translate }}</h3>
  <div
    class="cancel-order-box"
    *ngIf="
      this.orderDetails?.status === this.orderStatus?.CANCELLED &&
      this.orderDetails?.order_cancel_by
    "
  >
    <!-- <div class="cancel-order-title d-flex align-center">
      <span class="heading-left"
        >{{ "ORDER_CANCEL_BY" | translate }}&nbsp;</span
      >
      <span class="heading-right">
        {{
          this.orderDetails?.shahen_order === 1 ||
          (this.orderDetails?.order_cancel_by?.user?.roles)[0]?.name ===
            this.adminTypes.SUPER_ADMIN_ROLE
            ? this.shahenName.SHAHEN_EXPRESS
            : this.orderDetails?.order_cancel_by?.user?.name +
              "(" +
              getCancelOrderAuthorDetails(this.orderDetails) +
              ")"
        }}&nbsp; {{ "AT" | translate }}&nbsp;
        {{
          this.orderDetails?.order_cancel_by?.cancelled_at
            | customDate: momentDateTimeFormat
        }}
      </span>
    </div>
    <p class="cancel-reason">{{ this.orderDetails?.refused_comment }}</p> -->

    <div class="canceled">
      <div class="alert-container">
        <div class="alert danger">
          <div class="cancellation-reason-text">
            <strong class="reason-title"
              >{{ "CANCELLATION_REASON" | translate }}:</strong
            >
            <span class="cancelled-reason">{{
              this.orderDetails?.refused_comment
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="canceled">
      <div class="alert-container">
        <div class="alert danger">
          <span class="reason-text">
            <strong class="reason-title"
              >{{ "ORDER_CANCEL_BY" | translate }}:</strong
            >
            {{
              this.orderDetails?.shahen_order === 1 ||
              (this.orderDetails?.order_cancel_by?.user?.roles)[0]?.name ===
                this.adminTypes.SUPER_ADMIN_ROLE
                ? this.shahenName.SHAHEN_EXPRESS
                : this.orderDetails?.order_cancel_by?.user?.name +
                  "(" +
                  getCancelOrderAuthorDetails(this.orderDetails) +
                  ")"
            }}&nbsp; {{ "AT" | translate }}&nbsp;
            {{
              this.orderDetails?.order_cancel_by?.cancelled_at
                | customDate: momentDateTimeFormat
            }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="border-box">
    <div class="order-data">
      <div class="status-btn-wrapper d-flex">
        <div
          class="status-box"
          *ngIf="
            this.orderDetails?.sp_status !==
              this.orderStatus?.SHIPMENT_ON_THE_WAY &&
            this.orderDetails?.sp_status !==
              this.orderStatus?.SHIPMENT_IN_PROGRESS
          "
        >
          <div
            class="state"
            [ngClass]="{
              'status-approval-yellow':
                this.orderDetails?.sp_status ===
                this.orderStatus?.WAITING_FOR_APPROVAL,
              'status-new':
                this.orderDetails?.sp_status === this.orderStatus?.NEW
            }"
          >
            <div class="icon-area">
              <i
                *ngIf="
                  this.orderDetails?.sp_status === this.orderStatus?.NEW ||
                  this.orderDetails?.sp_status ===
                    this.orderStatus?.SR_INVOICE_SENT ||
                  this.orderDetails?.sp_status ===
                    this.orderStatus?.INVOICE_APPROVED ||
                  this.orderDetails?.sp_status === this.orderStatus.DELIVERED ||
                  this.orderDetails?.sp_status ===
                    this.orderStatus.INVOICE_PAID ||
                  this.orderDetails?.sp_status ===
                    this.orderStatus.INVOICE_SENT ||
                  this.orderDetails?.sp_status === this.orderStatus.CANCELLED ||
                  this.orderDetails?.sp_status ===
                    this.orderStatus?.WAITING_FOR_APPROVAL_SP
                "
                class="icon-mark"
              ></i>
            </div>
            <span [ngStyle]="{ color: orderDetails?.status_color?.hex }">
              {{ this.orderDetails?.orderStatus }}
            </span>
          </div>
          <label class="floating-label">{{ "STATUS" | translate }}</label>
        </div>
        <ng-container
          *ngIf="
            this.orderDetails?.sp_status ===
              this.orderStatus?.SHIPMENT_ON_THE_WAY ||
            this.orderDetails?.sp_status ===
              this.orderStatus?.SHIPMENT_IN_PROGRESS
          "
        >
          <div class="dropdown">
            <div class="form-group floatingLabel">
              <div class="icon-area"><span class="fill"></span></div>
              <select
                class="form-control-select status-box"
                #order_status="ngModel"
                required
                id="order_status"
                [(ngModel)]="model.order_status"
                name="order_status"
                (change)="changeProviderStatus()"
                [ngStyle]="{ color: orderDetails?.status_color?.hex }"
              >
                <option
                  [value]="model.order_status"
                  class="select-dropdown__list-item"
                  selected
                >
                  {{ this.orderDetails?.orderStatus }}
                </option>
                <ng-container *ngFor="let item of orderStatusList">
                  <ng-container
                    *ngIf="item.key !== this.orderDetails?.sp_status"
                  >
                    <option
                      [value]="item.key"
                      class="select-dropdown__list-item"
                    >
                      {{ item.label }}
                    </option>
                  </ng-container>
                </ng-container>
              </select>
              <i class="icon-arrow-down"></i>
              <label class="floating-label" for="order_status">{{
                "STATUS" | translate
              }}</label>
            </div>
          </div>
        </ng-container>
        <div
          class="order-btn"
          *ngIf="
            this.orderDetails?.sp_status === this.orderStatus.NEW &&
            (this.orderDetails.section === this.orderTypes.ORDER ||
              this.orderDetails.section === this.orderTypes.OFFERS)
          "
        >
          <a
            (click)="openProviderTruckModal()"
            class="btn btn-medium accept"
            id="openAcceptTrucks"
            >{{ "ACCEPT" | translate }}</a
          >
          <a
            (click)="openRejectPopUpModal(rejectOrderPopup)"
            class="btn btn-medium-reject"
            >{{ "REJECT" | translate }}</a
          >
        </div>
        <ng-container
          *ngIf="
            this.orderDetails?.sp_status === this.orderStatus.NEW &&
            (this.orderDetails.section === this.orderTypes.MEMBERSHIP ||
              this.orderDetails?.section === this.orderTypes.SPECIAL_ORDER ||
              this.orderDetails?.section === this.orderTypes.OFFERS) &&
            (this.orderDetails?.sp_approve === 0 ||
              this.orderDetails.shahen_order === 0)
          "
        >
          <div class="sp-approve-order">
            <app-admin-handle-approve-reject
              (handleApproveReject)="approveRejectOrder($event)"
              [isReasonVisible]="isReasonVisible"
              [isDetailedReason]="isDetailedReason"
            ></app-admin-handle-approve-reject>
          </div>
        </ng-container>
      </div>
      <div class="shipment-information">
        <div class="shipment-box">
          <div
            [totalTrips]="
              orderDetails?.providers && orderDetails?.shahen_order === 1
                ? showTruckQuantity
                : orderDetails?.quantity
            "
            [ngClass]="storeProgressBarClass"
            class="circular-progress"
            appCircularProgress
            [currentTrips]="this.orderDetails?.tripStatues?.at_loading_location"
          >
            <span class="progress-value">
              {{ this.orderDetails?.tripStatues?.at_loading_location }}/{{
                this.orderDetails?.providers &&
                this.orderDetails?.shahen_order === 1
                  ? showTruckQuantity
                  : this.orderDetails?.quantity
              }}
            </span>
          </div>
          <span class="shipment-status">
            {{ "SHIPMENT_IN_PROGRESS" | translate }}
          </span>
        </div>
        <div class="shipment-box">
          <div
            [ngClass]="storeProgressBarClass"
            class="circular-progress"
            [currentTrips]="this.orderDetails?.tripStatues?.on_the_way_trip"
            [totalTrips]="
              orderDetails?.providers && orderDetails?.shahen_order === 1
                ? showTruckQuantity
                : orderDetails?.quantity
            "
            appCircularProgress
          >
            <span class="progress-value">
              {{ this.orderDetails?.tripStatues?.on_the_way_trip }}/{{
                this.orderDetails?.providers &&
                this.orderDetails?.shahen_order === 1
                  ? showTruckQuantity
                  : this.orderDetails?.quantity
              }}
            </span>
          </div>
          <span class="shipment-status">
            {{ "SHIPMENT_ON_THE_WAY" | translate }}
          </span>
        </div>
        <div class="shipment-box">
          <div
            [ngClass]="storeProgressBarClass"
            class="circular-progress"
            appCircularProgress
            [currentTrips]="
              this.orderDetails?.tripStatues?.at_unloading_location
            "
            [totalTrips]="
              orderDetails?.providers && orderDetails?.shahen_order === 1
                ? showTruckQuantity
                : orderDetails?.quantity
            "
          >
            <span class="progress-value">
              {{ this.orderDetails?.tripStatues?.at_unloading_location }}/{{
                this.orderDetails?.providers &&
                this.orderDetails?.shahen_order === 1
                  ? showTruckQuantity
                  : this.orderDetails?.quantity
              }}
            </span>
          </div>
          <span class="shipment-status">
            {{ "DELIVERED" | translate }}
          </span>
        </div>
      </div>
    </div>
    <!-- <div class="detail-area">
      <div class="data">
        <div class="detail-data">
          <span class="title">{{ "REQUSTER_NAME" | translate }}</span>
          <span
            *ngIf="
              this.orderType.special_order !==
              this.orderType[this.orderDetails?.section]
            "
            class="detail"
            title="{{
              this.orderDetails?.shahen_order === 1
                ? orderTypes.SHAHEN
                : 'this.orderDetails?.requester?.service_requester?.company_name'
            }}"
          >
            {{
              this.orderDetails?.shahen_order === 1
                ? orderTypes.SHAHEN
                : this.orderDetails?.requester?.service_requester?.company_name
            }}
          </span>
          <span
            class="detail"
            *ngIf="
              this.orderType.special_order ===
              this.orderType[this.orderDetails?.section]
            "
          >
            {{ this.orderTypes.SHAHEN }}
          </span>
        </div>
        <div class="detail-data">
          <span class="title">{{ "ORDER_DATE" | translate }}</span>
          <span class="detail">
            {{
              this.orderDetails?.created_at_format
                | customDate: dateFormatListing
            }}
          </span>
        </div>
        <div class="detail-data">
          <span class="title">{{ "ORDER_TYPE" | translate }}</span>
          <span class="detail">
            {{
              this.orderDetails?.offer_id
                ? this.orderType.offer_order
                : this.orderType[this.orderDetails?.section]
            }}
          </span>
        </div>
        <div class="detail-data">
          <span class="title">{{ "PRICE" | translate }}</span>
          <span class="detail">{{
            orderDetails?.providers && orderDetails?.providers?.length > 1
              ? this.orderDetails?.providers_without_vat_price +
                " " +
                this.measurementUnits.SAR
              : ((this.orderDetails?.providers &&
                  this.orderDetails?.shahen_order === 1 &&
                  this.orderTypes.MEMBERSHIP === this.orderDetails?.section) ||
                this.orderTypes.ORDER === this.orderDetails?.section ||
                this.orderTypes.OFFERS === this.orderDetails?.section ||
                this.orderDetails?.section === this.orderTypes.SPECIAL_ORDER
                  ? this.orderDetails?.price
                  : this.orderDetails?.sp_unit_price) +
                " X " +
                (this.orderTypes.MEMBERSHIP === this.orderDetails?.section &&
                orderDetails?.providers &&
                orderDetails?.providers?.length > 1
                  ? this.showQuantity
                  : this.orderDetails?.quantity) +
                " = " +
                (this.orderDetails?.providers &&
                this.orderDetails?.shahen_order === 1 &&
                this.orderTypes.MEMBERSHIP === this.orderDetails?.section
                  ? this.orderDetails?.sub_total
                  : this.orderDetails?.section === this.orderTypes.SPECIAL_ORDER
                  ? this.orderDetails?.providers_without_vat_price
                  : this.orderDetails?.sp_price_without_vat) +
                " " +
                this.measurementUnits.SAR
          }}</span>
        </div>
        <div class="detail-data">
          <span class="title"
            >{{ "TOTAL_PRICE" | translate }} ({{ "INCL_VAT" | translate }}
            <b>{{ this.orderDetails?.vat_percentage }}</b
            >%)</span
          >
          <span class="detail">{{
            (orderDetails?.providers && orderDetails.providers.length > 0) ||
            this.orderDetails?.section === this.orderTypes.SPECIAL_ORDER
              ? this.orderDetails?.providers_with_vat_price +
                " " +
                this.measurementUnits.SAR
              : this.orderDetails?.order_prices?.sp_price_vat +
                " " +
                this.measurementUnits.SAR
          }}</span>
        </div>
      </div>
      <div class="btn-area">
        <a
          *ngIf="
            this.orderDetails?.sp_status !== this.orderStatus?.CANCELLED &&
            (this.orderDetails?.paid === 1 ||
              this.orderTypes.MEMBERSHIP === this.orderDetails?.section ||
              this.orderTypes.SPECIAL_ORDER === this.orderDetails?.section) &&
            (this.orderDetails?.sp_approve === 1 ||
              (this.orderDetails?.shahen_order === 0 &&
                this.orderDetails?.sp_status !== this.orderStatus.NEW &&
                this.orderDetails?.sp_status !==
                  this.orderStatus.WAITING_FOR_APPROVAL &&
                this.orderDetails?.sp_status !==
                  this.orderStatus.WAITING_FOR_APPROVAL_SR))
          "
          [routerLink]="[
            this.orderType.membership ===
            this.orderType[this.orderDetails?.section]
              ? this.getRoutesRole() +
                '/contracts/add-drivers-truck/' +
                this.orderDetails?.order_id
              : this.orderType.order ===
                  this.orderType[this.orderDetails?.section] &&
                this.orderDetails?.offer_id === null
              ? this.getRoutesRole() +
                '/direct-orders/add-drivers-truck/' +
                this.orderDetails?.order_id
              : this.orderDetails?.offer_id
              ? this.getRoutesRole() +
                '/offer-orders/add-drivers-truck/' +
                this.orderDetails?.order_id
              : this.orderType.special_order ===
                this.orderType[this.orderDetails?.section]
              ? this.getRoutesRole() +
                '/special-orders/add-drivers-truck/' +
                this.orderDetails?.order_id
              : ''
          ]"
          class="btn"
          [ngClass]="
            (this.orderDetails?.sp_status === this.orderStatus.DELIVERED ||
              this.orderDetails?.sp_status === this.orderStatus.INVOICE_PAID ||
              this.orderDetails?.sp_status === this.orderStatus.INVOICE_SENT ||
              this.orderDetails?.sp_status === this.orderStatus.CANCELLED) &&
            this.orderDetails?.bayan_trips_count > 0
              ? 'btn-view'
              : this.orderDetails?.bayan_trips_count === 0
              ? 'btn-add'
              : 'btn-edit'
          "
        >
          <i
            class="icon-Plus"
            *ngIf="this.orderDetails?.bayan_trips_count === 0"
          ></i>
          {{
            ((this.orderDetails?.sp_status === this.orderStatus.DELIVERED ||
              this.orderDetails?.sp_status === this.orderStatus.INVOICE_PAID ||
              this.orderDetails?.sp_status === this.orderStatus.INVOICE_SENT ||
              this.orderDetails?.sp_status === this.orderStatus.CANCELLED) &&
            this.orderDetails?.bayan_trips_count > 0
              ? "VIEW_DRIVERS_AND_TRUCKS"
              : this.orderDetails?.bayan_trips_count > 0
              ? "EDIT_DRIVERS_AND_TRUCKS"
              : "ADD_DRIVERS_AND_TRUCKS"
            ) | translate
          }}
        </a>

        <a
          (click)="openModal()"
          *ngIf="
            this.orderDetails?.bayan_trips_count > 0 &&
            this.orderDetails?.sp_status !== this.orderStatus?.CANCELLED
          "
          class="btn bordered-btn btn-medium btn-medium-small"
          [ngClass]="
            this.orderDetails?.sp_status === this.orderStatus.DELIVERED ||
            this.orderDetails?.sp_status === this.orderStatus.INVOICE_PAID ||
            this.orderDetails?.sp_status === this.orderStatus.INVOICE_SENT ||
            (this.orderDetails?.sp_status === this.orderStatus.CANCELLED &&
              this.orderDetails?.bayan_trips_count > 0)
              ? 'btn-view'
              : this.orderDetails?.bayan_trips_count === 0
              ? 'btn-add'
              : 'btn-edit'
          "
        >
          {{
            (this.orderDetails?.sp_status === this.orderStatus.DELIVERED ||
            this.orderDetails?.sp_status === this.orderStatus.INVOICE_PAID ||
            this.orderDetails?.sp_status === this.orderStatus.INVOICE_SENT ||
            this.orderDetails?.sp_status === this.orderStatus.CANCELLED
              ? "VIEW_TRIP_DETAILS"
              : "EDIT_TRIP_DETAILS"
            ) | translate
          }}
        </a>
        <a
          (click)="downloadInvoice()"
          target="_blank"
          class="btn btn-secondary btn-medium btn-medium-small"
          *ngIf="
            (this.orderDetails.section === this.orderTypes.ORDER ||
            this.orderDetails.section === this.orderTypes.OFFERS
              ? this.orderDetails?.paid === 1 &&
                this.orderDetails?.sp_status !== this.orderStatus?.CANCELLED
              : false) ||
            (this.orderDetails.section === this.orderTypes.MEMBERSHIP ||
            this.orderDetails.section === this.orderTypes.SPECIAL_ORDER
              ? this.orderDetails?.sp_status ===
                  this.orderStatus?.INVOICE_SENT ||
                this.orderDetails?.sp_status === this.orderStatus?.INVOICE_PAID
              : false)
          "
        >
          <i class="icon-Invoice"></i>
          {{ "VIEW_INVOICES" | translate }}
        </a>
      </div>
    </div> -->
    <!-- <div
      class="price-part"
      *ngIf="orderDetails?.providers && orderDetails.providers.length > 0"
    >
      <div class="form-group price-dropdown">
        <div class="select-dropdown">
          <select
            id="shipment-select-1"
            placeholder-text="Domestic"
            class="form-control-select"
          >
            <option value="" class="select-dropdown__list-item">
              {{
                ("TOTAL_PRICE" | translate) +
                  " : " +
                  orderDetails?.providers_without_vat_price
              }}
            </option>
            <option
              class="select-dropdown__list-item"
              *ngFor="let item of orderDetails?.providers"
              [value]="item"
            >
              {{
                item?.user?.company_name +
                  " : " +
                  item?.price +
                  " x " +
                  item?.quantity +
                  " = " +
                  item?.price * item?.quantity
              }}
            </option>
          </select>
        </div>
        <i class="icon-arrow-down"></i>
        <label class="floating-label">{{ "PRICE" | translate }}</label>
      </div>
    </div> -->
  </div>
</div>
<ng-template #rejectOrderPopup>
  <button type="button" class="close" (click)="decline()">
    <span aria-hidden="true"><i class="icon-close"></i></span>
  </button>
  <app-reject-order-popup
    (orderReject)="rejectOrder($event)"
    (close)="decline()"
  >
  </app-reject-order-popup>
</ng-template>
