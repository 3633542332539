import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BaseComponent } from "@components/base.component";
import { AutoUnsubscribe } from "@decorator/autounsubscribe";

@Component({
  selector: "app-confirmation-popup-modal",
  templateUrl: "./confimation-popup-modal.component.html",
  styleUrls: ["./confimation-popup-modal.component.scss"],
})
@AutoUnsubscribe()
export class ConfirmationPopupModalComponent extends BaseComponent {
  @Input() popupMessage!: string;
  @Output() close: EventEmitter<string> = new EventEmitter<string>();
  @Output() submit: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Handle close modal
   */
  decline(): void {
    this.close.emit();
  }

  /**
   * Handle logout
   */
  async handleLogout() {
    this.submit.emit();
  }
}
