import { Injectable } from "@angular/core";
import { IRoleBasedLanguage } from "@model/common";

@Injectable({
  providedIn: "root",
})
export class MultilingualService {
  saveLanguage(value: IRoleBasedLanguage[]) {
    localStorage.setItem("languages", JSON.stringify(value));
  }

  getLanguage() {
    const data = localStorage.getItem("languages");
    if (data) {
      return JSON.parse(data);
    } else {
      return [];
    }
  }
}
