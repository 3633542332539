import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from "@angular/core";
import { BaseComponent } from "@components/base.component";
import { isCanceled } from "@config/app-constants";
import { AutoUnsubscribe } from "@decorator/autounsubscribe";
import { DownloadFileResponse } from "@model/adminDirectOrders";
import { IBayanTrips } from "@model/common";
import { IStatusItem } from "@model/directOrdersDetails";
import { ISelectedTripDetails } from "@model/directOrderTripDetails";
import { IErrorResponse } from "@model/errorResponse";
import { ISuccessResponse } from "@model/successResponse";
import { AdminContractsService } from "@services/admin-contracts.service";
import { DirectOrdersService } from "@services/direct-orders.service";
import { OrdersCommonService } from "@services/orders-common-service";
import { orderTypes } from "@utils/enum-const";
import { first } from "rxjs/operators";
import { DeleteRecordConfirmationModalComponent } from "../delete-record-confirmation-modal/delete-record-confirmation-modal.component";

@Component({
  selector: "app-edit-trip-details-popup",
  templateUrl: "./edit-trip-details.component.html",
  styleUrls: ["./edit-trip-details.component.scss"],
})
@AutoUnsubscribe()
export class EditTripDetailsPopupComponent extends BaseComponent
  implements OnInit, OnChanges {
  @Input() orderTripDetails: any;
  @Input() orderTripStatusList: any;
  @Input() orderData!: {
    order_id: string | number;
    weight: string;
    detail_address_to: string;
    detail_address_from: string;
    section: string | number;
  };
  @Input() tripStatus!: IStatusItem[];
  selectedChangeTrip: ISelectedTripDetails | null = null;
  tripContainerDetails: any = null;
  listStatus: IStatusItem[] = [];
  listTripData: IBayanTrips[] = [];
  orderId!: number;
  tripId!: number | undefined;
  editDateTimeTitle!: string | undefined;
  editModalDate = "";
  editModalLoadingDate = "";
  containerEditMode = false;
  @Output() close = new EventEmitter();
  @Output() editTripDateTime = new EventEmitter();
  @Output() changeTripStatusData = new EventEmitter<{
    tripId: number;
    tripStatus: string;
  }>();
  orderTypes = orderTypes;
  isCanceled = isCanceled;
  @Output() handleTripStatusChange = new EventEmitter<{
    tripId: number;
    tripStatus: string;
  }>();
  isInvalidQuantity = false;
  @Output() manageBayanAction = new EventEmitter<{
    tripData: any;
  }>();

  public queryParam = {
    orderNo: "",
    tripId: "",
    driver: "",
    driver_mobile: "",
    truck_plate_no: "",
    truck_type: "",
    weight: "",
    laoding_point: "",
    offloading_point: "",
    orderType: "",
  };
  constructor(
    private orderService: DirectOrdersService,
    private readonly adminContractOrderService: AdminContractsService,
    private readonly ManageOrdersCommonService: OrdersCommonService
  ) {
    super();
  }

  ngOnInit(): void {
    this.setTripData();
  }

  openContainerModal(
    template: TemplateRef<string>,
    tripData: ISelectedTripDetails,
    editMode: boolean,
    index?: number
  ) {
    const updatedTripData = { ...tripData };
    this.containerEditMode = editMode;
    this.selectedChangeTrip = tripData || null;
    if (
      editMode &&
      index !== undefined &&
      updatedTripData?.trip_containers?.[index]
    ) {
      // Assert that the value is defined to satisfy TypeScript
      const selectedContainer = updatedTripData.trip_containers[index];
      if (selectedContainer) {
        updatedTripData.trip_containers = [selectedContainer];
      }
      this.selectedChangeTrip = updatedTripData;
    }
    this.modalRef = this.modalService.show(template, { class: "modal-md-sm" });
  }

  /**
   * Refresh popup data
   */
  refreshData() {
    this.editTripDateTime.emit();
  }

  /**
   * Manage changes in popup
   */
  ngOnChanges(changes: SimpleChanges) {
    this.setTripData();
    if (changes["orderTripDetails"]) {
      this.selectedChangeTrip = this.orderTripDetails.filter(
        (container: any) => {
          if (container.id === this.selectedChangeTrip?.id) {
            return container;
          }
        }
      )[0];
    }
  }

  /**
   * Close Popup
   */
  closePopup() {
    this.close.emit();
  }

  /**
   * Change Trip Status
   */
  changeTripStatus(tripId: number, tripStatus: string) {
    this.changeTripStatusData.emit({ tripId, tripStatus });
  }

  /**
   * Handle Trip Status
   */
  handleTripStatus(tripId: number, tripStatus: string) {
    this.handleTripStatusChange.emit({ tripId, tripStatus });
  }

  /**
   * Downlaod Bayal - Waybill
   */
  downloadBayanWaybillFile(tripId: number, fileType: string) {
    this.spinner.showLoader();
    const payload = { type: fileType };
    this.orderService
      .downloadBayanWaybill(tripId, payload)
      .pipe(first())
      .subscribe(
        (data: DownloadFileResponse) => {
          this.spinner.hideLoader();
          if (data.status_code === this.statusCode.OK) {
            if (data.data.link) {
              window.open(data.data.link, "_blank");
            }
          }
        },
        (error: IErrorResponse) => {
          this.spinner.hideLoader();
          this.errorHandler(this.toastr, this.translateService, error);
        }
      );
  }

  /**
   * Handle chat redirection with details
   */
  redirectToChat(tripId: number) {
    const orderTrip = this.listTripData.filter(
      (m: any) => m?.trip_id === tripId
    )[0];
    this.queryParam.orderNo = this.orderData?.order_id as string;
    this.queryParam.tripId = orderTrip?.trip_id as string;
    this.queryParam.driver = orderTrip?.driver?.name as string;
    this.queryParam.driver_mobile = (orderTrip?.driver?.absher_mobile
      ? orderTrip?.driver?.absher_mobile
      : orderTrip?.driver?.mobile) as string;
    this.queryParam.truck_type = orderTrip?.vehicle?.truck?.name
      ? orderTrip?.vehicle?.truck?.name
      : "-";
    this.queryParam.truck_plate_no = orderTrip?.vehicle?.full_plate_number
      ? orderTrip?.vehicle?.full_plate_number
      : "-";

    this.closePopup();
    this.router.navigateByUrl(this.getRoutesRole() + "/chat", {
      state: { tripDetails: this.queryParam },
    });
  }

  /**
   * Set trip data
   */
  setTripData() {
    this.listTripData = this.orderTripDetails;
    this.listStatus = this.tripStatus;
    this.queryParam.weight =
      this.orderData?.weight + " " + this.measurementUnits.TON;
    this.queryParam.offloading_point = this.orderData?.detail_address_to;
    this.queryParam.laoding_point = this.orderData?.detail_address_from;
    this.queryParam.orderType = this.orderType[this.orderData?.section];
    this.queryParam.orderNo = this.orderId?.toString();
    this.orderId = this.orderData?.order_id as number;
  }

  /**
   * Handle bayan sync actions
   */
  manageBayanSyncAction(orderTrip: any) {
    this.manageBayanAction.emit({ tripData: orderTrip });
  }

  openModal(
    template: TemplateRef<string>,
    tripId?: number,
    title?: string,
    date?: string,
    loadingDate?: string
  ) {
    this.tripId = tripId;
    this.editModalDate = date ?? "";
    this.editModalLoadingDate = loadingDate ?? "";
    this.editDateTimeTitle = title ?? "EDIT_DATE_AND_TIME";
    this.modalRef = this.modalService.show(template, { class: "modal-md-xs" });
  }

  /**
   * Handle close modal
   */
  decline(): void {
    this.modalRef.hide();
  }

  submitDate(event: string) {
    this.spinner.showLoader();
    let objKey = "";
    if (this.editDateTimeTitle?.includes("LOADING")) {
      objKey = "loading_date";
    }
    if (this.editDateTimeTitle?.includes("ETA")) {
      objKey = "eta_date";
    }
    if (this.editDateTimeTitle?.includes("DELIVERED")) {
      objKey = "offloading_date";
    }
    this.adminContractOrderService
      .editTripDateAndTime(this.tripId, { [objKey]: event })
      .pipe(first())
      .subscribe(
        (data: ISuccessResponse) => {
          this.spinner.hideLoader();
          this.modalRef.hide();
          if (data?.status_code === this.statusCode.OK) {
            this.toastr.success(data.message);
          }
          this.decline();
          this.editTripDateTime.emit();
        },
        (error: IErrorResponse) => {
          this.spinner.hideLoader();
          this.errorHandler(this.toastr, this.translateService, error);
        }
      );
  }

  /**
   * Delete Container
   */
  deleteTripContainer(containerId: number, tripData: ISelectedTripDetails) {
    this.spinner.showLoader();
    this.ManageOrdersCommonService.deleteTripContainer(tripData.id, containerId)
      .pipe(first())
      .subscribe(
        (response) => {
          if (response.status_code === this.statusCode.OK) {
            this.toastr.success(response.message);
            this.editTripDateTime.emit();
            this.spinner.hideLoader();
          }
        },
        (error: IErrorResponse) => {
          this.spinner.hideLoader();
          this.errorHandler(this.toastr, this.translateService, error);
        }
      );
    // this.spinner.showLoader()
  }

  /**
   * Redirect to trip detail
   */
  redirectToTripDetail(id: number) {
    const redirectLink = this.getRoutesRole() + "/trip/view/" + id;
    window.open(redirectLink, "_blank");
  }

  /**
   * Handle confirmation on delete container trip
   */
  deleteTripModal(containerId: number, tripData: ISelectedTripDetails) {
    this.modalRef = this.modalService.show(
      DeleteRecordConfirmationModalComponent,
      { class: "modal-md" }
    );
    this.modalRef.content.popupMessage = "CONFIRM_DELETE_MSG";
    this.modalRef.content.ConfirmAction.subscribe(() => {
      this.deleteTripContainer(containerId, tripData);
      this.decline();
    });
    this.modalRef.content.close.subscribe(() => {
      this.decline();
    });
  }
}
