import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { Router } from "@angular/router";
import { CONFIG } from "@config/app-config";
import {
  ISelectDropdownResponseData,
  StatusData,
} from "@model/adminDirectOrders";
import {
  IGoodsTypeResponse,
  ILocationResponse,
  IWeightsListResponse,
} from "@model/adminPricing";
import {
  IApproveRejectOrderPayload,
  IBayanTrips,
  IDriverListPayload,
  IMutiProviderOrderPrice,
  IorderStatusPayload,
  IOrderVehicle,
} from "@model/common";
import {
  IContactRequest,
  ITermsAndConditionAndPrivacyPolicy,
} from "@model/contact";
import {
  IAccessoriesList,
  IChangeDescriptionModel,
  IDropdownFieldResponse,
} from "@model/contract";
import { IDropdownPayload } from "@model/directOrdersDetails";
import { IAddDriverListing } from "@model/driver";
import {
  INotificationPayload,
  INotificationResponse,
} from "@model/notification";
import { IDownloadInvoiceResponse } from "@model/payment";
import {
  IContractProvider,
  IProviderOrderInfo,
  IWeightList,
} from "@model/provider";
import {
  ProviderListApiResponse,
  RequesterListApiResponse,
} from "@model/providerList";
import {
  IProviderPricePayload,
  IVehicleListApiPayload,
} from "@model/serviceModel";
import { ISuccessResponse } from "@model/successResponse";
import {
  IGetTruckInterface,
  IPlateTypesResponse,
  IShipmentTruckTypesData,
  IShipmentTypeResponse,
  ITrucksTypeResponse,
  ITruckTypesData,
} from "@model/truck";
import { CommonService } from "@services/common.service";
import { orderTypes, RoutesRole, UserRole } from "@utils/enum-const";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CommonApiService {
  userType = "";
  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private chatMessage: AngularFireMessaging,
    private router: Router
  ) {
    this.userType = this.commonService.getLoginUserType();
  }

  /**
   * Get truck types
   */
  getTruckTypes() {
    return this.http.get<ITruckTypesData>(CONFIG.getTruckTypesURL).pipe(
      map((response: ITruckTypesData) => {
        return response;
      })
    );
  }

  /**
   * Get shipment types
   */
  getShipmentTypes() {
    return this.http.get<IShipmentTypeResponse>(CONFIG.getShipmentType).pipe(
      map((response: IShipmentTypeResponse) => {
        return response;
      })
    );
  }

  /**
   * Get trucks details
   * @shipmentType Number
   */
  getTrucks(shipmentType: number) {
    return this.http
      .get<ITrucksTypeResponse>(CONFIG.getTrucks + "/" + shipmentType)
      .pipe(
        map((response: ITrucksTypeResponse) => {
          return response;
        })
      );
  }

  /**
   * Get plat types
   */
  getPlatTypes() {
    return this.http.get<IPlateTypesResponse>(CONFIG.getPlatTypesURL).pipe(
      map((response: IPlateTypesResponse) => {
        return response;
      })
    );
  }

  /**
   * Get goods type listing
   */
  getGoodsTypesList() {
    return this.http.get<IGoodsTypeResponse>(CONFIG.getGoodsTypesURL).pipe(
      map((response: IGoodsTypeResponse) => {
        return response;
      })
    );
  }

  /**
   * Get loading location
   */
  getLoadingLocation() {
    return this.http.post<ILocationResponse>(CONFIG.getCitiesURL, []).pipe(
      map((response: ILocationResponse) => {
        return response;
      })
    );
  }

  /**
   * Get loading location
   */
  getContractProvider() {
    const thisUser = this.commonService.getLoginUserType();
    let getContractsProviderURL: string;

    if (thisUser === UserRole.SUPER_ADMIN_USER) {
      getContractsProviderURL = CONFIG.getAdminContractProvider;
    } else if (thisUser === UserRole.SERVICE_PROVIDER) {
      getContractsProviderURL = CONFIG.getProviderContractProvider;
    } else {
      getContractsProviderURL = CONFIG.getRequesterContractProvider;
    }
    return this.http.get<IContractProvider>(getContractsProviderURL).pipe(
      map((response: IContractProvider) => {
        return response;
      })
    );
  }

  /**
   * Get contract provider for requester
   */
  getContractProviderforRequester() {
    return this.http.get<IContractProvider>(CONFIG.getContractProvider).pipe(
      map((response: IContractProvider) => {
        return response;
      })
    );
  }

  /**
   * Get provider list
   */
  getProviderList() {
    return this.http
      .get<ProviderListApiResponse>(CONFIG.getProvidersForListing)
      .pipe(
        map((response: ProviderListApiResponse) => {
          return response;
        })
      );
  }

  /**
   * Get requester listing
   */
  getRequesterList() {
    return this.http
      .get<RequesterListApiResponse>(CONFIG.fetchAllServiceRequesters)
      .pipe(
        map((response: RequesterListApiResponse) => {
          return response;
        })
      );
  }

  /**
   * Get weight data
   */
  getWeightData() {
    return this.http.get<IWeightsListResponse>(CONFIG.getWeightTypes).pipe(
      map((response: IWeightsListResponse) => {
        return response;
      })
    );
  }

  /**
   * Get weight listing
   */
  getWeightDataList() {
    return this.http.get<IWeightList>(CONFIG.getWeight).pipe(
      map((response: IWeightList) => {
        return response;
      })
    );
  }

  /**
   * Get accessories data
   */
  getAccessories() {
    return this.http.get<IAccessoriesList>(CONFIG.getAccessories).pipe(
      map((response: IAccessoriesList) => {
        return response;
      })
    );
  }

  /**
   * Get accessories data
   */
  getAccesoriesData() {
    return this.http
      .get<ISelectDropdownResponseData>(CONFIG.getAccessoriesURL)
      .pipe(
        map((response: ISelectDropdownResponseData) => {
          return response;
        })
      );
  }

  /**
   * Get shipment truck types
   * @shipmentId shipment ID
   */
  getShipmentTruckTypes(shipmentId: number) {
    return this.http
      .get<IShipmentTruckTypesData>(CONFIG.getShipmentTrucks + shipmentId)
      .pipe(
        map((response: IShipmentTruckTypesData) => {
          return response;
        })
      );
  }

  /**
   * Get drodowm list
   */
  getDropdwonFields(requesterData: IDropdownPayload) {
    return this.http
      .post<IDropdownFieldResponse>(CONFIG.getDropdownField, requesterData)
      .pipe(
        map((response: IDropdownFieldResponse) => {
          return response;
        })
      );
  }

  /**
   * Get vehicle list
   * @id order ID
   */
  getVehicleList(id: number | null) {
    return this.http
      .get<IGetTruckInterface>(CONFIG.getVehicleDetailsURL + "/" + id)
      .pipe(
        map((response: IGetTruckInterface) => {
          return response;
        })
      );
  }

  /**
   * Get driver listing
   */
  getDriverList() {
    return this.http.get<IAddDriverListing>(CONFIG.getDriverDetailsURL).pipe(
      map((response: IAddDriverListing) => {
        return response;
      })
    );
  }

  /**
   * Get driver listing based on provider ID
   * @providerId provider ID number
   */
  getDriverFromProvider(driverPayload: IDriverListPayload) {
    return this.http
      .post<IAddDriverListing>(CONFIG.getDriverListContractOrder, driverPayload)
      .pipe(
        map((response: IAddDriverListing) => {
          return response;
        })
      );
  }

  /**
   * Get status filter listing
   * @typePayload status list for filter dropdown payload
   */
  getStatusFilterList(typePayload: IorderStatusPayload) {
    return this.http
      .post<StatusData>(CONFIG.getDirectOrderStatusFilterList, typePayload)
      .pipe(
        map((response: StatusData) => {
          return response;
        })
      );
  }

  /**
   * Get all notifications listing
   */
  getAllNotification() {
    return this.http.get<INotificationResponse>(CONFIG.getNotificationURL).pipe(
      map((response: INotificationResponse) => {
        return response;
      })
    );
  }

  /**
   * Get page wise notification
   */
  getPageNotification(notificaionPayload: INotificationPayload) {
    return this.http
      .post<INotificationResponse>(
        CONFIG.getAllNotificationURL,
        notificaionPayload
      )
      .pipe(
        map((response: INotificationResponse) => {
          return response;
        })
      );
  }

  /**
   * Get service truck types
   */
  getServiceTruckTypes() {
    return this.http.get<IShipmentTruckTypesData>(CONFIG.getTrucksType).pipe(
      map((response: IShipmentTruckTypesData) => {
        return response;
      })
    );
  }

  /**
   * Get service truck types
   * @priceId delete service price
   */
  deleteServicePricing(priceId: number) {
    return this.http
      .post<IShipmentTruckTypesData>(
        CONFIG.deleteSpServicePricing + priceId,
        ""
      )
      .pipe(
        map((response: IShipmentTruckTypesData) => {
          return response;
        })
      );
  }

  /**
   * Handle notification seen API
   * @notificationId notification Id
   */
  notificationSeen(notificationId: number) {
    return this.http
      .get<ISuccessResponse>(CONFIG.notificationRead + notificationId)
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }

  /**
   * Handle contact us submit form
   * @requestData fields for contact form
   */
  saveContactUs(requestData: IContactRequest) {
    const url = this.router.url;
    const saveContact = url.startsWith(RoutesRole.SERVICE_PROVIDER)
      ? CONFIG.saveProviderContactUs
      : CONFIG.saveRequesterContactUs;
    return this.http.post<ISuccessResponse>(saveContact, requestData).pipe(
      map((response: ISuccessResponse) => {
        return response;
      })
    );
  }

  /**
   * Get terms and condtion details
   */
  getTermsAndCondition() {
    const termsAndCondition =
      this.userType === UserRole.SERVICE_PROVIDER
        ? CONFIG.getProviderTermsCondition
        : CONFIG.getRequesterTermsCondition;
    return this.http
      .get<ITermsAndConditionAndPrivacyPolicy>(termsAndCondition)
      .pipe(
        map((response: ITermsAndConditionAndPrivacyPolicy) => {
          return response;
        })
      );
  }

  /**
   * Get privacy policy data
   */
  getPrivacyPolicy() {
    const privacyPolicy =
      this.userType === UserRole.SERVICE_PROVIDER
        ? CONFIG.getProviderPrivacyPolicy
        : CONFIG.getRequesterPrivacyPolicy;
    return this.http
      .get<ITermsAndConditionAndPrivacyPolicy>(privacyPolicy)
      .pipe(
        map((response: ITermsAndConditionAndPrivacyPolicy) => {
          return response;
        })
      );
  }

  /**
   * Handle delete account
   */
  deleteAccount() {
    return this.http.get<ISuccessResponse>(CONFIG.deleteAccount).pipe(
      map((response: ISuccessResponse) => {
        return response;
      })
    );
  }

  /**
   * Download invoice
   * @requestParam payload for invoice API
   */
  downloadInvoice(requestParam: IProviderOrderInfo) {
    return this.http
      .post<IDownloadInvoiceResponse>(CONFIG.downloadInvoice, requestParam)
      .pipe(
        map((response: IDownloadInvoiceResponse) => {
          return response;
        })
      );
  }

  /**
   * Get assigned provider
   * @orderType string - Based on order type get assigned provider set
   * @order_id number
   */
  getAssignProvidersList(orderType: string, order_id: number) {
    const requestObservable =
      orderType === orderTypes.MEMBERSHIP
        ? this.http.post<ProviderListApiResponse>(
            CONFIG.fetchProviderListToAssign,
            { order_id }
          )
        : this.http.get<ProviderListApiResponse>(CONFIG.getProvidersForListing);

    return requestObservable.pipe(
      map((response: ProviderListApiResponse) => response)
    );
  }

  /**
   * Approve/Reject Order
   * @orderId number
   * @payload Reason for Reject order
   */
  approveRejectOrder(orderId: number, payload: IApproveRejectOrderPayload) {
    return this.http
      .post<ISuccessResponse>(CONFIG.acceptProviderContract + orderId, payload)
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }

  /**
   * Get vehicle payload
   * @vehicleListPayload vehicle list from order
   */
  getVehicleFromOrder(vehicleListPayload: IOrderVehicle) {
    return this.http
      .post<IGetTruckInterface>(
        CONFIG.getVehicleListContractOrder,
        vehicleListPayload
      )
      .pipe(
        map((response: IGetTruckInterface) => {
          return response;
        })
      );
  }

  /**
   * Get provider list for invoice notes
   * @orderId number
   */
  getProviderListForInvoiceNotes(orderId: number) {
    return this.http
      .get<ProviderListApiResponse>(
        CONFIG.getProvidersForInvoiceNotes + orderId
      )
      .pipe(
        map((response: ProviderListApiResponse) => {
          return response;
        })
      );
  }

  /**
   * Get types of trucks
   */
  getTypesOfTrucks() {
    return this.http.get<ITrucksTypeResponse>(CONFIG.getTrucks).pipe(
      map((response: ITrucksTypeResponse) => {
        return response;
      })
    );
  }

  /**
   * Sync bayan trips
   * @tripId number
   * @orderId number
   */
  syncBayanTrip(tripId: number, orderId: number) {
    return this.http
      .get<ProviderListApiResponse>(
        CONFIG.bayanSyncCloseCancelTrip + "/" + tripId + "/" + orderId
      )
      .pipe(
        map((response: ProviderListApiResponse) => {
          return response;
        })
      );
  }

  /**
   * Close bayan trip
   * @tripId nuber
   * @orderId number
   */
  closeBayanTrip(tripId: number, orderId: number) {
    return this.http
      .get<ProviderListApiResponse>(
        CONFIG.bayanSyncCloseCancelTrip + "/close/" + tripId + "/" + orderId
      )
      .pipe(
        map((response: ProviderListApiResponse) => {
          return response;
        })
      );
  }

  /**
   * Cancel bayan trip
   * @tripId nuber
   * @orderId number
   */
  cancelBayanTrip(tripId: number, orderId: number) {
    return this.http
      .get<ProviderListApiResponse>(
        CONFIG.bayanSyncCloseCancelTrip + "/cancel/" + tripId + "/" + orderId
      )
      .pipe(
        map((response: ProviderListApiResponse) => {
          return response;
        })
      );
  }

  /**
   * Change order desctiption
   * @orderId nuber
   * @payload descriptionFiled
   */
  changeOrderDescription(orderId: number, payload: IChangeDescriptionModel) {
    return this.http
      .post<ILocationResponse>(CONFIG.updateOrderDescription + orderId, payload)
      .pipe(
        map((response: ILocationResponse) => {
          return response;
        })
      );
  }

  /**
   * Handle get provider price
   * @providerPricePayload IVehicleListApiPayload
   */
  getProviderPrice(providerPricePayload: IVehicleListApiPayload) {
    return this.http
      .post<IMutiProviderOrderPrice>(
        CONFIG.getProviderPrice,
        providerPricePayload
      )
      .pipe(
        map((response: IMutiProviderOrderPrice) => {
          return response;
        })
      );
  }

  /**
   * Get mitiple provider prices
   * @providerPricePayload IVehicleListApiPayload
   */
  getProviderTruckPrice(providerPricePayload: IProviderPricePayload) {
    return this.http
      .post<IMutiProviderOrderPrice>(
        CONFIG.getProviderTruckPrice,
        providerPricePayload
      )
      .pipe(
        map((response: IMutiProviderOrderPrice) => {
          return response;
        })
      );
  }

  /**
   * Sync vehicles
   * @returns boolean
   */
  syncVehicles(truckId: number) {
    const syncVehicleURL =
      this.userType === UserRole.SERVICE_PROVIDER
        ? CONFIG.waslProviderSyncVehicle
        : CONFIG.waslAdminSyncVehicle;
    return this.http.get<IPlateTypesResponse>(syncVehicleURL + truckId).pipe(
      map((response: IPlateTypesResponse) => {
        return response;
      })
    );
  }

  /**
   * Sync vehicles
   */
  syncDrivers(driverId: number) {
    const syncDriverURL =
      this.userType === UserRole.SERVICE_PROVIDER
        ? CONFIG.waslProviderSyncDriver
        : CONFIG.waslAdminSyncDriver;
    return this.http.get<ISuccessResponse>(syncDriverURL + driverId).pipe(
      map((response: ISuccessResponse) => {
        return response;
      })
    );
  }

  /**
   * Get Browser Token
   */
  getBrowserToken() {
    return this.chatMessage.requestToken.pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error); // rethrow the error
      })
    );
  }

  /**
   * Get existing Token
   */
  getBrowserLoginToken() {
    return this.chatMessage.getToken.pipe(
      map((response) => {
        return response as string;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  /**
   * Get Browser Token
   */
  updateDeviceToken(deviceTokenPayload: any, userId: string) {
    return this.http
      .post<IMutiProviderOrderPrice>(
        CONFIG.updateDeviceToken + userId,
        deviceTokenPayload
      )
      .pipe(
        map((response: IMutiProviderOrderPrice) => {
          return response;
        })
      );
  }

  /**
   * Seen all APIS
   */
  notificationSeenAll(userType: string) {
    let config;
    switch (userType) {
      case UserRole.SERVICE_PROVIDER:
        config = CONFIG.seenAllProviderAPI;
        break;
      case UserRole.SERVICE_REQUESTER:
        config = CONFIG.seenAllRequesterAPI;
        break;
      case UserRole.ADMIN_USER:
        config = CONFIG.seenAllAdminAPI;
        break;
    }

    return this.http.get<ISuccessResponse>(config as string).pipe(
      map((response: ISuccessResponse) => {
        return response;
      })
    );
  }

  /**
   * Handle get trip details based on order ID
   * @orderId Number - Order ID
   * @author prahsank.shah@brainvire.com
   */
  getProvidersTripDetails(orderId: number) {
    return this.http
      .get<{ data: IBayanTrips }>(CONFIG.getProviderTripDetailsById + orderId)
      .pipe(
        map((response: { data: IBayanTrips }) => {
          return response;
        })
      );
  }

  /**
   * Handle get trip details based on order ID
   * @orderId Number - Order ID
   * @author prahsank.shah@brainvire.com
   */
  getRequesterTripDetails(orderId: number) {
    return this.http
      .get<{ data: IBayanTrips }>(CONFIG.getRequesterTripDetailsById + orderId)
      .pipe(
        map((response: { data: IBayanTrips }) => {
          return response;
        })
      );
  }
}
