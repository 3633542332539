import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { CONFIG } from "@config/app-config";
import {
  IPasswordType,
  IProfileResponse,
  IProviderUserData,
  IUserModel,
} from "@model/profile";
import { ISuccessResponse } from "@model/successResponse";
import { UserRole } from "@utils/enum-const";
import { CommonService } from "@services/common.service";

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  userType = "";
  constructor(private http: HttpClient, private commonService: CommonService) {
    this.userType = this.commonService.getLoginUserType();
  }

  /**
   * Get login user profile details
   */
  getProfileDetails() {
    return this.http.get<IProfileResponse>(CONFIG.getProviderProfile).pipe(
      map((data: IProfileResponse) => {
        return data;
      })
    );
  }

  /**
   * Change password from profile section
   */
  changePassword(userData: IPasswordType) {
    const changePasswordURL =
      this.userType === UserRole.SERVICE_PROVIDER
        ? CONFIG.changeProviderPasswordURL
        : CONFIG.changeRequesterPasswordURL;
    return this.http.post<ISuccessResponse>(changePasswordURL, userData).pipe(
      map((response: ISuccessResponse) => {
        return response;
      })
    );
  }

  /**
   * Update profile details
   */
  updateProfileDetails(userData: IProviderUserData) {
    const updateProfile =
      this.userType === UserRole.SERVICE_PROVIDER
        ? CONFIG.providerUpdateProfileURL
        : CONFIG.reuqesterUpdateProfileURL;
    return this.http.post<ISuccessResponse>(updateProfile, userData).pipe(
      map((response: ISuccessResponse) => {
        return response;
      })
    );
  }

  /**
   * Update admin profile
   */
  updateAdminProfile(adminProfileData: IUserModel) {
    return this.http
      .post<ISuccessResponse>(CONFIG.updateAdminProfile, adminProfileData)
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }
}
