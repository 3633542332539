import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { CONFIG } from "@config/app-config";
import {
  IChangeProviderPayload,
  IRejectOrder,
  IStatusApiResponse,
} from "@model/directOrdersDetails";
import { IPaymentPayload } from "@model/payment";
import { ISuccessResponse } from "@model/successResponse";
import { UserRole } from "@utils/enum-const";
import { BaseComponent } from "@components/base.component";
import { CommonService } from "@services/common.service";
import { PaymentInitiate } from "@model/adminDirectOrders";
import { IorderStatusPayload } from "@model/common";

@Injectable({
  providedIn: "root",
})
export class OrdersCommonService extends BaseComponent {
  userType = "";
  constructor(private http: HttpClient, private commonService: CommonService) {
    super();
    this.userType = this.commonService.getLoginUserType();
  }

  /**
   * Get order status
   */
  getOrderStatus(orderPayload: IorderStatusPayload) {
    let getOrderStatusURL: string;

    if (this.userType === UserRole.SUPER_ADMIN_USER) {
      getOrderStatusURL = CONFIG.getAdminOrderStatusURL;
    } else if (this.userType === UserRole.SERVICE_PROVIDER) {
      getOrderStatusURL = CONFIG.getProviderOrderStatusURL;
    } else {
      getOrderStatusURL = CONFIG.getRequesterOrderStatusURL;
    }

    return this.http
      .post<IStatusApiResponse>(getOrderStatusURL, orderPayload)
      .pipe(
        map((response: IStatusApiResponse) => {
          return response;
        })
      );
  }

  /**
   * Create Trip Container
   */
  createTripContainer(
    tripId: string,
    payload: { container_number: string; seal_number: string }
  ) {
    let createContainerPrefixUrl: string;

    if (this.userType === UserRole.SUPER_ADMIN_USER) {
      createContainerPrefixUrl = CONFIG.createContainerAdminPrefix;
    } else {
      createContainerPrefixUrl = CONFIG.createContainerProviderPrefix;
    }

    return this.http
      .post<IStatusApiResponse>(
        createContainerPrefixUrl + tripId + "/container/store",
        payload
      )
      .pipe(
        map((response: IStatusApiResponse) => {
          return response;
        })
      );
  }

  /**
   * Edit Trip Container
   */
  editTripContainer(
    tripId: string,
    containerId?: number,
    payload?: { container_number: string; seal_number: string }
  ) {
    let editContainerPrefixUrl: string;

    if (this.userType === UserRole.SUPER_ADMIN_USER) {
      editContainerPrefixUrl = CONFIG.createContainerAdminPrefix;
    } else {
      editContainerPrefixUrl = CONFIG.createContainerProviderPrefix;
    }

    return this.http
      .post<IStatusApiResponse>(
        editContainerPrefixUrl + tripId + "/container/update/" + containerId,
        payload
      )
      .pipe(
        map((response: IStatusApiResponse) => {
          return response;
        })
      );
  }

  /**
   * Delete Trip Container
   */
  deleteTripContainer(tripId: string, containerId?: number) {
    let editContainerPrefixUrl: string;

    if (this.userType === UserRole.SUPER_ADMIN_USER) {
      editContainerPrefixUrl = CONFIG.createContainerAdminPrefix;
    } else {
      editContainerPrefixUrl = CONFIG.createContainerProviderPrefix;
    }

    return this.http
      .get<IStatusApiResponse>(
        editContainerPrefixUrl + tripId + "/container/delete/" + containerId
      )
      .pipe(
        map((response: IStatusApiResponse) => {
          return response;
        })
      );
  }

  /**
   * Get order trip status
   */
  getOrderTripStatusList() {
    return this.http
      .get<IStatusApiResponse>(CONFIG.getOrderTripStatusListURL)
      .pipe(
        map((response: IStatusApiResponse) => {
          return response;
        })
      );
  }

  /**
   * Handle order change status
   */
  changeOrderStatus(status: string, id: number) {
    return this.http
      .post<ISuccessResponse>(CONFIG.changeOrderStatusURL + id, {
        status: status,
      })
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }

  /**
   * Handle cancel order
   */
  cancelOrder(id: number, orderPayload: IRejectOrder) {
    let cancelOrderURL: string;

    if (this.userType === UserRole.SUPER_ADMIN_USER) {
      cancelOrderURL = CONFIG.cancelOrderURL + id;
    } else if (this.userType === UserRole.SERVICE_PROVIDER) {
      cancelOrderURL = CONFIG.cancelSpOrderURL + id;
    } else {
      cancelOrderURL = CONFIG.cancelSrOrderURL + id + "/cancel";
    }

    const getOrderStatusURL = cancelOrderURL;

    return this.http
      .post<ISuccessResponse>(getOrderStatusURL, orderPayload)
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }

  /**
   * Handle order trip status
   */
  changeOrderTripStatus(status: string, id: number) {
    const tripStatusChangeUrl = this.isAdminRoute
      ? CONFIG.changeContractOrderTripStatusURL
      : CONFIG.changeOrderTripStatusURL;
    return this.http
      .post<ISuccessResponse>(tripStatusChangeUrl + id, { status: status })
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }

  /**
   * Handle set vehicle count
   */
  setVehicleCount(vehicleCount: number, orderId: number) {
    return this.http
      .post<ISuccessResponse>(CONFIG.setVehcileCountURL + orderId, {
        vehicle_count: vehicleCount,
      })
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }

  /**
   * Handle accept order
   */
  acceptOrder(orderId: number) {
    return this.http
      .get<ISuccessResponse>(CONFIG.acceptOrderURL + orderId)
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }

  /**
   * Handle reject order with comments
   */
  rejectOrder(orderId: number, orderComments: IRejectOrder) {
    return this.http
      .post<ISuccessResponse>(CONFIG.rejectOrderURL + orderId, orderComments)
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }

  /**
   * Order change provider
   */
  changePorvider(providerData: IChangeProviderPayload, orderId: number) {
    return this.http
      .post<ISuccessResponse>(CONFIG.changeProviderURL + orderId, providerData)
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }

  /**
   * Handle order payment
   */
  shipmentPayment(orderId: number, paymentDetails: IPaymentPayload) {
    return this.http
      .post<ISuccessResponse>(
        CONFIG.shipmentPaymentURL + orderId + "/checkout",
        paymentDetails
      )
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }

  /**
   * Handle payment initate
   */
  paymentInitiate(orderId: string) {
    const params = {
      order_id: orderId,
    };
    return this.http
      .post<PaymentInitiate>(CONFIG.paymentInitiateURL + orderId, params)
      .pipe(
        map((response: PaymentInitiate) => {
          return response;
        })
      );
  }

  /**
   * On successful payment checkout API call
   */
  paymentCheckout(
    payment_uuid: string | null,
    TranId: string,
    ResponseCode: string,
    responseHash: string,
    json?: string,
    token?: string | null,
    lan?: string | null
  ) {
    const params = {
      payment_uuid: payment_uuid,
      TranId: TranId,
      ResponseCode: ResponseCode,
      responseHash: responseHash,
      json: json,
    };

    const headers = new HttpHeaders({
      Authorization: "Bearer " + token,
      Lang: lan ?? "",
    });
    return this.http
      .post<ISuccessResponse>(CONFIG.paymentCheckoutURL, params, { headers })
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }

  /**
   * Handle payment case for web view
   */
  paymentWebView(orderId: string, token: string, lan: string) {
    const params = {
      order_id: orderId,
    };

    const headers = new HttpHeaders({
      Authorization: "Bearer " + token,
      Lang: lan,
    });
    return this.http
      .post<PaymentInitiate>(CONFIG.paymentInitiateURL, params, { headers })
      .pipe(
        map((response: PaymentInitiate) => {
          return response;
        })
      );
  }

  /**
   * Speical order change trip status
   */
  specialOrderChangeOrderTripStatus(status: string, id: number) {
    return this.http
      .post<ISuccessResponse>(CONFIG.specialOrderChangeStatus + id, {
        status: status,
      })
      .pipe(
        map((response: ISuccessResponse) => {
          return response;
        })
      );
  }
}
