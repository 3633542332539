// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --prod` or `ng build --configuration=production` then `environment.prod.ts` will be used instead.
// `ng build --configuration=staging` then `environment.stage.ts` will be used instead.
// The list of which env maps to which file can be found in `angular.json`.

export const environment = {
  production: false,
  apiEndpoint: "https://apistaging.shahenexpress.com",
  apiAdminVersion: "/admin/api/v1",
  apiFrontVersion: "/front/api/v1",
  googleMapKey: process.env["GOOGLE_MAPS_API_KEY_DEMO"],

  dashboardReloadDuration: 60000,
  firebase: {
    apiKey: process.env["STAGE_APP_KEY"],
    authDomain: process.env["STAGE_AUTH_DOMAIN"],
    projectId: process.env["STAGE_PROJECT_ID"],
    storageBucket: process.env["STAGE_STORAGE_BUCKET"],
    messagingSenderId: process.env["STAGE_MESSAGING_ID"],
    appId: process.env["STAGE_APP_ID"],
    measurementId: process.env["STAGE_MEASUREMENT_ID"],
  },
};
