import { Component, OnInit, ViewContainerRef } from "@angular/core";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { CONFIGCONSTANTS, RoleBasedLanguage } from "@config/app-constants";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "@services/language.service";
import { MultilingualService } from "@services/multilingual.service";
import { environment } from "environments/environment";
import { Subscription } from "rxjs";
import { filter, first, map, mergeMap } from "rxjs/operators";
import { CONFIG } from "./config/app-config";
import {
  AccountType,
  languageStorage,
  languageTypes,
  RoutesRole,
  UserRole,
} from "./utils/enum-const";
import { ChatMessageService } from "./_services/chatMessage.service";
import { CommonApiService } from "./_services/common-api.service";
import { CommonService } from "./_services/common.service";
import { EncrDecrService } from "./_services/encr-decr.service";

@Component({
  // tslint:disable-next-line
  selector: "body",
  template: ` <router-outlet></router-outlet> `,
  styles: [``],
})
export class AppComponent implements OnInit {
  appTitle: string = CONFIGCONSTANTS.siteName;
  fullTitle!: string;
  Language = localStorage.getItem("lan") ?? "en";
  subscription: Subscription;
  languageOption: boolean = CONFIGCONSTANTS.languageOption;
  currentUser = "";
  loginUser!: { user: { id: string } };

  constructor(
    private router: Router,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    public viewContainerRef: ViewContainerRef,
    private languageSwitcher: LanguageService,
    protected multilingualService: MultilingualService,
    private firebaseService: AngularFireMessaging,
    private ManageCommonService: CommonService,
    private ManageCommonApiService: CommonApiService,
    private EncrDecr: EncrDecrService,
    private chatCountService: ChatMessageService
  ) {
    const languages = RoleBasedLanguage;
    this.multilingualService.saveLanguage(languages);
    this.subscription = this.languageSwitcher.getLanguage().subscribe((lan) => {
      this.translate.setDefaultLang(lan);
      this.translate.use(lan);
    });
  }

  ngOnInit() {
    this.setChatCount();
    this.requestNotificationPermission();
    this.loadGoogleMapsScript();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUser = this.getUserType(this.router.url);
        const userKey = this.currentUser + "_lan";
        const language =
          userKey === languageStorage.ADMIN_LAN
            ? languageTypes.DEFAULT_ADMIN
            : this.languageSwitcher.getUserDefaultLanguage(userKey);

        this.languageSwitcher.setUserTypeLanguage(userKey, language);
      }
    });
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    // set dynamic html title
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route = this.activatedRoute.firstChild;
          let child = route;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
              route = child;
            } else {
              child = null;
            }
          }
          return route;
        }),
        mergeMap((route: any) => route.data)
      )
      .subscribe((data: any) => {
        this.toggleMenu();
        if (data["title"]) {
          this.translate.get(data["title"]).subscribe((translateResp) => {
            this.fullTitle = this.appTitle + " | " + translateResp;
            this.titleService.setTitle(this.fullTitle);
          });
        } else {
          this.fullTitle = this.appTitle;
          this.titleService.setTitle(this.fullTitle);
        }
      });
  }

  toggleMenu() {
    const element = document.getElementsByTagName("body");
    element[0]?.classList.remove("sidebar-show");
  }

  getUserType(url: string) {
    if (url.startsWith(RoutesRole.SERVICE_PROVIDER)) {
      return UserRole.SERVICE_PROVIDER;
    }
    if (url.startsWith(RoutesRole.SERVICE_REQUESTER)) {
      return UserRole.SERVICE_REQUESTER;
    } else {
      return UserRole.ADMIN_USER;
    }
  }

  /**
   * Load google map
   */
  private loadGoogleMapsScript(): void {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapKey}&libraries=drawing,places,geometry&callback=googleMapsLoaded`;
    script.defer = true;
    script.async = true;
    script.integrity = "";
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);
  }

  /**
   * Requester notification permission
   */
  requestNotificationPermission() {
    if (!("Notification" in window)) {
      console.error("This browser does not support notifications.");
    } else if (Notification.permission === "granted") {
      this.subscribeToNotifications();
    } else if (Notification.permission === "default") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          this.subscribeToNotifications();
        } else {
          console.log("User denied notification permission.");
        }
      });
    } else if (Notification.permission === "denied") {
      console.log(
        "Notification permission was previously denied. Cannot request permission again."
      );
    }
  }

  /**
   * Subscribe notifications
   */
  subscribeToNotifications() {
    this.firebaseService.requestToken.subscribe(
      (token: string | null) => {
        if (token) {
          const loginUser = this.ManageCommonService.currentActiveUser();
          const getUserToken = localStorage.getItem(
            this.currentUser + "_browser_token"
          );
          if (loginUser && token !== getUserToken) {
            this.loginUser = JSON.parse(
              this.EncrDecr.get(CONFIG.EncrDecrKey as string, loginUser)
            );
            this.handleUpdateToken(token, this.loginUser?.user?.id);
          }
          localStorage.setItem(this.currentUser + "_browser_token", token);
        } else {
          localStorage.removeItem(this.currentUser + "_browser_token");
        }
      },
      (err) => {
        console.error("Could not subscribe to notifications", err);
      }
    );
  }

  /**
   * Handle update token
   */
  handleUpdateToken(token: string, userId: string) {
    const payload = {
      device_token: token,
      platform: AccountType.WEB,
    };
    this.ManageCommonApiService.updateDeviceToken(payload, userId)
      .pipe(first())
      .subscribe(
        () => {
          return;
        },
        () => {
          return;
        }
      );
  }

  /**
   * Hanlde admin chat count
   */
  setChatCount() {
    this.firebaseService.messages.subscribe(
      (message: any) => {
        const notificaitonData = message?.data;
        this.chatCountService.updateUnreadMessageCount(
          notificaitonData?.unread_message_count
        );
      },
      (err) => {
        console.error("Could not subscribe to notifications", err);
      }
    );
  }
}
